/* eslint-disable eqeqeq */
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  NavLink,
  Card,
  CardTitle,
  InputGroup,
  CustomInput,
} from "reactstrap";
import { AxiosResponse } from "axios";
import { Link, Prompt, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import DataTable, { SortOrder, TableColumn } from "react-data-table-component";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";

import { useGlobalState } from "../../Context";
import { ErrorAlert, LoadingSection, MaterialDesignSwitch } from "../../Components/Display";
import { SkinnedButton } from "../../Components/Form/Buttons";
import { CustomCheckbox, DatePicker, TextAreaInput, TextInput } from "../../Components/Form/Inputs";
import { Features } from "../../Components/Display/Features";
import ValidationErrors from "../Errors/ValidationErrors";
import SessionExpiredError from "../Errors/SessionExpiredError";
import { ProductDetailVM, ProductFeatureVM, ProductVM, CampaignVM, CampaignListVM } from "./Models";
import { PriceVM } from "../Registration/Models";
import { Helper, adminGenericError, basePathName } from "../../Utilities/HelperData";
import { useCustomForm } from "../../Utilities/UseCustomForm";
import { useApiWorker } from "../../Utilities/CommonHooks";
import { ErrorHelper } from "../../Utilities/ErrorHelper";
import { HistoryHelper } from "../../Utilities/HistoryHelper";
import { PaginationOptions, SortOptions } from "../ProviderSearch";
import { constants } from "../../Utilities/Constants";

import "./ProductEdit.scss";

const validationSchema = Yup.object({
  stripeProductId: Yup.string()
    .max(
      1000,
      "The length of the Stripe Product ID must be 1000 characters or fewer"
    )
    .required(""),
  stripePricingId: Yup.string()
    .max(
      1000,
      "The length of the Stripe Pricing ID must be 1000 characters or fewer"
    )
    .required(""),
  name: Yup.string()
    .max(
      1000,
      "The length of the Subscription Name must be 1000 characters or fewer"
    )
    .required(""),
  tag: Yup.string()
    .nullable()
    .max(45, "The length of the Tag must be 45 characters or fewer"),
  firstLine: Yup.string()
    .nullable()
    .max(
      255,
      "The length of the Subscription First Line must be 255 characters or fewer"
    ),
  secondLine: Yup.string()
    .nullable()
    .max(
      255,
      "The length of the Subscription Second Line must be 255 characters or fewer"
    ),
  totalAfterTrialLabel: Yup.string()
    .nullable()
    .max(
      255,
      "The length of the Additional Pricing Information must be 255 characters or fewer"
    ),
  totalAfterTrialValue: Yup.string()
    .nullable()
    .max(
      255,
      "The length of the Additional Pricing Information must be 255 characters or fewer"
    ),
  upgradeMarketingUrl: Yup.string()
    .nullable()
    .max(
      255,
      "The length of the Upgrade Marketing Url must be 250 characters or fewer"
    ),
  upgradeMarketingText: Yup.string()
    .nullable()
    .max(
      1000,
      "The length of the Upgrade Marketing Text must be 1000 characters or fewer"
    ),
  features: Yup.array()
    .max(10, "Features allows only up to 10 items")
    .of(
      Yup.object({
        name: Yup.string()
          .nullable()
          .max(255, "The length of Name must be 255 characters or fewer")
          .required(""),
      })
    ),
  freeTrialDays: Yup.number().when("isFreeTrial", {
    is: true,
    then: Yup.number()
      .typeError("Free Trial Days must be a number")
      .min(1, "Free Trial Days must be a number greater than 0")
      .max(730, "Free Trial Days must be less than 730")
      .required(""),
  }),
  stripeSecondaryPricingId: Yup.string()
    .nullable()
    .when("enableSecondaryPricing", {
      is: true,
      then: Yup.string()
        .typeError("Stripe Secondary Pricing ID must not be empty")
        .max(
          1000,
          "The length of the Stripe Secondary Pricing ID must be 1000 characters or fewer"
        )
        .required(""),
    }),
});

export const ProductEdit = () => {

  const defaultPageSize = 10;

  const { currentUser, enableSecondaryPricing } = useGlobalState();
  const { id } = useParams<{ id: string }>();
  const apiWorker = useApiWorker();
  const history = useHistory();
  const featuresRef = useRef<any>({});
  const scrollElementRef = useRef<null | HTMLDivElement>(null);
  const pageElementRef = useRef<null | HTMLDivElement>(null);

  const initialValues = new ProductVM();

  const [isGridLoading, setIsGridLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialPrice, setInitialPrice] = useState(" ");
  const [secondaryPrice, setSecondaryPrice] = useState(" ");
  const [serverErrors, setServerErrors] = useState<any[] | null>(null);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const [approveModal, setApproveModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [initialIsFreeTrial, setInitialIsFreeTrial] = useState(false);
  const [initialFreeTrialDays, setInitialFreeTrialDays] = useState("");
  const [initialProductId, setInitialProductId] = useState("");
  const [initialPricingId, setInitialPricingId] = useState("");
  const [initialSecondaryPricingId, setInitialSecondaryPricingId] = useState("");
  const [errorMessageIsVisible, setErrorMessageIsVisible] = useState<boolean>(false);
  const [campaignData, setCampaignData] = useState<CampaignVM[]>([]);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [switchStatusModal, setSwitchStatusModal] = useState(false);
  const [switchDeleteModal, setSwitchDeleteModal] = useState(false);
  const [inputsChanged, setInputsChanged] = useState(false);

  const [totalRows, setTotalRows] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [page, setPage] = useState<number>(1);
  const [sortOptions, setSortOptions] = useState<SortOptions>();
  
  const toggleApproveModal = () => setApproveModal(!approveModal);
  const toggleSwitchStatusModal = () => setSwitchStatusModal(!switchStatusModal);
  const toggleSwitchDeleteModal = () => setSwitchDeleteModal(!switchDeleteModal);

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      return "Leave site? Changes you made may not be saved.";
    }
  }, []);

  useEffect(() => {
    setInputsChanged(inputsChanged);
  }, [inputsChanged]);

  useEffect(() => {
    if (currentUser && id) {
      getProduct(id);
    } else {
      setIsLoading(false);
    }
    if (currentUser) searchCampaigns();
  }, [currentUser, id]);

  const scrollToTop = () => {
    if (scrollElementRef && scrollElementRef.current) {
      scrollElementRef.current.scrollIntoView({ behavior: "auto" });
    }
  };

  const getProduct = async (id: string) => {
    let success = true;
    const result = await apiWorker
      .get<ProductDetailVM>(`${basePathName}/api/product/${id}`)
      .catch((err: any) => {
        setIsLoading(false);
        if (ErrorHelper.handleServerErrors(err, setServerErrors, setSessionExpired, scrollToTop))
          success = false;
      });

    if (!success) return;

    if (result && result.data) {
      if (result.data.stripeSecondaryPricingId) {
        result.data.enableSecondaryPricing = true;
      }
      setValues(result.data);
      setServerErrors(null);
      setInitialProductId(result.data.stripeProductId || "");
      setInitialPricingId(result.data.stripePricingId || "");
      setInitialIsFreeTrial(result.data.isFreeTrial || false);
      setInitialFreeTrialDays(result?.data?.freeTrialDays?.toString() || "");
      setInitialSecondaryPricingId(result.data.stripeSecondaryPricingId || "");
      setSwitchChecked(!result.data.isEnabled || false);
    }
    setIsLoading(false);
    if (result && result.data && result.data.stripePricingId) {
      await getInitialPriceValue(result.data.stripePricingId, result.data);
    }
    if (result && result.data && result.data.stripeSecondaryPricingId) {
      await getSecondaryPriceValue(result.data.stripeSecondaryPricingId);
    }
  };

  const getInitialPriceValue = async (
    id: string,
    product?: ProductDetailVM
  ) => {
    const price = await apiWorker.get<PriceVM>(
      `${basePathName}/api/product/getprices?ids=${id}`
    );
    if (
      price &&
      price.data &&
      Array.isArray(price.data) &&
      price.data.length > 0
    ) {
      setInitialPrice(
        `Price of $${Helper.formatMoney(
          (price.data[0].price || 0) / 100,
          2,
          ".",
          ","
        )}/${price.data[0].billingFrequency}`
      );

      // update preview total due today
      if (typeof product !== "undefined") {
        setValues({
          ...product,
          price: price.data[0].price,
          billingFrequency: price.data[0].billingFrequency,
        });
      } else {
        setValues({
          ...values,
          price: price.data[0].price,
          billingFrequency: price.data[0].billingFrequency,
        });
      }
    } else {
      setInitialPrice("");
    }
  };

  const getSecondaryPriceValue = async (id: string) => {
    const price = await apiWorker.get<PriceVM>(
      `${basePathName}/api/product/getprices?ids=${id}`
    );
    if (
      price &&
      price.data &&
      Array.isArray(price.data) &&
      price.data.length > 0
    ) {
      setSecondaryPrice(
        `After that, $${Helper.formatMoney(
          (price.data[0].price || 0) / 100,
          2,
          ".",
          ","
        )}/${price.data[0].billingFrequency}`
      );
    } else {
      setSecondaryPrice("");
    }
  };

  const handleChangeInFeatures = (
    event: ChangeEvent<HTMLInputElement>,
    items: ProductFeatureVM[] | undefined
  ) => {
    items?.map((x, index) => {
      x.order = index;
      return x;
    });
    const newElements = { ...values, features: items };
    setValues(newElements);
  };

  const confirmSave = async () => {
    setIsLoading(true);
    toast.dismiss();

    let positions: Array<any> = [];
    if (featuresRef.current.children) {
      for (
        let i = 0;
        i < featuresRef.current.children[0].children.length;
        i++
      ) {
        positions.push({
          id: featuresRef.current.children[0].children[i].dataset.elementid,
          order: i,
        });
      }
      values.features?.map((x: any) => {
        let item = positions.filter((y) => y.id == x.id)[0];
        x.order = parseInt(item.order);
        return x;
      });
    }

    setInitialProductId(values.stripeProductId);
    setInitialPricingId(values.stripePricingId);
    setInitialSecondaryPricingId(values.stripeSecondaryPricingId);
    setInitialIsFreeTrial(values.isFreeTrial);
    setInitialFreeTrialDays(values.freeTrialDays);
    if (!values.enableSecondaryPricing) {
      values.stripeSecondaryPricingId = "";
    }
    let result: AxiosResponse<ProductVM> | void;
    let success = true;

    if (id) {
      result = await apiWorker
        .put<ProductVM>(`${basePathName}/api/product`, values, {})
        .catch((errors: any) => {
          success = handleCampaingSaveErrors(errors);
        });
    } else {
      result = await apiWorker
        .post<ProductVM>(`${basePathName}/api/product`, values, {})
        .catch((errors: any) => {
          success = handleCampaingSaveErrors(errors);
        });
    }
    if (approveModal) {
      toggleApproveModal();
    }

    if (!success) return;

    if (result) {
      setServerErrors(null);
      toast.success("The product has been saved");
      setInputsChanged(false);
      setIsLoading(false);
      history.push(`/product/${result.data.id}`);
    } else {
      setIsLoading(false);
    }
  };

  const handleCampaingSaveErrors = (errors: any): boolean => {
    setIsLoading(false);
    if (ErrorHelper.handleServerErrors(errors, setServerErrors, setSessionExpired, scrollToTop)) {
      return false;
    }

    if (
      errors &&
      errors.isAxiosError === undefined &&
      Object.keys(errors).length &&
      Object.keys(errors).filter((x) => x !== "").length
    ) {
      setErrorMessageIsVisible(true);
    }

    errors = Helper.cleanUpErrorMessages(errors);
    setFormErrors(errors);

    let err: any = Helper.convertNonFieldErrorsToArray(errors);
    if (err && Array.isArray(err) && err.length) {
      setServerErrors(err);
    } else {
      setServerErrors([]);
    }
    scrollToTop();
    return true;
  };

  const {
    values,
    formErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    setFormErrors,
  } = useCustomForm({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      const noErrors = Object.keys(values.errors || {}).length === 0;
      if (!noErrors) {
        setErrorMessageIsVisible(true);
        scrollToTop();
        toast.dismiss();
        setServerErrors(null);
        return;
      }
      setErrorMessageIsVisible(false);
      if (
        (initialProductId || "") != (values.values.stripeProductId || "") ||
        (initialPricingId || "") != (values.values.stripePricingId || "") ||
        (initialIsFreeTrial || "") != (values.values.isFreeTrial || false) ||
        (initialFreeTrialDays || "") !=
          (values.values.freeTrialDays?.toString() || "") ||
        (initialSecondaryPricingId || "") !=
          (values.values.stripeSecondaryPricingId || "")
      ) {
        const info = await apiWorker.get<PriceVM[]>(
          `${basePathName}/api/product/getprices?ids=${
            values.values.stripePricingId
          }${
            values.values.stripeSecondaryPricingId
              ? "&ids=" + values.values.stripeSecondaryPricingId
              : ""
          }`
        );

        if (
          info &&
          info.data &&
          Array.isArray(info.data) &&
          info.data.length > 0
        ) {
          // Check if I have the initial price or the secondary price. If I don't have all necessary prices, let
          // the flow continue without the modal. If I have both prices or just the one I need, prepare the text of the popup
          if (
            values.values.stripePricingId &&
            values.values.stripeSecondaryPricingId &&
            info.data.length === 2
          ) {
            const text = `A starting price of $${Helper.formatMoney(
              (info.data[0].price || 0) / 100,
              2,
              ".",
              ","
            )} for 1 ${
              info.data[0].billingFrequency
            } and then $${Helper.formatMoney(
              (info.data[1].price || 0) / 100,
              2,
              ".",
              ","
            )}/${info.data[1].billingFrequency} after that.`;
            setModalText(text);
            toast.dismiss();
            toggleApproveModal();
          } else if (values.values.stripePricingId && info.data.length == 1) {
            const text = `${
              values.values.isFreeTrial
                ? `Free trial for ${values.values.freeTrialDays} days. Price after free trial is `
                : "A price of "
            } $${Helper.formatMoney(
              (info.data[0].price || 0) / 100,
              2,
              ".",
              ","
            )}/${info.data[0].billingFrequency}.`;
            setModalText(text);
            toast.dismiss();
            toggleApproveModal();
          } else {
            confirmSave();
          }
        } else {
          confirmSave();
        }
      } else {
        confirmSave();
      }
    },
    formChanged: () => {
      setInputsChanged(true);
    },
  });

  const goBack = (event: any) => {
    HistoryHelper.goBack(event, history, "/campaign-product");
  };

  const searchCampaigns = async (paginationOptions?: PaginationOptions, sortOptions?: SortOptions) => {
    setIsGridLoading(true);
    const data = {
      searchText: values.searchText,
      isActive: values.isActive,
      isInactive: values.isInactive,
      campaignStartDate: values.campaignStartDate,
      campaignEndDate: values.campaignEndDate,
      pageSize: paginationOptions?.paginationPageSize || defaultPageSize,
      page: paginationOptions?.paginationPage || page,
      sortOrder: sortOptions?.sortOrder || 'asc',
      sortField: sortOptions?.field || 'name'
    };
    let success = true;
    const result = await apiWorker
      .get<CampaignListVM>(`${basePathName}/api/campaign/list`, {
        params: {
          ...data,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((err) => {
        setIsGridLoading(false);
        if (ErrorHelper.handleServerErrors(err, setServerErrors, setSessionExpired, scrollToTop)) {
          success = false;
          return;
        }
        setServerErrors([adminGenericError]);
      });

    if (!success) return;

    if (result) {
      setServerErrors([]);
      setIsGridLoading(false);
      setCampaignData(result.data.data || []);
      setTotalRows(result.data.total!);
    }
  };

  const assignCampaignToProduct = (event: React.MouseEvent, record: CampaignVM) => {
    event.preventDefault();

    if (values.campaigns && values.campaigns.find((x: CampaignVM) => x.id === record.id)) {
      toast.warn('Campaign already associated to this product.');
      return;
    }

    const item = new CampaignVM();
    item.id = record.id;
    item.name = record.name;
    item.isEnabled = record.isEnabled;
    let elements = [...(values.campaigns || []), item];
    setValues({ ...values, campaigns: elements });
    setInputsChanged(true);
  };

  const handleSwitchChange = () => {
    document.querySelector(".main__content")?.scrollTo(0, 0);
    toast.dismiss();
    toggleSwitchStatusModal();
  };

  const deleteProduct = async (event: React.MouseEvent) => {
    event.preventDefault();
    setIsLoading(true);
    toast.dismiss();
    let success = true;
    const result = await apiWorker
      .put<boolean>(
        `${basePathName}/api/product/delete`,
        { SelectedRows: [values.id] },
        {}
      )
      .catch((err: any) => {
        setIsLoading(false);
        if (ErrorHelper.handleServerErrors(err, setServerErrors, setSessionExpired, scrollToTop)) {
          success = false;
          return;
        }

        err = Helper.cleanUpErrorMessages(err);
        setFormErrors(err);
        if (err && err[""]) {
          setServerErrors(err[""]);
        }
      });

    if (!success) return;

    if (result) {
      history.push(`/campaign-product`);
      toast.success("Product deleted");
    }
    setIsLoading(false);
  };

  const postSwitchChange = async (event: React.MouseEvent) => {
    event.preventDefault();
    setIsLoading(true);
    toast.dismiss();
    toggleSwitchStatusModal();
    let success = true;
    const result = await apiWorker
      .put<boolean>(
        `${basePathName}/api/product/${
          switchChecked ? "reactivate" : "deactivate"
        }`,
        { SelectedRows: [values.id] },
        {}
      )
      .catch((err: any) => {
        setIsLoading(false);
        if (ErrorHelper.handleServerErrors(err, setServerErrors, setSessionExpired, scrollToTop)) {
          success = false;
          return;
        }

        err = Helper.cleanUpErrorMessages(err);
        setFormErrors(err);
        if (err && err[""]) {
          setServerErrors(err[""]);
        }
        toggleSwitchStatusModal();
      });

    if (!success) return;

    if (result) {
      setServerErrors(null);
      toast.success(
        switchChecked ? "Product reactivated" : "Product deactivated"
      );
      if (!switchChecked) {
        setValues({ ...values, campaigns: [], isEnabled: switchChecked });
      } else {
        setValues({ ...values, isEnabled: switchChecked });
      }
      setSwitchChecked(!switchChecked);
      success = true;
      if (!success) return;
    }
    setIsLoading(false);
  };

  const columns: TableColumn<CampaignVM>[] = [
    {
      name: <>Campaign</>,
      selector: row => row.name!,
      cell: row => (
        <Link 
          className="font=weight-normal"
          to={(row.isDefault ? "/default-campaign/" : "/campaign/") + row.id}
          target="_blank">{row.name}</Link>),
      sortable: true,
      width: '25%'
    },
    {
      name: <>Campaign Status</>,
      selector: row => row.status!,
      sortable: true,
      width: '15%'
    },
    {
      name: <>Campaign Start</>,
      selector: row => row.startDate?.toString()!,
      sortable: true,
      width: '16%'
    },
    {
      name: <>Campaign End</>,
      selector: row => row.endDate?.toString()!,
      sortable: true,
      width: '16%'
    },
    {
      name: <>Offer Page</>,
      selector: row => row.offerPage!,
      cell: (row) => row.offerPage && (
        <Link
          className="font-weight-normal"
          to={`${constants.CampaignPreview}${row.offerPage ? "/" + row.offerPage : ""}`}
          tabIndex={-1}
          target="_blank"
        >
          View
        </Link>
      ),
      width: '13%'
    },
    {
      name: <>Assign</>,
      cell: (row: CampaignVM) =>
        row.isEnabled && (
          <NavLink className="assignCampaign" onClick={(event) => assignCampaignToProduct(event, row)}>
            <FontAwesomeIcon size="sm" icon={Icon.faPlus} />
          </NavLink>
        ),
      width: '8%',
      center: true
    }
  ];

  const fieldToString = (field: TableColumn<CampaignVM>): string => {
    switch (field.id) {
      case 1:
        return 'name';
      case 2:
        return 'status';
      case 3:
        return 'startDate';
      case 4:
        return 'endDate';
      default: 
        return '';
    }
  };

  const handleChangeRowsPerPage = (rowsPerPage: number, page: number) => {
    searchCampaigns({paginationPage: page, paginationPageSize: rowsPerPage}, 
      {sortOrder: sortOptions?.sortOrder, field: sortOptions?.field });
    setPageSize(rowsPerPage);
  };
  
  const handleChangePage = (newPage: number) => {
    searchCampaigns({paginationPage: newPage, paginationPageSize: pageSize}, 
      {sortOrder: sortOptions?.sortOrder, field: sortOptions?.field });
    setPage(newPage);
  };
  
  const handleSortChange = (field: TableColumn<CampaignVM>, sortDirection: SortOrder) => {
    const fieldName = fieldToString(field);
    searchCampaigns({paginationPageSize: pageSize, paginationPage: page}, {sortOrder: sortDirection, field: fieldName});
    setSortOptions({ sortOrder: sortDirection, field: fieldName});
  };

  return (
    <Container fluid={true} className="mt-4 position-relative">
      <LoadingSection
        isLoading={isLoading}
        height={pageElementRef.current?.clientHeight}
        width={pageElementRef.current?.clientWidth}
      >
        <div ref={pageElementRef}>
          <div ref={scrollElementRef} id="scrollElement"></div>
          <Modal isOpen={approveModal} toggle={toggleApproveModal}>
            <ModalHeader toggle={toggleApproveModal}>
              Does this pricing look correct?
            </ModalHeader>
            <ModalBody>{modalText || ""}</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={confirmSave}>
                Yes
              </Button>
              <Button color="secondary" onClick={toggleApproveModal}>
                No
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            fade={false}
            isOpen={switchStatusModal}
            toggle={toggleSwitchStatusModal}
          >
            <ModalHeader toggle={toggleSwitchStatusModal}>
              {switchChecked ? "Reactivate" : "Deactivate"} Product Confirmation
            </ModalHeader>
            <ModalBody className="large-font">
              Are you sure you want to{" "}
              {switchChecked ? "reactivate" : "deactivate"} this product
              {switchChecked ? "" : " and remove it from all campaigns"}?
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={postSwitchChange}
                className="px-4 large-font"
              >
                {switchChecked ? "Reactivate" : "Deactivate"} Product
              </Button>
              <Button
                color="secondary"
                onClick={toggleSwitchStatusModal}
                className="large-font"
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            fade={false}
            isOpen={switchDeleteModal}
            toggle={toggleSwitchDeleteModal}
          >
            <ModalHeader toggle={toggleSwitchDeleteModal}>
              Delete Product Confirmation
            </ModalHeader>
            <ModalBody className="large-font">
              Are you sure you want to delete this product and remove it from
              all campaigns?
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={deleteProduct}
                className="px-4 large-font"
              >
                Delete Product
              </Button>
              <Button
                color="secondary"
                onClick={toggleSwitchDeleteModal}
                className="large-font"
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Prompt
            when={inputsChanged}
            message={
              "Are you sure you want to leave the page? Any unsaved changes will be lost.\r\nClick OK to Proceed. Click Cancel to stay."
            }
          />
          <Form className="mt-2 ml-5 mr-5" onSubmit={handleSubmit}>
            <Row>
              <Col md="12" className="px-0">
                {errorMessageIsVisible && (
                  <ErrorAlert message="Please update the highlighted fields before saving"></ErrorAlert>
                )}
                <ValidationErrors
                  serverErrors={serverErrors}
                  formErrors={{}}
                  customErrors={[]}
                />
                <SessionExpiredError showError={sessionExpired} />
              </Col>
            </Row>
            <Row className="py-2">
              <Col
                md="5"
                className="px-0 mb-0 font-weight-light larger-font pt-3"
              >
                {values.id ? "Product Detail" : "Create New Product"}
              </Col>
              <Col md="7" className="pt-2 px-0 text-right">
                {values.id && (
                  <Row className="d-inline" style={{ padding: "1em" }}>
                    <span className="mt-1 mr-3 regular-font">Active</span>
                    <MaterialDesignSwitch
                      status={switchChecked}
                      onChange={handleSwitchChange}
                      className="align-middle mb-0"
                    />
                    <span className="mt-1 ml-3 regular-font">Inactive</span>
                  </Row>
                )}
                <SkinnedButton color="primary" className="py-2 px-4 ml-4">
                  {values.id ? "Save" : "Create"}
                </SkinnedButton>
                {values.id && (
                  <SkinnedButton
                    onClick={toggleSwitchDeleteModal}
                    className="py-2 ml-2 px-4"
                  >
                    Delete
                  </SkinnedButton>
                )}
                <SkinnedButton onClick={goBack} className="ml-2 px-4">
                  Cancel
                </SkinnedButton>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Row>
                  <Col md="12" className="rounded px-0 mb-4">
                    <Row>
                      <Col md="12">
                        <TextInput
                          maxLength={1000}
                          labelName="Product Name"
                          requiredField={true}
                          className={classnames({
                            highlighted:
                              isNotUndefined(formErrors.name) ||
                              isNotUndefined(formErrors.Name),
                          })}
                          fieldText={values.name || ""}
                          fieldName="name"
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          labelClass={classnames({
                            "highlighted-text":
                              isNotUndefined(formErrors.name) ||
                              isNotUndefined(formErrors.Name),
                          })}
                          error={formErrors.name}
                        ></TextInput>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <TextInput
                          maxLength={1000}
                          formGroupClass="mb-0"
                          labelName="Stripe Product ID"
                          requiredField={true}
                          className={classnames({
                            highlighted:
                              isNotUndefined(formErrors.stripeProductId) ||
                              isNotUndefined(formErrors.StripeProductId),
                            "mb-0": true,
                          })}
                          fieldText={values.stripeProductId || ""}
                          fieldName="stripeProductId"
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          labelClass={classnames({
                            "highlighted-text":
                              isNotUndefined(formErrors.stripeProductId) ||
                              isNotUndefined(formErrors.StripeProductId),
                          })}
                          error={formErrors.stripeProductId}
                        ></TextInput>
                      </Col>
                      <Col md="6">
                        <TextInput
                          maxLength={1000}
                          formGroupClass="mb-0"
                          labelName="Stripe Pricing ID"
                          requiredField={true}
                          className={classnames({
                            highlighted:
                              isNotUndefined(formErrors.stripePricingId) ||
                              isNotUndefined(formErrors.StripePricingId),
                            "mb-0": true,
                          })}
                          fieldText={values.stripePricingId || ""}
                          fieldName="stripePricingId"
                          handleChange={handleChange}
                          onBlur={(event: any) => {
                            handleBlur(event);
                            if (event.target.value) {
                              getInitialPriceValue(event.target.value);
                            } else {
                              setInitialPrice("");
                            }
                          }}
                          labelClass={classnames({
                            "highlighted-text":
                              isNotUndefined(formErrors.stripePricingId) ||
                              isNotUndefined(formErrors.StripePricingId),
                          })}
                          error={formErrors.stripePricingId}
                        ></TextInput>
                      </Col>
                      <Col md="6"></Col>
                      <Col md="6">
                        <div className="font-italic">{initialPrice}&nbsp;</div>
                      </Col>
                    </Row>
                    {enableSecondaryPricing && (
                      <>
                        <Row>
                          <Col md="6" className="ml-4 wrap-around mr-n4">
                            <CustomCheckbox
                              hideEmptyLabel={true}
                              text="Enable secondary pricing: this is the pricing that is applied after the initial pricing period has elapsed. This is not required if the pricing is the same for the duration of the subscription."
                              checked={values.enableSecondaryPricing || false}
                              fieldName="enableSecondaryPricing"
                              handleChange={(event) => {
                                if (!event.target.checked) {
                                  values.stripeSecondaryPricingId = "";
                                  setSecondaryPrice("");
                                }
                                handleChange(event);
                              }}
                            ></CustomCheckbox>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <TextInput
                              maxLength={1000}
                              formGroupClass="mb-0"
                              labelName="Stripe Secondary Pricing ID"
                              requiredField={true}
                              className={classnames({
                                highlighted:
                                  isNotUndefined(
                                    formErrors.stripeSecondaryPricingId
                                  ) ||
                                  isNotUndefined(
                                    formErrors.StripeSecondaryPricingId
                                  ),
                                "mb-0": true,
                              })}
                              fieldText={
                                values.enableSecondaryPricing
                                  ? values.stripeSecondaryPricingId || ""
                                  : ""
                              }
                              fieldName="stripeSecondaryPricingId"
                              handleChange={handleChange}
                              onBlur={(event: any) => {
                                handleBlur(event);
                                if (event.target.value) {
                                  getSecondaryPriceValue(event.target.value);
                                } else {
                                  setSecondaryPrice("");
                                }
                              }}
                              disabled={
                                !(values.enableSecondaryPricing || false)
                              }
                              labelClass={classnames({
                                "highlighted-text":
                                  isNotUndefined(
                                    formErrors.stripeSecondaryPricingId
                                  ) ||
                                  isNotUndefined(
                                    formErrors.StripeSecondaryPricingId
                                  ),
                              })}
                              error={formErrors.stripeSecondaryPricingId}
                            ></TextInput>
                          </Col>
                          <Col md="12">
                            <div className="font-italic">
                              {values.enableSecondaryPricing
                                ? secondaryPrice
                                : ""}
                              &nbsp;
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col md="6">
                        <Row>
                          <Col md="12" className="pb-3">
                            <CustomCheckbox
                              hideEmptyLabel={true}
                              text="Is Free Trial"
                              checked={values.isFreeTrial || false}
                              fieldName="isFreeTrial"
                              handleChange={(event: any) => {
                                values.isCreditCardRequired = false;
                                handleChange(event);
                              }}
                            ></CustomCheckbox>
                          </Col>
                          <Col md="12" className="pb-3">
                            <CustomCheckbox
                              hideEmptyLabel={true}
                              text="Credit Card Required For Free Trials"
                              checked={
                                values.isFreeTrial
                                  ? values.isCreditCardRequired || false
                                  : false
                              }
                              fieldName="isCreditCardRequired"
                              handleChange={(event: any) => {
                                handleChange(event);
                              }}
                              disabled={!(values.isFreeTrial || false)}
                            ></CustomCheckbox>
                          </Col>
                          <Col md="12">
                            <CustomCheckbox
                              hideEmptyLabel={true}
                              text="Display Additional Pricing Information"
                              checked={
                                values.displayAdditionalPricingInformation ||
                                false
                              }
                              fieldName="displayAdditionalPricingInformation"
                              handleChange={handleChange}
                            ></CustomCheckbox>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="6">
                        <Row>
                          <Col md="12">
                            <TextInput
                              maxLength={4}
                              labelName="Number of Free Trial Days"
                              requiredField={values.isFreeTrial || false}
                              className={classnames({
                                highlighted:
                                  isNotUndefined(formErrors.freeTrialDays) ||
                                  isNotUndefined(formErrors.freeTrialDays),
                                "auto-width": true,
                                "pt-3": true,
                                "mb-0": true,
                                number: true,
                              })}
                              fieldText={
                                values.isFreeTrial
                                  ? values.freeTrialDays || ""
                                  : ""
                              }
                              fieldName="freeTrialDays"
                              handleChange={handleChange}
                              onBlur={handleBlur}
                              size={3}
                              disabled={!(values.isFreeTrial || false)}
                              labelClass={classnames({
                                "highlighted-text":
                                  isNotUndefined(formErrors.freeTrialDays) ||
                                  isNotUndefined(formErrors.freeTrialDays),
                              })}
                              error={formErrors.freeTrialDays}
                            ></TextInput>
                          </Col>
                          <Col md="12">
                            <CustomCheckbox
                              hideEmptyLabel={true}
                              text="Display Promo Code Entry"
                              checked={values.displayPromoCodeEntry || false}
                              fieldName="displayPromoCodeEntry"
                              handleChange={handleChange}
                            ></CustomCheckbox>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <TextInput
                          maxLength={255}
                          labelName=""
                          showLabel={true}
                          placeholder="Optional pricing informacion"
                          className={classnames({
                            highlighted:
                              isNotUndefined(formErrors.totalAfterTrialLabel) ||
                              isNotUndefined(formErrors.TotalAfterTrialLabel),
                          })}
                          fieldText={
                            values.displayAdditionalPricingInformation
                              ? values.totalAfterTrialLabel || ""
                              : ""
                          }
                          fieldName="totalAfterTrialLabel"
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          disabled={
                            !(
                              values.displayAdditionalPricingInformation ||
                              false
                            )
                          }
                          labelClass={classnames({
                            "highlighted-text":
                              isNotUndefined(formErrors.totalAfterTrialLabel) ||
                              isNotUndefined(formErrors.TotalAfterTrialLabel),
                          })}
                          error={formErrors.totalAfterTrialLabel}
                        ></TextInput>
                      </Col>
                      <Col md="6">
                        <TextInput
                          maxLength={255}
                          labelName=""
                          showLabel={true}
                          placeholder="Optional price"
                          className={classnames({
                            highlighted:
                              isNotUndefined(formErrors.totalAfterTrialValue) ||
                              isNotUndefined(formErrors.TotalAfterTrialValue),
                          })}
                          fieldText={
                            values.displayAdditionalPricingInformation
                              ? values.totalAfterTrialValue || ""
                              : ""
                          }
                          fieldName="totalAfterTrialValue"
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          disabled={
                            !(
                              values.displayAdditionalPricingInformation ||
                              false
                            )
                          }
                          labelClass={classnames({
                            "highlighted-text":
                              isNotUndefined(formErrors.totalAfterTrialValue) ||
                              isNotUndefined(formErrors.TotalAfterTrialValue),
                          })}
                          error={formErrors.totalAfterTrialValue}
                        ></TextInput>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <TextInput
                          maxLength={45}
                          labelName="Tag"
                          requiredField={false}
                          className={classnames({
                            highlighted:
                              isNotUndefined(formErrors.tag) ||
                              isNotUndefined(formErrors.Tag),
                          })}
                          fieldText={values.tag || ""}
                          fieldName="tag"
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          labelClass={classnames({
                            "highlighted-text":
                              isNotUndefined(formErrors.tag) ||
                              isNotUndefined(formErrors.Tag),
                          })}
                          error={formErrors.tag}
                        ></TextInput>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <TextInput
                          maxLength={255}
                          labelName="Product First Line"
                          requiredField={false}
                          className={classnames({
                            highlighted:
                              isNotUndefined(formErrors.firstLine) ||
                              isNotUndefined(formErrors.FirstLine),
                          })}
                          fieldText={values.firstLine || ""}
                          fieldName="firstLine"
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          labelClass={classnames({
                            "highlighted-text":
                              isNotUndefined(formErrors.firstLine) ||
                              isNotUndefined(formErrors.FirstLine),
                          })}
                          error={formErrors.firstLine}
                        ></TextInput>
                      </Col>
                      <Col md="12">
                        <TextInput
                          maxLength={255}
                          labelName="Product Second Line"
                          requiredField={false}
                          className={classnames({
                            highlighted:
                              isNotUndefined(formErrors.secondLine) ||
                              isNotUndefined(formErrors.SecondLine),
                          })}
                          fieldText={values.secondLine || ""}
                          fieldName="secondLine"
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          labelClass={classnames({
                            "highlighted-text":
                              isNotUndefined(formErrors.secondLine) ||
                              isNotUndefined(formErrors.SecondLine),
                          })}
                          error={formErrors.secondLine}
                        ></TextInput>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <TextInput
                          maxLength={250}
                          labelName="Upgrade Url Path"
                          requiredField={false}
                          placeholder="i.e. register/upgrade"
                          className={classnames({
                            highlighted:
                              isNotUndefined(formErrors.upgradeUrl) ||
                              isNotUndefined(formErrors.UpgradeUrl),
                          })}
                          fieldText={values.upgradeUrl || ""}
                          fieldName="upgradeUrl"
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          labelClass={classnames({
                            "highlighted-text":
                              isNotUndefined(formErrors.upgradeUrl) ||
                              isNotUndefined(formErrors.UpgradeUrl),
                          })}
                          error={formErrors.secondLine}
                        ></TextInput>
                      </Col>
                      <Col md="6">
                        <TextAreaInput
                          rows={4}
                          maxLength={1000}
                          labelName="Upgrade Marketing Text"
                          requiredField={false}
                          className={classnames({
                            highlighted:
                              isNotUndefined(formErrors.upgradeMarketingText) ||
                              isNotUndefined(formErrors.UpgradeMarketingText),
                          })}
                          fieldText={values.upgradeMarketingText || ""}
                          fieldName="upgradeMarketingText"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          labelClass={classnames({
                            "highlighted-text":
                              isNotUndefined(formErrors.upgradeMarketingText) ||
                              isNotUndefined(formErrors.UpgradeMarketingText),
                          })}
                          error={formErrors.secondLine}
                        ></TextAreaInput>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Label className="font-weight-bold regular-font">
                          Features
                        </Label>
                      </Col>
                    </Row>
                    <div ref={featuresRef} className="features-container pl-3">
                      <Features
                        formErrors={formErrors}
                        elements={values.features || []}
                        handleItemsChange={handleChangeInFeatures}
                        onBlur={handleBlur}
                        compactUI={true}
                      ></Features>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md="4" className="pt-4">
                <Row>
                  <Col md="12">
                    <Card onClick={(event: any) => {}} body className="plans">
                      <CardTitle className="mb-4 popular">
                        <CustomInput
                          type="radio"
                          id={`planType`}
                          name="planType"
                          label={values.name || "Product Name"}
                          checked={false}
                          onChange={(event: any) => {}}
                          className={classnames({
                            "empty-plan": !values.name,
                            "font-weight-light": !values.name,
                          })}
                        />
                        <div
                          className={classnames({
                            "most-popular": true,
                          })}
                        >
                          <div
                            className={classnames({
                              "empty-plan": !values.tag,
                              "px-1": true,
                            })}
                          >
                            {values.tag || "Tag"}
                          </div>
                        </div>
                      </CardTitle>
                      <Row className="card-wrapper-desc">
                        <Col md="12" className="header1">
                          <Label
                            className={classnames({
                              "empty-plan": !values.firstLine,
                              "font-weight-light": !values.firstLine,
                              "p-1": !values.firstLine,
                              "w-100": true,
                            })}
                          >
                            {(values.firstLine || false) && values.firstLine}
                            {!values.firstLine && "Product first line"}
                          </Label>
                        </Col>
                        <Col md="12">
                          <Label
                            className={classnames({
                              bullet: true,
                              "empty-plan": !values.secondLine,
                              "font-weight-light": !values.secondLine,
                              "p-1": !values.secondLine,
                              "w-100": true,
                            })}
                          >
                            {(values.secondLine || false) && values.secondLine}
                            {!values.secondLine && "Product second line"}
                          </Label>
                        </Col>
                      </Row>
                      <Row className="card-wrapper-bullets">
                        <Col md="12">
                          {(values.features?.length &&
                          values.features?.length > 0
                            ? values.features
                            : [0, 1, 2, 3, 4]
                          ).map((feature: ProductFeatureVM) => (
                            <div key={feature?.id || uuid()}>
                              {feature?.name ? (
                                <div key={feature.id}>
                                  <Label className="bullet">
                                    <FontAwesomeIcon
                                      icon={Icon.faCheck}
                                    ></FontAwesomeIcon>
                                    &nbsp;&nbsp;{feature.name}
                                  </Label>
                                </div>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon={Icon.faCheck}
                                  ></FontAwesomeIcon>
                                  <Label
                                    className={classnames({
                                      bullet: true,
                                      "empty-plan": !values.secondLine,
                                      "font-weight-light": !values.secondLine,
                                      "ml-1": !values.secondLine,
                                      "w-75": true,
                                    })}
                                  >
                                    &nbsp;&nbsp;
                                    {feature?.name || "Feature"}
                                  </Label>
                                </>
                              )}
                            </div>
                          ))}
                        </Col>
                      </Row>
                      <Row className="card-wrapper-promo">
                        {values.displayPromoCodeEntry && (
                          <>
                            <Col md="8">
                              <TextInput
                                labelName="&nbsp;"
                                requiredField={false}
                                fieldText=""
                                fieldName="prices"
                                handleChange={(e) => {}}
                                onBlur={() => {}}
                                isPassword={false}
                                autoComplete="off"
                                placeholder="Promo Code"
                              ></TextInput>
                            </Col>
                            <Col md="4" className="pt-1">
                              <div>&nbsp;</div>
                              <Button onClick={(event) => {}}>Apply</Button>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col>
                          <hr className="split-line"></hr>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="7">
                          <Label
                            className={classnames({
                              "empty-plan": !values.totalAfterTrialLabel,
                              "font-weight-light": !values.totalAfterTrialLabel,
                              "ml-1": !values.totalAfterTrialLabel,
                              "px-1": !values.totalAfterTrialLabel,
                              "smaller-font": !values.totalAfterTrialLabel,
                            })}
                          >
                            {values.totalAfterTrialLabel ||
                              "Optional pricing information"}
                          </Label>
                        </Col>
                        <Col md="5" className="text-right">
                          <span>&nbsp;&nbsp;&nbsp;</span>
                          <Label
                            className={classnames({
                              "empty-plan": !values.totalAfterTrialValue,
                              "font-weight-light": !values.totalAfterTrialValue,
                              "ml-1": !values.totalAfterTrialValue,
                              "px-1": !values.totalAfterTrialLabel,
                              "smaller-font": !values.totalAfterTrialLabel,
                            })}
                          >
                            {values.totalAfterTrialValue || "Optional price"}
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="7" className="font-weight-bold">
                          Total due today
                        </Col>
                        <Col md="5" className="text-right font-weight-bold">
                          <span>
                            $
                            {values.price > 0 && !values.isFreeTrial
                              ? Helper.formatMoney(
                                  (values.priceAfterPromoCode || values.price) /
                                    100,
                                  2,
                                  ".",
                                  ","
                                )
                              : "0.00"}
                          </span>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col md="12" className="mt-4">
                    <table className="assignment w-100">
                      <thead>
                        <tr>
                          <th className="w-75 font-weight-light thirdTitle pb-1">
                            Campaign Assignments
                          </th>
                          <th className="w-25"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.campaigns?.map((item: CampaignVM, index: number) => (
                          <tr key={item.id}>
                            <td
                              className={classnames({
                                "px-2": true,
                                rounded: true,
                                "dusky-blue": true,
                                "opacity-3": !item.isEnabled,
                              })}
                            >
                              <span className="float-left pt-2 pl-1 text-white">
                                {item.name}
                              </span>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`/providers/${
                                  item.isDefault
                                    ? "default-campaign/"
                                    : "campaign/"
                                }${item.id}`}
                                className="float-right py-1 button-link px-3 btn-secondary btn mx-0"
                              >
                                Edit
                              </a>
                            </td>
                            <td>
                              <span className="pl-2">
                                {item.offerPage && (
                                  <Link
                                    className="font-weight-normal"
                                    to={`${constants.CampaignPreview}/${
                                      item.offerPage || ""
                                    }`}
                                    target="_blank"
                                  >
                                    View
                                  </Link>
                                )}
                              </span>
                              <Button
                                className="trashCan mt-n1"
                                onClick={(event) => {
                                  values.campaigns.splice(index, 1);
                                  setValues({
                                    ...values,
                                    campaigns: values.campaigns,
                                  });
                                }}
                              >
                                <FontAwesomeIcon
                                  size="lg"
                                  icon={Icon.faTrash}
                                ></FontAwesomeIcon>
                              </Button>
                            </td>
                          </tr>
                        ))}
                        {(!values.campaigns || !values.campaigns.length) && (
                          <tr className="empty">
                            <td
                              colSpan={2}
                              className="py-2 px-4 font-weight-light"
                            >
                              This product is not assigned to a campaign
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="pl-0 pb-2 larger-font font-weight-light">
                Assign to a Campaign
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Row className="rounded p-3 blue-background">
                  <div className="w-100 font-weight-bold regular-font pb-2">
                    Campaign Search
                  </div>
                  <div className="w-100">
                    <TextInput
                      maxLength={1000}
                      labelClass="font-weight-normal regular-font pb-2"
                      showLabel={false}
                      formGroupClass="d-inline-flex w-100 mb-0"
                      parentClassName="w-100"
                      labelName=""
                      requiredField={false}
                      fieldText={values.searchText || ""}
                      fieldName="searchText"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      placeholder="Search for a Campaign to assign this Product to"
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          searchCampaigns();
                        }
                      }}
                    ></TextInput>
                  </div>
                  <div className="w-100 d-flex align-items-center flex-wrap">
                    <div className="d-flex align-items-center flex-wrap mt-3">
                      <CustomCheckbox
                        hideEmptyLabel={true}
                        text="Active"
                        parentClassName="mt-1"
                        className="regular-font pl-0"
                        checked={values?.isActive || false}
                        fieldName="isActive"
                        handleChange={handleChange}
                      ></CustomCheckbox>
                      <CustomCheckbox
                        hideEmptyLabel={true}
                        text="Inactive"
                        parentClassName="mt-1 mr-3"
                        className="regular-font pl-0"
                        checked={values?.isInactive || false}
                        fieldName="isInactive"
                        handleChange={handleChange}
                      ></CustomCheckbox>
                      <div className="d-inline-flex align-items-center">
                        <span className="regular-font mr-3">
                          Filter by Campaign date
                        </span>
                        <div className="d-inline-flex align-items-center">
                          <InputGroup>
                            <div className="input-group-prepend">
                              <div className="input-group-text">From</div>
                            </div>
                            <DatePicker
                              showLabel={false}
                              fieldName="campaignEndDate"
                              fieldValue={values?.campaignStartDate || ""}
                              handleChange={(event: any) => {
                                setValues({
                                  ...values,
                                  campaignStartDate: event.target.value,
                                });
                              }}
                              skipFormGroup
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  searchCampaigns();
                                }
                              }}
                            ></DatePicker>
                          </InputGroup>
                          <InputGroup className="ml-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">To</div>
                            </div>
                            <DatePicker
                              showLabel={false}
                              fieldName="campaignEndDate"
                              fieldValue={values?.campaignEndDate || ""}
                              handleChange={(event: any) => {
                                setValues({
                                  ...values,
                                  campaignEndDate: event.target.value,
                                });
                              }}
                              skipFormGroup
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  searchCampaigns();
                                }
                              }}
                            ></DatePicker>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                    <div className="ml-auto mt-3">
                      <SkinnedButton
                        color="primary"
                        onClick={(event: any) => {
                          event.preventDefault();
                          searchCampaigns();
                        }}
                      >
                        Search
                      </SkinnedButton>
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row className="pt-3 pb-5">
              <Col className="px-0">
                <DataTable
                  data={campaignData}
                  columns={columns}
                  defaultSortFieldId={1}
                  defaultSortAsc={true}
                  highlightOnHover
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  onChangePage={handleChangePage}
                  onSort={handleSortChange}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 20, 50, 100]}
                  paginationTotalRows={totalRows}
                  progressPending={isGridLoading}
                  striped
                  />
              </Col>
            </Row>
          </Form>
        </div>
      </LoadingSection>
    </Container>
  );
};
