/* eslint-disable eqeqeq */
import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { Row, Col, Form, Container, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";

import { ProviderProfileSearchVM, ProviderSearchVM } from "./Models";
import { TextInput, CustomCheckbox } from "../../Components/Form/Inputs";
import { LoadingSection } from "../../Components/Display";
import { SkinnedButton } from "../../Components/Form/Buttons";
import {
  ProfileStatus,
  StripeSubscriptionStatus,
  SubscriptionStatus,
} from "../../Components/ProviderProfile/Models";
import ValidationErrors from "../Errors/ValidationErrors";
import SessionExpiredError from "../Errors/SessionExpiredError";
import { useApiWorker } from "../../Utilities/CommonHooks";
import { useCustomForm } from "../../Utilities/UseCustomForm";
import { Helper, basePathName, adminGenericError } from "../../Utilities/HelperData";

import "./ProviderSearch.scss";

class ProviderSearchFormValues {
  searchText?: string = "";
  pendingApproval: boolean = false;
  deactivatedProfile: boolean = false;
  noSubscription: boolean = false;
}

export type SortOptions = {
  sortOrder?: SortOrder,
  field?: string
}

export type PaginationOptions = {
  paginationPage: number,
  paginationPageSize: number
}

const validationSchema = Yup.object({});

export const ProviderSearch = () => {

  const defaultPageSize = 10;

  const apiWorker = useApiWorker();
  const [data, setData] = useState<ProviderProfileSearchVM[]>([]);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isGridLoading, setIsGridLoading] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  
  const [totalRows, setTotalRows] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [page, setPage] = useState<number>(1);
  const [sortOptions, setSortOptions] = useState<SortOptions>();

  const pageElementRef = useRef<null | HTMLDivElement>(null);
  const initialValues = new ProviderSearchFormValues();

  const columns: TableColumn<ProviderProfileSearchVM>[] = [
    {
      name: <>Name</>,
      selector: row => row.fullName!,
      cell: (row) => (
        <Link className="font-weight-normal" to={"provider/" + row.id} tabIndex={-1}>
          {row.fullName}
        </Link>
      ),
      sortable: true,
      width: '12%'
    },
    {
      name: <>Supervisor</>,
      selector: row => row.supervisorName!,
      sortable: true,
      width: '12%'
    },
    {
      name: <>Account Type</>,
      selector: row => row.accountType!,
      sortable: true,
      width: '11%'
    },
    {
      name: <>Log in Email</>,
      selector: row => row.email!,
      sortable: true,
      width: '18%'
    },
    {
      name: <>Contact Phone Number</>,
      selector: row => row.contactPhoneNumber!,
      cell: row => <div>{formatPhoneNumber(row.contactPhoneNumber!)}</div>,
      sortable: true,
      width: '13%'
    },
    {
      name: <>Profile Status</>,
      selector: row => row.profileStatus!,
      cell: row => (
        <div className="profile-state font-weight-bold">
          {Helper.isProfilePublished(
            row.profileStatus, row.publishedStatus, row.publishSucceeded) ? (
            <span className="green">Listed</span>
          ) : row.profileStatus == ProfileStatus.Denied ? (
            <span className="red">Action Required</span>
          ) : row.profileStatus == ProfileStatus.Incomplete ? (
            <span className="grey">Not submitted</span>
          ) : row.profileStatus == ProfileStatus.PendingApproval ? (
            <span className="grey">Pending Review</span>
          ) : row.profileStatus == ProfileStatus.Hidden ? (
            <span className="red">Unlisted</span>
          ) : Helper.isProfileUnpublished(row.profileStatus, row.publishedStatus) ? (
            <span className="red">Unlisted</span>
          ) : Helper.isProfilePublished(
              row.profileStatus, row.publishedStatus, row.publishSucceeded
            ) ? (
            <span className="green">Listed</span>
          ) : row.profileStatus == ProfileStatus.DeactivatedAndLoggedOut ? (
            <span className="red">Deactivated</span>
          ) : (
            <span></span>
          )}
        </div>),
      sortable: true,
      width: '12%'
    }, 
    {
      name: <>Account Status</>,
      selector: row => row.subscriptionStatus!,
      cell: row => (
        <span className="statusIndicator">
          <FontAwesomeIcon
            icon={
              row.subscriptionStatus == SubscriptionStatus.Active || row.subscriptionStatus == null
                ? Icon.faCheckCircle
                : Icon.faBan
            }
            size="lg"
          />
        </span>
      ),
      sortable: true,
      width: '11%'
    },
    {
      name: <>Stripe Status</>,
      center: true,
      selector: row => row.stripeSubscriptionStatus!,
      cell: row => (
        <span>
          {row.stripeSubscriptionStatus == StripeSubscriptionStatus.active ? "Active"
            : row.stripeSubscriptionStatus == StripeSubscriptionStatus.past_due ? "Past due"
            : row.stripeSubscriptionStatus == StripeSubscriptionStatus.unpaid ? "Unpaid"
            : row.stripeSubscriptionStatus == StripeSubscriptionStatus.canceled ? "Canceled"
            : row.stripeSubscriptionStatus == StripeSubscriptionStatus.incomplete ? "Incomplete"
            : row.stripeSubscriptionStatus == StripeSubscriptionStatus.incomplete_expired ? "Incomplete expired"
            : row.stripeSubscriptionStatus == StripeSubscriptionStatus.trialing ? "Trialing"
            : row.stripeSubscriptionStatus == StripeSubscriptionStatus.ended ? "Ended"
            : row.stripeSubscriptionStatus}
        </span>
      ),
      sortable: true,
      width: '11%'
    }
  ];

  const formatPhoneNumber = (text: string) => {
    const cleaned = ("" + text).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return null;
  };

  const handleServerErrors = (errors: any): boolean => {
    if (errors?.status === 401) {
      setSessionExpired(true);
      window.scroll(0, 0);
      return true;
    } else if (errors?.status === 500) {
      setServerErrors([adminGenericError]);
      return true;
    }
    return false;
  };

  const searchProviders = async (paginationOptions?: PaginationOptions, sortOptions?: SortOptions) => {

    setIsGridLoading(true);
    const data = {
      searchText: values.searchText,
      pendingApproval: values.pendingApproval,
      deactivatedProfile: values.deactivatedProfile,
      noSubscription: values.noSubscription,
      pageSize: paginationOptions?.paginationPageSize || defaultPageSize,
      page: paginationOptions?.paginationPage || page,
      sortOrder: sortOptions?.sortOrder || 'asc',
      sortField: sortOptions?.field || 'fullName'
    };
    let success = true;
    const result = await apiWorker
      .get<ProviderSearchVM>(`${basePathName}/api/providerprofile/search`, {
        params: {
          ...data,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((err) => {
        setIsGridLoading(false);
        if (handleServerErrors(err)) {
          success = false;
          return;
        }
        setServerErrors([adminGenericError]);
      });

    if (!success) return;

    if (result) {
      setServerErrors([]);
      setIsGridLoading(false);
      setData(result.data.data || []);
      setTotalRows(result.data.total || 0);
    }
  };

  useEffect(() => {
    setIsPageLoading(true);
    searchProviders();
    setIsPageLoading(false);
  }, []);

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useCustomForm({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      searchProviders();
    },
  });

  const fieldToString = (field: TableColumn<ProviderProfileSearchVM>): string => {
    switch(field.id) {
      case 1:
        return 'fullName';
      case 2:
        return 'supervisorName';
      case 3:
        return 'accountType';
      case 4:
        return 'email';
      case 5:
        return 'contactPhoneNumber';
      case 6:
        return 'profileStatus';
      case 7:
        return 'subscriptionStatus'
      case 8:
        return 'stripeSubscriptionStatus'
      default:
        return '';
    }
  };

  const handleChangeRowsPerPage = (rowsPerPage: number, page: number) => {
    searchProviders({paginationPage: page, paginationPageSize: rowsPerPage}, 
      {sortOrder: sortOptions?.sortOrder, field: sortOptions?.field });
    setPageSize(rowsPerPage);
  };

  const handleChangePage = (newPage: number) => {
    searchProviders({paginationPage: newPage, paginationPageSize: pageSize}, 
      {sortOrder: sortOptions?.sortOrder, field: sortOptions?.field });
    setPage(newPage);
  };

  const handleSortChange = (field: TableColumn<ProviderProfileSearchVM>, sortDirection: SortOrder) => {
    const fieldName = fieldToString(field);
    searchProviders({paginationPageSize: pageSize, paginationPage: page}, {sortOrder: sortDirection, field: fieldName});
    setSortOptions({ sortOrder: sortDirection, field: fieldName});
  };

  return (
    <Container fluid={true} className="mt-4 position-relative">
      <LoadingSection
        isLoading={isPageLoading}
        height={pageElementRef.current?.clientHeight}
        width={pageElementRef.current?.clientWidth}
      >
        <div ref={pageElementRef}>
          <Form
            className="mt-2 pt-2 ml-5 mr-5 profile-search"
            onSubmit={handleSubmit}
          >
            <Row>
              <Col md="12" className="px-0">
                <ValidationErrors
                  serverErrors={serverErrors}
                  formErrors={[]}
                  customErrors={[]}
                />
                <SessionExpiredError showError={sessionExpired} />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Row className="rounded pt-2 pb-3 blue-background">
                  <Col md="12">
                    <TextInput
                      maxLength={1000}
                      labelClass="font-weight-normal regular-font pb-2"
                      labelName="Search by first name, last name, supervisor name, email or phone number (phone number without dashes or spaces)"
                      requiredField={false}
                      fieldText={values.searchText || ""}
                      fieldName="searchText"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      autoFocus
                    ></TextInput>
                  </Col>
                  <Col md="12">
                    <Label className="font-weight-bold regular-font mb-0">
                      Filter by
                    </Label>
                  </Col>
                  <Col md="2" className="pt-1 px-0 ml-n1">
                    <CustomCheckbox
                      hideEmptyLabel={true}
                      text="Pending review"
                      className="regular-font"
                      checked={values.pendingApproval || false}
                      fieldName="pendingApproval"
                      handleChange={handleChange}
                    ></CustomCheckbox>
                  </Col>
                  <Col md="2" className="pt-1 px-0">
                    <CustomCheckbox
                      hideEmptyLabel={true}
                      text="Deactivated profile"
                      className="regular-font"
                      checked={values.deactivatedProfile || false}
                      fieldName="deactivatedProfile"
                      handleChange={handleChange}
                    ></CustomCheckbox>
                  </Col>
                  <Col md="2" className="pt-1 px-0">
                    <CustomCheckbox
                      hideEmptyLabel={true}
                      text="No subscription"
                      className="regular-font"
                      checked={values.noSubscription || false}
                      fieldName="noSubscription"
                      handleChange={handleChange}
                    ></CustomCheckbox>
                  </Col>
                  <Col md="6" className="text-right">
                    <SkinnedButton color="primary" className="mr-4">
                      Search
                    </SkinnedButton>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
             <Col>
                <DataTable
                  data={data}
                  columns={columns}
                  defaultSortFieldId={1}
                  defaultSortAsc={true}
                  highlightOnHover
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  onChangePage={handleChangePage}
                  onSort={handleSortChange}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 20, 50, 100]}
                  paginationTotalRows={totalRows}
                  progressPending={isGridLoading}
                  striped
                />
              </Col> 
            </Row>
          </Form>
        </div>
      </LoadingSection>
    </Container>
  );
};
