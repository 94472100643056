import { ChangeEvent, FocusEvent, Fragment, useEffect, useState } from "react";
import "./BoardCertifications.scss";
import { Button, Col, NavLink, Row } from "reactstrap";
import { BoardCertificationVM } from "../../Components/ProviderProfile/Models/BoardCertificationVM";
import { v4 as uuid } from "uuid";
import { TextInput } from "../Form/Inputs";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import Dragula from "react-dragula";
import { MoveIcon } from ".";

interface IProps {
  elements: BoardCertificationVM[] | undefined;
  formErrors: any;
  handleItemsChange: (
    event: ChangeEvent<HTMLInputElement>,
    items: BoardCertificationVM[] | undefined
  ) => void;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
}

export const BoardCertifications = ({
  elements,
  formErrors,
  handleItemsChange,
  onBlur,
}: IProps) => {
  useEffect(() => {
    setItems(elements);
  }, [elements]);
  const containers: any[] = [];
  const [items, setItems] = useState(elements);

  useEffect(() => {
    const drake = Dragula(containers, {
      moves: function (el: any, source: any, handle: any, sibling: any) {
        return (
          handle &&
          handle.parentElement &&
          (handle.parentElement.className === "handle position-relative" ||
            (handle.parentElement.parentElement &&
              handle.parentElement.parentElement.className ===
                "handle position-relative"))
        );
      },
    });

    // handle drop manually to adjust CSS because React rendering
    // does not get notified from changes after moving the DOM
    drake.on('drop', (el : Element) => {
      // add top border to all children
      el.parentElement?.querySelectorAll('[data-part="separator"]')
      .forEach(separator => separator.classList.add('border-top'));

      // remove top border from first child
      el.parentElement?.querySelector('[data-part="separator"]')
        ?.classList.remove('border-top');
    });
  }, []);

  const dragulaDecorator = (componentBackingInstance: any) => {
    if (componentBackingInstance) {
      containers.push(componentBackingInstance);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    let value: any;
    value = event.target.value;

    const elements = items?.map((el) =>
      el.id === id ? { ...el, [event.target.name]: value } : el
    );
    setItems(elements);
    handleItemsChange(event, elements);
  };

  const addRow = (event: any) => {
    event.preventDefault();
    const item = new BoardCertificationVM();
    item.id = uuid();
    let elements = [...(items || []), item];
    setItems(elements);
    handleItemsChange(event, elements);
  };

  const deleteRow = (event: any, id: string) => {
    event.preventDefault();
    const elements = items?.filter((x) => x.id !== id);
    setItems(elements);
    handleItemsChange(event, elements);
  };

  return (
    <Fragment>
      <div
        className={classnames({ "drag-handles": true })}
        ref={dragulaDecorator}
      >
        {items?.map((item, index) => (
          <Row key={item.id} className="pb-1" data-elementid={item.id}>
            <Col>
              <Row
                data-part="separator"
                className={classnames({
                  'border-top': index !== 0,
                  'hide-font': true,
                  'mx-1': true
                })}
              >
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col md="10">
                  <TextInput
                    labelName="Name"
                    className={classnames({
                      highlighted:
                        isNotUndefined(
                          formErrors[`boardCertifications[${index}].name`]
                        ) ||
                        isNotUndefined(
                          formErrors[`boardCertifications[${index}].Name`]
                        ),
                    })}
                    fieldText={item?.name || ""}
                    fieldName="name"
                    handleChange={(selected) => {
                      handleChange(selected, item.id);
                    }}
                    labelClass={classnames({
                      "highlighted-text":
                        isNotUndefined(
                          formErrors[`boardCertifications[${index}].name`]
                        ) ||
                        isNotUndefined(
                          formErrors[`boardCertifications[${index}].Name`]
                        ),
                    })}
                    error={
                      formErrors[`boardCertifications[${index}].name`] ||
                      formErrors[`boardCertifications[${index}].Name`]
                    }
                    onBlur={onBlur}
                    maxLength={500}
                  ></TextInput>
                </Col>
                <Col md="2" className="text-right">
                  <div>&nbsp;</div>
                  <span className="handle position-relative">
                    {items && items.length > 1 && (
                      <MoveIcon tooltipMessage="When adding multiple board certifications to your profile use the up/down arrow to the right of each entry to change the order in which it displays on your public profile"></MoveIcon>
                    )}
                  </span>
                  <Button
                    className={classnames({ trashCan: true, "ml-3": true })}
                    onClick={(event) => deleteRow(event, item.id)}
                  >
                    <FontAwesomeIcon
                      icon={Icon.faTrash}
                      size="lg"
                    ></FontAwesomeIcon>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </div>
      <Row>
        <Col md="10">
          <NavLink className="plusMessage pt-0" onClick={addRow}>
            <FontAwesomeIcon size="sm" icon={Icon.faPlus}></FontAwesomeIcon>
            &nbsp;&nbsp;Add New Board Certification
          </NavLink>
        </Col>
      </Row>
    </Fragment>
  );
};
