import { CoreEnum } from "../../../Features/Shared/Models/SharedModels";

export class CertificationVM {
  id: any;
  certificationName?: string;
  certificationOrganizationType?: CoreEnum;
  certificationNumber?: string;
  expiration?: Date;
  expirationYear?: CoreEnum;
  issued?: Date;
  issuedYear?: CoreEnum;
  position?: number;
  otherCertificationOrganizationType?: string;
  runPublishingValidators?: boolean;
}