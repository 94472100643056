import "./Layout.scss";
import React, { useRef, useEffect, useState } from "react";
import {
  PageState,
  useGlobalState,
  useGlobalDispatch,
  GlobalActionTypes,
} from "./../../Context";
import {
  Label,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavbarToggler,
  Collapse,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { BodyLoadingSection } from "../../Components/Display";
import classnames from "classnames";
import { basePathName } from "../../Utilities/HelperData";
import { useApiWorker } from "../../Utilities/CommonHooks";
import { DripIcon, DripIcons } from "../../Components/Display/DripIcon";
import {
  PublishStatus,
  ProviderProfileVM,
} from "../../Components/ProviderProfile/Models/index";
import { ProfileStatus } from "../../Components/ProviderProfile/Models";
import { UrlHelper } from "../../Utilities/UrlHelper";
import { AdminHelper } from "../../Utilities/AdminHelper";
import { constants, apiUrls } from "../../Utilities/Constants";
import { UpgradeAdButton } from "../Shared/UpgradeAdButton";

export const Layout: React.FC = ({ children }) => {
  const { currentUser, pageState, wordPressSiteUrl, providerProfileStatus } =
    useGlobalState();
  const apiWorker = useApiWorker();
  const mainTopRef = useRef<null | HTMLDivElement>(null);
  const dispatch = useGlobalDispatch();
  const [navigatingAwayFromSite, setNavigatingAwayFromSite] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  // no access to useParams here (to get the id from the url using react, so we need to parse it from the url)
  const providerId = UrlHelper.getProviderIdFromUrl();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleNavbar = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (
      (currentUser?.permissions || []).some((x: any) =>
        ["providerprofile.editself"].includes(x)
      ) ||
      ((currentUser?.permissions || []).some((x: any) =>
        ["providerprofile.editany"].includes(x)
      ) &&
        AdminHelper.isUserBeingImpersonated())
    ) {
      getProviderPublicProfileStatus();
    }
  }, [currentUser]);

  const getProviderPublicProfileStatus = async () => {
    const profile = await apiWorker
      .get<ProviderProfileVM>(
        UrlHelper.getApiUrl(
          currentUser?.permissions,
          apiUrls.PublicProfileStatusUrl,
          `${apiUrls.ImpersonatePublicProfileStatusUrl}${providerId}`
        )
      )
      .catch(() => {});

    if (profile && profile.data) {
      dispatch({
        type: GlobalActionTypes.SetProfileStatus,
        payload: profile.data,
      });
      setLoginEmail(profile.data.loginEmail || "");
    }
  };

  const isProviderOrImpersonateView =
    (currentUser?.permissions || []).some((x: any) =>
      ["providerprofile.editself"].includes(x)
    ) ||
    ((currentUser?.permissions || []).some((x: any) =>
      ["providerprofile.editany"].includes(x)
    ) &&
      AdminHelper.isUserBeingImpersonated());

  const isSearchLinkActive =
    window.location.pathname.indexOf("/provider-search") >= 0 ||
    window.location.href.endsWith(
      window.location.origin + basePathName + "/"
    ) ||
    window.location.href.endsWith(window.location.origin) ||
    window.location.pathname.indexOf("/providers/provider/") >= 0;

  const isProviderApprovalLinkActive =
    window.location.pathname.indexOf("/provider-approval") >= 0;

  const isProductCampaignLinkActive =
    window.location.pathname.indexOf("/campaign-product") >= 0;

  return (
    <div
      className={classnames({
        main: true,
        "top-banner":
          AdminHelper.isUserBeingImpersonated() ||
          AdminHelper.isEditingDefaultCampaign(),
      })}
    >
      {AdminHelper.isUserBeingImpersonated() && (
        <div className="main__header regular-font align-items-center">
          You are impersonating&nbsp;<strong>{loginEmail}</strong>. Please close
          this tab when you are finished.
        </div>
      )}
      {AdminHelper.isEditingDefaultCampaign() && (
        <div className="main__header regular-font align-items-center">
          This is the&nbsp;<strong>default campaign</strong>&nbsp;for
          therapist.com
        </div>
      )}
      <BodyLoadingSection
        isLoading={
          pageState === PageState.Pending ||
          pageState === PageState.Unauthorized ||
          navigatingAwayFromSite
        }
      >
        <div className="navbar-background">
          <Navbar className="navbar-dark main__nav pr-0 pl-0" expand="lg">
            <NavbarBrand
              href={wordPressSiteUrl}
              onClick={(e: any) => {
                e.preventDefault();
                setNavigatingAwayFromSite(true);
                window.location.replace(wordPressSiteUrl || "");
              }}
            >
              <img
                src={`${basePathName}/therapist_logo_white.svg`}
                className="logo"
                alt="logo"
              />
            </NavbarBrand>
            <NavbarToggler className="mr-2" onClick={toggleNavbar} />
            <Collapse navbar isOpen={isMenuOpen}>
              <Nav>
                {(currentUser?.permissions || []).some((x: any) =>
                  ["providerprofile.search"].includes(x)
                ) &&
                  !AdminHelper.isUserBeingImpersonated() && (
                    <NavItem
                      className={classnames({
                        "menu-box": true,
                        "py-2": true,
                        "active-link": isSearchLinkActive,
                      })}
                    >
                      <NavLink
                        activeClassName="active-link"
                        to="/provider-search"
                        className={classnames({
                          "pl-1": true,
                          "d-block": true,
                          admin: true,
                          "active-link": isSearchLinkActive,
                        })}
                      >
                        <DripIcon
                          icon={DripIcons.Search}
                          className="menu-icon"
                        />
                        <Label className="menu-label ml-2 mt-2">
                          Provider Search
                        </Label>
                      </NavLink>
                    </NavItem>
                  )}
                {(currentUser?.permissions || []).some((x: any) =>
                  ["providerprofile.approve"].includes(x)
                ) &&
                  !AdminHelper.isUserBeingImpersonated() && (
                    <NavItem
                      className={classnames({
                        "menu-box": true,
                        "py-2": true,
                        "active-link": isProviderApprovalLinkActive,
                      })}
                    >
                      <NavLink
                        activeClassName="active-link"
                        to="/provider-approval"
                        className={classnames({
                          "pl-1": true,
                          "d-block": true,
                          admin: true,
                          "active-link": isProviderApprovalLinkActive,
                        })}
                      >
                        <DripIcon
                          icon={DripIcons.Approve}
                          className="menu-icon"
                        />
                        <Label className="menu-label ml-2 mt-2">
                          Provider Approval
                        </Label>
                      </NavLink>
                    </NavItem>
                  )}
                {(currentUser?.permissions || []).some((x: any) =>
                  ["providerprofile.editany"].includes(x)
                ) &&
                  !AdminHelper.isUserBeingImpersonated() && (
                    <>
                      <NavItem
                        className={classnames({
                          "menu-box": true,
                          "py-2": true,
                          "active-link":
                            isProductCampaignLinkActive ||
                            window.location.pathname.indexOf("/campaign") >=
                              0 ||
                            window.location.pathname.indexOf("/product") >= 0,
                        })}
                      >
                        <NavLink
                          activeClassName="active-link"
                          to="/campaign-product"
                          className={classnames({
                            "pl-1": true,
                            "d-block": true,
                            admin: true,
                            "active-link": isProductCampaignLinkActive,
                          })}
                        >
                          <DripIcon
                            icon={DripIcons.Subscriptions}
                            className="menu-icon"
                          />
                          <Label className="menu-label ml-2 mt-2">
                            Products & Campaigns
                          </Label>
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({
                          "menu-box": true,
                          "py-2": true,
                        })}
                      >
                        <a
                          className={classnames({
                            "pl-1": true,
                            "d-block": true,
                            admin: true,
                            "py-2": true,
                          })}
                          href="https://dashboard.stripe.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <DripIcon
                            icon={DripIcons.Stripe}
                            className="menu-icon"
                          />
                          <span className="menu-label ml-2 mt-2">
                            Log in to Admin Stripe
                          </span>
                        </a>
                      </NavItem>
                    </>
                  )}
                {isProviderOrImpersonateView && (
                  <NavItem
                    className={classnames({
                      "menu-box": true,
                      "py-2": true,
                      "active-link":
                        window.location.pathname.indexOf("/dashboard") >= 0 ||
                        window.location.href.endsWith(
                          window.location.origin + basePathName + "/"
                        ) ||
                        window.location.href.endsWith(window.location.origin),
                    })}
                  >
                    <NavLink
                      activeClassName="active-link"
                      to={UrlHelper.getMenuLink(
                        currentUser?.permissions,
                        "/",
                        `${constants.PartialImpersonateBrowserPath}dashboard/${providerId}`
                      )}
                      className={classnames({
                        "pl-1": true,
                        "d-block": true,
                      })}
                    >
                      <DripIcon
                        icon={DripIcons.GraphBar}
                        className="menu-icon"
                      />
                      <Label className="menu-label ml-2 mt-2">Dashboard</Label>
                    </NavLink>
                  </NavItem>
                )}
                {isProviderOrImpersonateView && (
                  <NavItem
                    className={classnames({
                      "menu-box": true,
                      "py-2": true,
                      "active-link":
                        window.location.pathname.indexOf("/provider-profile") >=
                        0,
                    })}
                  >
                    <NavLink
                      to={UrlHelper.getMenuLink(
                        currentUser?.permissions,
                        "/provider-profile",
                        `${constants.PartialImpersonateBrowserPath}provider-profile/${providerId}`
                      )}
                      activeClassName="active-link"
                      className={classnames({
                        "pl-1": true,
                        "d-block": true,
                      })}
                    >
                      <DripIcon icon={DripIcons.Pencil} className="menu-icon" />
                      <Label className="menu-label ml-2 mt-2">
                        Edit Profile
                      </Label>
                    </NavLink>
                  </NavItem>
                )}
                {isProviderOrImpersonateView &&
                  providerProfileStatus?.publishedStatus &&
                  providerProfileStatus?.profileStatus?.value !==
                    ProfileStatus.Hidden &&
                  providerProfileStatus?.publishedStatus?.value ==
                    PublishStatus.Public && (
                    <NavItem
                      className={classnames({
                        "menu-box": true,
                        "py-2": true,
                      })}
                    >
                      <a
                        className={classnames({
                          "pl-1": true,
                          "d-block": true,
                        })}
                        target="_blank"
                        rel="noreferrer"
                        href={providerProfileStatus?.publishedProfileUrl}
                      >
                        <DripIcon icon={DripIcons.User} className="menu-icon" />
                        <Label className="menu-label ml-2 mt-2">
                          View Profile
                        </Label>
                      </a>
                    </NavItem>
                  )}
                {isProviderOrImpersonateView && (
                  <NavItem
                    className={classnames({
                      "menu-box": true,
                      "py-2": true,
                      "active-link":
                        window.location.pathname.indexOf("/settings") >= 0 ||
                        window.location.pathname.indexOf("/change-password") >=
                          0,
                    })}
                  >
                    <NavLink
                      to={UrlHelper.getMenuLink(
                        currentUser?.permissions,
                        "/settings",
                        `${constants.PartialImpersonateBrowserPath}settings/${providerId}`
                      )}
                      activeClassName="active-link"
                      className={classnames({
                        "pl-1": true,
                        "d-block": true,
                      })}
                    >
                      <DripIcon icon={DripIcons.Gear} className="menu-icon" />
                      <Label className="menu-label ml-2 mt-2">
                        Account Settings
                      </Label>
                    </NavLink>
                  </NavItem>
                )}
                {isProviderOrImpersonateView && (
                  <NavItem
                    className={classnames({
                      "menu-box": true,
                      "py-2": true,
                      "active-link":
                        window.location.pathname.indexOf("/help") >= 0,
                    })}
                  >
                    <NavLink
                      to={UrlHelper.getMenuLink(
                        currentUser?.permissions,
                        "/help",
                        `${constants.PartialImpersonateBrowserPath}help/${providerId}`
                      )}
                      activeClassName="active-link"
                      className={classnames({
                        "pl-1": true,
                        "d-block": true,
                      })}
                    >
                      <DripIcon
                        icon={DripIcons.Question}
                        className="menu-icon"
                      />
                      <Label className="menu-label ml-2 mt-2">Help</Label>
                    </NavLink>
                  </NavItem>
                )}
                {!AdminHelper.isUserBeingImpersonated() && (
                  <NavItem
                    className={classnames({ "menu-box": true, "py-2": true })}
                  >
                    <NavLink
                      to="/authentication/logout"
                      activeClassName="active-link"
                      className={classnames({
                        "pl-1": true,
                        "d-block": true,
                      })}
                    >
                      <DripIcon icon={DripIcons.Exit} className="menu-icon" />
                      <Label className="menu-label ml-2 mt-2">Logout</Label>
                    </NavLink>
                  </NavItem>
                )}
                {isProviderOrImpersonateView && (
                  <NavItem
                    className={classnames({ "menu-box": true, "py-2": true })}
                  >
                    <UpgradeAdButton providerId={providerId} />
                  </NavItem>
                )}
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </BodyLoadingSection>
      <main className="main__content">
        <div ref={mainTopRef} id="mainTopRef"></div>
        {children}
      </main>
    </div>
  );
};
