import { useEffect, useState, useRef } from "react";
import { Container, Form, Row, Col } from "reactstrap";
import {
  LoadingSection,
  ShareIcon,
  TextIcon,
  PhoneIcon,
  SearchIcon,
  LargeVisibilityIcon,
  EmailIcon,
} from "../../Components/Display";
import classnames from "classnames";
import { useGlobalState } from "../../Context";
import { DashboardVM, Timeframe } from "./Models";
import { useApiWorker } from "../../Utilities/CommonHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as IconRegular from "@fortawesome/free-regular-svg-icons";
import { useHistory } from "react-router-dom";
import "./Dashboard.scss";
import { Helper } from "../../Utilities/HelperData";
import ValidationErrors from "../Errors/ValidationErrors";
import SessionExpiredError from "../Errors/SessionExpiredError";
import { genericServerError } from "../../Utilities/HelperData";
import {
  ProfileStatus,
  PublishStatus,
} from "../../Components/ProviderProfile/Models";
import { CoreEnum } from "../Shared/Models/SharedModels";
import { ProfileStatusIndicator } from "../Home/ProfileStatusIndicator";
import { useParams } from "react-router";
import { UrlHelper } from "../../Utilities/UrlHelper";
import { apiUrls, constants } from "../../Utilities/Constants";
import { SessionStorageHelper } from "../../Utilities/SessionStorageHelper";
import { UpgradeAdBanner } from "../Shared/UpgradeAdBanner";

export const Dashboard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dashboardData, setDashboardData] = useState<DashboardVM>(
    new DashboardVM()
  );
  const { customerSupportContactEmail, customerSupportContactPhoneNumber } =
    useGlobalState();
  const scrollElementRef = useRef<null | HTMLDivElement>(null);
  const pageElementRef = useRef<null | HTMLDivElement>(null);
  const { currentUser, subscriptionStatus, providerProfileStatus } =
    useGlobalState();
  const [selectedFilter, setSelectedFilter] = useState(4);
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const apiWorker = useApiWorker();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dashboardServerError = "There was an error retrieving analytics data from the server.";

  useEffect(() => {
    if (currentUser) {
      // This value is returned by the ProfileService.cs and is set only when the provider
      // has not picked a subscription. However the ProfileService.cs is not called after getting
      // a subscription so we need to rely on SetProfileStatus
      if (subscriptionStatus === "register") {
        history.push("/usersubscription");
        return;
      }
      SessionStorageHelper.clearSessionStorageItem(constants.ReturnUrl);
      getDashboardData(4);
    }
  }, [currentUser]);

  const handleServerErrors = (errors: any): boolean => {
    if (errors?.status === 401) {
      setSessionExpired(true);
      scrollToTop();
      return true;
    } else if (errors?.status === 500) {
      setServerErrors([
        genericServerError +
          `${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`,
      ]);
      scrollToTop();
      return true;
    }
    return false;
  };

  const getDashboardData = async (timeframe: Timeframe) => {
    if (!isLoading) setIsLoading(true);

    const dashboardData = await apiWorker
      .get<DashboardVM>(
        UrlHelper.getApiUrl(
          currentUser?.permissions,
          `${apiUrls.DashboardUrl}/${timeframe}`,
          `${apiUrls.ImpersonateDashboardUrl}${id}/${timeframe}`
        ),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((err) => {
        handleServerErrors(err);
        setIsLoading(false);
      });

    if (dashboardData && dashboardData.data && typeof(dashboardData.data.profileViews) === "number") {
      setDashboardData(dashboardData.data);
    }
    else {
        //although no error was thrown, data did not come back as expected 
        setServerErrors([
        dashboardServerError
      ]);
      scrollToTop();
    }
    setIsLoading(false);
  };

  const scrollToTop = () => {
    if (scrollElementRef && scrollElementRef.current) {
      scrollElementRef.current.scrollIntoView({ behavior: "auto" });
    }
  };

  return (
    <div ref={pageElementRef} className="w-100">
      <Container fluid={true} className="mt-4 position-relative">
        <LoadingSection
          isLoading={isLoading}
          height={pageElementRef.current?.clientHeight}
          width={pageElementRef.current?.clientWidth}
        >
          <div ref={scrollElementRef} id="scrollElement"></div>
          <UpgradeAdBanner providerId={id} />
          <Form className="ml-5 mr-5">
            <Row>
              <Col md="12" className="pt-3 mt-2 px-0">
                <div className="float-left">
                  <ProfileStatusIndicator
                    profileStatus={
                      new CoreEnum(
                        providerProfileStatus?.profileStatus?.value ||
                          ProfileStatus.Incomplete.toString(),
                        providerProfileStatus?.profileStatus?.value?.toString() ||
                          ProfileStatus.Incomplete.toString()
                      )
                    }
                    publishStatus={
                      new CoreEnum(
                        providerProfileStatus?.publishedStatus?.value ||
                          PublishStatus.None.toString(),
                        providerProfileStatus?.publishedStatus?.value?.toString() ||
                          PublishStatus.None.toString()
                      )
                    }
                    publishSucceeded={
                      providerProfileStatus?.publishSucceeded || false
                    }
                  ></ProfileStatusIndicator>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="px-0">
                <ValidationErrors
                  serverErrors={serverErrors}
                  formErrors={[]}
                  customErrors={[]}
                  className="mt-2 mb-0"
                />
                <SessionExpiredError showError={sessionExpired} />
              </Col>
            </Row>
            <Row>
              <Col md="12" className="pt-2">
                <Row className="pt-4 justify-content-end pr-3">
                  <Col md="12 px-0 dashboard-filter">
                    <label className="regular-font pr-3">
                      Filter by Time Period:
                    </label>
                    <button
                      className={classnames({
                        "text-center": true,
                        "dashboard-link": true,
                        "regular-font": true,
                        "highlighted-link": selectedFilter === 0,
                        "text-decoration-none": true,
                      })}
                      onClick={(event: any) => {
                        event.preventDefault();
                        setSelectedFilter(0);
                        getDashboardData(Timeframe.Last30Days);
                      }}
                    >
                      Last 30 Days
                    </button>
                    <button
                      className={classnames({
                        "text-center": true,
                        "dashboard-link": true,
                        "regular-font": true,
                        "highlighted-link": selectedFilter === 1,
                        "text-decoration-none": true,
                      })}
                      onClick={(event: any) => {
                        event.preventDefault();
                        setSelectedFilter(1);
                        getDashboardData(Timeframe.Last60Days);
                      }}
                    >
                      Last 60 Days
                    </button>
                    <button
                      className={classnames({
                        "text-center": true,
                        "dashboard-link": true,
                        "regular-font": true,
                        "highlighted-link": selectedFilter === 2,
                        "text-decoration-none": true,
                      })}
                      onClick={(event: any) => {
                        event.preventDefault();
                        setSelectedFilter(2);
                        getDashboardData(Timeframe.Last90Days);
                      }}
                    >
                      Last 90 Days
                    </button>
                    <button
                      className={classnames({
                        "text-center": true,
                        "dashboard-link": true,
                        "regular-font": true,
                        "highlighted-link": selectedFilter === 3,
                        "text-decoration-none": true,
                      })}
                      onClick={(event: any) => {
                        event.preventDefault();
                        setSelectedFilter(3);
                        getDashboardData(Timeframe.Last120Days);
                      }}
                    >
                      Last 12 Months
                    </button>
                    <button
                      className={classnames({
                        "text-center": true,
                        "dashboard-link": true,
                        "regular-font": true,
                        "highlighted-link": selectedFilter === 4,
                        "text-decoration-none": true,
                      })}
                      onClick={(event: any) => {
                        event.preventDefault();
                        setSelectedFilter(4);
                        getDashboardData(Timeframe.Lifetime);
                      }}
                    >
                      Lifetime
                    </button>
                  </Col>
                </Row>
                <Row className="pt-3">
                  <Col md="12" className="mb-2 px-0">
                    <label className="subTitle pt-2">Profile Visibilty</label>
                  </Col>
                  <Col md="6" sm="6" className="pb-4">
                    <Row className="pl-0 pr-4">
                      <Col className="px-3">
                        <Row className="shadow-box">
                          <Col md="12" className="mt-2">
                            <SearchIcon />
                            <span className="total pl-1">
                              {Helper.formatMoney(
                                dashboardData.searchResultsMatches || 0,
                                0
                              )}
                            </span>
                          </Col>
                          <Col md="12" className="total-label mb-3 pl-4">
                            <label>
                              Search results that included your profile
                              <span className="help-text-button">
                                <FontAwesomeIcon
                                  icon={IconRegular.faQuestionCircle}
                                  className="visible shape"
                                />
                                <span className="help-text">
                                  The number of times your profile appeared in
                                  search results during the selected period.
                                </span>
                              </span>
                            </label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6" sm="6" className="pb-4">
                    <Row className="pl-0 pr-4">
                      <Col className="px-3">
                        <Row className="shadow-box">
                          <Col md="12" className="mt-2">
                            <LargeVisibilityIcon />
                            <span className="total pl-1">
                              {Helper.formatMoney(
                                dashboardData.profileViews || 0,
                                0
                              )}
                            </span>
                          </Col>
                          <Col md="12" className="total-label mb-3 pl-4">
                            <label>
                              Your profile views
                              <span className="help-text-button">
                                <FontAwesomeIcon
                                  icon={IconRegular.faQuestionCircle}
                                  className="visible shape"
                                />
                                <span className="help-text">
                                  The number of times your profile was viewed
                                  during the selected period.
                                </span>
                              </span>
                            </label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="mb-2">
                    <label className="subTitle pt-2">
                      Profile Interactions
                    </label>
                  </Col>
                  <Col lg="3" md="6" className="mb-4">
                    <Row className="pl-0 pr-4">
                      <Col className="px-3">
                        <Row className="shadow-box">
                          <Col md="12" className="mt-2">
                            <EmailIcon />
                            <span className="total pl-1">
                              {Helper.formatMoney(
                                dashboardData.clickedToEmail || 0,
                                0
                              )}
                            </span>
                          </Col>
                          <Col md="12" className="total-label mb-3 pl-4">
                            <label>
                              Clicked to Email
                              <span className="help-text-button">
                                <FontAwesomeIcon
                                  icon={IconRegular.faQuestionCircle}
                                  className="visible shape"
                                />
                                <span className="help-text">
                                  The number of times users emailed you using
                                  the email option associated with your profile.
                                </span>
                              </span>
                            </label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="3" md="6" className="mb-4">
                    <Row className="pl-0 pr-4">
                      <Col className="px-3">
                        <Row className="shadow-box">
                          <Col md="12" className="mt-2">
                            <PhoneIcon />
                            <span className="total pl-1">
                              {Helper.formatMoney(
                                dashboardData.clickedToCall || 0,
                                0
                              )}
                            </span>
                          </Col>
                          <Col md="12" className="total-label mb-3 pl-4">
                            <label>
                              Clicked to Call
                              <span className="help-text-button">
                                <FontAwesomeIcon
                                  icon={IconRegular.faQuestionCircle}
                                  className="visible shape"
                                />
                                <span className="help-text">
                                  The number of times users clicked the call
                                  option associated with your profile during the
                                  selected period. This does not indicate the
                                  total number of calls received.
                                </span>
                              </span>
                            </label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="3" md="6" className="mb-4">
                    <Row className="pl-0 pr-4">
                      <Col className="px-3">
                        <Row className="shadow-box">
                          <Col md="12" className="mt-2">
                            <TextIcon />
                            <span className="total pl-1">
                              {Helper.formatMoney(
                                dashboardData.clickedToText || 0,
                                0
                              )}
                            </span>
                          </Col>
                          <Col md="12" className="total-label mb-3 pl-4">
                            <label>
                              Clicked to Text
                              <span className="help-text-button">
                                <FontAwesomeIcon
                                  icon={IconRegular.faQuestionCircle}
                                  className="visible shape"
                                />
                                <span className="help-text">
                                  The number of times users clicked the text
                                  option associated with your profile during the
                                  selected period. This does not indicate the
                                  total number of texts received.
                                </span>
                              </span>
                            </label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="3" md="6" className="mb-4">
                    <Row className="pl-0 pr-4">
                      <Col className="px-3">
                        <Row className="shadow-box">
                          <Col md="12" className="mt-2">
                            <ShareIcon />
                            <span className="total pl-1">
                              {Helper.formatMoney(
                                dashboardData.clickedToShare || 0,
                                0
                              )}
                            </span>
                          </Col>
                          <Col md="12" className="total-label mb-3 pl-4">
                            <label>
                              Clicked to Share
                              <span className="help-text-button">
                                <FontAwesomeIcon
                                  icon={IconRegular.faQuestionCircle}
                                  className="visible shape"
                                />
                                <span className="help-text">
                                  The number of times users shared your profile
                                  using the share option associated with your
                                  profile.
                                </span>
                              </span>
                            </label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </LoadingSection>
      </Container>
    </div>
  );
};
