import "./App.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { GlobalProvider } from "./Context";
import { Subscription, UserSubscription, Billing } from "./Features/Registration";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { ToastContainer } from "react-toastify";
import PrivateLayout from "./PrivateLayout";
import { AuthorizeRoute } from "./Components/ApiAuthorization/AuthorizeRoute";
import { ApplicationPaths } from "./Components/ApiAuthorization/ApiAuthorizationConstants";
import { ApiAuthorizationRoutes } from "./Components/ApiAuthorization/ApiAuthorizationRoutes";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./Components/Display/ScrollToTop";
import { basePathName } from "./Utilities/HelperData";
import { constants } from "./Utilities/Constants";

library.add(fab);

export const App = () => {
  return (
    <BrowserRouter basename={basePathName}>
      <ScrollToTop />
      <ToastContainer />
      <GlobalProvider>
        <Switch>
          <AuthorizeRoute
            path={"/register/:offerPage"}
            component={UserSubscription}
            permissions={["providerprofile.editself"]}
          ></AuthorizeRoute>
          <AuthorizeRoute
            path="/register"
            component={UserSubscription}
            permissions={["providerprofile.editself"]}
          ></AuthorizeRoute>
          <AuthorizeRoute
            path={`${constants.CampaignPreview}/:offerPage`}
            component={UserSubscription}
            permissions={["providerprofile.editany"]}
          ></AuthorizeRoute>
          <AuthorizeRoute
            path={constants.CampaignPreview}
            component={UserSubscription}
            permissions={["providerprofile.editany"]}
          ></AuthorizeRoute>
          <AuthorizeRoute
            path="/billing"
            component={Billing}
            permissions={["providerprofile.editself"]}
          ></AuthorizeRoute>
          <AuthorizeRoute
            path={"/usersubscription/:offerPage"}
            component={UserSubscription}
            permissions={["providerprofile.editself"]}
          ></AuthorizeRoute>
          <AuthorizeRoute
            path="/usersubscription"
            component={UserSubscription}
            permissions={["providerprofile.editself"]}
          ></AuthorizeRoute>
          <Route path="/subscription/:offerPage" component={Subscription} />
          <Route path="/subscription" component={Subscription} />
          <Route
            path={ApplicationPaths.ApiAuthorizationPrefix}
            component={ApiAuthorizationRoutes}
          />
          <Route path="/" component={PrivateLayout}></Route>
        </Switch>
      </GlobalProvider>
    </BrowserRouter>
  );
};

export default App;
