import { ChangeEvent, Fragment, useState, useEffect } from "react";
import { Button, Col, FormGroup, NavLink, Row } from "reactstrap";
import { CheckedDay, ErrorMessage } from ".";
import { BusinessHoursVM } from "../../Components/ProviderProfile/Models/BusinessHoursVM";
import { DataDropDown } from "../Form/Inputs";
import "./BusinessHours.scss";
import classnames from "classnames";
import { v4 as uuid } from "uuid";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import { redBorder, grayBorder, white, red } from "../../Utilities/HelperData";

interface IProps {
  elements: BusinessHoursVM[] | undefined;
  formErrors: any;
  handleChange: (
    event: ChangeEvent<HTMLInputElement>,
    items: BusinessHoursVM[] | undefined
  ) => void;
}

export const BusinessHours = ({
  elements,
  handleChange,
  formErrors,
}: IProps) => {
  useEffect(() => {
    setItems(elements);
  }, [elements]);

  const [items, setItems] = useState(elements);
  const options: any[] = [
    { value: "AM", label: "AM" },
    { value: "PM", label: "PM" },
  ];

  const handleInputMaskChange = (
    event: ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const elements = items?.map((el) =>
      el.id === id ? { ...el, [event.target.name]: event?.target.value } : el
    );
    setItems(elements);
    event.target.type = "businessHours";
    handleChange(event, elements);
  };

  const handleFromDropDownChange = (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
    text: string
  ) => {
    const value = {
      value: event.target.value,
      label: text,
    };
    const elements = items?.map((el) =>
      el.id === id ? { ...el, [event.target.name]: value } : el
    );
    setItems(elements);
    event.target.type = "businessHours";
    handleChange(event, elements);
  };

  const handleCheckChange = (
    event: any,
    checked: boolean,
    name: string,
    id: string
  ) => {
    const elements = items?.map((el) =>
      el.id === id ? { ...el, [name]: checked } : el
    );
    setItems(elements);
    event.target.type = "businessHours";
    handleChange(event, elements);
  };

  const addRow = (event: any) => {
    event.preventDefault();
    const item = new BusinessHoursVM();
    item.id = uuid();
    item.startPeriodType = options[0];
    item.endPeriodType = options[0];
    let elements = [...(items || []), item];
    setItems(elements);
    handleChange(event, elements);
  };

  const deleteRow = (event: any, id: string) => {
    event.preventDefault();
    const elements = items?.filter((x) => x.id !== id);
    setItems(elements);
    event.target.type = "businessHours";
    handleChange(event, elements);
  };

  const customStyles = {
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: "0",
    }),
    valueContainer: (base: any) => ({
      ...base,
      fontSize: "14px",
    }),
    control: (base: any) => ({
      ...base,
      width: "55px",
    }),
    container: (base: any) => ({
      ...base,
      width: "55px",
    }),
  };

  return (
    <Fragment>
      {items?.map((item, index) => (
        <Row key={item.id} className="businessHours">
          <Col md="6">
            <Row className="pl-2">
              <Col xs="4" sm="2" md="auto pl-0 pr-0">
                <CheckedDay
                  text="Mon"
                  name="onMonday"
                  isChecked={item.onMonday || false}
                  handle={(event, checked, name) => {
                    handleCheckChange(event, checked, name, item.id);
                    if (formErrors) {
                      formErrors[`businessHours[${index}].onMonday`] =
                        undefined;
                      formErrors[`businessHours[${index}].OnMonday`] =
                        undefined;
                    }
                  }}
                  classname={classnames({
                    highlighted:
                      isNotUndefined(
                        formErrors[`businessHours[${index}].onMonday`]
                      ) ||
                      isNotUndefined(
                        formErrors[`businessHours[${index}].OnMonday`]
                      ),
                  })}
                ></CheckedDay>
              </Col>
              <Col xs="4" sm="2 pr-0" md="auto pl-0 pr-0">
                <CheckedDay
                  text="Tue"
                  name="onTuesday"
                  isChecked={item.onTuesday || false}
                  handle={(event, checked, name) => {
                    handleCheckChange(event, checked, name, item.id);
                    if (formErrors) {
                      formErrors[`businessHours[${index}].onMonday`] =
                        undefined;
                      formErrors[`businessHours[${index}].OnMonday`] =
                        undefined;
                    }
                  }}
                  classname={classnames({
                    highlighted:
                      isNotUndefined(
                        formErrors[`businessHours[${index}].onMonday`]
                      ) ||
                      isNotUndefined(
                        formErrors[`businessHours[${index}].OnMonday`]
                      ),
                  })}
                ></CheckedDay>
              </Col>
              <Col xs="4" sm="2 pr-0" md="auto pl-0 pr-0">
                <CheckedDay
                  text="Wed"
                  name="onWednesday"
                  isChecked={item.onWednesday || false}
                  handle={(event, checked, name) => {
                    handleCheckChange(event, checked, name, item.id);
                    if (formErrors) {
                      formErrors[`businessHours[${index}].onMonday`] =
                        undefined;
                      formErrors[`businessHours[${index}].OnMonday`] =
                        undefined;
                    }
                  }}
                  classname={classnames({
                    highlighted:
                      isNotUndefined(
                        formErrors[`businessHours[${index}].onMonday`]
                      ) ||
                      isNotUndefined(
                        formErrors[`businessHours[${index}].OnMonday`]
                      ),
                  })}
                ></CheckedDay>
              </Col>
              <Col xs="4" sm="2 pr-0" md="auto pl-0 pr-0">
                <CheckedDay
                  text="Thu"
                  name="onThursday"
                  isChecked={item.onThursday || false}
                  handle={(event, checked, name) => {
                    handleCheckChange(event, checked, name, item.id);
                    if (formErrors) {
                      formErrors[`businessHours[${index}].onMonday`] =
                        undefined;
                      formErrors[`businessHours[${index}].OnMonday`] =
                        undefined;
                    }
                  }}
                  classname={classnames({
                    highlighted:
                      isNotUndefined(
                        formErrors[`businessHours[${index}].onMonday`]
                      ) ||
                      isNotUndefined(
                        formErrors[`businessHours[${index}].OnMonday`]
                      ),
                  })}
                ></CheckedDay>
              </Col>
              <Col xs="4" sm="2 pr-0" md="auto pl-0 pr-0">
                <CheckedDay
                  text="Fri"
                  name="onFriday"
                  isChecked={item.onFriday || false}
                  handle={(event, checked, name) => {
                    handleCheckChange(event, checked, name, item.id);
                    if (formErrors) {
                      formErrors[`businessHours[${index}].onMonday`] =
                        undefined;
                      formErrors[`businessHours[${index}].OnMonday`] =
                        undefined;
                    }
                  }}
                  classname={classnames({
                    highlighted:
                      isNotUndefined(
                        formErrors[`businessHours[${index}].onMonday`]
                      ) ||
                      isNotUndefined(
                        formErrors[`businessHours[${index}].OnMonday`]
                      ),
                  })}
                ></CheckedDay>
              </Col>
              <Col xs="4" sm="2 pr-0" md="auto pl-0 pr-0">
                <CheckedDay
                  text="Sat"
                  name="onSaturday"
                  isChecked={item.onSaturday || false}
                  handle={(event, checked, name) => {
                    handleCheckChange(event, checked, name, item.id);
                    if (formErrors) {
                      formErrors[`businessHours[${index}].onMonday`] =
                        undefined;
                      formErrors[`businessHours[${index}].OnMonday`] =
                        undefined;
                    }
                  }}
                  classname={classnames({
                    highlighted:
                      isNotUndefined(
                        formErrors[`businessHours[${index}].onMonday`]
                      ) ||
                      isNotUndefined(
                        formErrors[`businessHours[${index}].OnMonday`]
                      ),
                  })}
                ></CheckedDay>
              </Col>
              <Col xs="4" sm="2 pr-0" md="auto pl-0 pr-0">
                <CheckedDay
                  text="Sun"
                  name="onSunday"
                  isChecked={item.onSunday || false}
                  handle={(event, checked, name) => {
                    handleCheckChange(event, checked, name, item.id);
                    if (formErrors) {
                      formErrors[`businessHours[${index}].onMonday`] =
                        undefined;
                      formErrors[`businessHours[${index}].OnMonday`] =
                        undefined;
                    }
                  }}
                  classname={classnames({
                    highlighted:
                      isNotUndefined(
                        formErrors[`businessHours[${index}].onMonday`]
                      ) ||
                      isNotUndefined(
                        formErrors[`businessHours[${index}].OnMonday`]
                      ),
                  })}
                ></CheckedDay>
              </Col>
              <ErrorMessage
                visible={
                  isNotUndefined(
                    formErrors[`businessHours[${index}].onMonday`]
                  ) ||
                  isNotUndefined(formErrors[`businessHours[${index}].OnMonday`])
                }
                message={
                  formErrors[`businessHours[${index}].onMonday`] ||
                  formErrors[`businessHours[${index}].OnMonday`]
                }
              />
            </Row>
          </Col>
          <Col md="2 mt-1" sm="5 mt-1" xs="12 mt-1">
            <Row>
              <Col xs="6" sm="6 pr-0" md="auto pr-0">
                <FormGroup>
                  <NumberFormat
                    className={classnames({
                      highlighted:
                        isNotUndefined(
                          formErrors[`businessHours[${index}].startTime`]
                        ) ||
                        isNotUndefined(
                          formErrors[`businessHours[${index}].StartTime`]
                        ),
                      timeInput: true,
                    })}
                    name="startTimeUI"
                    defaultValue={item.startTimeUI}
                    value={item.startTimeUI}
                    allowEmptyFormatting
                    format="##:##"
                    mask="_"
                    onChange={(selected) => {
                      handleInputMaskChange(selected, item.id);
                    }}
                  ></NumberFormat>
                </FormGroup>
              </Col>
              <Col xs="6" sm="6 pr-0" md="auto pr-0">
                <DataDropDown
                  fieldName="startPeriodType"
                  fieldText="Start"
                  hideLabel={true}
                  values={options}
                  styles={{
                    ...customStyles,
                    control: (provided: any) => ({
                      ...provided,
                      border:
                        isNotUndefined(
                          formErrors[`businessHours[${index}].startTime`]
                        ) ||
                        isNotUndefined(
                          formErrors[`businessHours[${index}].StartTime`]
                        )
                          ? redBorder
                          : grayBorder,
                      backgroundColor:
                        isNotUndefined(
                          formErrors[`businessHours[${index}].startTime`]
                        ) ||
                        isNotUndefined(
                          formErrors[`businessHours[${index}].StartTime`]
                        )
                          ? red
                          : white,
                    }),
                  }}
                  selectedValue={item.startPeriodType}
                  handleChange={(selected, text) => {
                    handleFromDropDownChange(selected, item.id, text);
                  }}
                  className={classnames({ selectControl: true })}
                ></DataDropDown>
              </Col>
              <ErrorMessage
                visible={
                  isNotUndefined(
                    formErrors[`businessHours[${index}].startTime`]
                  ) ||
                  isNotUndefined(
                    formErrors[`businessHours[${index}].StartTime`]
                  )
                }
                message={
                  formErrors[`businessHours[${index}].startTime`] ||
                  formErrors[`businessHours[${index}].StartTime`]
                }
              />
            </Row>
          </Col>
          <Col md="2 mt-1" sm="5 mt-1" xs="12 mt-1">
            <Row>
              <Col xs="6" sm="6 pr-0" md="auto pr-0">
                <FormGroup>
                  <NumberFormat
                    className={classnames({
                      highlighted:
                        isNotUndefined(
                          formErrors[`businessHours[${index}].endTime`]
                        ) ||
                        isNotUndefined(
                          formErrors[`businessHours[${index}].EndTime`]
                        ),
                      timeInput: true,
                    })}
                    name="endTimeUI"
                    defaultValue={item.endTimeUI}
                    value={item.endTimeUI}
                    allowEmptyFormatting
                    format="##:##"
                    mask="_"
                    onChange={(selected) => {
                      handleInputMaskChange(selected, item.id);
                    }}
                  ></NumberFormat>
                </FormGroup>
              </Col>
              <Col xs="6" sm="6 pr-0" md="auto pr-0">
                <DataDropDown
                  fieldName="endPeriodType"
                  fieldText="End"
                  styles={{
                    ...customStyles,
                    control: (provided: any) => ({
                      ...provided,
                      border:
                        isNotUndefined(
                          formErrors[`businessHours[${index}].endTime`]
                        ) ||
                        isNotUndefined(
                          formErrors[`businessHours[${index}].EndTime`]
                        )
                          ? redBorder
                          : grayBorder,
                      backgroundColor:
                        isNotUndefined(
                          formErrors[`businessHours[${index}].endTime`]
                        ) ||
                        isNotUndefined(
                          formErrors[`businessHours[${index}].EndTime`]
                        )
                          ? red
                          : white,
                    }),
                  }}
                  hideLabel={true}
                  values={options}
                  selectedValue={item.endPeriodType}
                  handleChange={(selected, text) => {
                    handleFromDropDownChange(selected, item.id, text);
                  }}
                  className={classnames({ selectControl: true })}
                ></DataDropDown>
              </Col>
              <ErrorMessage
                visible={
                  isNotUndefined(
                    formErrors[`businessHours[${index}].endTime`]
                  ) ||
                  isNotUndefined(formErrors[`businessHours[${index}].EndTime`])
                }
                message={
                  formErrors[`businessHours[${index}].endTime`] ||
                  formErrors[`businessHours[${index}].EndTime`]
                }
              />
            </Row>
          </Col>
          <Col md="1">
            <Button
              className="trashCan"
              onClick={(event) => deleteRow(event, item.id)}
            >
              <FontAwesomeIcon size="lg" icon={Icon.faTrash}></FontAwesomeIcon>
            </Button>
          </Col>
        </Row>
      ))}
      <Row>
        <Col md="4">
          <NavLink className="plusMessage pt-0" onClick={addRow}>
            <FontAwesomeIcon size="sm" icon={Icon.faPlus}></FontAwesomeIcon>
            &nbsp;&nbsp;Add hours of operation
          </NavLink>
        </Col>
      </Row>
    </Fragment>
  );
};
