import { Container, Form, Row, Col } from "reactstrap";
import { useApiWorker } from "../../Utilities/CommonHooks";
import { useGlobalState } from "../../Context";
import { useEffect, useRef, useCallback, useState } from "react";
import { useParams, useHistory } from "react-router";
import { ErrorAlert, LoadingSection } from "../../Components/Display";
import { SessionStorageHelper } from "../../Utilities/SessionStorageHelper";
import { apiUrls, constants } from "../../Utilities/Constants";
import { UrlHelper } from "../../Utilities/UrlHelper";
import { SkinnedButton } from "../../Components/Form/Buttons";
import { useCustomForm } from "../../Utilities/UseCustomForm";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  Helper,
  genericServerError,
} from "../../Utilities/HelperData";
import ValidationErrors from "../Errors/ValidationErrors";
import SessionExpiredError from "../Errors/SessionExpiredError";
import { TextAreaInput, TextInput } from "../../Components/Form/Inputs";
import classnames from "classnames";

class ContactSupportFormValues {
  name?: string = "";
  subject?: string = "";
  comments?: string = "";

  constructor(name: string) {
    this.name = name;
  }
}

const validationSchema = Yup.object({
  name: Yup.string().required("Please enter your name."),
  comments: Yup.string().required("Please enter a message."),
});

export const ContactSupport = () => {
  const {
    currentUser,
    customerSupportContactEmail,
    customerSupportContactPhoneNumber,
  } = useGlobalState();
  const history = useHistory();
  const apiWorker = useApiWorker();
  const pageElementRef = useRef<null | HTMLDivElement>(null);
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const initialValues = new ContactSupportFormValues(currentUser?.user_fullname ?? "");
  const [serverErrors, setServerErrors] = useState<any[] | null>(null);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const [errorMessageIsVisible, setErrorMessageIsVisible] =
      useState<boolean>(false);

  const checkIsActive = useCallback(async () => {
    await apiWorker
      .get<string>(
        UrlHelper.getApiUrl(
          currentUser?.permissions,
          apiUrls.HelpUrl,
          `${apiUrls.ImpersonateHelpUrl}${id}`
        )
      )
      .catch((err: any) => {});
  }, [apiWorker, currentUser?.permissions, id]);

  useEffect(() => {
    if (currentUser) {
      checkIsActive();
      SessionStorageHelper.clearSessionStorageItem(constants.ReturnUrl);
    }
  }, [checkIsActive, currentUser]);

  const {
    values,
    setFormErrors,
    setValues,
    formErrors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useCustomForm({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      const noErrors = Object.keys(values.errors || {}).length === 0;
      if (!noErrors) {
        setErrorMessageIsVisible(true);
        setServerErrors(null);
        return;
      }

      setErrorMessageIsVisible(false);
      setIsLoading(true);
      let success = true;
      const result = await apiWorker
        .put<ContactSupportFormValues>(
          UrlHelper.getApiUrl(
            currentUser?.permissions,
            apiUrls.ContactSupportUrl,
            `${apiUrls.ImpersonateContactSupportUrl}${id}`
          ),
          // providerId is only used by the fluent validator during impersonation
          { ...values.values, impersonateProviderId: id },
          {}
        )
        .catch((errors: any) => {
          setIsLoading(false);
          if (handleServerErrors(errors)) {
            success = false;
            return;
          }

          if (
            errors &&
            errors.isAxiosError === undefined &&
            Object.keys(errors).length &&
            Object.keys(errors).filter((x) => x !== "").length
          ) {
            setErrorMessageIsVisible(true);
          }

          errors = Helper.cleanUpErrorMessages(errors);
          setFormErrors(errors);

          let err: any = Helper.convertNonFieldErrorsToArray(errors);
          if (err && Array.isArray(err) && err.length) {
            setServerErrors(err);
          } else {
            setServerErrors([]);
          }
        });

      if (!success) return;

      if (result && result.data) {
        setServerErrors(null);
        toast.success("Thank you. Your message has been sent to our Support Team.");
      } else if (result && !result.data) {
        setServerErrors([
          genericServerError +
            `${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`,
        ]);
      }
      setIsLoading(false);
    },
  });

  const handleServerErrors = (errors: any): boolean => {
    if (errors?.status === 401) {
      setSessionExpired(true);
      setErrorMessageIsVisible(false);
      return true;
    } else if (errors?.status === 500) {
      setServerErrors([
        genericServerError +
          `${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`,
      ]);
      setErrorMessageIsVisible(false);
      return true;
    }
    return false;
  };

    const goBack = async (event: any) => {
      event.preventDefault();
      history.goBack();
    };

    return (
      <Container fluid={true} className="mt-4 position-relative">
        <LoadingSection
          isLoading={isLoading}
          height={pageElementRef.current?.clientHeight}
          width={pageElementRef.current?.clientWidth}
        >
          <div ref={pageElementRef}>
            <Form className="ml-5 mr-5">
              <Row>
                <Col className="px-0">
                  {errorMessageIsVisible && (
                    <ErrorAlert message="Please update the highlighted fields before sending."></ErrorAlert>
                  )}
                  <ValidationErrors
                    serverErrors={serverErrors}
                    formErrors={{}}
                    customErrors={[]}
                  />
                  <SessionExpiredError showError={sessionExpired} />
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md="5" className="px-0 mt-2 mb-n2">
                  <h2 className="blue-header">Contact Customer Support</h2>
                </Col>
                <Col md="7" className="pt-2 px-0 text-right">
                  <SkinnedButton onClick={goBack} className="btn-profile-edit">
                    Go Back
                  </SkinnedButton>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <TextInput
                    formGroupClass="mb-0 mt-1"
                    maxLength={100}
                    labelName="Your Name"
                    requiredField={true}
                    className={classnames({
                      highlighted:
                        isNotUndefined(formErrors.name) ||
                        isNotUndefined(formErrors.Name),
                    })}
                    fieldText={values.name || ""}
                    fieldName="name"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="name"
                    error={formErrors.name || formErrors.Name}
                  ></TextInput>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="pt-2">
                  <TextAreaInput
                    maxLength={5000}
                    labelName="Describe your issue, question or comment"
                    requiredField={true}
                    fieldText={values.comments || ""}
                    fieldName="comments"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    rows={10}
                    autoComplete="new-password"
                    error={formErrors.comments || formErrors.Comments}
                  ></TextAreaInput>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <SkinnedButton
                    onClick={(event: any) => handleSubmit(event)}
                    className="ml-2 btn-profile-edit mb-3"
                    color="primary"
                  >
                    Send
                  </SkinnedButton>
                </Col>
              </Row>
            </Form>
          </div>
        </LoadingSection>
      </Container>
    );
};
