import { useEffect, useRef } from "react";
import { Container, Form, Row, Col } from "reactstrap";
import { LoadingSection, VisibilityOffIcon } from "../../Components/Display";
import { useGlobalState } from "../../Context";
import "./Help.scss";
import { useApiWorker } from "../../Utilities/CommonHooks";
import { useParams } from "react-router";
import { UrlHelper } from "../../Utilities/UrlHelper";
import { apiUrls, constants } from "../../Utilities/Constants";
import { SessionStorageHelper } from "../../Utilities/SessionStorageHelper";
import { NavLink } from "react-router-dom";

export const Help = () => {
  const {
    writeForUSUrl,
    customerSupportContactPhoneNumber,
    customerSupportContactEmail,
    currentUser,
  } = useGlobalState();
  const apiWorker = useApiWorker();
  const pageElementRef = useRef<null | HTMLDivElement>(null);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (currentUser) {
      // the help screen originally didn't contact the api and would not realize a provider
      // could be deactivated. This call is to validate the provider is still active
      checkIsActive();
      SessionStorageHelper.clearSessionStorageItem(constants.ReturnUrl);
    }
  }, [currentUser]);

  const checkIsActive = async () => {
    await apiWorker
      .get<string>(
        UrlHelper.getApiUrl(
          currentUser?.permissions,
          apiUrls.HelpUrl,
          `${apiUrls.ImpersonateHelpUrl}${id}`
        )
      )
      .catch((err: any) => {});
  };

  return (
    <Container fluid={true} className="mt-4 position-relative">
      <LoadingSection
        isLoading={false}
        height={pageElementRef.current?.clientHeight}
        width={pageElementRef.current?.clientWidth}
      >
        <div ref={pageElementRef}>
          <Form className="ml-5 mr-5 large-font">
            <Row>
              <Col className="px-0 pt-3 mt-2">
                <h2 className="blue-header">FAQs</h2>
              </Col>
            </Row>
            <Row>
              <Col className="px-0 blue-header">
                <Row className="pb-3">
                  <Col md="12" className="pb-0 font-weight-bold">
                    How will I know whether my profile has been approved?
                  </Col>
                  <Col md="12" className="black-font font-weight-light">
                    You will receive an email from us within 2-4 business days
                    regarding the status of your profile. You can also look at
                    the top of your therapist.com Edit Profile page; if
                    approved, your Profile Status will change from "Pending
                    Review" to "Listed."
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md="12" className="pb-0 font-weight-bold">
                    Once my profile is approved, does that mean it's listed, (or
                    "live") on therapist.com?
                  </Col>
                  <Col md="12" className="black-font font-weight-light">
                    Yes, once your profile is approved by the therapist.com
                    team, it is live on therapist.com. If you decide you want to
                    remove your profile from the therapist.com, you can adjust
                    your Public Profile Status indicator by sliding to
                    "Unlisted" on the Settings page.
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md="12" className="pb-0 font-weight-bold">
                    What does it mean if my profile is not approved?
                  </Col>
                  <Col md="12" className="black-font font-weight-light">
                    In most cases, an unapproved profile needs more information.
                    But don't worry, someone from our team will be in contact
                    with you within 24-48 business hours with additional
                    information. Or you can contact customer service by using
                    our{" "}
                    <NavLink
                      className="link"
                      to={UrlHelper.getMenuLink(
                        currentUser?.permissions,
                        "/contact-support",
                        `${constants.PartialImpersonateBrowserPath}contact-support/${id}`
                      )}
                    >
                      contact form
                    </NavLink>
                    , or by emailing us at{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:${customerSupportContactEmail}`}
                    >
                      {customerSupportContactEmail}
                    </a>
                    , or call {customerSupportContactPhoneNumber}. We are open
                    Monday through Friday, 6:30am-7:00pm Central time.
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md="12" className="pb-0 font-weight-bold">
                    How do I make changes to my profile?
                  </Col>
                  <Col md="12" className="black-font font-weight-light">
                    You can update your profile in the Edit Profile section of
                    your therapist.com account. Selecting Save saves the changes
                    you made but does not publish them to your live profile.
                    Selecting Save and Publish saves your changes and publishes
                    them to your live profile. It can take up to one hour for
                    changes to appear on your live profile after selecting Save
                    and Publish. If your changes have not appeared on your
                    public profile after one hour please contact customer
                    service by using our{" "}
                    <NavLink
                      className="link"
                      to={UrlHelper.getMenuLink(
                        currentUser?.permissions,
                        "/contact-support",
                        `${constants.PartialImpersonateBrowserPath}contact-support/${id}`
                      )}
                    >
                      contact form
                    </NavLink>
                    , or by emailing us at{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:${customerSupportContactEmail}`}
                    >
                      {customerSupportContactEmail}
                    </a>
                    , or call {customerSupportContactPhoneNumber}. We are open
                    Monday through Friday, 6:30am-7:00pm Central time.
                    <br />
                    <br />
                    Please note: You must have an active subscription in order
                    to view, make changes to, or publish your profile.
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md="12" className="pb-0 font-weight-bold">
                    How do I know what information I provide will be visible on
                    my public listing?
                  </Col>
                  <Col md="12" className="black-font font-weight-light">
                    Any information that will not be visible on your public
                    listing will be indicated with a <VisibilityOffIcon /> next
                    to the field.
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md="12" className="pb-0 font-weight-bold">
                    How do I manage my billing and subscription information?
                  </Col>
                  <Col md="12" className="black-font font-weight-light">
                    All billing and subscription information is managed under
                    the Settings page located in the left menu. From there, you
                    can click on the Billing Portal to adjust any billing or
                    subscription information.
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md="12" className="pb-0 font-weight-bold">
                    How do I apply to write for therapist.com?
                  </Col>
                  <Col md="12" className="black-font font-weight-light">
                    Therapist.com is currently accepting submissions from
                    licensed mental health professionals to write authentic,
                    relevant pieces that promote positive mental and emotional
                    health. We are looking for in-depth explorations of issues
                    pertaining to mental, emotional, behavioral, social,
                    cultural, and whole-person well-being rather than just
                    high-level information or general advice. If you're
                    interested in writing for us, please complete the{" "}
                    <a
                      className="pointer-cursor"
                      href={writeForUSUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Write For Us Form
                    </a>
                    {", "}
                    and we will take a look at your work and determine if we
                    would like to incorporate it into therapist.com. Please
                    understand, given the volume of interest, we are unable to
                    respond to every inquiry. However, if we believe there is an
                    opportunity with your suggestion, we will follow up with you
                    in the next few weeks.
                  </Col>
                </Row>
                <Row className="pb-3" id="profileStatus">
                  <Col md="12" className="pb-0 font-weight-bold">
                    What does "Profile Status" on my Dashboard and Edit Profile
                    mean?
                  </Col>
                  <Col md="12" className="profile-state help-profile-status">
                    <dl className="row">
                      <div className="d-none d-sm-block col-sm-3 col-lg-2 font-weight-bold header">
                        Profile Status
                      </div>
                      <div className="d-none d-sm-block col-sm-9 col-lg-10 font-weight-bold header">
                        Definition
                      </div>
                      <dt className="col-sm-3 col-lg-2 grey font-weight-bold">
                        Not Submitted
                      </dt>
                      <dd className="col-sm-9 col-lg-10 black font-weight-light align-middle">
                        You have not submitted your profile for review yet.
                        Complete your profile in just a few steps and click the
                        "Save & Submit" button to submit your profile to our
                        team.
                      </dd>
                      <dt className="col-sm-3 col-lg-2 grey font-weight-bold">
                        Pending Review
                      </dt>
                      <dd className="col-sm-9 col-lg-10 black font-weight-light">
                        Your profile has been submitted for review and is still
                        awaiting approval. Please allow 2-4 business days for
                        reviews to be complete.
                      </dd>
                      <dt className="col-sm-3 col-lg-2 green font-weight-bold">
                        Listed
                      </dt>
                      <dd className="col-sm-9 col-lg-10 black font-weight-light">
                        Your initial profile has been reviewed, verified, and
                        approved by a therapist.com professional. Your profile
                        is now live on therapist.com.
                      </dd>
                      <dt className="col-sm-3 col-lg-2 font-weight-bold red">
                        Action Required
                      </dt>
                      <dd className="col-sm-9 col-lg-10 black font-weight-light">
                        Your initial profile has been reviewed, and our
                        therapist.com professionals have determined that further
                        action is needed before they can approve. Please check
                        your email for communication from our therapist.com
                        team.
                      </dd>
                      <dt className="col-sm-3 col-lg-2 red font-weight-bold">
                        Unlisted
                      </dt>
                      <dd className="col-sm-9 col-lg-10 black font-weight-light">
                        Your initial profile has been reviewed, verified, and
                        approved by a therapist.com professional. Your profile
                        visibility status has since been marked unlisted and is
                        no longer live on therapist.com. If you'd like to change
                        your profile from "Unlisted" to "Listed", simply
                        navigate to the Settings page located in the left menu
                        of your therapist.com account, and adjust your Profile
                        Status indicator to "Listed".
                      </dd>
                      <dt className="col-sm-3 col-lg-2 red font-weight-bold">
                        Deactivated
                      </dt>
                      <dd className="col-sm-9 col-lg-10 black font-weight-light">
                        Your profile has been deactivated by our therapist.com
                        team and is no longer listed on therapist.com. For more
                        information on why your profile was deactivated, contact
                        customer service by using our{" "}
                        <NavLink
                          className="link"
                          to={UrlHelper.getMenuLink(
                            currentUser?.permissions,
                            "/contact-support",
                            `${constants.PartialImpersonateBrowserPath}contact-support/${id}`
                          )}
                        >
                          contact form
                        </NavLink>
                        , or by emailing us at{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`mailto:${customerSupportContactEmail}`}
                        >
                          {customerSupportContactEmail}
                        </a>
                        , or call
                        {customerSupportContactPhoneNumber}. We are open Monday
                        through Friday, 6:30am-7:00pm Central time.
                      </dd>
                    </dl>
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md="12" className="pb-0 font-weight-bold">
                    How do I change my log in password?
                  </Col>
                  <Col md="12" className="black-font font-weight-light">
                    If you registered with your email address and password, your
                    password can be updated by going to the Settings page in the
                    left menu and clicking "Change Password". If you registered
                    using a social media account, your password can be updated
                    by going to that social media platform and following their
                    directions to update your password.
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md="12" className="pb-0 font-weight-bold">
                    How do I change my log in email?
                  </Col>
                  <Col md="12" className="black-font font-weight-light">
                    If you need to change your log in email, please contact
                    customer service by using our{" "}
                    <NavLink
                      className="link"
                      to={UrlHelper.getMenuLink(
                        currentUser?.permissions,
                        "/contact-support",
                        `${constants.PartialImpersonateBrowserPath}contact-support/${id}`
                      )}
                    >
                      contact form
                    </NavLink>
                    , or by emailing us at{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:${customerSupportContactEmail}`}
                    >
                      {customerSupportContactEmail}
                    </a>
                    , or call {customerSupportContactPhoneNumber}. We are open
                    Monday through Friday, 6:30am-7:00pm Central time.
                    <br />
                    <br />
                    Please note: Your log in email is not used to send you
                    messages from your public profile. Potential client
                    communications will be sent to the business email address
                    you provided in your portal setup. Any emails about your
                    account will be sent to your log in email.
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md="12" className="pb-0 font-weight-bold">
                    How do I advertise with therapist.com?
                  </Col>
                  <Col md="12" className="black-font font-weight-light">
                    Therapist.com will put a spotlight on your brand for
                    millions to see. Whether you want to promote your business
                    to clients looking for mental health help or possibly
                    broadcast your product to therapists, we've got you covered.
                    Learn more about advertising here.
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md="12" className="pb-0 font-weight-bold">
                    How can I upgrade my subscription?
                  </Col>
                  <Col md="12" className="black-font font-weight-light">
                    If you are looking to upgrade your subscription, please
                    contact customer service by using our{" "}
                    <NavLink
                      className="link"
                      to={UrlHelper.getMenuLink(
                        currentUser?.permissions,
                        "/contact-support",
                        `${constants.PartialImpersonateBrowserPath}contact-support/${id}`
                      )}
                    >
                      contact form
                    </NavLink>
                    , or by emailing us at{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:${customerSupportContactEmail}`}
                    >
                      {customerSupportContactEmail}
                    </a>
                    , or call {customerSupportContactPhoneNumber}.
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md="12" className="pb-0 font-weight-bold">
                    What's included in my subscription?
                  </Col>
                  <Col md="12" className="black-font font-weight-light">
                    <b>Free subscribers</b>: Includes a free listing on
                    therapist.com for one year and insights as to how your
                    listing is performing.
                    <br />
                    <b>Free plus subscribers</b>: Includes a free listing on
                    therapist.com for one year and insights as to how your
                    listing is performing. It also includes 10 free continuing
                    education trainings throughout your first year,
                    complimentary worksheets, tips and tools, and exclusive
                    members-only discounts.
                  </Col>
                </Row>

                <Row className="pb-3">
                  <Col md="12" className="pb-0 font-weight-bold">
                    How do I cancel my therapist.com subscription?
                  </Col>
                  <Col md="12" className="black-font font-weight-light">
                    With therapist.com, you can cancel at any time and easily
                    renew your subscription if you choose to come back in the
                    future.
                    <br />
                    <br />
                    To cancel your subscription:
                    <ol>
                      <li>
                        Select <strong>Settings</strong> in the left menu.
                      </li>
                      <li>
                        Select <strong>Billing Portal</strong>.
                      </li>
                      <li>
                        Select <strong>Cancel</strong> under Your Subscription.
                      </li>
                      <li>
                        Complete the remaining on-screen prompts to complete the
                        cancellation process.
                      </li>
                    </ol>
                    After you’ve successfully cancelled, we’ll send a
                    confirmation email to the email address we have on file -
                    please keep this email for your records.
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </LoadingSection>
    </Container>
  );
};
