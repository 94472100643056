import { ProductVM } from "./ProductVM";

export class CampaignVM {
  [value: string]: any
  key?: string | null;
  id?: string;
  name?: string;
  status?: string;
  isEnabled?: boolean;
  startDate?: Date;
  endDate?: Date;
  startDateValue?: string;
  endDateValue?: string;
  startTimeValue?: string;
  endTimeValue?: string;
  offerPage?: string;
  products?: ProductVM;
  isForwardToSignUp?: boolean;
}
