export const constants = {
  PartialImpersonateBrowserPath: "/provider/impersonate/",
  ImpersonateApiPath: "/api/impersonate/",
  Multiple: "(Multiple)",
  asc: "asc",
  desc: "desc",
  DefaultCampaign: "default-campaign",
  CampaignPreview: "/register-template",
  ReturnUrl: "therapist-return-url",
  RedirectToSignUp: "redirect-to-signup",
  CampaignSession: "campaign",
  SelectedSubscriptionSession: "selectedSubscription",
} as const;

export const apiUrls = {
  PublicProfileStatusUrl: "/api/providerprofile/publicprofilestatus",
  ImpersonatePublicProfileStatusUrl: `${constants.ImpersonateApiPath}providerprofile/publicprofilestatus/`,
  DashboardUrl: "/api/dashboard",
  ImpersonateDashboardUrl: `${constants.ImpersonateApiPath}dashboard/`,
  HelpUrl: "/api/subscription/status",
  ImpersonateHelpUrl: `${constants.ImpersonateApiPath}subscription/status/`,
  ProviderProfileUrl: "/api/providerprofile",
  ImpersonateProviderProfileUrl: `${constants.ImpersonateApiPath}providerprofile/`,
  ProviderProfilePublishUrl: "/api/providerprofile/publish",
  ImpersonateProviderProfilePublishUrl: `${constants.ImpersonateApiPath}providerprofile/publish/`,
  ChangePasswordUrl: "/api/settings/changepassword",
  ImpersonateChangePasswordUrl: `${constants.ImpersonateApiPath}settings/changepassword/`,
  GetStripeSessionUrl: "/customerportal/getsession",
  ImpersonateGetStripeSessionUrl: `${constants.ImpersonateApiPath}customerportal/getsession/`,
  GetProfileStatusUrl: "/api/settings/getprofilestatus",
  ImpersonateGetProfileStatusUrl: `${constants.ImpersonateApiPath}settings/getprofilestatus/`,
  SetPublicProfileStatusUrl: "/api/settings/setpublicprofilestatus",
  ImpersonateSetPublicProfileStatusUrl: `${constants.ImpersonateApiPath}settings/setpublicprofilestatus/`,
  ContactSupportUrl: "/api/contactus/contactsupport",
  ImpersonateContactSupportUrl: `${constants.ImpersonateApiPath}contactus/contactsupport/`,
  CurrentSubscriptionUpgrade: `/api/subscription/currentsubscriptionupgrade`,
  ImpersonateCurrentSubscriptionUpgrade: `${constants.ImpersonateApiPath}subscription/currentsubscriptionupgrade/`,
  GetStripeCustomerUrl: "/customerportal/getcustomer",
  ImpersonateGetStripeCustomerUrl: `${constants.ImpersonateApiPath}customerportal/getcustomer/`,
  GetStripeCustomerSubscriptionsUrl: "/customerportal/getsubscriptions",
  ImpersonateGetStripeCustomerSubscriptionsUrl: `${constants.ImpersonateApiPath}customerportal/getsubscriptions/`,
  GetStripeCustomerInvoicesUrl: "/customerportal/getinvoices",
  ImpersonateGetStripeCustomerInvoicesUrl: `${constants.ImpersonateApiPath}customerportal/getinvoices/`,
  GetStripeCustomerPaymentMethodsUrl: "/customerportal/getpaymentmethods",
  ImpersonateGetStripeCustomerPaymentMethodsUrl: `${constants.ImpersonateApiPath}customerportal/getpaymentmethods/`,
} as const;
