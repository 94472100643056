import { ChangeEvent, FocusEvent, Fragment, InputHTMLAttributes } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import RequiredField from "../RequiredField";
import classnames from "classnames";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import * as IconRegular from "@fortawesome/free-regular-svg-icons";
import * as Icons from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, VisibilityOffIcon } from "../../Display";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  fieldText: string | number;
  fieldName: string;
  labelName: string;
  requiredField?: boolean;
  tooltipMessage?: string;
  showLabel?: boolean;
  subText?: Array<any>;
  className?: any;
  parentClassName?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  labelIcon?: Icons.IconDefinition;
  showLabelIcon?: boolean;
  labelIconColor?: string;
  placeholder?: string;
  isPassword?: boolean;
  labelClass?: string;
  formGroupClass?: string;
  error?: string;
  maxLength?: number;
  visibilityOff?: boolean;
  visibilityOffNextToTextInput?: boolean;
}

export const TextInput = ({
  fieldText,
  fieldName,
  handleChange,
  onBlur,
  labelName,
  requiredField = false,
  tooltipMessage = undefined,
  showLabel = true,
  subText = [],
  className,
  parentClassName = "",
  showLabelIcon = false,
  labelIcon = Icon.faEnvelope,
  labelIconColor = "",
  placeholder = "",
  isPassword = false,
  labelClass = "",
  formGroupClass = "",
  error = "",
  maxLength,
  visibilityOff = false,
  visibilityOffNextToTextInput = false,
  ...props
}: IProps) => {
  return (
    <FormGroup className={formGroupClass}>
      {showLabel ? (
        <Label className={labelClass}>
          {showLabelIcon ? (
            <Fragment>
              <FontAwesomeIcon
                color={labelIconColor}
                icon={labelIcon}
                size="lg"
                className="ml-0 mt-2"
              />
              &nbsp;
            </Fragment>
          ) : null}
          {labelName} {requiredField ? <RequiredField /> : null}{" "}
          {visibilityOff && <VisibilityOffIcon />}
          {tooltipMessage ? (
            <span className="help-text-button">
              <FontAwesomeIcon
                icon={IconRegular.faQuestionCircle}
                className="visible shape ml-1"
              />
              <span className="help-text">{tooltipMessage}</span>
            </span>
          ) : null}
        </Label>
      ) : null}
      {subText.length ? (
        <div className={classnames("character-limit")}>
          {subText.map((item, i) => (
            <span key={i}>{item}</span>
          ))}
        </div>
      ) : null}
      {isPassword ? (
        <Input
          className={className}
          type={"password" as any}
          value={fieldText}
          name={fieldName}
          onChange={handleChange}
          onBlur={onBlur}
          placeholder={placeholder}
          {...props}
        ></Input>
      ) : (
        <div className={`d-flex ${parentClassName}`}>
          {visibilityOffNextToTextInput && (
            <div className="pt-2 pr-1">
              <VisibilityOffIcon />
            </div>
          )}
          <Input
            className={className}
            type={"text" as any}
            value={fieldText || ""}
            name={fieldName}
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={placeholder}
            maxLength={maxLength}
            {...props}
          ></Input>
        </div>
      )}
      <ErrorMessage visible={isNotUndefined(error)} message={error} />
    </FormGroup>
  );
};
