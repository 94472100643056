import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Label,
  NavLink,
  Button,
  ModalFooter,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  CustomInput,
} from "reactstrap";
import { Link, Prompt, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable, { SortOrder, TableColumn } from "react-data-table-component"
import * as IconRegular from "@fortawesome/free-regular-svg-icons";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosResponse } from "axios";
import classnames from "classnames";
import { format } from "date-fns";
import * as Yup from "yup";

import { useGlobalState } from "../../Context";
import { ErrorAlert, LoadingSection, MaterialDesignSwitch } from "../../Components/Display";
import { SkinnedButton } from "../../Components/Form/Buttons";
import {
  CustomCheckbox,
  DatePicker,
  TextAreaInput,
  TextInput,
  TimePicker,
} from "../../Components/Form/Inputs";
import { CampaignVM, ProductVM } from "../Product/Models";
import ValidationErrors from "../Errors/ValidationErrors";
import SessionExpiredError from "../Errors/SessionExpiredError";
import { Helper, adminGenericError, basePathName } from "../../Utilities/HelperData";
import { useApiWorker } from "../../Utilities/CommonHooks";
import { useCustomForm } from "../../Utilities/UseCustomForm";
import { constants } from "../../Utilities/Constants";
import { ErrorHelper } from "../../Utilities/ErrorHelper";
import { HistoryHelper } from "../../Utilities/HistoryHelper";
import { PaginationOptions, SortOptions } from "../ProviderSearch";
import { CampaignDetails } from "../Shared";

import "./CampaignEdit.scss";

const validationSchema = Yup.object({
  name: Yup.string()
    .max(
      1000,
      "The length of the Campaign Name must be 1000 characters or fewer"
    )
    .required(""),
  offerPage: Yup.string()
    .nullable()
    .max(255, "The length of the URL Suffix must be 255 characters or fewer"),
  notes: Yup.string()
    .nullable()
    .max(4000, "The length of the Notes must be 4000 characters or fewer"),
  startDateValue: Yup.string()
    .nullable()
    .test({
      name: "validate-time-field",
      message: "",
      test: (value: any, context) => {
        if (context.parent.startTimeValue && !value) {
          return false;
        }
        return true;
      },
    }),
});

export const CampaignEdit = () => {

  const defaultPageSize = 10;

  const history = useHistory();
  const { currentUser, baseUrl } = useGlobalState();
  const apiWorker = useApiWorker();
  const { id } = useParams<{ id: string }>();

  const initialValues = new CampaignVM();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [serverErrors, setServerErrors] = useState<any[] | null>(null);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const [errorMessageIsVisible, setErrorMessageIsVisible] = useState<boolean>(false);
  const [productData, setProductData] = useState<ProductVM[]>([]);
  const [isGridLoading, setIsGridLoading] = useState<boolean>(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [switchStatusModal, setSwitchStatusModal] = useState(false);
  const [switchDeleteModal, setSwitchDeleteModal] = useState(false);
  const [inputsChanged, setInputsChanged] = useState(false);

  const [totalRows, setTotalRows] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [page, setPage] = useState<number>(1);
  const [sortOptions, setSortOptions] = useState<SortOptions>();

  const scrollElementRef = useRef<null | HTMLDivElement>(null);
  const pageElementRef = useRef<null | HTMLDivElement>(null);
  const productsRef = useRef<any>({});

  const toggleSwitchStatusModal = () => setSwitchStatusModal(!switchStatusModal);
  const toggleSwitchDeleteModal = () => setSwitchDeleteModal(!switchDeleteModal);

  useEffect(() => {
    setInputsChanged(inputsChanged);
  }, [inputsChanged]);

  useEffect(() => {
    if (currentUser && id) {
      getCampaign(id);
    } else {
      setIsLoading(false);
    }
    if (currentUser) searchProducts();
  }, [currentUser, id]);

  const scrollToTop = () => {
    if (scrollElementRef && scrollElementRef.current) {
      scrollElementRef.current.scrollIntoView({ behavior: "auto" });
    }
  };

  const columns: TableColumn<CampaignVM>[] = [
    {
      name: <>Product</>,
      selector: row => row.name!,
      sortable: true,
      cell: (row) => 
        <Link className="font-weight-normal" to={"product/" + row.id} tabIndex={-1} target="_blank">
          {row.name}
        </Link>,
      width: '12%'
    },
    {
      name: <>Product Status</>,
      selector: row => row.status!,
      sortable: true,
      width: '12%'
    },
    {
      name: <>Campaign</>,
      selector: row => row.campaignName!,
      sortable: true,
      cell: (row) => row.campaignId === constants.Multiple ? (row.campaignName) : (
        <Link
          className="font-weight-normal"
          to={(row.isDefault ? "default-campaign/" : "campaign/") + row.campaignId}
          tabIndex={-1}
        >
          {row.campaignName}
        </Link>
      ),
      width: '18%'
    },
    {
      name: <>Campaign Status</>,
      selector: row => row.campaignStatus!,
      sortable: true,
      width: '16%'
    },
    {
      name: <>Start</>,
      selector: row => row.campaignStartDate!,
      sortable: true,
      width: '11%'
    },
    {
      name: <>End</>,
      selector: row => row.campaignEndDate!,
      sortable: true,
      width: '11%'
    },
    {
      name: <>Offer Page</>,
      selector: row => row.offerPage!,
      cell: (row: CampaignVM) =>
      row.offerPage === constants.Multiple ? (
        row.offerPage
      ) : (
        <Link
          className="font-weight-normal"
          to={`${constants.CampaignPreview}/${row.offerPage || ""}`}
          tabIndex={-1}
          target="_blank"
        >
          View
        </Link>
      ),
      width: '10%'
    },
    {
      name: <>Assign</>,
      cell: (row: CampaignVM) =>
        row.isEnabled && (
          <NavLink className="assignProduct" onClick={(event) => assignProductToCampaign(event, row)}>
            <FontAwesomeIcon size="sm" icon={Icon.faPlus} />
          </NavLink>
        ),
      width: '6%',
      center: true
    }
  ];

  const assignProductToCampaign = (event: React.MouseEvent, record: CampaignVM) => {
    event.preventDefault();

    if (
      values &&
      values.products &&
      values.products.length &&
      values.products.length === 3
    ) {
      toast.warn("Only three products can be assigned to a campaign.");
      return;
    }

    if (
      values.products &&
      values.products.find((x: CampaignVM) => x.id === record.id)
    ) {
      toast.warn("Product already exist in this campaign.");
      return;
    }

    const item = new ProductVM();
    item.id = record.id;
    item.name = record.name;
    item.isEnabled = record.isEnabled;
    let elements = [...(values.products || []), item];
    setValues({ ...values, products: elements });
    setInputsChanged(true);
    toast.success('Product added to the campaign. Click "Save" to save your changes.');
  };

  const getCampaign = async (id: string) => {
    let success = true;
    const element = await apiWorker
      .get<CampaignVM>(`${basePathName}/api/campaign/${id}`)
      .catch((err: any) => {
        setIsLoading(false);
        if (
          ErrorHelper.handleServerErrors(
            err,
            setServerErrors,
            setSessionExpired,
            scrollToTop
          )
        )
          success = false;
      });

    if (!success) return;

    if (element && element.data) {
      setValues({
        ...element.data,
        startDateValue:
          element.data.startDate instanceof Date
            ? format(element.data.startDate, "yyyy-MM-dd")
            : "",
        startTimeValue:
          element.data.startDate instanceof Date
            ? format(element.data.startDate, "HH:mm")
            : "",
        endDateValue:
          element.data.endDate instanceof Date
            ? format(element.data.endDate, "yyyy-MM-dd")
            : "",
        endTimeValue:
          element.data.endDate instanceof Date
            ? format(element.data.endDate, "HH:mm")
            : "",
      });
      setSwitchChecked(!element.data.isEnabled || false);
      setServerErrors(null);
    }
    setIsLoading(false);
  };

  const saveCampaign = async () => {
    setIsLoading(true);
    toast.dismiss();

    let result: AxiosResponse<CampaignVM> | void;
    let success = true;

    let positions: Array<any> = [];
    if (productsRef.current.children) {
      for (let i = 0; i < productsRef.current.children.length; i++) {
        positions.push({
          id: productsRef.current.children[i].dataset.elementid,
          position: i,
        });
      }
      values.products?.map((x: CampaignVM) => {
        let position = positions.filter((y) => y.id == x.id)[0];
        x.position = parseInt(position.position);
        return x;
      });
    }

    values.startDate =
      values.startDateValue && values.startTimeValue
        ? new Date(
            values.startDateValue.substr(0, 4),
            parseInt(values.startDateValue.substr(5, 2)) - 1,
            values.startDateValue.substr(8, 2),
            values.startTimeValue.substr(0, 2),
            values.startTimeValue.substr(3, 2)
          )
        : values.startDateValue
        ? new Date(
            values.startDateValue.substr(0, 4),
            parseInt(values.startDateValue.substr(5, 2)) - 1,
            values.startDateValue.substr(8, 2),
            0,
            0
          )
        : null;

    values.endDate =
      values.endDateValue && values.endTimeValue
        ? new Date(
            values.endDateValue.substr(0, 4),
            parseInt(values.endDateValue.substr(5, 2)) - 1,
            values.endDateValue.substr(8, 2),
            values.endTimeValue.substr(0, 2),
            values.endTimeValue.substr(3, 2)
          )
        : values.endDateValue
        ? new Date(
            values.endDateValue.substr(0, 4),
            parseInt(values.endDateValue.substr(5, 2)) - 1,
            values.endDateValue.substr(8, 2),
            0,
            0
          )
        : null;

    var now = new Date();
    let isNewCampaign = false;
    if (id) {
      result = await apiWorker
        .put<CampaignVM>(`${basePathName}/api/campaign`, values, {})
        .catch((errors: any) => {
          success = handleCampaingSaveErrors(errors);
        });
    } else {
      if (!values.startDateValue) {
        values.startDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          0,
          0
        );
      }
      isNewCampaign = true;
      result = await apiWorker
        .post<CampaignVM>(`${basePathName}/api/campaign`, values, {})
        .catch((errors: any) => {
          success = handleCampaingSaveErrors(errors);
        });
    }

    if (!success) return;

    if (result) {
      if (isNewCampaign) {
        setValues({
          ...values,
          startDateValue: `${now.getMonth()}/${now.getDate()}/${now.getFullYear()}`,
        });
      }
      setServerErrors(null);
      toast.success("The campaign has been saved");
      setInputsChanged(false);
      setIsLoading(false);
      history.push(
        `/${result.data.isDefault ? "default-campaign" : "campaign"}/${
          result.data.id
        }`
      );
    } else {
      setIsLoading(false);
    }
  };

  const handleCampaingSaveErrors = (errors: any): boolean => {
    setIsLoading(false);
    if (
      ErrorHelper.handleServerErrors(
        errors,
        setServerErrors,
        setSessionExpired,
        scrollToTop
      )
    ) {
      return false;
    }

    if (
      errors &&
      errors.isAxiosError === undefined &&
      Object.keys(errors).length &&
      Object.keys(errors).filter((x) => x !== "").length
    ) {
      setErrorMessageIsVisible(true);
    }

    errors = Helper.cleanUpErrorMessages(errors);
    setFormErrors(errors);

    let err: any = Helper.convertNonFieldErrorsToArray(errors);
    if (err && Array.isArray(err) && err.length) {
      setServerErrors(err);
    } else {
      setServerErrors([]);
    }
    scrollToTop();
    return true;
  };

  const {
    values,
    formErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    setFormErrors,
  } = useCustomForm({
    initialValues,
    validationSchema,
    onSubmit: async (result: any) => {
      const noErrors = Object.keys(result.errors || {}).length === 0;
      if (!noErrors) {
        setErrorMessageIsVisible(true);
        scrollToTop();
        toast.dismiss();
        setServerErrors(null);
        return;
      }
      setErrorMessageIsVisible(false);
      saveCampaign();
    },
    formChanged: () => {
      setInputsChanged(true);
    },
  });

  const goBack = (event: any) => {
    HistoryHelper.goBack(event, history, "/campaign-product");
  };

  const handleSwitchChange = () => {
    document.querySelector(".main__content")?.scrollTo(0, 0);
    toast.dismiss();
    toggleSwitchStatusModal();
  };

  const deleteCampaign = async (event: React.MouseEvent) => {
    event.preventDefault();
    setIsLoading(true);
    toast.dismiss();
    let success = true;
    const result = await apiWorker
      .put<boolean>(
        `${basePathName}/api/campaign/delete`,
        { SelectedRows: [values.id] },
        {}
      )
      .catch((err: any) => {
        setIsLoading(false);
        if (
          ErrorHelper.handleServerErrors(
            err,
            setServerErrors,
            setSessionExpired,
            scrollToTop
          )
        ) {
          success = false;
          return;
        }

        err = Helper.cleanUpErrorMessages(err);
        setFormErrors(err);
        if (err && err[""]) {
          setServerErrors(err[""]);
        }
      });

    if (!success) return;

    if (result) {
      history.push(`/campaign-product`);
      toast.success("Campaign deleted");
    } else {
      // set error campaign is default
      setServerErrors(["The default campaign cannot be deleted"]);
    }
    setIsLoading(false);
  };

  const postSwitchChange = async (event: React.MouseEvent) => {
    event.preventDefault();
    setIsLoading(true);
    toast.dismiss();
    toggleSwitchStatusModal();
    let success = true;
    const result = await apiWorker
      .put<boolean>(
        `${basePathName}/api/campaign/${
          switchChecked ? "reactivate" : "deactivate"
        }`,
        { SelectedRows: [values.id] },
        {}
      )
      .catch((err: any) => {
        setIsLoading(false);
        if (
          ErrorHelper.handleServerErrors(
            err,
            setServerErrors,
            setSessionExpired,
            scrollToTop
          )
        ) {
          success = false;
          return;
        }

        err = Helper.cleanUpErrorMessages(err);
        setFormErrors(err);
        if (err && err[""]) {
          setServerErrors(err[""]);
        }
        toggleSwitchStatusModal();
      });

    if (!success) return;

    if (result) {
      setServerErrors(null);
      toast.success(
        switchChecked ? "Campaign reactivated" : "Campaign deactivated"
      );
      setValues({ ...values, isEnabled: switchChecked });
      setSwitchChecked(!switchChecked);
    } else {
      // set error campaign is default
      setServerErrors(["The default campaign cannot be deactivated"]);
    }
    setIsLoading(false);
  };

  const searchProducts = async (paginationOptions?: PaginationOptions, sortOptions?: SortOptions) => {
    setIsGridLoading(true);
    const data = {
      searchText: values.searchText,
      isActive: values.isActive,
      isInactive: values.isInactive,
      campaignStartDate: values.campaignStartDate,
      campaignEndDate: values.campaignEndDate,
      pageSize: paginationOptions?.paginationPageSize || defaultPageSize,
      page: paginationOptions?.paginationPage || page,
      sortOrder: sortOptions?.sortOrder || 'asc',
      sortField: sortOptions?.field || 'name'
    };
    let success = true;
    const result = await apiWorker
      .get<ProductVM>(`${basePathName}/api/product/list`, {
        params: {
          ...data,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((err) => {
        setIsGridLoading(false);
        if (
          ErrorHelper.handleServerErrors(
            err,
            setServerErrors,
            setSessionExpired,
            scrollToTop
          )
        ) {
          success = false;
          return;
        }
        setServerErrors([adminGenericError]);
      });

    if (!success) return;

    if (result) {
      setServerErrors([]);
      setIsGridLoading(false);
      setProductData(result.data.data || []);
      setTotalRows(result.data.total);
    }
  };

  const defaultProductSelectionChanged = (targetItem: number) => {
    for (let i = 0; i < values.products.length; i++) {
      if (i !== targetItem) {
        values.products[i].isDefaultSelection = false;
      }
    }
    values.products[targetItem].isDefaultSelection = true;
    setValues({
      ...values,
      products: values.products,
    });
  };

  const fieldToString = (field: TableColumn<CampaignVM>): string => {
    switch(field.id) {
      case 1:
        return 'name';
      case 2:
        return 'status';
      case 3:
        return 'campaignName';
      case 4:
        return 'campaignStatus';
      case 5:
        return 'campaignStartDate';
      case 6:
        return 'campaignEndDate';
      default:
        return '';
    }
  };

  const handleChangeRowsPerPage = (rowsPerPage: number, page: number) => {
    searchProducts({paginationPage: page, paginationPageSize: rowsPerPage}, 
      {sortOrder: sortOptions?.sortOrder, field: sortOptions?.field });
    setPageSize(rowsPerPage);
  };
  
  const handleChangePage = (newPage: number) => {
    searchProducts({paginationPage: newPage, paginationPageSize: pageSize}, 
      {sortOrder: sortOptions?.sortOrder, field: sortOptions?.field });
    setPage(newPage);
  };
  
  const handleSortChange = (field: TableColumn<CampaignVM>, sortDirection: SortOrder) => {
    const fieldName = fieldToString(field);
    searchProducts({paginationPageSize: pageSize, paginationPage: page}, {sortOrder: sortDirection, field: fieldName});
    setSortOptions({ sortOrder: sortDirection, field: fieldName});
  };

  return (
    <Container fluid={true} className="mt-4 position-relative">
      <LoadingSection
        isLoading={isLoading}
        height={pageElementRef.current?.clientHeight}
        width={pageElementRef.current?.clientWidth}
      >
        <div ref={pageElementRef}>
          <div ref={scrollElementRef} id="scrollElement"></div>
          <Modal
            fade={false}
            isOpen={switchStatusModal}
            toggle={toggleSwitchStatusModal}
          >
            <ModalHeader toggle={toggleSwitchStatusModal}>
              {switchChecked ? "Reactivate" : "Deactivate"} Campaign
              Confirmation
            </ModalHeader>
            <ModalBody className="large-font">
              Are you sure you want to{" "}
              {switchChecked ? "reactivate" : "deactivate"} this campaign?
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={postSwitchChange}
                className="px-4 large-font"
              >
                {switchChecked ? "Reactivate" : "Deactivate"} Campaign
              </Button>
              <Button
                color="secondary"
                onClick={toggleSwitchStatusModal}
                className="large-font"
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            fade={false}
            isOpen={switchDeleteModal}
            toggle={toggleSwitchDeleteModal}
          >
            <ModalHeader toggle={toggleSwitchDeleteModal}>
              Delete Campaign Confirmation
            </ModalHeader>
            <ModalBody className="large-font">
              Are you sure you want to delete this campaign?
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={deleteCampaign}
                className="px-4 large-font"
              >
                Delete Campaign
              </Button>
              <Button
                color="secondary"
                onClick={toggleSwitchDeleteModal}
                className="large-font"
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Prompt
            when={inputsChanged}
            message={
              "Are you sure you want to leave the page? Any unsaved changes will be lost.\r\nClick OK to Proceed. Click Cancel to stay."
            }
          />
          <Form className="mt-2 ml-5 mr-5" noValidate onSubmit={handleSubmit}>
            <Row>
              <Col md="12" className="px-0">
                {errorMessageIsVisible && (
                  <ErrorAlert message="Please update the highlighted fields before saving"></ErrorAlert>
                )}
                <ValidationErrors
                  serverErrors={serverErrors}
                  formErrors={{}}
                  customErrors={[]}
                />
                <SessionExpiredError showError={sessionExpired} />
              </Col>
            </Row>
            <Row className="py-2">
              <Col
                md="5"
                className="px-0 mb-0 font-weight-light larger-font pt-3"
              >
                {values.id ? "Campaign Detail" : "Create New Campaign"}
              </Col>
              <Col md="7" className="pt-2 px-0 text-right">
                {values.id && (
                  <Row className="d-inline" style={{ padding: "1em" }}>
                    <span className="mt-1 mr-3 regular-font">Active</span>
                    <MaterialDesignSwitch
                      status={switchChecked}
                      onChange={handleSwitchChange}
                      className="align-middle mb-0"
                      disabled={values.isDefault}
                    />
                    <span className="mt-1 ml-3 regular-font">Inactive</span>
                  </Row>
                )}
                <SkinnedButton color="primary" className="py-2 px-4 ml-4">
                  {values.id ? "Save" : "Create"}
                </SkinnedButton>
                {values.id && (
                  <SkinnedButton
                    onClick={toggleSwitchDeleteModal}
                    className="py-2 ml-2 px-4"
                    disabled={values.isDefault}
                  >
                    Delete
                  </SkinnedButton>
                )}
                <SkinnedButton onClick={goBack} className="ml-2 px-4">
                  Cancel
                </SkinnedButton>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="rounded px-0 mb-4">
                <Row>
                  <Col md="6">
                    <TextInput
                      maxLength={255}
                      formGroupClass="mb-0"
                      labelName="Name"
                      requiredField={true}
                      className={classnames({
                        highlighted:
                          isNotUndefined(formErrors.name) ||
                          isNotUndefined(formErrors.Name),
                        "mb-0": true,
                      })}
                      fieldText={values.name || ""}
                      fieldName="name"
                      onBlur={handleBlur}
                      handleChange={handleChange}
                      labelClass={classnames({
                        "highlighted-text":
                          isNotUndefined(formErrors.name) ||
                          isNotUndefined(formErrors.Name),
                      })}
                      error={formErrors.name}
                      disabled={values.isDefault}
                    ></TextInput>
                  </Col>
                  <Col md="5">
                    <Row>
                      <Col md="12">
                        <TextInput
                          maxLength={1000}
                          formGroupClass="mb-0"
                          labelName="URL Suffix"
                          requiredField={true}
                          className={classnames({
                            highlighted:
                              isNotUndefined(formErrors.offerPage) ||
                              isNotUndefined(formErrors.OfferPage),
                            "mb-0": true,
                          })}
                          fieldText={values.offerPage || ""}
                          fieldName="offerPage"
                          handleChange={(event) => {
                            event.target.value = event.target.value.replace(
                              " ",
                              "-"
                            );
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                          labelClass={classnames({
                            "highlighted-text":
                              isNotUndefined(formErrors.offerPage) ||
                              isNotUndefined(formErrors.OfferPage),
                          })}
                          error={formErrors.offerPage}
                          disabled={values.isDefault}
                        ></TextInput>
                      </Col>
                      <Col md="12">
                        {(values.offerPage || values.isDefault) && (
                          <u>
                            <Link
                              className="font-weight-normal"
                              to={`${constants.CampaignPreview}/${
                                values.offerPage || ""
                              }`}
                              target="_blank"
                            >
                              View
                            </Link>
                          </u>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col md="1" className="ml-n4">
                    <div className="pb-3">&nbsp;</div>
                    <FontAwesomeIcon
                      className="pt-0 light-blue-color copy"
                      icon={IconRegular.faClone}
                      size="lg"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${baseUrl}providers/subscription/${
                            values.offerPage || ""
                          }`
                        );
                        toast.success("URL copied successfully");
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="4" className="offset-md-6">
                    <CustomCheckbox
                      hideEmptyLabel={true}
                      text="Forward User To Sign-Up Page"
                      parentClassName="mt-1"
                      className="regular-font pl-0"
                      checked={values.isForwardToSignUp || false}
                      fieldName="isForwardToSignUp"
                      handleChange={handleChange}
                    ></CustomCheckbox>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="pt-3">
                    <TextAreaInput
                      placeholder="Add Campaign description or note..."
                      maxLength={4000}
                      labelName="Notes"
                      showLabel={true}
                      requiredField={false}
                      fieldText={values.notes || ""}
                      fieldName="notes"
                      inputClassName="three-rows"
                      handleChange={handleChange}
                      handleBlur={() => {}}
                      rows={5}
                      className={classnames({
                        highlighted:
                          isNotUndefined(formErrors.notes) ||
                          isNotUndefined(formErrors.Notes),
                      })}
                      error={formErrors.notes || formErrors.Notes}
                    ></TextAreaInput>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <table className="assignment w-100">
                      <thead>
                        <tr>
                          <th
                            className="w-65 regular-font font-weight-normal pb-1"
                            colSpan={2}
                          >
                            Product Display Order
                          </th>
                          <th className="w-25 regular-font font-weight-normal pb-1 text-center">
                            Default Selection
                          </th>
                          <th className="w-10">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody ref={productsRef}>
                        {values.products?.map((item: CampaignVM, index: number) => (
                          <tr key={item.id} data-elementid={item.id}>
                            <td className="px-2 text-center">{index + 1}</td>
                            <td
                              className={classnames({
                                "px-2": true,
                                rounded: true,
                                "dusky-blue": true,
                                "opacity-3": !item.isEnabled,
                              })}
                            >
                              <span className="float-left pt-2 pl-1 text-white">
                                {item.name}
                              </span>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`/providers/product/${item.id}`}
                                className="float-right py-1 button-link px-3 btn-secondary btn mx-0"
                              >
                                Edit
                              </a>
                            </td>
                            <td className="text-center">
                              <CustomInput
                                type="radio"
                                id={`isDefaultSelection${index}`}
                                name="isDefaultSelection"
                                defaultChecked={item.isDefaultSelection || false}
                                onChange={() => {
                                  defaultProductSelectionChanged(index);
                                }}
                              />
                            </td>
                            <td>
                              <Button
                                className="trashCan mt-n1"
                                onClick={(event: React.MouseEvent) => {
                                  values.products.splice(index, 1);
                                  setValues({
                                    ...values,
                                    products: values.products,
                                  });
                                }}
                              >
                                <FontAwesomeIcon
                                  size="lg"
                                  icon={Icon.faTrash}
                                ></FontAwesomeIcon>
                              </Button>
                            </td>
                          </tr>
                        ))}
                        {(!values.products || !values.products.length) && (
                          <tr className="empty">
                            <td colSpan={2} className="py-1 font-weight-light">
                              <Label className="px-2">
                                No products in this campaign
                              </Label>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Col>
                  <Col md="6">
                    <div className="form-group mb-0">
                      <Label>
                        Schedule{" "}
                        <span className="help-text-button">
                          <FontAwesomeIcon
                            icon={IconRegular.faQuestionCircle}
                            className="visible shape"
                          />
                          <span className="help-text">
                            The date and time fields are not required. Please
                            leave these fields blank to make this campaign
                            always available.
                          </span>
                        </span>
                      </Label>
                    </div>
                    <div>
                      <div className="d-inline-flex">
                        <Label className="pt-2 pr-3 date-label">Start</Label>
                        <DatePicker
                          showLabel={false}
                          fieldName="startDateValue"
                          fieldValue={values.startDateValue || ""}
                          handleChange={(event: any) => {
                            setValues({
                              ...values,
                              startDateValue: event.target.value,
                            });
                          }}
                          inputClassName={classnames({
                            highlighted: isNotUndefined(
                              formErrors.startDateValue
                            ),
                          })}
                          error={formErrors.startDateValue}
                          disabled={values.isDefault}
                        ></DatePicker>
                        <TimePicker
                          className="pl-3 time-label"
                          showLabel={false}
                          fieldName="startTimeValue"
                          fieldValue={values.startTimeValue || ""}
                          handleChange={(event: any) => {
                            setValues({
                              ...values,
                              startTimeValue: event.target.value,
                            });
                          }}
                          disabled={values.isDefault}
                        ></TimePicker>
                      </div>
                    </div>
                    <div className="d-inline-flex">
                      <Label className="pt-2 pr-3 date-label">End</Label>
                      <DatePicker
                        showLabel={false}
                        fieldName="endDateValue"
                        fieldValue={values.endDateValue || ""}
                        handleChange={(event: any) => {
                          setValues({
                            ...values,
                            endDateValue: event.target.value,
                          });
                        }}
                        inputClassName={classnames({
                          highlighted: isNotUndefined(formErrors.endDateValue),
                        })}
                        error={formErrors.endDateValue}
                        disabled={values.isDefault}
                      ></DatePicker>
                      <TimePicker
                        className="pl-3 time-label"
                        showLabel={false}
                        fieldName="endTimeValue"
                        fieldValue={values.endTimeValue || ""}
                        handleChange={(event: any) => {
                          setValues({
                            ...values,
                            endTimeValue: event.target.value,
                          });
                        }}
                        disabled={values.isDefault}
                      ></TimePicker>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="pl-0 pb-2 larger-font font-weight-light">
                Add Products
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Row className="rounded p-3 blue-background">
                  <div className="w-100 font-weight-bold regular-font pb-2">
                    Product Search
                  </div>
                  <div className="w-100">
                    <TextInput
                      maxLength={1000}
                      labelClass="font-weight-normal regular-font pb-2"
                      showLabel={false}
                      formGroupClass="d-inline-flex w-100 mb-0"
                      parentClassName="w-100"
                      labelName=""
                      requiredField={false}
                      fieldText={values.searchText || ""}
                      fieldName="searchText"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          searchProducts();
                        }
                      }}
                      placeholder="Search for Products to add to this Campaign"
                    ></TextInput>
                  </div>
                  <div className="w-100 d-flex align-items-center flex-wrap">
                    <div className="d-flex align-items-center flex-wrap mt-3">
                      <CustomCheckbox
                        hideEmptyLabel={true}
                        text="Active"
                        parentClassName="mt-1"
                        className="regular-font pl-0"
                        checked={values?.isActive || false}
                        fieldName="isActive"
                        handleChange={handleChange}
                      ></CustomCheckbox>
                      <CustomCheckbox
                        hideEmptyLabel={true}
                        text="Inactive"
                        parentClassName="mt-1 mr-3"
                        className="regular-font pl-0"
                        checked={values?.isInactive || false}
                        fieldName="isInactive"
                        handleChange={handleChange}
                      ></CustomCheckbox>
                      <div className="d-inline-flex align-items-center">
                        <span className="regular-font mr-3">
                          Filter by Campaign date
                        </span>
                        <div className="d-inline-flex align-items-center">
                          <InputGroup>
                            <div className="input-group-prepend">
                              <div className="input-group-text">From</div>
                            </div>
                            <DatePicker
                              showLabel={false}
                              fieldName="campaignEndDate"
                              fieldValue={values?.campaignStartDate || ""}
                              handleChange={(event: any) => {
                                setValues({
                                  ...values,
                                  campaignStartDate: event.target.value,
                                });
                              }}
                              className="d-inline-block"
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  searchProducts();
                                }
                              }}
                              skipFormGroup
                            ></DatePicker>
                          </InputGroup>
                          <InputGroup className="ml-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">To</div>
                            </div>
                            <DatePicker
                              showLabel={false}
                              fieldName="campaignEndDate"
                              fieldValue={values?.campaignEndDate || ""}
                              handleChange={(event: any) => {
                                setValues({
                                  ...values,
                                  campaignEndDate: event.target.value,
                                });
                              }}
                              className="d-inline-block"
                              skipFormGroup
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  searchProducts();
                                }
                              }}
                            ></DatePicker>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                    <div className="ml-auto mt-3">
                      <SkinnedButton
                        color="primary"
                        onClick={(event: React.MouseEvent) => {
                          event.preventDefault();
                          searchProducts();
                        }}
                      >
                        Search
                      </SkinnedButton>
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row className="pt-3 pb-5">
              <Col className="px-0">
                <DataTable
                  data={productData}
                  columns={columns}
                  defaultSortFieldId={1}
                  defaultSortAsc={true}
                  expandableRows
                  expandableRowsComponent={CampaignDetails}
                  expandableRowDisabled={(row) => row.campaigns.length === 1}
                  highlightOnHover
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  onChangePage={handleChangePage}
                  onSort={handleSortChange}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 20, 50, 100]}
                  paginationTotalRows={totalRows}
                  progressPending={isGridLoading}
                  striped
                  />
              </Col>
            </Row>
          </Form>
        </div>
      </LoadingSection>
    </Container>
  );
};
