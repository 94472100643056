import { Route, Redirect } from "react-router-dom";
import {
  ApplicationPaths,
  QueryParameterNames,
} from "./ApiAuthorizationConstants";
import { useGlobalState, PageState } from "./../../Context";
import { BodyLoadingSection } from "../Display";

export const AuthorizeRoute = (props: any) => {
  const { pageState, currentUser } = useGlobalState();

  const link = document.createElement("a");
  link.href = props.path;
  const returnUrl = window.location.href;
  const redirectUrl = `${ApplicationPaths.Login}?${
    QueryParameterNames.ReturnUrl
  }=${encodeURIComponent(returnUrl)}`;

  const { component: Component, permissions, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (pageState == PageState.Authorized) {
          return permissions == null ||
            permissions.length === 0 ||
            (currentUser?.permissions || []).some((x: any) =>
              permissions.includes(x)
            ) ? (
            <Component {...props} />
          ) : null;
        } else {
          if (pageState == PageState.Unauthorized) {
            return <Redirect to={redirectUrl} />;
          } else {
            return <BodyLoadingSection isLoading={true}></BodyLoadingSection>;
          }
        }
      }}
    ></Route>
  );
};
