import { FormGroup, Label } from "reactstrap";

interface IProps {
  fieldText: string | number;
  labelName: string;
  hideLabel?: boolean;
  className?: string;
  formGroupClass?: string;
}

export const LabelValue = ({
  fieldText,
  labelName,
  hideLabel = false,
  className = "",
  formGroupClass = "",
}: IProps) => {
  return (
    <FormGroup className={formGroupClass}>
      {!hideLabel && <Label>{labelName}</Label>}
      <div>
        <span className={className}>{fieldText}</span>
      </div>
    </FormGroup>
  );
};
