import { ChangeEvent, FocusEvent, Fragment, useEffect, useState } from "react";
import "./Licenses.scss";
import { CustomInput, Button, Col, NavLink, Row } from "reactstrap";
import { LicenseVM } from "../../Components/ProviderProfile/Models/LicenseVM";
import { v4 as uuid } from "uuid";
import { DataDropDown, InputWithIcon, TextInput } from "../Form/Inputs";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import { CoreEnum } from "../../Features/Shared/Models/SharedModels";
import { useGlobalState } from "../../Context";
import {
  months,
  years,
  redBorder,
  white,
  red,
  darkBlue,
  licenseInformationMessage,
  blueBorder,
} from "../../Utilities/HelperData";
import Dragula from "react-dragula";
import { MoveIcon } from ".";

interface IProps {
  elements: LicenseVM[] | undefined;
  states: CoreEnum[];
  isLicensed?: boolean;
  isApproved: boolean;
  licenseTypes: CoreEnum[];
  formErrors: any;
  handleItemsChange: (
    event: ChangeEvent<HTMLInputElement>,
    items: LicenseVM[] | undefined
  ) => void;
  displayStatusMessage: boolean;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
}

export const Licenses = ({
  elements,
  handleItemsChange,
  states,
  formErrors,
  isLicensed,
  isApproved,
  licenseTypes,
  displayStatusMessage = true,
  onBlur,
}: IProps) => {
  const containers: any[] = [];

  useEffect(() => {
    const drake = Dragula(containers, {
      moves: function (el: any, source: any, handle: any, sibling: any) {
        return (
          handle &&
          handle.parentElement &&
          (handle.parentElement.className === "handle position-relative" ||
            (handle.parentElement.parentElement &&
              handle.parentElement.parentElement.className ===
                "handle position-relative"))
        );
      },
    });

    // handle drop manually to adjust CSS because React rendering 
    // does not get notified from changes after moving the DOM
    drake.on('drop', (el : Element) => {
      // add top border to all children
      el.parentElement?.querySelectorAll('[data-part="separator"]')
      .forEach(separator => separator.classList.add('border-top'));
  
      // remove top border from first child
      el.parentElement?.querySelector('[data-part="separator"]')
        ?.classList.remove('border-top');
    });
  }, []);

  const dragulaDecorator = (componentBackingInstance: any) => {
    if (componentBackingInstance) {
      containers.push(componentBackingInstance);
    }
  };

  useEffect(() => {
    setItems(elements);
    setAvailableLicenseTypes(licenseTypes);
  }, [elements]);
  useEffect(() => {
    setAvailableLicenseTypes(licenseTypes);
  }, [isLicensed]);

  const { customerSupportContactEmail } = useGlobalState();
  const [items, setItems] = useState(elements);
  const [availableLicenseTypes, setAvailableLicenseTypes] = useState<
    CoreEnum[]
  >([]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    let value: any;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    const elements = items?.map((el) =>
      el.id === id ? { ...el, [event.target.name]: value } : el
    );
    setItems(elements);
    handleItemsChange(event, elements);
  };

  const handleFromDropDownChange = (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
    text: string
  ) => {
    const value = {
      value: event.target.value,
      label: text,
    };

    const elements = items?.map((el) =>
      el.id === id
        ? {
            ...el,
            [event.target.name]: value,
          }
        : el
    );
    setItems(elements);
    handleItemsChange(event, elements);
  };

  const addRow = (event: any) => {
    event.preventDefault();
    const item = new LicenseVM();
    item.id = uuid();
    let elements = [...(items || []), item];
    if (elements.length === 1) {
      elements[0].displayInHeader = true;
    }
    setItems(elements);
    handleItemsChange(event, elements);
  };

  const deleteRow = (event: any, id: string) => {
    event.preventDefault();
    let elements = items?.filter((x) => x.id !== id) || [];
    if (elements.length === 1) {
      elements[0].displayInHeader = true;
    }
    setItems(elements);
    handleItemsChange(event, elements);
  };

  const radioButtonChange = (event: any, targetLicense: LicenseVM) => {
    if (elements) {
      for (let index = 0; index < elements.length; index++) {
        const license = elements[index];
        if (targetLicense.position !== license.position) {
          license.displayInHeader = false;
        } else {
          license.displayInHeader = true;
        }
      }
    }
  };

  return (
    <Fragment>
      <div
        className={classnames({ "drag-handles": true })}
        ref={dragulaDecorator}
      >
        {items?.map((item, index) => (
          <Row key={item.id} className="pb-1" data-elementid={item.id}>
            <Col>
              <Row 
                data-part="separator" 
                className={classnames({
                'border-top': index !== 0,
                'hide-font': true,
                'mx-1': true
                })}
              >
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col md="10">
                  <CustomInput
                    className="pt-2 pb-3 regular-font"
                    type="radio"
                    id={`displayInHeaderCb${index}`}
                    name="displayInHeaderCb"
                    label="Display this license in the header of my profile."
                    defaultChecked={item.displayInHeader}
                    onClick={(event) => {
                      radioButtonChange(event, item);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <DataDropDown
                    tooltipMessage={
                      licenseInformationMessage +
                      `<a href="mailto:${customerSupportContactEmail}" target="_blank">${customerSupportContactEmail}</a>.`
                    }
                    fieldName="licenseType"
                    fieldText="Credential Type"
                    hideLabel={false}
                    requiredField={true}
                    values={availableLicenseTypes}
                    styles={{
                      control: (provided: any) => ({
                        ...provided,
                        border:
                          isNotUndefined(
                            formErrors[`licenses[${index}].licenseType.label`]
                          ) ||
                          isNotUndefined(
                            formErrors[`licenses[${index}].LicenseType`]
                          )
                            ? redBorder
                            : blueBorder,
                        backgroundColor:
                          isNotUndefined(
                            formErrors[`licenses[${index}].licenseType.label`]
                          ) ||
                          isNotUndefined(
                            formErrors[`licenses[${index}].LicenseType`]
                          )
                            ? red
                            : white,
                      }),
                      menu: (provided: any) => ({
                        ...provided,
                        zIndex: 2,
                      }),
                      dropdownIndicator: (provided: any) => ({
                        ...provided,
                        color: darkBlue,
                      }),
                    }}
                    selectedValue={item.licenseType}
                    handleChange={(selected, text) => {
                      handleFromDropDownChange(selected, item.id, text);
                    }}
                    labelClass={classnames({
                      "highlighted-text":
                        isNotUndefined(
                          formErrors[`licenses[${index}].licenseType.label`]
                        ) ||
                        isNotUndefined(
                          formErrors[`licenses[${index}].LicenseType`]
                        ),
                    })}
                    error={
                      formErrors[`licenses[${index}].licenseType.label`] ||
                      formErrors[`licenses[${index}].LicenseType`]
                    }
                    className={classnames({
                      selectControl: true,
                    })}
                    onBlur={onBlur}
                  ></DataDropDown>
                </Col>
                <Col md="5">
                  <TextInput
                    maxLength={100}
                    labelName="License Number"
                    requiredField={true}
                    className={classnames({
                      highlighted:
                        isNotUndefined(
                          formErrors[`licenses[${index}].licenseNumber`]
                        ) ||
                        isNotUndefined(
                          formErrors[`licenses[${index}].LicenseNumber`]
                        ),
                    })}
                    labelClass={classnames({
                      "highlighted-text":
                        isNotUndefined(
                          formErrors[`licenses[${index}].licenseNumber`]
                        ) ||
                        isNotUndefined(
                          formErrors[`licenses[${index}].LicenseNumber`]
                        ),
                    })}
                    error={
                      formErrors[`licenses[${index}].licenseNumber`] ||
                      formErrors[`licenses[${index}].LicenseNumber`]
                    }
                    fieldText={item?.licenseNumber || ""}
                    fieldName="licenseNumber"
                    handleChange={(selected) => {
                      handleChange(selected, item.id);
                    }}
                    onBlur={onBlur}
                  ></TextInput>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <DataDropDown
                    fieldName="state"
                    fieldText="License State"
                    requiredField={true}
                    hideLabel={false}
                    styles={{
                      control: (provided: any) => ({
                        ...provided,
                        border:
                          isNotUndefined(
                            formErrors[`licenses[${index}].state.label`]
                          ) ||
                          isNotUndefined(formErrors[`licenses[${index}].State`])
                            ? redBorder
                            : blueBorder,
                        backgroundColor:
                          isNotUndefined(
                            formErrors[`licenses[${index}].state.label`]
                          ) ||
                          isNotUndefined(formErrors[`licenses[${index}].State`])
                            ? red
                            : white,
                      }),
                      menu: (provided: any) => ({
                        ...provided,
                        zIndex: 2,
                      }),
                      dropdownIndicator: (provided: any) => ({
                        ...provided,
                        color: darkBlue,
                      }),
                    }}
                    values={states}
                    selectedValue={item.state}
                    handleChange={(selected, text) => {
                      handleFromDropDownChange(selected, item.id, text);
                    }}
                    labelClass={classnames({
                      "highlighted-text":
                        isNotUndefined(
                          formErrors[`licenses[${index}].state.label`]
                        ) ||
                        isNotUndefined(formErrors[`licenses[${index}].State`]),
                    })}
                    error={
                      formErrors[`licenses[${index}].state.label`] ||
                      formErrors[`licenses[${index}].State`]
                    }
                    className={classnames({
                      selectControl: true,
                    })}
                    onBlur={onBlur}
                  ></DataDropDown>
                </Col>
                <Col lg="3" md="5">
                  <DataDropDown
                    fieldName="convertedExpirationMonth"
                    fieldText="License Expiration Month"
                    hideLabel={false}
                    values={months}
                    requiredField={true}
                    visibilityOff={true}
                    styles={{
                      control: (provided: any) => ({
                        ...provided,
                        border:
                          isNotUndefined(
                            formErrors[
                              `licenses[${index}].convertedExpirationMonth.label`
                            ]
                          ) ||
                          isNotUndefined(
                            formErrors[
                              `licenses[${index}].ConvertedExpirationMonth`
                            ]
                          )
                            ? redBorder
                            : blueBorder,
                        backgroundColor:
                          isNotUndefined(
                            formErrors[
                              `licenses[${index}].convertedExpirationMonth.label`
                            ]
                          ) ||
                          isNotUndefined(
                            formErrors[
                              `licenses[${index}].ConvertedExpirationMonth`
                            ]
                          )
                            ? red
                            : white,
                      }),
                      menu: (provided: any) => ({
                        ...provided,
                        zIndex: 2,
                      }),
                      dropdownIndicator: (provided: any) => ({
                        ...provided,
                        color: darkBlue,
                      }),
                    }}
                    selectedValue={item.convertedExpirationMonth}
                    handleChange={(selected, text) => {
                      handleFromDropDownChange(selected, item.id, text);
                    }}
                    labelClass={classnames({
                      "highlighted-text":
                        isNotUndefined(
                          formErrors[
                            `licenses[${index}].convertedExpirationMonth.label`
                          ]
                        ) ||
                        isNotUndefined(
                          formErrors[
                            `licenses[${index}].ConvertedExpirationMonth`
                          ]
                        ),
                    })}
                    error={
                      formErrors[
                        `licenses[${index}].convertedExpirationMonth.label`
                      ] ||
                      formErrors[`licenses[${index}].ConvertedExpirationMonth`]
                    }
                    className={classnames({
                      selectControl: true,
                    })}
                    onBlur={onBlur}
                  ></DataDropDown>
                </Col>
                <Col lg="3" md="5">
                  <DataDropDown
                    fieldName="convertedExpirationYear"
                    fieldText="License Expiration Year"
                    hideLabel={false}
                    visibilityOff={true}
                    styles={{
                      control: (provided: any) => ({
                        ...provided,
                        border:
                          isNotUndefined(
                            formErrors[
                              `licenses[${index}].convertedExpirationYear.label`
                            ]
                          ) ||
                          isNotUndefined(
                            formErrors[
                              `licenses[${index}].ConvertedExpirationYear`
                            ]
                          )
                            ? redBorder
                            : blueBorder,
                        backgroundColor:
                          isNotUndefined(
                            formErrors[
                              `licenses[${index}].convertedExpirationYear.label`
                            ]
                          ) ||
                          isNotUndefined(
                            formErrors[
                              `licenses[${index}].ConvertedExpirationYear`
                            ]
                          )
                            ? red
                            : white,
                      }),
                      menu: (provided: any) => ({
                        ...provided,
                        zIndex: 2,
                      }),
                      dropdownIndicator: (provided: any) => ({
                        ...provided,
                        color: darkBlue,
                      }),
                    }}
                    values={years}
                    requiredField={true}
                    selectedValue={item.convertedExpirationYear}
                    handleChange={(selected, text) => {
                      handleFromDropDownChange(selected, item.id, text);
                    }}
                    labelClass={classnames({
                      "highlighted-text":
                        isNotUndefined(
                          formErrors[
                            `licenses[${index}].convertedExpirationYear.label`
                          ]
                        ) ||
                        isNotUndefined(
                          formErrors[
                            `licenses[${index}].ConvertedExpirationYear`
                          ]
                        ),
                    })}
                    error={
                      formErrors[
                        `licenses[${index}].convertedExpirationYear.label`
                      ] ||
                      formErrors[`licenses[${index}].ConvertedExpirationYear`]
                    }
                    className={classnames({
                      selectControl: true,
                    })}
                    onBlur={onBlur}
                  ></DataDropDown>
                </Col>
                <Col lg="3" md="2" className="text-right">
                  <div>&nbsp;</div>
                  <span className="handle position-relative">
                    {items && items.length > 1 && (
                      <MoveIcon tooltipMessage="When adding multiple professional licenses to your profile use the up/down arrow to the right of each entry to change the order in which it displays on your public profile"></MoveIcon>
                    )}
                  </span>
                  <Button
                    className={classnames({ trashCan: true, "ml-3": true })}
                    onClick={(event) => deleteRow(event, item.id)}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={Icon.faTrash}
                    ></FontAwesomeIcon>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md="9">
                  <InputWithIcon
                    className={{
                      highlighted:
                        isNotUndefined(
                          formErrors[`licenses[${index}].licensingBoardUrl`]
                        ) ||
                        isNotUndefined(
                          formErrors[`licenses[${index}].LicensingBoardUrl`]
                        ),
                      "pl-5": true,
                    }}
                    visibilityOff={true}
                    requiredField={false}
                    labelClass={classnames({
                      "highlighted-text":
                        isNotUndefined(
                          formErrors[`licenses[${index}].licensingBoardUrl`]
                        ) ||
                        isNotUndefined(
                          formErrors[`licenses[${index}].LicensingBoardUrl`]
                        ),
                    })}
                    error={
                      formErrors[`licenses[${index}].licensingBoardUrl`] ||
                      formErrors[`licenses[${index}].LicensingBoardUrl`]
                    }
                    tooltipMessage={
                      "License status must be verified by therapist.com before your profile can be activated. Please help expedite this process by providing the website of the state board(s) issuing your license(s)."
                    }
                    placeholder="https://www.bhec.texas.gov/verify-a-license/"
                    labelName="Licensing Board Website"
                    fieldText={item?.licensingBoardUrl || ""}
                    fieldName="licensingBoardUrl"
                    handleChange={(selected) => {
                      handleChange(selected, item.id);
                    }}
                    icon={Icon.faGlobe}
                    handleBlur={onBlur}
                  ></InputWithIcon>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </div>
      <Row>
        <Col md="4">
          <NavLink className="plusMessage pt-0" onClick={addRow}>
            <FontAwesomeIcon size="sm" icon={Icon.faPlus}></FontAwesomeIcon>
            &nbsp;&nbsp;Add New License
          </NavLink>
        </Col>
      </Row>
    </Fragment>
  );
};
