import { useEffect, useState } from "react";
import authService from "./AuthorizeService";
import { AuthenticationResultStatus } from "./AuthorizeService";
import {
  LoginActions,
  QueryParameterNames,
  ApplicationPaths,
} from "./ApiAuthorizationConstants";
import { useGlobalDispatch, GlobalActionTypes } from "../../Context";
import { BodyLoadingSection } from "../Display";
import { useHistory } from "react-router-dom";
import { basePathName } from "../../Utilities/HelperData";
import { SessionStorageHelper } from "../../Utilities/SessionStorageHelper";
import { constants } from "../../Utilities/Constants";
import { useApiWorker } from "../../Utilities/CommonHooks";

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export const Login = (props: any) => {
  const history = useHistory();
  const dispatch = useGlobalDispatch();
  const apiWorker = useApiWorker();

  const getCampaignForwarding = async (offerName: string) => {
    return await apiWorker
      .get<boolean>(
        `${basePathName}/api/publiccampaign/getcampaignforwarding/${offerName}`
      )
      .then((result) => {
        return result.data;
      })
      .catch((err: any) => {
        return false;
      });
  };

  const login = async (returnUrl: string) => {
    // Remove the hash tag that is somehow causing issues on iOS and the Google OAuth Provider
    if (returnUrl.endsWith("#")) {
      returnUrl = returnUrl.replace("#", "");
    }
    const state = { returnUrl };
    // save url into session storage only if it doesn't already exists because this method
    // gets executed twice during login when having a redirect url

    let newReturnUrl = SessionStorageHelper.setSessionStorageItemIfNotExists(
      constants.ReturnUrl,
      returnUrl
    );
    if (newReturnUrl) {
      let subDirectory = returnUrl.substring(returnUrl.lastIndexOf("/") + 1);
      if (subDirectory.length) {
        //check if subDirectory exists as campaign and get if forwarding is on
        let isForwardToSignUp = await getCampaignForwarding(subDirectory);
        if (isForwardToSignUp) {
          SessionStorageHelper.setSessionStorageItem(
            constants.RedirectToSignUp,
            "1"
          );
        } else {
          SessionStorageHelper.clearSessionStorageItem(
            constants.RedirectToSignUp
          );
        }
      }
    }
    const result: any = await authService.signIn(state, dispatch);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        dispatch({
          type: GlobalActionTypes.SetProfile,
          payload: result.user,
        });
        await navigateToReturnUrl(returnUrl);
        break;
      case AuthenticationResultStatus.Fail:
        console.log("There was an error.");
        break;
      default:
        throw new Error(`Invalid status result ${result.status}.`);
    }
  };

  const processLoginCallback = async () => {
    const result: any = await authService.completeSignIn(
      window.location.href,
      dispatch
    );
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        throw new Error("Should not redirect.");
      case AuthenticationResultStatus.Success:
        if (result && result.user) {
          dispatch({
            type: GlobalActionTypes.SetProfile,
            payload: result.user,
          });
        }
        break;
      case AuthenticationResultStatus.Fail:
        console.log(result.message);
        break;
      default:
        throw new Error(
          `Invalid authentication result status '${result.status}'.`
        );
    }
  };

  const getReturnUrl = (state: any) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        "Invalid return url. The return url needs to have the same origin as the current page."
      );
    }
    return (
      (state && state.returnUrl) || fromQuery || `${window.location.origin}/`
    );
  };

  const redirectToRegister = () => {
    redirectToApiAuthorizationPath(
      `${ApplicationPaths.IdentityRegisterPath}?${
        QueryParameterNames.ReturnUrl
      }=${encodeURI(ApplicationPaths.Login)}`
    );
  };

  const redirectToProfile = () => {
    redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
  };

  const redirectToApiAuthorizationPath = (apiAuthorizationPath: string) => {
    history.push(`/${apiAuthorizationPath}`);
  };

  const navigateToReturnUrl = (returnUrl: string) => {
    const cachedReturnUrl = SessionStorageHelper.getSessionStorageItemAndRemove(
      constants.ReturnUrl
    );
    returnUrl = cachedReturnUrl || returnUrl;

    returnUrl = returnUrl.replace(
      window.location.origin + `${basePathName}`,
      ""
    );

    if (returnUrl.indexOf(window.location.origin) >= 0) {
      returnUrl = returnUrl.replace(window.location.origin, "");
    }
    history.push(returnUrl);
  };

  const executeAction = async (action: string) => {
    switch (action) {
      case LoginActions.Login:
        login(getReturnUrl(null));
        break;
      case LoginActions.LoginCallback:
        processLoginCallback();
        break;
      case LoginActions.LoginFailed:
        const params = new URLSearchParams(window.location.search);
        const error = params.get(QueryParameterNames.Message);
        console.log(error || "");
        break;
      case LoginActions.Profile:
        redirectToProfile();
        break;
      case LoginActions.Register:
        redirectToRegister();
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  };

  useEffect(() => {
    const action = props.action;
    executeAction(action);
  }, []);

  return <BodyLoadingSection isLoading={true}></BodyLoadingSection>;
};
