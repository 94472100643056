import { Fragment, useEffect, useRef, useState } from "react";
import { useApiWorker } from "../../Utilities/CommonHooks";
import { Item, Note, ProviderProfileManagementVM } from "./Models";
import {
  Row,
  Col,
  Form,
  Container,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  NavLink,
  Alert,
} from "reactstrap";
import { TextAreaInput } from "../../Components/Form/Inputs";
import { useCustomForm } from "../../Utilities/UseCustomForm";
import * as Yup from "yup";
import {
  ErrorAlert,
  LoadingSection,
  MaterialDesignSwitch,
} from "../../Components/Display";
import { SkinnedButton } from "../../Components/Form/Buttons";
import {
  ProviderProfileEdit,
  ProviderProfileStatusAlert,
} from "../../Components/ProviderProfile";
import { useParams } from "react-router";
import {
  ProfileStatus,
  ProviderProfileVM,
  PublishStatus,
} from "../../Components/ProviderProfile/Models/";
import { format } from "date-fns";
import {
  GlobalActionTypes,
  useGlobalDispatch,
  useGlobalState,
} from "../../Context";
import {
  adminGenericError,
  basePathName,
  Helper,
} from "../../Utilities/HelperData";
import { toast } from "react-toastify";
import {
  providerProfileValidationSchema,
  profileNotLoaded,
} from "../../Utilities/HelperData";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";
import "./ProviderProfileManagement.scss";
import { CoreEnum, ChangeLog } from "../Shared/Models/SharedModels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import ValidationErrors from "../Errors/ValidationErrors";
import SessionExpiredError from "../Errors/SessionExpiredError";
import { ActionType } from "../Shared/Models/SharedModels";
import { ProfileStatusIndicator } from "../Home";
import { CompleteProfileAlert } from "../Shared";
import classnames from "classnames";

class NoteFormValues {
  text?: string = "";

  constructor(item?: NoteFormValues) {
    if (item) {
      this.text = item.text;
    }
  }
}

const validationSchema = Yup.object({
  text: Yup.string()
    .max(4000, "Note is limited to 4,000 characters, please shorten your note")
    .required(""),
});

export const ProviderProfileManagement = () => {
  const initialTab = "1";
  const totalTabs = "5";
  const apiWorker = useApiWorker();
  const [providerProfile, setProviderProfile] = useState<
    ProviderProfileVM | undefined
  >(undefined);
  const [collapseNotes, setCollapseNotes] = useState(false);
  const toggleNotes = () => setCollapseNotes(!collapseNotes);
  const [notes, setNotes] = useState<Note[]>([]);
  const [changeLog, setChangeLog] = useState<ChangeLog[]>([]);
  const [collapseChangeLog, setCollapseChangeLog] = useState(false);
  const toggleChangeLog = () => setCollapseChangeLog(!collapseChangeLog);
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [profileLoadedOk, setProfileLoadedOk] = useState(false);
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const [actionType, setActionType] = useState(ActionType.Publish);
  const [errorMessageIsVisible, setErrorMessageIsVisible] =
    useState<boolean>(false);
  const [noteErrorMessageIsVisible, setNoteErrorMessageIsVisible] =
    useState<boolean>(false);
  const [touched, setTouched] = useState<string[]>([]);
  const [formErrors, setFormErrors] = useState<any>({});
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const initialValues = new ProviderProfileManagementVM();
  const { currentUser, user_fullname } = useGlobalState();
  const pageElementRef = useRef<null | HTMLDivElement>(null);

  // file upload
  const [newProfilePhoto, setNewProfilePhoto] = useState();
  const [isProfileImageSelected, setIsProfileImageSelected] = useState(false);
  const [runPublishingValidators, setRunPublishingValidators] = useState(false);
  const [officePhotos, setOfficePhotos] = useState<any[]>([]);
  const dispatch = useGlobalDispatch();
  const scrollElementRef = useRef<null | HTMLDivElement>(null);

  const licensesRef = useRef<any>({});
  const boardCertificationsRef = useRef<any>({});
  const certificationsRef = useRef<any>({});
  const professionalAssociationsRef = useRef<any>({});
  const schoolsRef = useRef<any>({});

  const [approveModal, setApproveModal] = useState(false);
  const [denyModal, setDenyModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [reactivateModal, setReactivateModal] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [canImpersonate, setCanImpersonate] = useState(false);
  const toggleApproveModal = () => setApproveModal(!approveModal);
  const toggleDenyModal = () => setDenyModal(!denyModal);
  const toggleDeactivateModal = () => setDeactivateModal(!deactivateModal);
  const toggleReactivateModal = () => setReactivateModal(!reactivateModal);
  const [currentTabPosition, setTabPosition] = useState<string>(initialTab);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [switchStatusModal, setSwitchStatusModal] = useState(false);
  const toggleSwitchStatusModal = () =>
    setSwitchStatusModal(!switchStatusModal);

  const [inputsChanged, setInputsChanged] = useState<boolean>(false);
  const [dropZoneReset, setDropZoneReset] = useState(false); 
  const changeDropZoneReset = (reset: boolean) => setDropZoneReset(reset);

  const handleSwitchChange = () => {
    document.querySelector(".main__content")?.scrollTo(0, 0);
    toast.dismiss();
    toggleSwitchStatusModal();
  };

  const postSwitchChange = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    toast.dismiss();
    toggleSwitchStatusModal();
    let success = true;
    const result = await apiWorker
      .put<boolean>(
        `${basePathName}/api/providerprofilemanagement/setpublicprofilestatus`,
        { isHidden: !switchChecked, userId: id },
        {}
      )
      .catch((err: any) => {
        setIsLoading(false);
        if (handleServerErrors(err)) success = false;
        toggleSwitchStatusModal();
      });

    if (!success) return;

    if (result) {
      setServerErrors([]);
      toast.success(
        result.data
          ? "The profile has been unlisted from therapist.com and will exclude you from any search results."
          : "The profile is live on therapist.com"
      );
      // true means unlisted. result.data is equal to the value we sent in the PUT request above
      setSwitchChecked(result.data);
      success = true;
      const profileStatus = await apiWorker
        .get<ProviderProfileVM>(
          `${basePathName}/api/providerprofilemanagement/getpublicprofilestatus/${id}`
        )
        .catch((err: any) => {
          setIsLoading(false);
          if (handleServerErrors(err)) success = false;
        });

      if (!success) return;

      if (profileStatus && profileStatus.data) {
        setServerErrors([]);
        setProviderProfile({
          ...providerProfile,
          profileStatus: profileStatus?.data?.profileStatus,
          publishedStatus: profileStatus?.data?.publishedStatus,
        });
      }
    }
    setIsLoading(false);
  };

  const tabPosition = (activeTab: string) => {
    setTabPosition(activeTab);
  };

  const changeTabPosition = (
    event: any,
    direction: string,
    position: string
  ) => {
    handleSave(event, false, true);
    let el = event.target;
    let tabInt: number = parseInt(currentTabPosition);
    tabInt = direction === "prev" ? tabInt - 1 : tabInt + 1;
    tabPosition(tabInt.toString());
    el.blur();
    if (position === "bottom" && scrollElementRef && scrollElementRef.current) {
      scrollElementRef.current.scrollIntoView({ behavior: "auto" });
    }
  };

  const scrollToTop = (type: string = "smooth") => {
    if (scrollElementRef && scrollElementRef.current) {
      scrollElementRef.current.scrollIntoView({
        behavior: type as ScrollBehavior,
      });
    }
  };

  const handleServerErrors = (errors: any): boolean => {
    if (errors?.status === 401) {
      setSessionExpired(true);
      scrollToTop();
      setErrorMessageIsVisible(false);
      setFormErrors({});
      return true;
    } else if (errors?.status === 500) {
      setServerErrors([adminGenericError]);
      scrollToTop();
      setErrorMessageIsVisible(false);
      return true;
    }
    return false;
  };

  const {
    values,
    formErrors: localFormErrors,
    touched: localFormTouched,
    handleChange,
    handleBlur: handleFormBlur,
    handleSubmit,
    handleChangeInDropDown,
    handleYesNoRadioChange,
    radioButtonChange,
    setValues,
    setFormErrors: localSetFormErrors,
  } = useCustomForm({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      const noErrors = Object.keys(values.errors || {}).length === 0;
      if (!noErrors) {
        setNoteErrorMessageIsVisible(true);
        setServerErrors([]);
        return;
      }

      setNoteErrorMessageIsVisible(false);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      setIsLoading(true);
      const message = { ...values.values, id: id };
      let success = true;
      const result = await apiWorker
        .post<NoteFormValues>(`${basePathName}/api/note`, message, config)
        .catch((errors: any) => {
          setIsLoading(false);
          if (handleServerErrors(errors)) {
            success = false;
            return;
          }

          if (
            errors &&
            errors.isAxiosError === undefined &&
            Object.keys(errors).length &&
            Object.keys(errors).filter((x) => x !== "").length
          ) {
            setNoteErrorMessageIsVisible(true);
          }

          let err: any = Helper.convertNonFieldErrorsToArray(errors);
          if (err && Array.isArray(err) && err.length) {
            setServerErrors(err);
          } else {
            setServerErrors([]);
          }
        });

      if (!success) return;

      if (result) {
        setServerErrors([]);
        const fullName =
          currentUser?.firstName || currentUser?.lastName
            ? `${currentUser?.firstName} ${currentUser?.lastName}`
            : currentUser?.user_fullname
            ? currentUser?.user_fullname
            : "Admin";
        notes.splice(0, 0, {
          text: message.text,
          id: uuid(),
          dateCreated: new Date(),
          createdBy: fullName,
        });
        setValues({ text: "" });
      }
      setIsLoading(false);
    },
  });

  const getProviderProfile = async (id: string) => {
    let success = true;
    const profile = await apiWorker
      .get<ProviderProfileManagementVM>(
        `${basePathName}/api/providerprofilemanagement/${id}`
      )
      .catch((errors) => {
        setIsLoading(false);

        if (handleServerErrors(errors)) {
          success = false;
          return;
        }

        errors = Helper.cleanUpErrorMessages(errors);
        setFormErrors(errors);
        if (errors && errors[""]) {
          setServerErrors(errors[""]);
        }
        scrollToTop();
      });

    if (!success) return;

    if (profile && profile.data) {
      setProfileLoadedOk(true);
      setProviderProfile(profile.data.providerProfile);
      setLoginEmail(profile.data.loginEmail || "");
      setCanImpersonate(profile.data.canImpersonate || false);
      setServerErrors([]);
      setNotes(profile.data.notes || []);
      // false means Listed. Can't do || true since that will set the value to true always
      setSwitchChecked(profile.data.isHidden || false);
      getChangeLog(id);
    }
    setIsLoading(false);
  };

  const getChangeLog = async (providerProfileId: string) => {
    let success = true;
    console.log("looking for change log...")
    const changes = await apiWorker
      .get<ChangeLog[]>(
        `${basePathName}/api/changelog/providerprofile/${providerProfileId}`
      )
      .catch((errors) => {
        console.log(errors);
        if (handleServerErrors(errors)) {
          success = false;
          return;
        }
      });
    console.log(changes);
    if (changes && changes.data) {
      setChangeLog(changes.data);
    }
  };

  const validateForm = async (
    ignoreTouchedFields: boolean = false,
    touchedElements: string[],
    applyRequiredValidations: boolean = false
  ): Promise<any> => {
    let errors: any = {};
    let modifiedProfile: ProviderProfileVM = new ProviderProfileVM();
    setRunPublishingValidators(applyRequiredValidations);
    try {
      if (providerProfile) {
        modifiedProfile = Helper.prepareProfileForValidation(
          applyRequiredValidations,
          providerProfile
        );
      }
      await providerProfileValidationSchema.validate(modifiedProfile, {
        abortEarly: false,
        context: { currentYear: new Date().getFullYear() },
      });
      setFormErrors({});
    } catch (e: any) {
      errors = Helper.processProfileValidationErrors(e);
      if (ignoreTouchedFields) {
        // this allows highlight to persist after submitting form even when not all
        // fields have been touched
        setTouched(Object.keys(errors));
      }
      setFormErrors(
        ignoreTouchedFields
          ? errors
          : Helper.convertProfileValidationErrors(errors, touchedElements)
      );
      return errors;
    }
  };

  const handlePublish = async (event: any) => {
    localSetFormErrors({});
    setNoteErrorMessageIsVisible(false);
    event.preventDefault();
    if (!profileLoadedOk) {
      setServerErrors([profileNotLoaded]);
      return;
    }
    setActionType(ActionType.Publish);
    setServerErrors([]);
    toast.dismiss();

    Helper.setPositions(
      providerProfile || new ProviderProfileVM(),
      certificationsRef.current.children[0],
      boardCertificationsRef.current.children[0],
      licensesRef && licensesRef.current && licensesRef.current.children
        ? licensesRef.current.children[0]
        : { children: [] },
      schoolsRef.current.children[0],
      professionalAssociationsRef.current.children[0]
    );
    Helper.buildProviderProfile(
      providerProfile || new ProviderProfileVM(),
      officePhotos
    );

    const errors = await validateForm(true, [], true);
    const noErrors = Object.keys(errors || {}).length === 0;
    if (!noErrors) {
      setErrorMessageIsVisible(true);
      scrollToTop();
      toast.dismiss();
      return;
    }

    setErrorMessageIsVisible(false);
    if (providerProfile == null) return;
    let formData = await Helper.buildFormData(
      providerProfile,
      isProfileImageSelected,
      newProfilePhoto,
      officePhotos
    );

    setIsLoading(true);
    let success = true;
    const savedProfile = await apiWorker
      .put<ProviderProfileVM>(
        `${basePathName}/api/providerprofilemanagement/publish`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .catch((errors) => {
        setIsLoading(false);
        if (handleServerErrors(errors)) {
          success = false;
          return;
        }

        errors = Helper.cleanUpErrorMessages(errors);

        if (Helper.hasValidationErrors(errors)) {
          setErrorMessageIsVisible(true);
        }
        setFormErrors(errors);
        if (errors && errors[""]) {
          setServerErrors(errors[""]);
        }
        scrollToTop();
      });

    if (!success) return;

    if (savedProfile) {
      setErrorMessageIsVisible(false);
      setServerErrors([]);
      updateUserName();
      setOfficePhotos([]);
      setProviderProfile(
        await Helper.setProfileRefs(savedProfile, providerProfile, officePhotos)
      );
      if (savedProfile.data.publishSucceeded) {
        setSwitchChecked(false);
        setServerErrors([]);
        // no else condition needed, there's a banner displayed in the page when publishSucceeded is false
        toast.success(
          "Your changes have been saved and will appear on your live profile in less than one hour"
        );
      }
    }
    scrollToTop();
    setIsLoading(false);
  };

  const handleProfileChanges = (currentProviderProfile: ProviderProfileVM) => {
    setProviderProfile(currentProviderProfile);
    setInputsChanged(true);
  };

  const handleProfileImageChanges = (item?: any) => {
    if(item) {
      setNewProfilePhoto(item);
      setIsProfileImageSelected(true);
    } else {
      setNewProfilePhoto(undefined);
      setIsProfileImageSelected(false);
    }
    setInputsChanged(true);
  };

  const handleOfficeImageChanges = (item: any) => {
    setOfficePhotos(item);
    setInputsChanged(true);
    setProviderProfile({
      ...providerProfile,
      inMemoryOfficePhotos: item.map((element: any) => {
        return { id: element.id, description: element.description };
      }),
    });
  };

  const handleBlur = async (item: any) => {
    setFormErrors({});
    await validateForm(false, item, runPublishingValidators);
  };

  const handleSave = async (
    event: any,
    isForApproval: boolean,
    isAsync: boolean = false
  ) => {
    localSetFormErrors({});
    setNoteErrorMessageIsVisible(false);
    if (!isAsync) {
      event.preventDefault();
    }
    if (!profileLoadedOk) {
      setServerErrors([profileNotLoaded]);
      return;
    }
    setDropZoneReset(true);
    setActionType(isForApproval ? ActionType.SubmitForReview : ActionType.Save);
    setServerErrors([]);
    toast.dismiss();

    Helper.setPositions(
      providerProfile || new ProviderProfileVM(),
      certificationsRef.current.children[0],
      boardCertificationsRef.current.children[0],
      licensesRef && licensesRef.current && licensesRef.current.children
        ? licensesRef.current.children[0]
        : { children: [] },
      schoolsRef.current.children[0],
      professionalAssociationsRef.current.children[0]
    );
    Helper.buildProviderProfile(
      providerProfile || new ProviderProfileVM(),
      officePhotos
    );

    const errors = await validateForm(
      true,
      [],
      isForApproval ||
        providerProfile?.profileStatus?.value == ProfileStatus.PendingApproval
    );
    const noErrors = Object.keys(errors || {}).length === 0;
    if (!noErrors) {
      setErrorMessageIsVisible(true);
      scrollToTop();
      toast.dismiss();
      return;
    }

    setErrorMessageIsVisible(false);
    if (providerProfile == null) return;
    let formData = await Helper.buildFormData(
      providerProfile,
      isProfileImageSelected,
      newProfilePhoto,
      officePhotos
    );

    if (!isAsync) {
      setIsLoading(true);
    }
    let success = true;
    const savedProfile = await apiWorker
      .put<ProviderProfileVM>(
        `${basePathName}/api/providerprofilemanagement`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .catch((errors) => {
        setIsLoading(false);
        if (handleServerErrors(errors)) {
          success = false;
          return;
        }

        errors = Helper.cleanUpErrorMessages(errors);
        setFormErrors(errors);

        if (Helper.hasValidationErrors(errors)) {
          setErrorMessageIsVisible(true);
        }
        if (errors && errors[""]) {
          setServerErrors(errors[""]);
        }
        scrollToTop();
      });

    if (!success) {
      if (!isAsync) {
        setIsLoading(false);
      }
      return;
    }

    if (savedProfile) {
      setErrorMessageIsVisible(false);
      setServerErrors([]);
      updateUserName();
      setOfficePhotos([]);
      setProviderProfile(
        await Helper.setProfileRefs(savedProfile, providerProfile, officePhotos)
      );
      let message = "The profile has been ";
      if (isForApproval) {
        message += "submitted for review.";
      } else {
        message += "saved.";
      }
      if (
        savedProfile.data.publishedStatus &&
        (savedProfile.data.publishedStatus.value === PublishStatus.Public ||
          savedProfile.data.publishedStatus.value === PublishStatus.Private)
      ) {
        message +=
          " Click Save & Publish to publish any changes to your live profile.";
      }
      if (!isAsync) {
        toast.success(message);
      }
    }
    if (!isAsync) {
      scrollToTop();
      setIsLoading(false);
    }
    setInputsChanged(false);
  };

  const updateUserName = () => {
    if (
      `${providerProfile?.firstName} ${providerProfile?.lastName}` !==
      user_fullname
    ) {
      dispatch({
        type: GlobalActionTypes.SetUserFullName,
        payload: providerProfile?.firstName + " " + providerProfile?.lastName,
      });
    }
  };

  useEffect(() => {
    if (currentUser && id) {
      getProviderProfile(id);
    }
  }, [currentUser, id]);

  const confirmApproval = (event: any) => {
    event.preventDefault();
    toast.dismiss();
    toggleApproveModal();
  };

  const confirmDeny = (event: any) => {
    event.preventDefault();
    toast.dismiss();
    toggleDenyModal();
  };

  const confirmDeactivate = (event: any) => {
    event.preventDefault();
    toast.dismiss();
    toggleDeactivateModal();
  };

  const confirmReactivate = (event: any) => {
    event.preventDefault();
    toast.dismiss();
    toggleReactivateModal();
  };

  const approveProfile = async (event: any) => {
    localSetFormErrors({});
    setNoteErrorMessageIsVisible(false);
    setIsLoading(true);
    toast.dismiss();
    let success = true;
    const result = await apiWorker
      .post<Item>(
        `${basePathName}/api/provideractions/approve`,
        {
          selectedRows: [id],
        },
        {}
      )
      .catch((err) => {
        toggleApproveModal();
        setIsLoading(false);
        if (handleServerErrors(err)) {
          success = false;
          return;
        }
      });

    if (!success) return;

    if (result && result.data) {
      const status = new CoreEnum();
      status.label = "Approved";
      status.value = ProfileStatus.Approved;
      const publishedStatus = new CoreEnum(PublishStatus.Public, "Public");
      setProviderProfile({
        ...providerProfile,
        profileStatus: status,
        publishedStatus: publishedStatus,
        publishedProfileUrl: result.data.publishedProfileUrl,
      });
      setSwitchChecked(false);
      setServerErrors([]);
      toast.success("This profile has been approved");
    }

    toggleApproveModal();
    setIsLoading(false);
  };

  const denyProfile = async (event: any) => {
    localSetFormErrors({});
    setNoteErrorMessageIsVisible(false);
    setIsLoading(true);
    toast.dismiss();
    let success = true;
    const result = await apiWorker
      .post<boolean>(
        `${basePathName}/api/provideractions/deny`,
        {
          selectedRows: [id],
        },
        {}
      )
      .catch((err) => {
        toggleDenyModal();
        setIsLoading(false);
        if (handleServerErrors(err)) {
          success = false;
          return;
        }
      });

    if (!success) return;

    if (result && result.data) {
      const status = new CoreEnum();
      status.label = "Disapproved";
      status.value = ProfileStatus.Denied;
      setProviderProfile({
        ...providerProfile,
        profileStatus: status,
      });
      setServerErrors([]);
      toast.success("This profile has been disapproved");
    }

    toggleDenyModal();
    setIsLoading(false);
  };

  const deactivateProfile = async (event: any) => {
    localSetFormErrors({});
    setNoteErrorMessageIsVisible(false);
    setIsLoading(true);
    toast.dismiss();
    let success = true;
    const result = await apiWorker
      .post<boolean>(
        `${basePathName}/api/provideractions/deactivate`,
        {
          selectedRows: [id],
        },
        {}
      )
      .catch((err) => {
        toggleDeactivateModal();
        setIsLoading(false);
        if (handleServerErrors(err)) {
          success = false;
          return;
        }
      });

    if (!success) return;

    if (result && result.data) {
      const profileStatus = new CoreEnum();
      profileStatus.label = "Deactivated";
      profileStatus.value = ProfileStatus.DeactivatedAndLoggedOut;
      const publishedStatus = new CoreEnum(PublishStatus.Private, "Private");
      setProviderProfile({
        ...providerProfile,
        profileStatus: profileStatus,
        publishedStatus:
          // Public means the profile was previously approved, therefore it needs to be made private now
          providerProfile!.publishedStatus?.value == PublishStatus.Public
            ? publishedStatus
            : providerProfile!.publishedStatus,
      });
      setSwitchChecked(true);
      setServerErrors([]);
      setCanImpersonate(false);
      toast.success("This profile has been deactivated");
    }
    toggleDeactivateModal();
    setIsLoading(false);
  };

  const reactivateProfile = async (event: any) => {
    localSetFormErrors({});
    setNoteErrorMessageIsVisible(false);
    setIsLoading(true);
    toast.dismiss();
    let success = true;
    const result = await apiWorker
      .post<boolean>(
        `${basePathName}/api/provideractions/reactivate`,
        {
          selectedRows: [id],
        },
        {}
      )
      .catch((err) => {
        toggleReactivateModal();
        setIsLoading(false);
        if (handleServerErrors(err)) {
          success = false;
          return;
        }
      });

    if (!success) return;

    if (result && result.data) {
      setProviderProfile({
        ...providerProfile,
        profileStatus:
          // None means the profile was never published, therefore never approved
          providerProfile!.publishedStatus?.value == PublishStatus.None
            ? new CoreEnum(ProfileStatus.Incomplete, "Incomplete")
            : new CoreEnum(ProfileStatus.Approved, "Approved"),
      });
      setServerErrors([]);
      setCanImpersonate(true);
      toast.success("This profile has been reactivated");
    }

    toggleReactivateModal();
    setIsLoading(false);
  };

  return (
    <Container fluid={true} className="position-relative">
      {providerProfile?.providerGroup ? (
        <div className="main__header regular-font align-items-center">
          Please do not make edits, refer to the group provider.
        </div>
      ) : (
        ""
      )}
      <LoadingSection
        isLoading={isLoading}
        height={pageElementRef.current?.clientHeight}
        width={pageElementRef.current?.clientWidth}
      >
        <div ref={pageElementRef}>
          <div ref={scrollElementRef} id="scrollElement"></div>
          <Modal
            fade={false}
            isOpen={switchStatusModal}
            toggle={toggleSwitchStatusModal}
          >
            <ModalHeader toggle={toggleSwitchStatusModal}>
              Change Profile Status Confirmation
            </ModalHeader>
            <ModalBody className="large-font">
              Are you sure you want to {switchChecked ? "list" : "unlist"} this
              profile?{" "}
              {!switchChecked ? (
                <div>
                  <br></br>
                  Setting this to unlisted will remove this profile listing from
                  therapist.com and will exclude you from any search results.
                </div>
              ) : (
                ""
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={postSwitchChange}
                className="px-4 large-font"
              >
                Yes
              </Button>
              <Button
                color="secondary"
                onClick={toggleSwitchStatusModal}
                className="large-font"
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={approveModal} toggle={toggleApproveModal}>
            <ModalHeader toggle={toggleApproveModal}>
              Approve Confirmation
            </ModalHeader>
            <ModalBody>
              Are you sure you want to approve the selected profile?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={approveProfile}>
                Approve
              </Button>
              <Button color="secondary" onClick={toggleApproveModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={denyModal} toggle={toggleDenyModal}>
            <ModalHeader toggle={toggleDenyModal}>
              Disapprove Confirmation
            </ModalHeader>
            <ModalBody>
              Are you sure you want to disapprove the selected profile?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={denyProfile}>
                Disapprove
              </Button>
              <Button color="secondary" onClick={toggleDenyModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={deactivateModal} toggle={toggleDeactivateModal}>
            <ModalHeader toggle={toggleDeactivateModal}>
              Deactivate Confirmation
            </ModalHeader>
            <ModalBody>
              Are you sure you want to deactivate the selected profile
              (unpublish and block access)?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={deactivateProfile}>
                Deactivate
              </Button>
              <Button color="secondary" onClick={toggleDeactivateModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={reactivateModal} toggle={toggleReactivateModal}>
            <ModalHeader toggle={toggleReactivateModal}>
              Reactivate Confirmation
            </ModalHeader>
            <ModalBody>
              Are you sure you want to reactivate the selected profile and grant
              access? You will still need to publish the profile to make their
              profile live on therapist.com.
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={reactivateProfile}>
                Reactivate
              </Button>
              <Button color="secondary" onClick={toggleReactivateModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Form className="mt-1 ml-5 mr-5 mt-4" onSubmit={handleSubmit}>
            <Row className="pt-0 pb-0">
              <Col md="12" className="px-0">
                {errorMessageIsVisible && (
                  <ErrorAlert
                    message={`Please update the highlighted fields on the red tabs below before ${
                      actionType === ActionType.Publish
                        ? "publishing"
                        : actionType === ActionType.Save
                        ? "saving"
                        : "submitting for review"
                    }`}
                  ></ErrorAlert>
                )}
                <CompleteProfileAlert
                  isIncompleteForPublishing={
                    providerProfile?.isIncompleteForPublishing || false
                  }
                ></CompleteProfileAlert>
                {providerProfile &&
                  !providerProfile?.publishSucceeded &&
                  !providerProfile?.isIncompleteForPublishing &&
                  !errorMessageIsVisible && (
                    <Alert className="alert-danger mb-0 font-weight-bold">
                      There's a delay publishing your profile, but we'll keep
                      trying and it will be live soon.
                    </Alert>
                  )}
              </Col>
              <Col className="pt-2 px-0">
                {noteErrorMessageIsVisible && (
                  <ErrorAlert message="Please update the highlighted fields before saving"></ErrorAlert>
                )}
                <ValidationErrors
                  serverErrors={serverErrors}
                  formErrors={{}}
                  customErrors={[]}
                  className="mb-0"
                />
                <SessionExpiredError showError={sessionExpired} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col
                    lg="3"
                    md="4"
                    className="px-0 pt-2 text-lg-left text-center"
                  >
                    {providerProfile?.publishedStatus &&
                      providerProfile?.profileStatus?.value !=
                        ProfileStatus.Hidden &&
                      providerProfile?.publishedStatus.value ==
                        PublishStatus.Public && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={providerProfile?.publishedProfileUrl}
                          className="btn-secondary btn mx-0 btn-profile-edit mb-3 mr-3"
                        >
                          View Profile
                        </a>
                      )}
                    {canImpersonate && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`/providers/provider/impersonate/provider-profile/${id}`}
                        className="btn-secondary btn mx-0 btn-profile-edit mb-3"
                      >
                        Impersonate
                      </a>
                    )}
                  </Col>
                  <Col
                    lg="9"
                    md="8"
                    className="pt-2 px-0 text-lg-right text-center"
                  >
                    {providerProfile?.publishedStatus &&
                      providerProfile?.profileStatus?.value !=
                        ProfileStatus.Denied &&
                      providerProfile?.profileStatus?.value !=
                        ProfileStatus.Incomplete &&
                      providerProfile?.profileStatus?.value !=
                        ProfileStatus.DeactivatedAndLoggedOut &&
                      providerProfile?.profileStatus?.value !=
                        ProfileStatus.PendingApproval && (
                        <div className="d-inline-block">
                          <span
                            className={classnames({
                              "mt-1": true,
                              "mr-3": true,
                              "ml-4":
                                providerProfile?.publishedStatus &&
                                providerProfile?.profileStatus?.value !=
                                  ProfileStatus.Hidden &&
                                providerProfile?.publishedStatus.value ==
                                  PublishStatus.Public,
                              "regular-font": true,
                              "align-top": true,
                            })}
                          >
                            Listed
                          </span>
                          <MaterialDesignSwitch
                            status={switchChecked}
                            onChange={handleSwitchChange}
                            className="align-middle"
                          />
                          <span className="align-top mt-1 mx-3 regular-font">
                            Unlisted
                          </span>
                        </div>
                      )}
                    <SkinnedButton
                      color="primary"
                      className="btn-profile-edit mb-3"
                      disabled={
                        !values.text || (values.text && !values.text.length)
                      }
                    >
                      Save Note
                    </SkinnedButton>
                    {(providerProfile?.profileStatus?.value ==
                      ProfileStatus.PendingApproval ||
                      providerProfile?.profileStatus?.value ==
                        ProfileStatus.Denied) &&
                      (currentUser?.permissions || []).includes(
                        "providerprofile.approve"
                      ) && (
                        <SkinnedButton
                          className="ml-2 green-btn btn-profile-edit mb-3"
                          onClick={confirmApproval}
                        >
                          Approve
                        </SkinnedButton>
                      )}
                    {providerProfile?.profileStatus?.value !=
                      ProfileStatus.DeactivatedAndLoggedOut && (
                      <SkinnedButton
                        className="ml-2 btn-profile-edit mb-3"
                        onClick={confirmDeactivate}
                      >
                        Deactivate Profile
                      </SkinnedButton>
                    )}
                    {providerProfile?.profileStatus?.value ==
                      ProfileStatus.PendingApproval &&
                      (currentUser?.permissions || []).includes(
                        "providerprofile.approve"
                      ) && (
                        <SkinnedButton
                          className="ml-2 btn-profile-edit mb-3"
                          onClick={confirmDeny}
                        >
                          Disapprove
                        </SkinnedButton>
                      )}
                    {providerProfile?.profileStatus?.value ==
                      ProfileStatus.DeactivatedAndLoggedOut && (
                      <SkinnedButton
                        className="ml-2 btn-profile-edit mb-3"
                        onClick={confirmReactivate}
                      >
                        Reactivate
                      </SkinnedButton>
                    )}
                    {providerProfile?.accountType?.label ===
                      "therapist.com" && (
                      <SkinnedButton
                        tag={Link}
                        to={`/reset-password/${id}`}
                        className="ml-2 btn-profile-edit mb-3"
                      >
                        Reset Password
                      </SkinnedButton>
                    )}
                    {providerProfile?.accountType?.label ===
                      "therapist.com" && (
                      <SkinnedButton
                        tag={Link}
                        to={{pathname: `/change-email/${id}`, 
                          state: {loginEmail, accountType: providerProfile.accountType.label}}}
                        className="ml-2 btn-profile-edit mb-3"
                      >
                        Change Email
                      </SkinnedButton>
                    )}
                  </Col>
                </Row>
                <Row className="rounded py-2 px-2 blue-background">
                  <Col md="12" className="mt-2 regular-font">
                    <Label className="font-weight-bold">
                      Log in email:&nbsp;
                    </Label>
                    <a href={`mailto:${loginEmail}`}>{loginEmail}</a>
                  </Col>
                  <Col md="12" className="regular-font">
                    <Label className="font-weight-bold">
                      Account type:&nbsp;
                    </Label>
                    <Label>{providerProfile?.accountType?.label}</Label>
                  </Col>
                  <Col md="12" className="regular-font">
                    <Label className="font-weight-bold">
                      Group type:&nbsp;
                    </Label>
                    <Label>{providerProfile?.providerGroup?.name}</Label>
                  </Col>
                  <Col md="12">
                    <TextAreaInput
                      placeholder="Add notes"
                      maxLength={4000}
                      labelName=""
                      showLabel={false}
                      requiredField={false}
                      fieldText={values.text || ""}
                      fieldName="text"
                      inputClassName="three-rows"
                      handleChange={handleChange}
                      handleBlur={() => {}}
                      rows={5}
                      className={classnames({
                        highlighted:
                          isNotUndefined(localFormErrors.text) ||
                          isNotUndefined(localFormErrors.Text),
                      })}
                      error={localFormErrors.text || localFormErrors.Text}
                    ></TextAreaInput>
                  </Col>
                  <Col md="12">
                    {notes.map(
                      (item: Note, index: number) =>
                        index < 3 && (
                          <Fragment key={item.id}>
                            <Row>
                              <Col
                                md="12"
                                className="regular-font font-weight-bold"
                              >
                                {item.createdBy} on{" "}
                                {format(item.dateCreated, "MMMM dd, yyyy")}
                              </Col>
                              <Col md="12" className="regular-font text-break">
                                {item.text}
                              </Col>
                            </Row>
                            <Row>
                              <Col>&nbsp;</Col>
                            </Row>
                          </Fragment>
                        )
                    )}
                  </Col>
                  {notes.length > 3 && (
                    <Col md="12" className="px-0">
                      <Collapse isOpen={collapseNotes} className="col-md-12">
                        <Row>
                          <Col md="12">
                            {notes.map(
                              (item: Note, index: number) =>
                                index > 2 && (
                                  <Fragment key={item.id}>
                                    <Row>
                                      <Col
                                        md="12"
                                        className="regular-font font-weight-bold"
                                      >
                                        {item.createdBy} on{" "}
                                        {format(
                                          item.dateCreated,
                                          "MMMM dd, yyyy"
                                        )}
                                      </Col>
                                      <Col
                                        md="12"
                                        className="regular-font text-break"
                                      >
                                        {item.text}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>&nbsp;</Col>
                                    </Row>
                                  </Fragment>
                                )
                            )}
                          </Col>
                        </Row>
                      </Collapse>
                      <Row>
                        <Col md="12" className="text-center">
                          <NavLink
                            className="plusMessage pt-0 pb-0"
                            onClick={toggleNotes}
                          >
                            {collapseNotes ? "View Less" : "View More"}
                          </NavLink>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
                {changeLog && changeLog.length > 0 && (
                  <Row className="rounded pb-2 px-2 mt-1 blue-background">
                    <Col md="12" className="px-0">
                      <Collapse
                        isOpen={collapseChangeLog}
                        className="col-md-12"
                      >
                        <Row className="pt-2">
                          <Col md="12">
                            {changeLog.map((item: ChangeLog, index: number) => (
                              <Fragment key={item.id}>
                                <Row className="change-log">
                                  <Col md="3">
                                    <span className="font-weight-bold">
                                      Field:
                                    </span>
                                    &nbsp;
                                    {item.fieldName}
                                  </Col>
                                  <Col md="3">
                                    <div>
                                      <span className="font-weight-bold">
                                        Was:
                                      </span>
                                      &nbsp;
                                      <span className="font-italic">
                                        {item.oldValue}
                                      </span>
                                    </div>
                                    <div>
                                      <span className="font-weight-bold">
                                        Changed To:
                                      </span>
                                      &nbsp;
                                      <span className="font-italic">
                                        {item.newValue}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col md="6">
                                    <span className="font-italic">
                                      {item.changeDate}
                                    </span>
                                    &nbsp; - &nbsp;
                                    <span className="font-weight-bold">
                                      {item.userName}
                                    </span>
                                  </Col>
                                </Row>
                              </Fragment>
                            ))}
                          </Col>
                        </Row>
                      </Collapse>
                      <Row>
                        <Col md="12">
                          <NavLink
                            className="pl-3 font-weight-bold regular-font"
                            onClick={toggleChangeLog}
                          >
                            {collapseChangeLog
                              ? "Hide Change Log"
                              : "View Change Log"}
                          </NavLink>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Form>
          <hr className="my-4 blue-background"></hr>
          <Form
            className="provider-form"
            onSubmit={(event: any) => handleSave(event, false)}
          >
            <Row>
              <Col md="12">
                <ProviderProfileStatusAlert
                  profileStatus={providerProfile?.profileStatus?.value}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Row className="pt-0 profile-state">
                  <Col md="4" className="pt-1 mt-1 pl-0 pb-3">
                    {(currentUser?.permissions || []).some((x: any) =>
                      ["providerprofile.editany"].includes(x)
                    ) && (
                      <Fragment>
                        <ProfileStatusIndicator
                          profileStatus={providerProfile?.profileStatus}
                          publishStatus={providerProfile?.publishedStatus}
                          publishSucceeded={
                            providerProfile?.publishSucceeded || false
                          }
                        ></ProfileStatusIndicator>
                      </Fragment>
                    )}
                  </Col>
                  <Col md="8" className="pt-0 px-0 text-right">
                    <SkinnedButton
                      className="ml-3 white-button btn-profile-edit mb-3"
                      color={
                        providerProfile?.profileStatus?.value ==
                        ProfileStatus.PendingApproval
                          ? "primary"
                          : "secondary"
                      }
                    >
                      Save
                    </SkinnedButton>
                    {(providerProfile?.profileStatus?.value ==
                      ProfileStatus.Approved ||
                      providerProfile?.profileStatus?.value ==
                        ProfileStatus.Hidden) && (
                      <SkinnedButton
                        onClick={handlePublish}
                        className="ml-2 mb-3 btn-profile-edit"
                        color="primary"
                      >
                        Save & Publish
                      </SkinnedButton>
                    )}
                    {(providerProfile?.profileStatus?.value ==
                      ProfileStatus.Denied ||
                      providerProfile?.profileStatus?.value ==
                        ProfileStatus.Incomplete) && (
                      <SkinnedButton
                        onClick={(event: any) => handleSave(event, true)}
                        className="ml-2 btn-profile-edit mb-3"
                        color="primary"
                      >
                        Save & Submit
                      </SkinnedButton>
                    )}
                    <div className="d-block d-sm-none"></div>
                    <SkinnedButton
                      className="arrow-button ml-2 btn-profile-edit mb-3"
                      onClick={(event: any) =>
                        changeTabPosition(event, "prev", "top")
                      }
                      disabled={
                        currentTabPosition === initialTab ? true : false
                      }
                    >
                      <FontAwesomeIcon icon={Icon.faChevronLeft} size="sm" />
                      &nbsp; Back
                    </SkinnedButton>
                    <SkinnedButton
                      className="arrow-button ml-2 btn-profile-edit mb-3"
                      onClick={(event: any) =>
                        changeTabPosition(event, "next", "top")
                      }
                      disabled={currentTabPosition === totalTabs ? true : false}
                    >
                      Next &nbsp;
                      <FontAwesomeIcon icon={Icon.faChevronRight} size="sm" />
                    </SkinnedButton>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <ProviderProfileEdit
                      handleProfileChanges={handleProfileChanges}
                      currentProviderProfile={providerProfile}
                      handleProfileImageChanges={handleProfileImageChanges}
                      handleOfficeImageChanges={handleOfficeImageChanges}
                      licensesRef={licensesRef}
                      boardCertificationsRef={boardCertificationsRef}
                      certificationsRef={certificationsRef}
                      professionalAssociationsRef={professionalAssociationsRef}
                      schoolsRef={schoolsRef}
                      formErrors={formErrors}
                      serverErrors={serverErrors}
                      handleBlurEvent={handleBlur}
                      touchedFields={touched}
                      tabPosition={tabPosition}
                      newTabPosition={currentTabPosition}
                      handleSave={handleSave}
                      inputsChanged={inputsChanged}
                      dropZoneReset={dropZoneReset}
                      changeDropZoneReset={changeDropZoneReset}
                    ></ProviderProfileEdit>
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col md="12" className="pt-3 px-0 text-right">
                    <SkinnedButton
                      className="ml-3 btn-profile-edit white-button"
                      color={
                        providerProfile?.profileStatus?.value ==
                        ProfileStatus.PendingApproval
                          ? "primary"
                          : "secondary"
                      }
                    >
                      Save
                    </SkinnedButton>
                    {(providerProfile?.profileStatus?.value ==
                      ProfileStatus.Denied ||
                      providerProfile?.profileStatus?.value ==
                        ProfileStatus.Incomplete) && (
                      <SkinnedButton
                        onClick={(event: any) => handleSave(event, true)}
                        className="ml-2 btn-profile-edit"
                        color="primary"
                      >
                        Save & Submit
                      </SkinnedButton>
                    )}
                    {(providerProfile?.profileStatus?.value ==
                      ProfileStatus.Approved ||
                      providerProfile?.profileStatus?.value ==
                        ProfileStatus.Hidden) && (
                      <SkinnedButton
                        onClick={handlePublish}
                        className="ml-2 btn-profile-edit"
                        color="primary"
                      >
                        Save & Publish
                      </SkinnedButton>
                    )}
                    <div className="d-block d-sm-none"></div>
                    <SkinnedButton
                      className="arrow-button btn-profile-edit ml-2"
                      onClick={(event: any) =>
                        changeTabPosition(event, "prev", "bottom")
                      }
                      disabled={
                        currentTabPosition === initialTab ? true : false
                      }
                    >
                      <FontAwesomeIcon icon={Icon.faChevronLeft} />
                      &nbsp; Back
                    </SkinnedButton>
                    <SkinnedButton
                      className="arrow-button btn-profile-edit ml-2"
                      onClick={(event: any) =>
                        changeTabPosition(event, "next", "bottom")
                      }
                      disabled={currentTabPosition === totalTabs ? true : false}
                    >
                      Next &nbsp;
                      <FontAwesomeIcon icon={Icon.faChevronRight} />
                    </SkinnedButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </LoadingSection>
    </Container>
  );
};
