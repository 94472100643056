import ReactSwitch from "../Switch/index";

export const MaterialDesignSwitch = (props: any) => {
  const toggleSwitchStatusModal = () => {
    props.onChange();
  };

  return (
    <label htmlFor="material-switch" className={props.className}>
      <ReactSwitch
        disabled={props.disabled}
        checked={props.status}
        onChange={toggleSwitchStatusModal}
        offColor="#7FBF92"
        onColor="#EC755A"
        onHandleColor="#fff"
        offHandleColor="#fff"
        handleDiameter={30}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        height={20}
        width={67}
        className="react-switch"
        id="material-switch"
      />
    </label>
  );
};
