export const VisibilityOffIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.27587 6.42529C7.98549 6.42529 7.70092 6.54263 7.49649 6.74819C7.2909 6.95376 7.17242 7.23833 7.17242 7.52874C7.17242 7.81915 7.2909 8.1036 7.49649 8.30929C7.70208 8.51486 7.98549 8.63219 8.27587 8.63219C8.56625 8.63219 8.85083 8.51486 9.05642 8.30929C9.26201 8.1036 9.37932 7.81915 9.37932 7.52874C9.37932 7.23833 9.26201 6.95376 9.05642 6.74819C8.85083 6.54263 8.56741 6.42529 8.27587 6.42529Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3977 6.3816C15.4197 6.3816 15.4417 6.38291 15.4634 6.38537C15.4426 6.38305 15.4206 6.3816 15.3977 6.3816ZM1.3421 6.99826C2.0824 7.98576 4.49373 10.7821 8.05157 10.7821C11.5777 10.7821 14.0062 8.05683 14.6693 7.09137C14.6613 7.08325 14.6531 7.07498 14.6451 7.06657C13.8352 6.23962 11.3711 3.72322 7.97693 3.72322C4.65565 3.72322 2.14752 6.12185 1.3421 6.99826ZM8.05157 11.9423C3.07753 11.9423 0.20706 7.45539 0.08737 7.26439C-0.0406459 7.06034 -0.026967 6.80044 0.121716 6.61017C0.250772 6.44498 3.33624 2.56299 7.97693 2.56299C11.8799 2.56299 14.6083 5.34943 15.5052 6.26514C15.5776 6.33925 15.6577 6.42105 15.7031 6.46412C15.7794 6.50821 15.8461 6.56811 15.8962 6.64062C15.9624 6.73649 16 6.85874 16 6.97419C16 7.49151 14.8507 8.84216 13.8231 9.70421C12.6062 10.7252 10.6169 11.9423 8.05157 11.9423Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.27592 4.31851C7.42804 4.31851 6.60173 4.66088 6.00913 5.25762C5.40929 5.85807 5.06582 6.6854 5.06582 7.52854C5.06582 8.37168 5.40929 9.19887 6.0079 9.79808C6.605 10.3958 7.43159 10.7386 8.27592 10.7386C9.12271 10.7386 9.95093 10.3958 10.548 9.79808C11.1441 9.20148 11.4859 8.37429 11.4859 7.52854C11.4859 6.68279 11.1441 5.85546 10.548 5.25886C9.95107 4.66129 9.12298 4.31851 8.27592 4.31851ZM8.27592 11.9423C7.11612 11.9423 5.97999 11.4719 5.15926 10.6517C4.33489 9.82809 3.86206 8.68979 3.86206 7.52854C3.86206 6.36729 4.33489 5.22885 5.15926 4.40512C5.97466 3.58547 7.11122 3.11475 8.27592 3.11475C9.43867 3.11475 10.5762 3.58505 11.3968 4.40526C12.2184 5.22617 12.6896 6.36461 12.6896 7.52854C12.6896 8.69246 12.2184 9.83076 11.3967 10.6517C10.5759 11.4719 9.43839 11.9423 8.27592 11.9423Z"
        fill="black"
      />
      <path d="M14.8412 1L1.56323 14.278" stroke="#4B5F9C" strokeWidth="2" />
    </svg>
  );
};
