import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as IconRegular from "@fortawesome/free-regular-svg-icons";

interface IProps {
  tooltipMessage?: string;
  tooltipPlacementLeft?: boolean;
}

export const TooltipMessage = ({
  tooltipMessage,
  tooltipPlacementLeft = false,
}: IProps) => {
  return (
    <span className="help-text-button relative">
      <FontAwesomeIcon
        icon={IconRegular.faQuestionCircle}
        className="visible shape"
      />
      <span className={tooltipPlacementLeft ? "help-text help-text-left" : "help-text"}>{tooltipMessage}</span>
    </span>
  );
};

export default TooltipMessage;
