import { ProviderProfileVM } from "../../../Components/ProviderProfile/Models";

export class ProviderProfileManagementVM {
  constructor(){
  }
  [key: string]: any
  id?: string | null;
  providerProfile?: ProviderProfileVM;
  notes?: Note[];
  accountType?: string;
  loginEmail?: string;
  isHidden?: boolean;
  canImpersonate?: boolean;
}

export class Note {
  constructor(){
  }
  [key: string]: any
  id?: string | null;
  text?: string;
  createdBy?: string;
  date?: Date;
}

export class Item {
  constructor(){
  }
  [key: string]: any
  publishedProfileUrl?: string;
}