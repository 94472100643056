import * as React from "react";
import { useEffect } from "react";
import { User } from "../Features/Shared/Models/SharedModels";
import authService from "../Components/ApiAuthorization/AuthorizeService";
import { ProviderProfileVM } from "../Components/ProviderProfile/Models";

export interface GlobalState {
  environmentName?: string;
  token?: string;
  baseUrl?: string;
  googleMapsKey?: string;
  stripeKey?: string;
  isUnauthorized?: boolean;
  currentUser?: User;
  pageState: PageState;
  wordPressSiteUrl?: string;
  aboutUrl?: string;
  privacyUrl?: string;
  termsUrl?: string;
  faqUrl?: string;
  writeForUSUrl?: string;
  getListedUrl?: string;
  enableBusinessHoursSection?: boolean;
  advertiseWithUsUrl?: string;
  accessibilityStatementUrl?: string;
  permissions: string[];
  user_fullname: string;
  customerSupportContactPhoneNumber: string;
  customerSupportContactEmail: string;
  providerProfileStatus?: ProviderProfileVM;
  initialGetRequestRanOk?: boolean;
  enableStrikethroughTextForFreeRegistration?: boolean;
  enableSecondaryPricing?: boolean;
  subscriptionStatus: string;
}

export enum PageState {
  Pending = "pending",
  Authorized = "authorized",
  Unauthorized = "unauthorized",
  Registering = "registering",
}

export enum GlobalActionTypes {
  SetUser = "setUser",
  SetProfile = "setProfile",
  SetUserFullName = "setUserFullName",
  SetSubscriptionStatus = "setSubscriptionStatus",
  SetToken = "setToken",
  SetUnauthorized = "setUnauthorized",
  SetConfig = "setConfig",
  SetPageState = "setPageState",
  SetPermissions = "SetPermissions",
  SetProfileStatus = "SetProfileStatus",
  SetInitialGetRequestRanOk = "SetInitialGetRequestRanOk",
}

export type GlobalAction =
  | { type: GlobalActionTypes.SetProfile; payload: any }
  | { type: GlobalActionTypes.SetUser; payload: any }
  | { type: GlobalActionTypes.SetToken; payload: string }
  | { type: GlobalActionTypes.SetUserFullName; payload: string }
  | { type: GlobalActionTypes.SetConfig; payload: any }
  | { type: GlobalActionTypes.SetPageState; payload: PageState }
  | { type: GlobalActionTypes.SetPermissions; payload: string[] }
  | { type: GlobalActionTypes.SetUnauthorized }
  | { type: GlobalActionTypes.SetProfileStatus; payload: any }
  | { type: GlobalActionTypes.SetInitialGetRequestRanOk; payload: boolean }
  | { type: GlobalActionTypes.SetSubscriptionStatus; payload: string };
type GlobalDispatch = (action: GlobalAction) => void;

const GlobalStateContext = React.createContext<GlobalState | undefined>(
  undefined
);

const GlobalDispatchContext = React.createContext<GlobalDispatch | undefined>(
  undefined
);

function globalReducer(state: GlobalState, action: GlobalAction): GlobalState {
  switch (action.type) {
    case GlobalActionTypes.SetProfile: {
      let tempUser = new User();
      let subscriptionStatus = "";
      Object.assign(tempUser, action.payload?.profile);
      if (tempUser && tempUser.permissions) {
        tempUser.permissions = JSON.parse(action.payload?.profile.permissions);
      }
      if (tempUser) {
        subscriptionStatus = tempUser.subscription_status || "";
      }
      return {
        ...state,
        currentUser: tempUser,
        user_fullname: tempUser.user_fullname || "",
        isUnauthorized: !tempUser,
        token: action.payload?.access_token,
        pageState: PageState.Authorized,
        subscriptionStatus: subscriptionStatus,
      };
    }
    case GlobalActionTypes.SetUser: {
      let tempUser = new User();
      Object.assign(tempUser, action.payload);
      return {
        ...state,
        user_fullname: tempUser.user_fullname || "",
        currentUser: tempUser,
      };
    }
    case GlobalActionTypes.SetUserFullName: {
      return { ...state, user_fullname: action.payload };
    }
    case GlobalActionTypes.SetToken: {
      return { ...state, token: action.payload };
    }
    case GlobalActionTypes.SetInitialGetRequestRanOk: {
      return { ...state, initialGetRequestRanOk: action.payload };
    }
    case GlobalActionTypes.SetConfig: {
      return { ...state, ...action.payload };
    }
    case GlobalActionTypes.SetPermissions: {
      return { ...state, ...action.payload };
    }
    case GlobalActionTypes.SetUnauthorized: {
      return {
        ...state,
        currentUser: new User(),
        isUnauthorized: true,
        pageState: PageState.Unauthorized,
      };
    }
    case GlobalActionTypes.SetPageState: {
      return { ...state, pageState: action.payload };
    }
    case GlobalActionTypes.SetProfileStatus: {
      return {
        ...state,
        providerProfileStatus: action.payload,
        // At this point the user is already registered, and the ProfileService.cs is not
        // called again so we need to depend on this action type
        subscriptionStatus: "registered",
      };
    }
    case GlobalActionTypes.SetSubscriptionStatus: {
      return {
        ...state,
        subscriptionStatus: action.payload,
      };
    }
    default: {
      throw new Error("Invalid action type");
    }
  }
}

export const GlobalProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    globalReducer,
    process.env.NODE_ENV === "development"
      ? {
          environmentName: process.env?.REACT_APP_ENVIRONMENT_NAME || "",
          baseUrl: process.env?.REACT_APP_BASE_URL || "",
          googleMapsKey: process.env?.REACT_APP_GOOGLE_MAPS_KEY || "",
          stripeKey: process.env?.REACT_APP_STRIPE_KEY || "",
          token: "",
          isUnauthorized: true,
          pageState: PageState.Pending,
          wordPressSiteUrl: process.env?.REACT_APP_WORDPRESSSITE_URL || "",
          aboutUrl: process.env?.REACT_APP_ABOUT_URL || "",
          privacyUrl: process.env?.REACT_APP_PRIVACY_URL || "",
          termsUrl: process.env?.REACT_APP_TERMS_URL || "",
          faqUrl: process.env?.REACT_APP_FAQ_URL || "",
          user_fullname: "",
          writeForUSUrl: process.env?.REACT_APP_WRITE_FOR_US_URL || "",
          getListedUrl: process.env?.REACT_APP_GET_LISTED_URL || "",
          advertiseWithUsUrl:
            process.env?.REACT_APP_ADVERTISE_WITH_US_URL || "",
          enableBusinessHoursSection: false,
          accessibilityStatementUrl:
            process.env?.REACT_APP_ACCESSIBILITY_STATEMENT_URL || "",
          customerSupportContactPhoneNumber:
            (process.env?.REACT_APP_CUSTOMER_SUPPORT_CONTACT_PHONE_NUMBER
              ? process.env?.REACT_APP_CUSTOMER_SUPPORT_CONTACT_PHONE_NUMBER.replace(
                  /(\d{3})(\d{3})(\d{4})/,
                  "($1) $2-$3"
                )
              : "") || "",
          customerSupportContactEmail:
            process.env?.REACT_APP_CUSTOMER_SUPPORT_CONTACT_EMAIL || "",
          permissions: [],
          initialGetRequestRanOk: undefined,
          enableStrikethroughTextForFreeRegistration: false,
          enableSecondaryPricing: false,
          subscriptionStatus: "",
        }
      : {
          environmentName: window.ENVIRONMENT_NAME || "",
          baseUrl: window.REACT_APP_BASE_URL || "",
          googleMapsKey: window.REACT_APP_GOOGLE_MAPS_KEY || "",
          stripeKey: window.REACT_APP_STRIPE_KEY || "",
          token: "",
          isUnauthorized: true,
          pageState: PageState.Pending,
          wordPressSiteUrl: window.REACT_APP_WORDPRESSSITE_URL || "",
          aboutUrl: window.REACT_APP_ABOUT_URL || "",
          privacyUrl: window.REACT_APP_PRIVACY_URL || "",
          termsUrl: window.REACT_APP_TERMS_URL || "",
          faqUrl: window.REACT_APP_FAQ_URL || "",
          writeForUSUrl: window.REACT_APP_WRITE_FOR_US_URL || "",
          user_fullname: "",
          getListedUrl: window.REACT_APP_GET_LISTED_URL || "",
          advertiseWithUsUrl: window.REACT_APP_ADVERTISE_WITH_US_URL || "",
          accessibilityStatementUrl:
            window.REACT_APP_ACCESSIBILITY_STATEMENT_URL || "",
          enableBusinessHoursSection: false,
          customerSupportContactPhoneNumber:
            (window.REACT_APP_CUSTOMER_SUPPORT_CONTACT_PHONE_NUMBER
              ? window.REACT_APP_CUSTOMER_SUPPORT_CONTACT_PHONE_NUMBER.replace(
                  /(\d{3})(\d{3})(\d{4})/,
                  "($1) $2-$3"
                )
              : "") || "",
          customerSupportContactEmail:
            window.REACT_APP_CUSTOMER_SUPPORT_CONTACT_EMAIL || "",
          permissions: [],
          initialGetRequestRanOk: undefined,
          enableStrikethroughTextForFreeRegistration:
            window.REACT_APP_ENABLE_STRIKETHROUGH_TEXT_FOR_FREE_REGISTRATION ||
            false,
          enableSecondaryPricing:
            window.REACT_APP_ENABLE_SECONDARY_PRICING === "true",
          subscriptionStatus: "",
        }
  );

  useEffect(() => {
    authService.getUser(dispatch).then((user) => {
      if (user && user.profile) {
        dispatch({ type: GlobalActionTypes.SetProfile, payload: user });
      } else {
        dispatch({
          type: GlobalActionTypes.SetPageState,
          payload: PageState.Unauthorized,
        });
      }
    });
  }, []);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const globalStateContext = React.useContext(GlobalStateContext);
  if (globalStateContext === undefined) {
    throw new Error("useGlobalState must be used within a GlobalProvider");
  }
  return globalStateContext;
};

export const useGlobalDispatch = () => {
  const globalDispatchContext = React.useContext(GlobalDispatchContext);
  if (globalDispatchContext === undefined) {
    throw new Error("useGlobalDispatch must be used within a GlobalProvider");
  }
  return globalDispatchContext;
};

export const useGlobalContext = () => {
  const state = useGlobalState();
  const dispatch = useGlobalDispatch();

  return [state, dispatch] as const;
};
