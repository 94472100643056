export const TextIcon = () => {
  return (
    <svg
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_0_1)">
        <path
          d="M64 6H12C9.79086 6 8 7.79086 8 10V62C8 64.2091 9.79086 66 12 66H64C66.2091 66 68 64.2091 68 62V10C68 7.79086 66.2091 6 64 6Z"
          fill="black"
        />
      </g>
      <path
        d="M64 6H12C9.79086 6 8 7.79086 8 10V62C8 64.2091 9.79086 66 12 66H64C66.2091 66 68 64.2091 68 62V10C68 7.79086 66.2091 6 64 6Z"
        fill="url(#paint0_linear_0_1)"
      />
      <path
        d="M46.42 16H28.78C26.6932 16 25 17.6797 25 19.75V52.25C25 54.3203 26.6932 56 28.78 56H46.42C48.5069 56 50.2 54.3203 50.2 52.25V19.75C50.2 17.6797 48.5069 16 46.42 16ZM37.6 53.5C36.2061 53.5 35.08 52.3828 35.08 51C35.08 49.6172 36.2061 48.5 37.6 48.5C38.9939 48.5 40.12 49.6172 40.12 51C40.12 52.3828 38.9939 53.5 37.6 53.5ZM46.42 45.0625C46.42 45.5781 45.9947 46 45.475 46H29.725C29.2052 46 28.78 45.5781 28.78 45.0625V20.6875C28.78 20.1719 29.2052 19.75 29.725 19.75H45.475C45.9947 19.75 46.42 20.1719 46.42 20.6875V45.0625Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_0_1"
          x="0"
          y="0"
          width="76"
          height="76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_0_1"
          x1="68"
          y1="66"
          x2="8"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8298CB" />
          <stop offset="1" stopColor="#4B5F9C" />
        </linearGradient>
      </defs>
    </svg>
  );
};
