import { Col, Row } from "reactstrap";
import { useGlobalState } from "../../Context";

export const ListedSection = () => {
  const { customerSupportContactPhoneNumber, customerSupportContactEmail } =
    useGlobalState();
  return (
    <>
      <Row className="py-4">
        <Col md="12" className="font-weight-bolder btn-profile-edit">
          How do I make changes to my profile?
        </Col>
        <Col
          md="12"
          className="font-weight-light btn-profile-edit light-black regular-line-height"
        >
          You can update your profile in the Edit Profile section of your
          therapist.com account. Selecting Save saves the changes you made but
          does not publish them to your live profile. Selecting Save and Publish
          saves your changes and publishes them to your live profile. It can
          take up to one hour for changes to appear on your live profile after
          selecting Save and Publish. If your changes have not appeared on your
          public profile after one hour please contact customer service at{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={`mailto:${customerSupportContactEmail}`}
          >
            {customerSupportContactEmail}
          </a>{" "}
          or call {customerSupportContactPhoneNumber}. We are open Monday
          through Friday, 6:30am-7:00pm Central time.
        </Col>
      </Row>
      <Row className="pb-3">
        <Col md="12" className="font-weight-bolder btn-profile-edit">
          <span>What does "Profile Status" on my dashboard mean?</span>
        </Col>
        <Col
          md="12"
          className="font-weight-light btn-profile-edit light-black regular-line-height"
        >
          Navigate to the Help page located in the left menu of your
          therapist.com account to learn more about profile statuses and their
          meanings. Please make sure to click Save or Save & Publish before
          navigating away from Edit Profile page.
        </Col>
      </Row>
    </>
  );
};
