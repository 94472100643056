import { Col, Row } from "reactstrap";

export const PendingReviewSection = () => {
  return (
    <>
      <Row className="pt-4">
        <Col md="12" className="font-weight-bolder btn-profile-edit">
          <i>
            Thanks for submitting your profile. Please allow 2-4 business days
            to process your profile. You will receive another email once the
            review is final.
          </i>
        </Col>
      </Row>
      <Row className="py-3">
        <Col md="12" className="font-weight-bolder btn-profile-edit">
          <span>What does "Profile Status" on my dashboard mean?</span>
        </Col>
        <Col
          md="12"
          className="font-weight-light btn-profile-edit light-black regular-line-height"
        >
          Navigate to the Help page located in the left menu of your
          therapist.com account to learn more about profile statuses and their
          meanings. Please make sure to click Save or Save & Submit before
          navigating away from Edit Profile page.
        </Col>
      </Row>
    </>
  );
};
