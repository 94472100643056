// This method of determining whether user is browsing on mobile device is based upon:
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent

const mQ = matchMedia?.("(pointer:coarse)");

export const isMobile = window.innerWidth < 415
  || navigator.maxTouchPoints > 0
  || (mQ?.media === ("(pointer:course)") ?? !!mQ.matches);

export const dropdownSelectLabel = isMobile ? "Select" : "Select or start typing to filter";
