import "./Loader.scss";

interface IProps {
  height?: number;
  width?: number;
  // This is used by the BodyLoadingSection component, which is used when loading the site
  className?: string;
  isFullPage?: boolean;
}

export const Loader = ({
  height = 0,
  width = 0,
  className = "",
  isFullPage = false,
}: IProps) => {
  const parentClassName = "position-absolute loading-mask mt-n4";
  const childClassName = "full-size flex-center position-fixed animate-fade-in";
  const subChildClassName = "flex-center loading-indicator";
  return (
    <>
      {isFullPage && (
        <div className="position-absolute w-100 h-100 loading-mask">
          <div className="w-100 h-100 flex-center position-absolute animate-fade-in">
            <div className="flex-center loading-indicator">
              <div className="sk-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isFullPage && height > 10 && (
        <div
          className={parentClassName}
          style={{ height: height + "px", width: width + "px" }}
        >
          <div className={childClassName}>
            <div className={subChildClassName}>
              <div className="sk-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isFullPage && height <= 10 && (
        <div className={parentClassName}>
          <div className={childClassName + " " + className}>
            <div className={subChildClassName}>
              <div className="sk-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
