import { useEffect, useState } from "react";
import { useGlobalState } from "../../Context/GlobalProvider";
import { SubscriptionUpgradeVM } from "./Models/SharedModels";
import { useApiWorker } from "../../Utilities/CommonHooks";
import { UrlHelper } from "../../Utilities/UrlHelper";
import { apiUrls } from "../../Utilities/Constants";
import { Button, Col, Row } from "reactstrap";
import "./UpgradeAd.scss";
import { useHistory } from "react-router-dom";

interface IProps {
  providerId?: string;
}

export const UpgradeAdButton = ({ providerId }: IProps) => {
  const apiWorker = useApiWorker();
  const { currentUser } = useGlobalState();
  const [upgradeBanner, setUpgradeBanner] = useState<SubscriptionUpgradeVM>(
    new SubscriptionUpgradeVM()
  );
  const history = useHistory();
  const routeChange = () => {
    let path = `${upgradeBanner.upgradeUrl || ""}`;
    history.push(path);
  };

  useEffect(() => {
    if (currentUser) {
      const getUpgradeData = async () => {
        return await apiWorker.get<SubscriptionUpgradeVM>(
          UrlHelper.getApiUrl(
            currentUser?.permissions,
            `${apiUrls.CurrentSubscriptionUpgrade}`,
            `${apiUrls.ImpersonateCurrentSubscriptionUpgrade}${providerId}`
          ),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      };
      getUpgradeData().then((upgradeInfo) => {
        if (upgradeInfo && upgradeInfo.data) {
          //show banner here
          setUpgradeBanner(upgradeInfo.data);
        }
      });
    }
  }, []);

  return (
    <>
      {upgradeBanner.upgradeUrl && (
        <div className="upgrade-ad">
          <div className="upgrade-sidebar">
            <Row>
              <Col xl="12">
                <div className="upgrade-sidebar-top"></div>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                Unlock FREE CE, worksheets, and deals when you upgrade.
              </Col>
            </Row>
            <Row className="pt-3">
              <Col xl="12">
                <Button
                  type="button"
                  className="upgrade-button-2"
                  onClick={routeChange}
                >
                  Upgrade your plan
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};
