import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";

interface Props {
  showError: boolean;
}

export default function ValidationErrors({ showError }: Props) {
  return (
    <Fragment>
      {showError && (
        <Alert color="danger" className="font-weight-bold">
          <ul className="pl-0">
            <li>
              Your session has expired. Please <Link to="/authentication/logout">log in</Link> again.
            </li>
          </ul>
        </Alert>
      )}
    </Fragment>
  );
}
