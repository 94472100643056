import { ChangeEvent, FocusEvent } from "react";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";
import RequiredField from "../RequiredField";
import { ErrorMessage, VisibilityOffIcon } from "../../Display";
import "../../../Utilities/Global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as IconRegular from "@fortawesome/free-regular-svg-icons";
import { isMobile, dropdownSelectLabel } from "../../../Utilities/IsMobile";

interface IProps {
  values: any[];
  fieldName: string;
  fieldText: string;
  hideLabel?: boolean;
  requiredField?: boolean;
  selectedValue: any | null | undefined;
  styles: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>, source: string) => void;
  className?: any;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  placeHolder?: string;
  labelClass?: string;
  error?: string;
  visibilityOff?: boolean;
  tooltipMessage?: string;
  formGroupClassName?: string;
  disabled?: boolean;
  tooltipPlacementLeft?: boolean;
}

export const DataDropDown = ({
  values,
  selectedValue,
  fieldName,
  fieldText,
  requiredField = false,
  hideLabel = false,
  styles,
  handleChange,
  onBlur = () => {},
  className = "",
  autoComplete = "",
  placeHolder = "",
  labelClass = "",
  error = "",
  visibilityOff = false,
  tooltipMessage = "",
  formGroupClassName = "",
  disabled = false,
  tooltipPlacementLeft = false,
}: IProps) => {
  return (
    <div>
      <FormGroup className={formGroupClassName}>
        {hideLabel ? (
          ""
        ) : (
          <Label className={labelClass}>
            {fieldText} {requiredField ? <RequiredField /> : null}{" "}
            {visibilityOff ? <VisibilityOffIcon /> : null}{" "}
            {tooltipMessage && (
              <span className="help-text-button relative">
                <FontAwesomeIcon
                  icon={IconRegular.faQuestionCircle}
                  className="visible shape"
                />
                <div
                  className={
                    tooltipPlacementLeft
                      ? "help-text help-text-left"
                      : "help-text"
                  }
                  dangerouslySetInnerHTML={{ __html: tooltipMessage }}
                ></div>
              </span>
            )}
          </Label>
        )}
        <Select
          isSearchable={!isMobile}
          placeholder={placeHolder || dropdownSelectLabel}
          styles={styles}
          className={className}
          name={fieldName}
          defaultValue={selectedValue}
          onChange={(selected) => {
            let event: any;
            event = {};
            event.target = {};
            event.target.type = "select";
            const text = values.filter((x) => x.value == selected?.value)[0];
            event.target.name = fieldName;
            event.target.value = selected?.value;
            handleChange(event, text.label);
          }}
          onBlur={(event: any) => {
            if (event.target.name === "") {
              event.target.name = fieldName;
            }
            onBlur(event);
          }}
          options={values}
          value={selectedValue}
          components={{ IndicatorSeparator: () => null }}
          autoComplete={autoComplete}
          isDisabled={disabled}
        ></Select>
        <ErrorMessage visible={isNotUndefined(error)} message={error} />
      </FormGroup>
    </div>
  );
};
