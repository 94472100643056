import { useEffect, useState } from "react";
import { useGlobalState } from "../../Context/GlobalProvider";
import { SubscriptionUpgradeVM } from "./Models/SharedModels";
import { useApiWorker } from "../../Utilities/CommonHooks";
import { UrlHelper } from "../../Utilities/UrlHelper";
import { apiUrls } from "../../Utilities/Constants";
import { Button, Col, Row } from "reactstrap";
import "./UpgradeAd.scss";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-regular-svg-icons";
import { useCookies } from "react-cookie";

interface IProps {
  providerId?: string;
}

export const UpgradeAdBanner = ({ providerId }: IProps) => {
  const apiWorker = useApiWorker();
  const { currentUser } = useGlobalState();
  const [isHidden, setHidden] = useState(false);
  const [cookies, setCookie] = useCookies(["therapist_hideUpgradeAd"]);
  const [upgradeBanner, setUpgradeBanner] = useState<SubscriptionUpgradeVM>(
    new SubscriptionUpgradeVM()
  );
  const history = useHistory();
  const routeChange = () => {
    let path = `${upgradeBanner.upgradeUrl || ""}`;
    history.push(path);
  };
  const hideAd = () => {
    let exDate = new Date();
    exDate.setDate(exDate.getDate() + 30);
    setHidden(true);
    setCookie("therapist_hideUpgradeAd", "true", { expires: exDate });
  };

  useEffect(() => {
    if (
      currentUser &&
      (!cookies.therapist_hideUpgradeAd ||
        cookies.therapist_hideUpgradeAd !== "true")
    ) {
      const getUpgradeData = async () => {
        return await apiWorker.get<SubscriptionUpgradeVM>(
          UrlHelper.getApiUrl(
            currentUser?.permissions,
            `${apiUrls.CurrentSubscriptionUpgrade}`,
            `${apiUrls.ImpersonateCurrentSubscriptionUpgrade}${providerId}`
          ),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      };
      getUpgradeData().then((upgradeInfo) => {
        if (upgradeInfo && upgradeInfo.data) {
          //show banner here
          setUpgradeBanner(upgradeInfo.data);
        }
      });
    }
  }, []);

  return (
    <>
      {!isHidden &&
        (!cookies.therapist_hideUpgradeAd ||
          cookies.therapist_hideUpgradeAd !== "true") &&
        upgradeBanner.upgradeUrl && (
          <div className="upgrade-ad">
            <div className="upgrade-banner">
              <Row>
                <Col sm="9" lg="10">
                  {upgradeBanner.upgradeMarketingText}
                </Col>
                <Col sm="3" lg="2" className="text-center">
                  <Button
                    type="button"
                    className="upgrade-button"
                    onClick={routeChange}
                  >
                    Upgrade your plan
                  </Button>
                </Col>
              </Row>
              <div className="closer">
                <span className="ad-help-text-button">
                  <Link to="#" onClick={hideAd}>
                    <FontAwesomeIcon
                      icon={Icon.faWindowClose}
                      className="visible shape"
                    />
                    <span className="ad-help-text">Hide</span>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        )}
    </>
  );
};
