export class PromoCodeVM {
  constructor() {
    
  }
  [key: string]: any
  isValid: boolean = false;
  priceAfterPromoCode: number = 0;
  price: number = 0;
  promoCodeApplied : boolean = false;
  promoCode?: string;
  promoCodeText?: string;
}