import { Col, Row } from "reactstrap";
import { SkinnedButton } from "../Form/Buttons";

interface IProps {
  handleSave: (event: any, isForApproval: boolean, isAsync: boolean) => void;
}

export const NotSubmittedSection = ({ handleSave }: IProps) => {
  return (
    <>
      <Row className="pt-4">
        <Col md="12" className="font-weight-bolder btn-profile-edit">
          <i>Your therapist.com profile is almost listed!</i>
        </Col>
        <Col md="12" className=" pt-4 font-weight-bolder btn-profile-edit">
          <i>
            Click the "Save & Submit" button below or at the top of the screen
            to submit your profile for review. Once submitted, please allow 2-4
            business days for our team to process your profile. You will receive
            an email once the review is final.
          </i>
        </Col>
        <Col md="12" className="pt-4">
          <SkinnedButton
            onClick={(event: any) => handleSave(event, true, false)}
            className="ml-2 btn-profile-edit"
            color="primary"
          >
            Save & Submit
          </SkinnedButton>
        </Col>
      </Row>
      <Row className="pt-4 pb-0">
        <Col md="12" className="font-weight-bolder btn-profile-edit">
          How will I know whether my profile has been approved?
        </Col>
        <Col
          md="12"
          className="font-weight-light btn-profile-edit light-black regular-line-height"
        >
          You will receive an email from our team within 2-4 business days
          regarding your profile status. You can also look at the top of your
          therapist.com Edit Profile page. If approved, your account will display as
          "Listed."
        </Col>
      </Row>
      <Row className="pt-4 pb-0">
        <Col md="12" className="font-weight-bolder btn-profile-edit">
          What happens when my profile is approved?
        </Col>
        <Col
          md="12"
          className="font-weight-light btn-profile-edit light-black regular-line-height"
        >
          Once your profile is approved, your profile will be listed to
          thousands of potential clients. Your account will display as "Listed"
          at the top of your therapist.com Edit Profile page.
        </Col>
      </Row>
      <Row className="pt-4 pb-0">
        <Col md="12" className="font-weight-bolder btn-profile-edit">
          What does it mean if my profile is not approved?
        </Col>
        <Col
          md="12"
          className="font-weight-light btn-profile-edit light-black regular-line-height"
        >
          In most cases, an unapproved profile needs more information. But don't
          worry, someone from our team will be in contact you within 24-48 business hours
          with additional information.
        </Col>
      </Row>
      <Row className="py-3">
        <Col md="12" className="font-weight-bolder btn-profile-edit">
          <span>What does "Profile Status" on my dashboard mean?</span>
        </Col>
        <Col
          md="12"
          className="font-weight-light btn-profile-edit light-black regular-line-height"
        >
          Navigate to the Help page located in the left menu of your
          therapist.com account to learn more about profile statuses and their
          meanings. Please make sure to click Save or Save & Submit before
          navigating away from Edit Profile page.
        </Col>
      </Row>
    </>
  );
};
