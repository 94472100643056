export const ShareIcon = () => {
  return (
    <svg
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_0_1)">
        <path
          d="M64 6H12C9.79086 6 8 7.79086 8 10V62C8 64.2091 9.79086 66 12 66H64C66.2091 66 68 64.2091 68 62V10C68 7.79086 66.2091 6 64 6Z"
          fill="black"
        />
      </g>
      <path
        d="M64 6H12C9.79086 6 8 7.79086 8 10V62C8 64.2091 9.79086 66 12 66H64C66.2091 66 68 64.2091 68 62V10C68 7.79086 66.2091 6 64 6Z"
        fill="url(#paint0_linear_0_1)"
      />
      <path
        d="M50.0748 41.8053C47.773 41.8053 45.663 42.7644 44.3202 44.4908L33.5783 37.9689C33.962 36.818 33.962 35.4753 33.5783 34.1325L44.3202 27.6106C46.4303 30.2961 50.4585 31.0634 53.5276 29.337C56.5967 27.6106 58.1313 23.9661 56.7885 20.7051C55.6376 17.4442 52.1849 15.526 48.5403 16.1014C45.0875 16.6769 42.402 19.746 42.402 23.1988C42.402 23.7742 42.5939 24.5415 42.7857 25.117L32.0438 31.6389C30.1256 29.1452 26.8646 28.3779 23.9873 29.337C20.9182 30.2961 19 32.9816 19 36.0507C19 39.1198 20.9182 41.8053 23.9873 42.7644C26.8646 43.7235 30.3174 42.7644 32.2356 40.4626L42.9775 46.9845C42.7857 47.5599 42.7857 48.3272 42.5939 48.9027C42.5939 52.9309 45.8548 56 49.883 56C53.9112 56 57.1722 52.7391 57.1722 48.9027C57.364 45.0663 54.1031 41.8053 50.0748 41.8053ZM50.0748 18.7869C52.5685 18.7869 54.4867 20.7051 54.4867 23.007C54.4867 25.3088 52.5685 27.227 50.0748 27.227C47.5812 27.227 45.663 25.3088 45.663 23.007C45.663 20.7051 47.5812 18.7869 50.0748 18.7869ZM26.481 40.4626C23.9873 40.4626 22.0691 38.5444 22.0691 36.2425C22.0691 33.9407 23.9873 32.0225 26.481 32.0225C28.9746 32.0225 30.8928 33.9407 30.8928 36.2425C30.8928 38.5444 28.7828 40.4626 26.481 40.4626ZM50.0748 53.3145C47.5812 53.3145 45.663 51.3963 45.663 49.0945C45.663 46.7926 47.5812 44.8744 50.0748 44.8744C52.5685 44.8744 54.4867 46.7926 54.4867 49.0945C54.4867 51.3963 52.3767 53.3145 50.0748 53.3145Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_0_1"
          x="0"
          y="0"
          width="76"
          height="76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_0_1"
          x1="68"
          y1="66"
          x2="8"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8298CB" />
          <stop offset="1" stopColor="#4B5F9C" />
        </linearGradient>
      </defs>
    </svg>
  );
};
