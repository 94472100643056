import { Fragment } from "react";
import * as IconRegular from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  toggleGroupMethod: any;
  hideGroup: boolean;
}
export const CheckboxGroupToggler = ({
  toggleGroupMethod,
  hideGroup
}: IProps) => {
  return (
    <Fragment>
      <button type="button" 
        className="btn btn-link p-0"
        onClick={(event: any) => toggleGroupMethod(event)}
      >
        <span>
          {hideGroup ? "See more +" : "See less -"}
        </span>
      </button>
    </Fragment>
  );
};
