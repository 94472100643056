import "./CustomCheckbox.scss";
import { ChangeEvent, InputHTMLAttributes } from "react";
import classnames from "classnames";
import { FormGroup, Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as IconRegular from "@fortawesome/free-regular-svg-icons";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  text: string;
  checked: boolean;
  fieldName: string;
  parentClassName?: string;
  hideEmptyLabel?: boolean;
  index?: number;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  tooltipEnabled?: boolean;
  tooltipMessage?: string;
}

export const CustomCheckbox = ({
  checked,
  fieldName,
  handleChange,
  index = -1,
  hideEmptyLabel = true,
  text,
  tooltipEnabled = false,
  tooltipMessage = "",
  parentClassName = "",
  ...props
}: IProps) => {
  return (
    <div className={parentClassName}>
      <FormGroup className="pl-0 break-spaces" check {...props}>
        {!hideEmptyLabel && <Label>&nbsp;</Label>}
        <div className="mr-2 text-nowrap">
          <Label className="custom-checkbox">
            <div className="inline-display wrap-left-negative">
              <Input
                className="hidden-checkbox ml-n1 p-0"
                type="checkbox"
                checked={checked}
                name={fieldName}
                onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                  if (index < 0) {
                    return handleChange(ev);
                  } else {
                    // Setting the value to the index, so that the code can check the item
                    ev.target.name = index.toString();
                    handleChange(ev);
                  }
                }}
              ></Input>
              <div
                className={classnames({
                  "styled-checkbox": true,
                  checked: checked,
                })}
              >
                <svg
                  viewBox="0 0 24 24"
                  className={classnames({
                    displayCheck: checked,
                  })}
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
            </div>
            <span className="text ml-2 wrap-left">{text}</span>
            {tooltipEnabled && (
              <span className="help-text-button">
                <FontAwesomeIcon
                  icon={IconRegular.faQuestionCircle}
                  className="visible shape"
                />
                <span className="help-text">{tooltipMessage}</span>
              </span>
            )}
          </Label>
        </div>
      </FormGroup>
    </div>
  );
};
