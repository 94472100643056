import { constants } from "./Constants";

export class SessionStorageHelper {
  static clearSessionStorageExceptReturnUrl = (): void => {
    const keys = Object.keys(localStorage);
    keys.forEach((key) => {
      if (key !== constants.ReturnUrl) sessionStorage.removeItem(key);
    });
  };
  static setSessionStorageItemIfNotExists = (
    key: string,
    value: string
  ): boolean => {
      if (!sessionStorage.getItem(key)) {
          sessionStorage.setItem(key, value);
          return true;
      };
      return false;
  };
  static setSessionStorageItem = (
    key: string,
    value: string
  ): void => {
    sessionStorage.setItem(key, value);
  }
  static getSessionStorageItemAndRemove = (key: string): string => {
    const value = sessionStorage.getItem(key);
    if (value) sessionStorage.removeItem(key);
    return value || "";
  };
  static clearSessionStorageItem = (key: string): void => {
    sessionStorage.removeItem(key);
  };
}
