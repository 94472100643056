export type NamedCoreEnum = {
  [x: string]: CoreEnum
};

export class CoreEnum {
  constructor(value?: string | number, label?: string) {
    this.value = value;
    this.label = label;
  }
  value?: string | number;
  label?: string;
}

export class BaseEnum {
  value?: string | number;
  displayName?: string;
}

export class Enumerations {
  constructor(
    genderType: NamedCoreEnum
  ) {
    this.genderType = genderType;
  }

  genderType: NamedCoreEnum;
}

export class User {
  id?: string;
  idp?: string;
  access_token?: string;
  expires_at?: Date;
  id_token?:string;
  profile?:any;
  refresh_token?:string;
  scope?:string;
  session_state?:string;
  state?:any;
  token_type?:string;
  expired?:boolean;
  isAuthenticated?: boolean;
  preferred_username?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  user_id?: string;
  user_fullname?: string;
  subscription_status?: string;
  provider_id?: string;
  admin_id?: string;
  permissions?: string[];
  name?: string;
}

export enum ActionType {
  Publish = 0,
  Save = 1,
  SubmitForReview = 2,
}

export class ChangeLog {
  id?: string;
  changeDate?: string;
  entityName?: string;
  entityRecordId?: string;
  fieldName?: string;
  oldValue?: string;
  newValue?: string;
  userName?: string;
}

export class SubscriptionUpgradeVM {
  upgradeUrl?: string;
  upgradeMarketingText?: string;
}