import { useRef, useEffect, useState } from "react";
import { Container, Form, Row, Col, Label } from "reactstrap";
import { ErrorAlert, LoadingSection } from "../../Components/Display";
import classnames from "classnames";
import { SkinnedButton } from "../../Components/Form/Buttons";
import ValidationErrors from "../Errors/ValidationErrors";
import SessionExpiredError from "../Errors/SessionExpiredError";
import { toast } from "react-toastify";
import { TextInput } from "../../Components/Form/Inputs";
import { useCustomForm } from "../../Utilities/UseCustomForm";
import { useApiWorker } from "../../Utilities/CommonHooks";
import * as Yup from "yup";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  Helper,
  basePathName,
  adminGenericError,
} from "../../Utilities/HelperData";
import { useGlobalDispatch, GlobalActionTypes } from "../../Context";

class ChangeEmailFormValues {
  email?: string = "";
  confirmEmail: string = "";

  constructor(item?: ChangeEmailFormValues) {
    if (item) {
      this.email = item.email;
      this.confirmEmail = item.confirmEmail;
    }
  }
}

type ChangeEmailLocation = {
  accountType: string,
  loginEmail: string
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address")
    .max(255, "The length of the Email field must be 255 characters or fewer")
    .required(""),
  confirmEmail: Yup.string().test(
    "validations",
    "Confirmed email address does not match",
    function (value) {
      return value === this.parent.email;
    }
  ),
});

export const ChangeEmail = () => {
  const location = useLocation<ChangeEmailLocation>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<any[] | null>(null);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const apiWorker = useApiWorker();
  const initialValues = new ChangeEmailFormValues();
  const { id } = useParams<{ id: string }>();
  const [errorMessageIsVisible, setErrorMessageIsVisible] =
    useState<boolean>(false);
  const dispatch = useGlobalDispatch();
  const pageElementRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    dispatch({
      type: GlobalActionTypes.SetInitialGetRequestRanOk,
      payload: true,
    });
  }, [dispatch]);

  const {
    values,
    formErrors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    handleChangeInDropDown,
    handleYesNoRadioChange,
    radioButtonChange,
    setValues,
    setFormErrors,
  } = useCustomForm({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      const noErrors = Object.keys(values.errors || {}).length === 0;
      if (!noErrors) {
        setErrorMessageIsVisible(true);
        setServerErrors(null);
        return;
      }

      setErrorMessageIsVisible(false);
      setIsLoading(true);
      let success = true;
      const result = await apiWorker
        .put<ChangeEmailFormValues>(
          `${basePathName}/api/provideractions/changeemail`,
          { ...values.values, id: id },
          {}
        )
        .catch((errors: any) => {
          setIsLoading(false);
          if (handleServerErrors(errors)) {
            success = false;
            return;
          }

          if (
            errors &&
            errors.isAxiosError === undefined &&
            Object.keys(errors).length &&
            Object.keys(errors).filter((x) => x !== "").length
          ) {
            setErrorMessageIsVisible(true);
          }

          errors = Helper.cleanUpErrorMessages(errors);
          setFormErrors(errors);

          let err: any = Helper.convertNonFieldErrorsToArray(errors);
          if (err && Array.isArray(err) && err.length) {
            setServerErrors(err);
          } else {
            setServerErrors([]);
          }
        });

      if (!success) return;

      if (result) {
        setServerErrors(null);
        toast.success("The provider log in email has been updated");
        history.goBack();
      }
    },
  });

  const handleServerErrors = (errors: any): boolean => {
    if (errors?.status === 401) {
      setSessionExpired(true);
      return true;
    } else if (errors?.status === 500) {
      setServerErrors([adminGenericError]);
      return true;
    }
    return false;
  };

  const goBack = async (event: any) => {
    event.preventDefault();
    history.goBack();
  };

  return (
    <Container fluid={true} className="mt-4 position-relative">
      <LoadingSection
        isLoading={isLoading}
        height={pageElementRef.current?.clientHeight}
        width={pageElementRef.current?.clientWidth}
      >
        <div ref={pageElementRef}>
          <Form className="mt-2 ml-5 mr-5" onSubmit={handleSubmit}>
            <Row>
              <Col md="12" className="px-0">
                {errorMessageIsVisible && (
                  <ErrorAlert message="Please update the highlighted fields before saving"></ErrorAlert>
                )}
                <ValidationErrors
                  serverErrors={serverErrors}
                  formErrors={{}}
                  customErrors={[]}
                />
                <SessionExpiredError showError={sessionExpired} />
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md="5" className="px-0 mt-2 mb-n2">
                <h2 className="dark-purple title">Change Email</h2>
              </Col>
              <Col md="7" className="pt-2 px-0 text-right">
                <SkinnedButton onClick={goBack} className="btn-profile-edit">
                  Go Back
                </SkinnedButton>
                <SkinnedButton
                  color="primary"
                  className="ml-2 btn-profile-edit"
                >
                  Save
                </SkinnedButton>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="rounded px-0 mb-4">
                <Label className="pt-4 regular-font">
                  Please specify the new email. Keep in mind this won't change
                  the Provider's Business Email.
                </Label>
                <br></br>
                <Label className="pt-1 regular-font">
                  The provider must log out for the changes to take effect.
                </Label>
                <br></br>
                <Label className="pt-1 regular-font">
                  Account type: <b>{location.state.accountType}</b>
                </Label>
                <br></br>
                <Label className="pt-0 regular-font">
                  Current login email: <b>{location.state.loginEmail}</b>
                </Label>
                <Row className="pt-3">
                  <Col md="5">
                    <TextInput
                      maxLength={255}
                      labelName="New login email"
                      requiredField={true}
                      className={classnames({
                        highlighted:
                          isNotUndefined(formErrors.email) ||
                          isNotUndefined(formErrors.Email),
                      })}
                      fieldText={values.email || ""}
                      fieldName="email"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      error={formErrors.email || formErrors.Email}
                    ></TextInput>
                  </Col>
                </Row>
                <Row className="pt-3">
                  <Col md="5">
                    <TextInput
                      maxLength={255}
                      labelName="Confirmed new login email"
                      requiredField={true}
                      className={classnames({
                        highlighted:
                          isNotUndefined(formErrors.confirmEmail) ||
                          isNotUndefined(formErrors.ConfirmEmail),
                      })}
                      fieldText={values.confirmEmail || ""}
                      fieldName="confirmEmail"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      error={formErrors.confirmEmail || formErrors.ConfirmEmail}
                    ></TextInput>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </LoadingSection>
    </Container>
  );
};
