import { useEffect, useState } from "react";
import { Alert, Container } from "reactstrap";
import PublicLayout from "../../PublicLayout";
import "./Subscription.scss";
import { SubscriptionSelect } from "./SubscriptionSelect";

export const UserSubscription = () => {

  const CanceledFlag = "canceled";
  const [showCanceledMessage, setShowCanceledMessage] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const isCanceledParam = params.get(CanceledFlag);
    if (isCanceledParam && isCanceledParam === "1") {
      setShowCanceledMessage(true);
    }
  }, []);

  return (
    <Container fluid={true} className="sub-root">
      <PublicLayout>
        <>
          <div id="progress-wrapper">
            <div className="progress-control">
              <div className="step step-1">
                <div className="circle completed-step">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="check"
                    className="check-circle svg-inline--fa fa-check fa-w-16 fa-1x "
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                    ></path>
                  </svg>
                </div>
                <div className="step-header">STEP 1</div>
              </div>
              <div className="step step-2">
                <div className="circle current-step"></div>
                <div className="step-header current-step">STEP 2</div>
              </div>
              <div className="step step-3">
                <div className="circle uncompleted-step"></div>
                <div className="step-header">STEP 3</div>
              </div>
              <div className="progress-bar progress-2"></div>
            </div>
          </div>
          {showCanceledMessage && (
            <Alert color="danger" className="font-weight-bold">
              <ul className="pl-0">
                <li>
                  Your subscription was canceled. To be listed on therapist.com,
                  you must have a subscription associated with your account.
                  <br />
                  Register for a subscription below to be listed.
                </li>
              </ul>
            </Alert>
          )}
          <SubscriptionSelect />
        </>
      </PublicLayout>
    </Container>
  );
};
