import { ChangeEvent, FocusEvent, InputHTMLAttributes } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import classnames from "classnames";
import RequiredField from "../RequiredField";
import { ErrorMessage } from "../../Display";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  fieldText: string;
  fieldName: string;
  labelName: string;
  requiredField?: boolean;
  showLabel?: boolean;
  formClassName?: any;
  inputClassName?: any;
  subText?: Array<any>;
  error?: string;
  rows?: number;
  maxLength?: number;
  labelClass?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: FocusEvent<HTMLInputElement>) => void;
}

export const TextAreaInput = ({
  fieldText,
  fieldName,
  handleChange,
  labelName,
  requiredField = false,
  showLabel = true,
  formClassName,
  inputClassName,
  subText = [],
  handleBlur,
  labelClass = "",
  error = "",
  maxLength,
  rows = 2,
  ...props
}: IProps) => {
  return (
    <FormGroup className={formClassName}>
      {showLabel ? (
        <Label className={labelClass}>
          {labelName} {requiredField ? <RequiredField /> : null}
        </Label>
      ) : null}
      {subText.length ? (
        <div className={classnames("character-limit mb-2")}>
          {subText.map((item, i) => (
            <span key={i}>{item}</span>
          ))}
        </div>
      ) : null}
      <Input
        className={inputClassName}
        type={"textarea" as any}
        rows={rows}
        value={fieldText}
        name={fieldName}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={maxLength}
        {...props}
      ></Input>
      <ErrorMessage visible={isNotUndefined(error)} message={error} />
    </FormGroup>
  );
};
