import { Fragment } from "react";
import { Col } from "reactstrap";

interface IProps {
  message: string;
  visible: boolean;
  displayAsCol?: boolean;
  className?: string;
}

export const ErrorMessage = ({
  message,
  visible = false,
  displayAsCol = false,
  className,
}: IProps) => {
  return (
    <Fragment>
      {visible &&
        (displayAsCol ? (
          <Col md="12">
            <div className="highlighted-text">{message}</div>
          </Col>
        ) : (
          <div className={className}>
            <div className="highlighted-text">{message}</div>
          </div>
        ))}
    </Fragment>
  );
};
