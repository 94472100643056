import { Alert } from "reactstrap";

interface IProps {
  message: string;
}

export const ErrorAlert = ({ message }: IProps) => {
  return (
    <Alert className="alert-danger mb-0 font-weight-bold mb-1">{message}</Alert>
  );
};
