import { useState, useRef, useEffect } from "react";
import { Container, Form, Row, Col } from "reactstrap";
import { ErrorAlert, LoadingSection } from "../../Components/Display";
import classnames from "classnames";
import { SkinnedButton } from "../../Components/Form/Buttons";
import ValidationErrors from "../Errors/ValidationErrors";
import SessionExpiredError from "../Errors/SessionExpiredError";
import { toast } from "react-toastify";
import { TextInput } from "../../Components/Form/Inputs";
import { useCustomForm } from "../../Utilities/UseCustomForm";
import { useApiWorker } from "../../Utilities/CommonHooks";
import * as Yup from "yup";
import { useGlobalState } from "../../Context";
import { useHistory, useParams } from "react-router-dom";
import { useGlobalDispatch, GlobalActionTypes } from "../../Context";
import { UrlHelper } from "../../Utilities/UrlHelper";
import { constants, apiUrls } from "../../Utilities/Constants";
import {
  Helper,
  genericServerError,
} from "../../Utilities/HelperData";

class ChangePasswordFormValues {
  currentPassword?: string = "";
  newPassword: string = "";
  confirmNewPassword: string = "";

  constructor() {}
}

const validationSchema = Yup.object({
  currentPassword: Yup.string().required(""),
  newPassword: Yup.string()
    .min(
      7,
      "New password must be between 7 and 100 characters with at least 1 uppercase, 1 lowercase and 1 number"
    )
    .max(
      100,
      "New password must be between 7 and 100 characters with at least 1 uppercase, 1 lowercase and 1 number"
    )
    .required(""),
  confirmNewPassword: Yup.string().test(
    "validations",
    "The passwords do not match",
    function (value) {
      return value === this.parent.newPassword;
    }
  ),
});

export const ChangePassword = () => {
  const history = useHistory();
  const dispatch = useGlobalDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<any[] | null>(null);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const apiWorker = useApiWorker();
  const initialValues = new ChangePasswordFormValues();
  const scrollElementRef = useRef<null | HTMLDivElement>(null);
  const [errorMessageIsVisible, setErrorMessageIsVisible] =
    useState<boolean>(false);
  const {
    currentUser,
    customerSupportContactEmail,
    customerSupportContactPhoneNumber,
  } = useGlobalState();
  const pageElementRef = useRef<null | HTMLDivElement>(null);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    dispatch({
      type: GlobalActionTypes.SetInitialGetRequestRanOk,
      payload: true,
    });
  }, [dispatch]);

  const {
    values,
    formErrors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    handleChangeInDropDown,
    handleYesNoRadioChange,
    radioButtonChange,
    setValues,
    setFormErrors,
  } = useCustomForm({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      const noErrors = Object.keys(values.errors || {}).length === 0;
      if (!noErrors) {
        setErrorMessageIsVisible(true);
        setServerErrors(null);
        return;
      }

      setErrorMessageIsVisible(false);
      setIsLoading(true);
      let success = true;
      const result = await apiWorker
        .put<ChangePasswordFormValues>(
          UrlHelper.getApiUrl(
            currentUser?.permissions,
            apiUrls.ChangePasswordUrl,
            `${apiUrls.ImpersonateChangePasswordUrl}${id}`
          ),
          // providerId is only used by the fluent validator during impersonation
          { ...values.values, impersonateProviderId: id },
          {}
        )
        .catch((errors: any) => {
          setIsLoading(false);
          if (handleServerErrors(errors)) {
            success = false;
            return;
          }

          if (
            errors &&
            errors.isAxiosError === undefined &&
            Object.keys(errors).length &&
            Object.keys(errors).filter((x) => x !== "").length
          ) {
            setErrorMessageIsVisible(true);
          }

          errors = Helper.cleanUpErrorMessages(errors);
          setFormErrors(errors);

          let err: any = Helper.convertNonFieldErrorsToArray(errors);
          if (err && Array.isArray(err) && err.length) {
            setServerErrors(err);
          } else {
            setServerErrors([]);
          }
        });

      if (!success) return;

      if (result && result.data) {
        setServerErrors(null);
        toast.success("You password has been updated");
        history.push(
          UrlHelper.getMenuLink(
            currentUser?.permissions,
            "/settings",
            `${constants.PartialImpersonateBrowserPath}settings/${id}`
          )
        );
      } else if (result && !result.data) {
        setServerErrors([
          genericServerError +
            `${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`,
        ]);
        scrollToTop();
      }
      setIsLoading(false);
    },
  });

  const handleServerErrors = (errors: any): boolean => {
    if (errors?.status === 401) {
      setSessionExpired(true);
      scrollToTop();
      setErrorMessageIsVisible(false);
      return true;
    } else if (errors?.status === 500) {
      setServerErrors([
        genericServerError +
          `${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`,
      ]);
      scrollToTop();
      setErrorMessageIsVisible(false);
      return true;
    }
    return false;
  };

  const scrollToTop = () => {
    if (scrollElementRef && scrollElementRef.current) {
      scrollElementRef.current.scrollIntoView({ behavior: "auto" });
    }
  };

  const goBack = async (event: any) => {
    event.preventDefault();
    history.goBack();
  };

  return (
    <div ref={pageElementRef} className="w-100">
      <Container fluid={true} className="mt-4 position-relative">
        <LoadingSection
          isLoading={isLoading}
          height={pageElementRef.current?.clientHeight}
          width={pageElementRef.current?.clientWidth}
        >
          <div ref={scrollElementRef} id="scrollElement"></div>
          <Form className="mt-2 ml-5 mr-5" onSubmit={handleSubmit}>
            <Row>
              <Col className="px-0">
                {errorMessageIsVisible && (
                  <ErrorAlert message="Please update the highlighted fields before saving"></ErrorAlert>
                )}
                <ValidationErrors
                  serverErrors={serverErrors}
                  formErrors={{}}
                  customErrors={[]}
                />
                <SessionExpiredError showError={sessionExpired} />
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md="5" className="px-0 mt-2 mb-n2">
                <h2 className="dark-purple title">Change Password</h2>
              </Col>
              <Col md="7" className="pt-2 px-0 text-right">
                <SkinnedButton onClick={goBack} className="btn-profile-edit">
                  Go Back
                </SkinnedButton>
                <SkinnedButton
                  color="primary"
                  className="ml-2 btn-profile-edit"
                >
                  Save
                </SkinnedButton>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="rounded px-0 mb-4">
                <Row className="pt-3">
                  <Col lg="3" md="6">
                    <TextInput
                      formGroupClass="mb-0 mt-1"
                      maxLength={100}
                      labelName="Current Password"
                      requiredField={true}
                      className={classnames({
                        highlighted:
                          isNotUndefined(formErrors.currentPassword) ||
                          isNotUndefined(formErrors.currentPassword),
                      })}
                      fieldText={values.currentPassword || ""}
                      fieldName="currentPassword"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      isPassword={true}
                      autoComplete="current-password"
                      error={
                        formErrors.currentPassword || formErrors.CurrentPassword
                      }
                    ></TextInput>
                    <TextInput
                      formGroupClass="mb-0 mt-1"
                      maxLength={100}
                      labelName="New Password"
                      requiredField={true}
                      className={classnames({
                        highlighted:
                          isNotUndefined(formErrors.newPassword) ||
                          isNotUndefined(formErrors.NewPassword),
                      })}
                      fieldText={values.newPassword || ""}
                      fieldName="newPassword"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      isPassword={true}
                      autoComplete="new-password"
                      error={formErrors.newPassword || formErrors.NewPassword}
                    ></TextInput>
                    <TextInput
                      formGroupClass="mb-0 mt-1"
                      maxLength={100}
                      labelName="Confirm New Password"
                      requiredField={true}
                      className={classnames({
                        highlighted:
                          isNotUndefined(formErrors.confirmNewPassword) ||
                          isNotUndefined(formErrors.ConfirmNewPassword),
                      })}
                      fieldText={values.confirmNewPassword || ""}
                      fieldName="confirmNewPassword"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      isPassword={true}
                      autoComplete="new-password"
                      error={
                        formErrors.confirmNewPassword ||
                        formErrors.ConfirmNewPassword
                      }
                    ></TextInput>
                  </Col>
                </Row>
                <Row>
                  <Col md="4" sm="12" className="mb-4 font-italic">
                    Passwords must be at least 7 characters with at least 1
                    uppercase, 1 lowercase and 1 number.
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </LoadingSection>
      </Container>
    </div>
  );
};
