import {
  PublishStatus,
  ProviderProfileVM,
} from "../../Components/ProviderProfile/Models/index";
import { ProfileStatus } from "../../Components/ProviderProfile/Models";
import { Helper } from "../../Utilities/HelperData";
import { GearIcon } from "../../Components/Display";
import { SkinnedButton } from "../../Components/Form/Buttons";
import { useHistory } from "react-router-dom";
import { useGlobalState } from "../../Context";
import { UrlHelper } from "../../Utilities/UrlHelper";
import { constants } from "../../Utilities/Constants";

export const ProfileStatusIndicator = ({
  profileStatus,
  publishStatus,
  publishSucceeded,
}: ProviderProfileVM) => {
  const history = useHistory();
  const { currentUser } = useGlobalState();

  const redirectToSettings = (id: string) => {
    history.push(
      UrlHelper.getMenuLink(
        currentUser?.permissions,
        "/settings",
        `${constants.PartialImpersonateBrowserPath}settings/${id}`
      )
    );
  };

  return (
    <div className="profile-state">
      <span className="larger-font black font-weight-bold">
        Profile Status:{" "}
      </span>
      {Helper.isProfilePublished(
        profileStatus?.value,
        publishStatus?.value,
        publishSucceeded
      ) ? (
        <span className="green larger-font mr-3 font-weight-bold">Listed</span>
      ) : profileStatus?.value == ProfileStatus.Denied ? (
        <span className="red larger-font mr-3 font-weight-bold">
          Action Required
        </span>
      ) : profileStatus?.value == ProfileStatus.Incomplete ? (
        <span className="grey larger-font mr-3 font-weight-bold">
          Not submitted
        </span>
      ) : profileStatus?.value == ProfileStatus.PendingApproval ? (
        <span className="grey larger-font mr-3 font-weight-bold">
          Pending Review
        </span>
      ) : // profileStatus == ProfileStatus.Approved && publishStatus == PublishStatus.Private can
      // also be applied to Subscription canceled, but the provider can't see it, because she can't
      // access the provider portal.
      profileStatus?.value == ProfileStatus.Hidden ? (
        <span className="red larger-font mr-3 font-weight-bold">
          Unlisted
          {(currentUser?.permissions || []).some((x: any) =>
            ["providerprofile.editself"].includes(x)
          ) && (
            <SkinnedButton
              onClick={redirectToSettings}
              color="primary"
              className="border-transparent background-transparent pl-0"
            >
              <GearIcon />
            </SkinnedButton>
          )}
        </span>
      ) : Helper.isProfileUnpublished(
          profileStatus?.value,
          publishStatus?.value
        ) ? (
        <span className="red larger-font mr-3 font-weight-bold">
          Unlisted
          {(currentUser?.permissions || []).some((x: any) =>
            ["providerprofile.editself"].includes(x)
          ) && (
            <SkinnedButton
              onClick={redirectToSettings}
              color="primary"
              className="border-transparent background-transparent pl-0"
            >
              <GearIcon />
            </SkinnedButton>
          )}
        </span>
      ) : profileStatus?.value == ProfileStatus.Approved &&
        publishStatus?.value == PublishStatus.Public &&
        !publishSucceeded ? (
        <span className="green larger-font mr-3 font-weight-bold">Listed</span>
      ) : profileStatus?.value == ProfileStatus.DeactivatedAndLoggedOut ? (
        <span className="red larger-font mr-3 font-weight-bold">
          Deactivated
        </span>
      ) : null}
    </div>
  );
};
