import React, { useRef, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable, { SortOrder, TableColumn } from "react-data-table-component";
import classnames from "classnames";
import {
  Row,
  Col,
  Form,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
} from "reactstrap";
import * as Yup from "yup";

import { CampaignVM, ProductVM, CampaignListVM, ProductListVM } from "./Models";
import { CustomCheckbox, DatePicker } from "../../Components/Form/Inputs";
import { LoadingSection } from "../../Components/Display";
import { SkinnedButton } from "../../Components/Form/Buttons";
import ValidationErrors from "../Errors/ValidationErrors";
import SessionExpiredError from "../Errors/SessionExpiredError";
import { useApiWorker } from "../../Utilities/CommonHooks";
import { constants } from "../../Utilities/Constants";
import { ErrorHelper } from "../../Utilities/ErrorHelper";
import { basePathName, adminGenericError } from "../../Utilities/HelperData";
import { useCustomForm } from "../../Utilities/UseCustomForm";
import { PaginationOptions, SortOptions } from "../ProviderSearch";
import { CampaignDetails } from "../Shared";

import "./ProductList.scss";

class ProviderSearchFormValues {
  searchText?: string = "";
  isActive?: boolean = false;
  isInactive?: boolean = false;
  campaignStartDate: Date | null = null;
  campaignEndDate: Date | null = null;
}

const validationSchema = Yup.object({});
const initialValues = new ProviderSearchFormValues();

export const ProductList = () => {

  const defaultPageSize = 10;

  const apiWorker = useApiWorker();
  const history = useHistory();

  const [campaignData, setCampaignData] = useState<CampaignVM[]>([]);
  const [productData, setProductData] = useState<ProductVM[]>([]);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isGridLoading, setIsGridLoading] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const [disableProductButtons, setDisableProductButtons] = useState(true);
  const [disableCampaignButtons, setDisableCampaignButtons] = useState(true);
  const [isProductScreenVisible, setIsProductScreenVisible] = useState(true);
  const [deactivateProductModal, setDeactivateProductModal] = useState(false);
  const [deleteProductModal, setDeleteProductModal] = useState(false);
  const [deactivateCampaignModal, setDeactivateCampaignModal] = useState(false);
  const [deleteCampaignModal, setDeleteCampaignModal] = useState(false);
  const [selectedProductKeys, setSelectedProductKeys] = useState<string[]>([]);
  const [selectedCampaignKeys, setSelectedCampaignKeys] = useState<string[]>([]);

  const [totalProductRows, setTotalProductRows] = useState<number>(0);
  const [pageSizeProduct, setPageSizeProduct] = useState<number>(defaultPageSize);
  const [pageProduct, setPageProduct] = useState<number>(1);
  const [sortOptionsProduct, setSortOptionsProduct] = useState<SortOptions>();

  const [totalCampaignRows, setTotalCampaignRows] = useState<number>(0);
  const [pageSizeCampaign, setPageSizeCampaign] = useState<number>(defaultPageSize);
  const [pageCampaign, setPageCampaign] = useState<number>(1);
  const [sortOptionsCampaign, setSortOptionsCampaign] = useState<SortOptions>();
  
  const pageElementRef = useRef<null | HTMLDivElement>(null);

  const toggleDeactivateProductModal = () => setDeactivateProductModal(!deactivateProductModal);
  const toggleDeleteProductModal = () => setDeleteProductModal(!deleteProductModal);
  const toggleDeactivateCampaignModal = () => setDeactivateCampaignModal(!deactivateCampaignModal);
  const toggleDeleteCampaignModal = () => setDeleteCampaignModal(!deleteCampaignModal);

  const handleRowSelection = (selected: {allSelected: boolean, selectedCount: number, selectedRows: ProductVM[]}) =>
  {
    const keys: string[] = [];
    if (isProductScreenVisible) {
      if (selected.selectedCount) {
        selected.selectedRows.forEach(row => {
          keys.push(row.id!)
        });
        setDisableProductButtons(false);
      } else {
        setDisableProductButtons(true);
      }
      setSelectedProductKeys(keys);
    } else {
      if (selected.selectedCount) {
        selected.selectedRows.forEach(row => {
          keys.push(row.id!);
        })
        setDisableCampaignButtons(false);
      } else {
        setDisableCampaignButtons(true);
      }
      setSelectedCampaignKeys(keys);
    }
  };

  const searchProducts = async (paginationOptions?: PaginationOptions, sortOptions?: SortOptions) => {
    setIsGridLoading(true);
    const data = {
      searchText: values.searchText,
      isActive: values.isActive,
      isInactive: values.isInactive,
      campaignStartDate: values.campaignStartDate,
      campaignEndDate: values.campaignEndDate,
      pageSize: paginationOptions?.paginationPageSize || defaultPageSize,
      page: paginationOptions?.paginationPage || pageProduct,
      sortOrder: sortOptions?.sortOrder || 'asc',
      sortField: sortOptions?.field || 'name'
    };
    let success = true;
    const result = await apiWorker
      .get<ProductListVM>(`${basePathName}/api/product/list`, {
        params: {
          ...data,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((err) => {
        setIsGridLoading(false);
        if (ErrorHelper.handleServerErrors(err, setServerErrors, setSessionExpired, null)) {
          success = false;
          return;
        }
        setServerErrors([adminGenericError]);
      });

    if (!success) return;

    if (result) {
      setServerErrors([]);
      setIsGridLoading(false);
      setProductData(result.data.data || []);
      setTotalProductRows(result.data.total || 0);
    }
  };

  const searchCampaigns = async (paginationOptions?: PaginationOptions, sortOptions?: SortOptions) => {
    setIsGridLoading(true);
    const data = {
      searchText: values.searchText,
      isActive: values.isActive,
      isInactive: values.isInactive,
      campaignStartDate: values.campaignStartDate,
      campaignEndDate: values.campaignEndDate,
      pageSize: paginationOptions?.paginationPageSize || defaultPageSize,
      page: paginationOptions?.paginationPage || pageCampaign,
      sortOrder: sortOptions?.sortOrder || 'asc',
      sortField: sortOptions?.field || 'name'
    };
    let success = true;
    const result = await apiWorker
      .get<CampaignListVM>(`${basePathName}/api/campaign/list`, {
        params: {
          ...data,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((err) => {
        setIsGridLoading(false);
        if (ErrorHelper.handleServerErrors(err, setServerErrors, setSessionExpired, null)) {
          success = false;
          return;
        }
        setServerErrors([adminGenericError]);
      });

    if (!success) return;

    if (result) {
      setServerErrors([]);
      setIsGridLoading(false);
      setCampaignData(result.data.data || []);
      setTotalCampaignRows(result.data.total || 0);
    }
  };

  const productFieldToString = (field: TableColumn<ProductVM>): string => {
    switch (field.id) {
      case 1:
        return 'name';
      case 2:
        return 'status';
      case 3:
        return 'campaignName';
      case 4:
        return 'campaignStatus';
      case 5:
        return 'campaignStartDate';
      case 6:
        return 'campaignEndDate';
      default:
        return '';
    }
  };

  const campaignFieldToString = (field: TableColumn<CampaignVM>): string => {
    switch (field.id) {
      case 1:
        return 'name';
      case 2:
        return 'status';
      case 3:
        return 'startDate';
      case 4:
        return 'endDate';
      default:
        return '';
    }
  };

  useEffect(() => {
    setIsPageLoading(true);
    searchProducts();
    setIsPageLoading(false);
  }, []);

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
  } = useCustomForm({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      if (isProductScreenVisible) {
        searchProducts();
      } else {
        searchCampaigns();
      }
    },
  });
  
  const addProduct = () => {
    history.push("/product");
  };

  const addCampaign = () => {
    history.push("/campaign");
  };

  const deleteProducts = async (event: React.MouseEvent) => {
    setIsPageLoading(true);
    toast.dismiss();
    let success = true;
    const result = await apiWorker
      .put<boolean>(
        `${basePathName}/api/product/delete`,
        {
          selectedRows: selectedProductKeys,
        },
        {}
      )
      .catch((err) => {
        toggleDeleteProductModal();
        setIsPageLoading(false);
        if (ErrorHelper.handleServerErrors(err, setServerErrors, setSessionExpired, null)) {
          success = false;
          return;
        }
        const message = `There was an error deleting ${
          selectedProductKeys.length > 1 ? "some of" : ""
        } the selected product${
          selectedProductKeys.length > 1 ? "s" : ""
        }. Please refresh this page and try again.`;
        if (err && err[""] && Array.isArray(err[""])) {
          const items = err[""];
          setServerErrors([message, ...items]);
        } else {
          setServerErrors([message]);
        }
      });

    if (!success) return;

    if (result && result.data) {
      setServerErrors([]);
      toast.success("Product(s) deleted");
      await searchProducts();
    }
    toggleDeleteProductModal();
    setIsPageLoading(false);
  };

  const deactivateProducts = async (event: React.MouseEvent) => {
    setIsPageLoading(true);
    toast.dismiss();
    let success = true;
    const result = await apiWorker
      .put<boolean>(
        `${basePathName}/api/product/deactivate`,
        {
          selectedRows: selectedProductKeys,
        },
        {}
      )
      .catch((err) => {
        toggleDeactivateProductModal();
        setIsPageLoading(false);
        if (ErrorHelper.handleServerErrors(err, setServerErrors, setSessionExpired, null)) {
          success = false;
          return;
        }
        const message = `There was an error deactivating ${
          selectedProductKeys.length > 1 ? "some of" : ""
        } the selected product${
          selectedProductKeys.length > 1 ? "s" : ""
        }. Please refresh this page and try again.`;
        if (err && err[""] && Array.isArray(err[""])) {
          const items = err[""];
          setServerErrors([message, ...items]);
        } else {
          setServerErrors([message]);
        }
      });

    if (!success) return;

    if (result && result.data) {
      setServerErrors([]);
      toast.success("Product(s) deactivated");
      await searchProducts();
    }
    toggleDeactivateProductModal();
    setIsPageLoading(false);
  };

  const deleteCampaign = async (event: React.MouseEvent) => {
    setIsPageLoading(true);
    toast.dismiss();
    let success = true;
    const result = await apiWorker
      .put<boolean>(
        `${basePathName}/api/campaign/delete`,
        {
          selectedRows: selectedCampaignKeys,
        },
        {}
      )
      .catch((err) => {
        toggleDeleteCampaignModal();
        setIsPageLoading(false);
        if (ErrorHelper.handleServerErrors(err, setServerErrors, setSessionExpired, null)) {
          success = false;
          return;
        }
        const message = `There was an error deleting ${
          selectedCampaignKeys.length > 1 ? "some of" : ""
        } the selected campaign${
          selectedCampaignKeys.length > 1 ? "s" : ""
        }. Please refresh this page and try again.`;
        if (err && err[""] && Array.isArray(err[""])) {
          const items = err[""];
          setServerErrors([message, ...items]);
        } else {
          setServerErrors([message]);
        }
      });

    if (!success) return;

    if (result && result.data) {
      setServerErrors([]);
      toast.success("Campaign(s) deleted");
      await searchCampaigns();
    } else if (result) {
      setServerErrors(["The default campaign cannot be deleted"]);
    }
    toggleDeleteCampaignModal();
    setIsPageLoading(false);
  };

  const deactivateCampaigns = async (event: React.MouseEvent) => {
    setIsPageLoading(true);
    toast.dismiss();
    let success = true;
    const result = await apiWorker
      .put<boolean>(
        `${basePathName}/api/campaign/deactivate`,
        {
          selectedRows: selectedCampaignKeys,
        },
        {}
      )
      .catch((err) => {
        toggleDeactivateCampaignModal();
        setIsPageLoading(false);
        if (ErrorHelper.handleServerErrors(err, setServerErrors, setSessionExpired, null)) {
          success = false;
          return;
        }
        const message = `There was an error deactivating ${
          selectedCampaignKeys.length > 1 ? "some of" : ""
        } the selected campaign${
          selectedCampaignKeys.length > 1 ? "s" : ""
        }. Please refresh this page and try again.`;
        if (err && err[""] && Array.isArray(err[""])) {
          const items = err[""];
          setServerErrors([message, ...items]);
        } else {
          setServerErrors([message]);
        }
      });

    if (!success) return;

    if (result && result.data) {
      setServerErrors([]);
      toast.success("Campaign(s) deactivated");
      await searchCampaigns();
    } else if (result) {
      setServerErrors(["The default campaign cannot be deactivated"]);
    }
    toggleDeactivateCampaignModal();
    setIsPageLoading(false);
  };

  const confirmDeactivateProduct = (event: React.MouseEvent) => {
    event.preventDefault();
    toast.dismiss();
    toggleDeactivateProductModal();
  };

  const confirmDeactivateCampaign = (event: React.MouseEvent) => {
    event.preventDefault();
    toast.dismiss();
    toggleDeactivateCampaignModal();
  };

  const confirmDeleteProduct = (event: React.MouseEvent) => {
    event.preventDefault();
    toast.dismiss();
    toggleDeleteProductModal();
  };

  const confirmDeleteCampaign = (event: React.MouseEvent) => {
    event.preventDefault();
    toast.dismiss();
    toggleDeleteCampaignModal();
  };

  const productColumns: TableColumn<ProductVM>[] = [
    {
      name: <>Product</>,
      selector: row => row.name!,
      cell: (row) => 
        <Link 
          className="font-weight-normal" 
          to={"product/" + row.id} 
          tabIndex={-1}>{row.name}
        </Link>,
      sortable: true,
      width: '12%'
    },
    {
      name: <>Product Status</>,
      selector: row => row.status,
      sortable: true,
      width: '12%'
    },
    {
      name: <>Campaign</>,
      selector: row => row.campaignName!,
      sortable: true,
      cell: (row) => row.campaignId === constants.Multiple ? (row.campaignName) : (
        <Link
          className="font-weight-normal"
          to={(row.isDefault ? "default-campaign/" : "campaign/") + row.campaignId}
          tabIndex={-1}
        >
          {row.campaignName}
        </Link>
      ),
      width: '20%'
    },
    {
      name: <>Campaign Status</>,
      selector: row => row.campaignStatus!,
      sortable: true,
      width: '14%'
    },
    {
      name: <>Start</>,
      selector: row => row.campaignStartDate!,
      sortable: true,
      width: '11%'
    },
    {
      name: <>End</>,
      selector: row => row.campaignEndDate!,
      sortable: true,
      width: '11%'
    },
    {
      name: <>Offer Page</>,
      selector: row => row.offerPage!,
      width: '12%'
    }
  ];

  const campaignColumns: TableColumn<CampaignVM>[] = [
    {
      name: <>Campaign</>,
      selector: row => row.name!,
      sortable: true,
      cell: (row) => 
        <Link 
          className="font-weight-normal" 
          to={(row.isDefault ? "default-campaign/" : "campaign/") + row.id}
          tabIndex={-1}
        >
          {row.name}
        </Link>,
      width: '25%'
    },
    {
      name: <>Campaign Status</>,
      selector: row => row.status!,
      sortable: true,
      width: '19%'
    },
    {
      name: <>Campaign Start</>,
      selector: row => row.startDate ? row.startDate.toString() : "",
      sortable: true,
      width: '19%'
    },
    {
      name: <>Campaign End</>,
      selector: row => row.endDate ? row.endDate.toString() : "",
      sortable: true,
      width: '19%'
    },
    {
      name: <>Offer Page</>,
      selector: row => row.offerPage!,
      cell: (row) => row.offerPage && (
        <Link
          className="font-weight-normal"
          to={`${constants.CampaignPreview}${row.offerPage ? "/" + row.offerPage : ""}`}
          tabIndex={-1}
          target="_blank"
        >
          View
        </Link>
      ),
      width: '13%'
    }
  ];

  const handleProductChangeRowsPerPage = (rowsPerPage: number, page: number) => {
    searchProducts({paginationPage: page, paginationPageSize: rowsPerPage}, 
      {sortOrder: sortOptionsProduct?.sortOrder, field: sortOptionsProduct?.field });
    setPageSizeProduct(rowsPerPage);
  };

  const handleProductChangePage = (page: number) => {
    searchProducts({paginationPage: page, paginationPageSize: pageSizeProduct}, 
      {sortOrder: sortOptionsProduct?.sortOrder, field: sortOptionsProduct?.field });
    setPageProduct(page);
  };
  
  const handleProductSortChange = (field: TableColumn<ProductListVM>, sortDirection: SortOrder) => {
    const fieldName = productFieldToString(field);
    searchProducts({paginationPageSize: pageSizeProduct, paginationPage: pageProduct}, 
      {sortOrder: sortDirection, field: fieldName});
    setSortOptionsProduct({ sortOrder: sortDirection, field: fieldName});
  };

  const handleCampaignChangeRowsPerPage = (rowsPerPage: number, page: number) => {
    searchCampaigns({paginationPage: page, paginationPageSize: rowsPerPage}, 
      {sortOrder: sortOptionsCampaign?.sortOrder, field: sortOptionsCampaign?.field });
    setPageSizeCampaign(rowsPerPage);
  };
  
  const handleCampaignChangePage = (page: number) => {
    searchCampaigns({paginationPage: page, paginationPageSize: pageSizeCampaign}, 
      {sortOrder: sortOptionsCampaign?.sortOrder, field: sortOptionsCampaign?.field });
    setPageCampaign(page);
  };
  
  const handleCampaignSortChange = (field: TableColumn<ProductListVM>, sortDirection: SortOrder) => {
    const fieldName = campaignFieldToString(field);
    searchCampaigns({paginationPageSize: pageSizeCampaign, paginationPage: pageCampaign}, 
      {sortOrder: sortDirection, field: fieldName});
    setSortOptionsCampaign({ sortOrder: sortDirection, field: fieldName});
  };

  return (
    <Container fluid={true} className="mt-4 position-relative">
      <LoadingSection
        isLoading={isPageLoading}
        height={pageElementRef.current?.clientHeight}
        width={pageElementRef.current?.clientWidth}
      >
        <Modal isOpen={deleteProductModal} toggle={toggleDeleteProductModal}>
          <ModalHeader toggle={toggleDeleteProductModal}>
            Delete Product Confirmation
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete{" "}
            {selectedProductKeys.length > 1 ? "these" : "this"}{" "}
            {selectedProductKeys.length > 1 ? "products" : "product"} and
            permanently remove {selectedProductKeys.length > 1 ? "them" : "it"}{" "}
            from all campaigns?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={deleteProducts}>
              Delete Product(s)
            </Button>
            <Button color="secondary" onClick={toggleDeleteProductModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={deactivateProductModal}
          toggle={toggleDeactivateProductModal}
        >
          <ModalHeader toggle={toggleDeactivateProductModal}>
            Deactivate Product Confirmation
          </ModalHeader>
          <ModalBody>
            Are you sure you want to deactivate{" "}
            {selectedProductKeys.length > 1 ? "these" : "this"}{" "}
            {selectedProductKeys.length > 1 ? "products" : "product"} and hide{" "}
            {selectedProductKeys.length > 1 ? "them" : "it"} from all campaigns?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={deactivateProducts}>
              Deactivate Product(s)
            </Button>
            <Button color="secondary" onClick={toggleDeactivateProductModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={deleteCampaignModal} toggle={toggleDeleteCampaignModal}>
          <ModalHeader toggle={toggleDeleteCampaignModal}>
            Delete Campaign Confirmation
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete{" "}
            {selectedCampaignKeys.length > 1 ? "these" : "this"}{" "}
            {selectedCampaignKeys.length > 1 ? "campaigns" : "campaign"} and
            permanently remove all products from{" "}
            {selectedCampaignKeys.length > 1 ? "them" : "it"}?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={deleteCampaign}>
              Delete Campaign(s)
            </Button>
            <Button color="secondary" onClick={toggleDeleteCampaignModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={deactivateCampaignModal}
          toggle={toggleDeactivateCampaignModal}
        >
          <ModalHeader toggle={toggleDeactivateCampaignModal}>
            Deactivate Campaign Confirmation
          </ModalHeader>
          <ModalBody>
            Are you sure you want to deactivate{" "}
            {selectedCampaignKeys.length > 1 ? "these" : "this"}{" "}
            {selectedCampaignKeys.length > 1 ? "campaigns" : "campaign"}?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={deactivateCampaigns}>
              Deactivate Campaign(s)
            </Button>
            <Button color="secondary" onClick={toggleDeactivateCampaignModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <div ref={pageElementRef}>
          <Form
            className="mt-2 pt-2 ml-5 mr-5 profile-search"
            onSubmit={handleSubmit}
          >
            <Row>
              <Col md="12" className="px-0">
                <ValidationErrors
                  serverErrors={serverErrors}
                  formErrors={[]}
                  customErrors={[]}
                />
                <SessionExpiredError showError={sessionExpired} />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Row className="pb-2">
                  <Col className="pt-2 px-0 text-right pb-3">
                    <SkinnedButton
                      className="mr-3 px-4"
                      color="primary"
                      onClick={addProduct}
                    >
                      New Product
                    </SkinnedButton>
                    <SkinnedButton
                      className="mr-3 px-4"
                      color="primary"
                      onClick={addCampaign}
                    >
                      New Campaign
                    </SkinnedButton>
                    {isProductScreenVisible && (
                      <>
                        <SkinnedButton
                          onClick={confirmDeactivateProduct}
                          className="px-4 py-2 mr-3"
                          disabled={disableProductButtons}
                        >
                          Deactivate Product
                        </SkinnedButton>
                        <SkinnedButton
                          onClick={confirmDeleteProduct}
                          className="px-4 py-2"
                          disabled={disableProductButtons}
                        >
                          Delete Product
                        </SkinnedButton>
                      </>
                    )}
                    {!isProductScreenVisible && (
                      <>
                        <SkinnedButton
                          onClick={confirmDeactivateCampaign}
                          className="px-4 py-2 mr-3"
                          disabled={disableCampaignButtons}
                        >
                          Deactivate Campaign
                        </SkinnedButton>
                        <SkinnedButton
                          onClick={confirmDeleteCampaign}
                          className="px-4 py-2"
                          disabled={disableCampaignButtons}
                        >
                          Delete Campaign
                        </SkinnedButton>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="product-search">
              <Col md="12">
                <Row className="rounded p-3 blue-background">
                  <div className="w-100 font-weight-bold regular-font">Search</div>
                  <div className="w-100">
                    <InputGroup className="d-inline-flex w-100 mb-0">
                      <ButtonGroup className="input-group-prepend">
                        <Button
                          active={isProductScreenVisible}
                          className={classnames({
                            "btn btn-primary": true,
                            "blue-shade-background": isProductScreenVisible,
                            "border-white": !isProductScreenVisible,
                          })}
                          onClick={async () => {
                            await searchProducts();
                            setIsProductScreenVisible(true);
                          }}
                        >
                          Products
                        </Button>
                        <Button
                          active={!isProductScreenVisible}
                          className={classnames({
                            "btn btn-primary": true,
                            "blue-shade-background": !isProductScreenVisible,
                            "border-white": isProductScreenVisible,
                          })}
                          onClick={async () => {
                            await searchCampaigns();
                            setIsProductScreenVisible(false);
                          }}
                        >
                          Campaigns
                        </Button>
                      </ButtonGroup>
                      <Input
                        type="text"
                        value={values?.searchText || ""}
                        name="searchText"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={1000}
                        placeholder={`Search ${
                          isProductScreenVisible ? "Products" : "Campaigns"
                        }`}
                        autoComplete="off"
                        autoFocus
                      ></Input>
                    </InputGroup>
                  </div>
                  <div className="w-100 d-flex align-items-center flex-wrap">
                    <div className="d-flex align-items-center flex-wrap mt-3">
                      <CustomCheckbox
                        hideEmptyLabel={true}
                        text="Active"
                        className="regular-font"
                        parentClassName="mt-1"
                        checked={values?.isActive || false}
                        fieldName="isActive"
                        handleChange={handleChange}
                      ></CustomCheckbox>
                      <CustomCheckbox
                        hideEmptyLabel={true}
                        text="Inactive"
                        className="regular-font"
                        parentClassName="mt-1 mr-3"
                        checked={values?.isInactive || false}
                        fieldName="isInactive"
                        handleChange={handleChange}
                      ></CustomCheckbox>
                      <div className="d-inline-flex align-items-center">
                        <span className="regular-font mr-3">Filter by Campaign date</span>
                        <div className="d-inline-flex align-items-center">
                        <InputGroup>
                            <div className="input-group-prepend">
                              <div className="input-group-text">From</div>
                            </div>
                            <DatePicker
                              showLabel={false}
                              fieldName="campaignStartDate"
                              fieldValue={values?.campaignStartDate || ""}
                              handleChange={(event: any) => {
                                setValues({
                                  ...values,
                                  campaignStartDate: event.target.value,
                                });
                              }}
                              skipFormGroup
                            ></DatePicker>
                          </InputGroup>
                          <InputGroup className="ml-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">To</div>
                            </div>
                            <DatePicker
                              showLabel={false}
                              fieldName="campaignEndDate"
                              fieldValue={values?.campaignEndDate || ""}
                              handleChange={(event: any) => {
                                setValues({
                                  ...values,
                                  campaignEndDate: event.target.value,
                                });
                              }}
                              skipFormGroup
                            ></DatePicker>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                    <div className="ml-auto mt-3">
                      <SkinnedButton color="primary">Search</SkinnedButton>
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row className="pt-3 pb-5">
              <Col className={classnames({ "d-none": !isProductScreenVisible })}>
                <DataTable
                  data={productData}
                  columns={productColumns}
                  defaultSortFieldId={1}
                  defaultSortAsc={true}
                  expandableRows
                  expandableRowsComponent={CampaignDetails}
                  expandableRowDisabled={(row) => row.campaigns.length === 1}
                  highlightOnHover
                  selectableRows
                  onSelectedRowsChange={handleRowSelection}
                  onChangePage={handleProductChangePage}
                  onChangeRowsPerPage={handleProductChangeRowsPerPage}
                  onSort={handleProductSortChange}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 20, 50, 100]}
                  paginationTotalRows={totalProductRows}
                  progressPending={isGridLoading}
                  striped
                />
              </Col>
              <Col className={classnames({ "d-none": isProductScreenVisible })}>
              <DataTable
                  columns={campaignColumns}
                  data={campaignData}
                  defaultSortFieldId={1}
                  defaultSortAsc={true}
                  highlightOnHover
                  selectableRows
                  onSelectedRowsChange={handleRowSelection}
                  onChangePage={handleCampaignChangePage}
                  onChangeRowsPerPage={handleCampaignChangeRowsPerPage}
                  onSort={handleCampaignSortChange}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 20, 50, 100]}
                  paginationTotalRows={totalCampaignRows}
                  progressPending={isGridLoading}
                  striped
                />
              </Col>
            </Row>
          </Form>
        </div>
      </LoadingSection>
    </Container>
  );
};
