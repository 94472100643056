import axios, { AxiosError } from "axios";
import {
  useGlobalState,
  useGlobalDispatch,
  GlobalActionTypes,
} from "../Context";
import { parseISO } from "date-fns";
import { toast } from "react-toastify";
import authService from "../Components/ApiAuthorization/AuthorizeService";
import { adminGenericError } from "./HelperData";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { basePathName } from "./HelperData";
import { SessionStorageHelper } from "./SessionStorageHelper";

export const useApiWorker = () => {
  const {
    baseUrl,
    token,
    customerSupportContactEmail,
    customerSupportContactPhoneNumber,
    currentUser,
    initialGetRequestRanOk,
  } = useGlobalState();
  const history = useHistory();
  const dispatch = useGlobalDispatch();
  const axiosConfig = useMemo(() => {
    return {
      baseURL: baseUrl,
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  }, [baseUrl, token]);
  const apiWorker = axios.create(axiosConfig);

  const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.?\d*Z?)?$/;

  function isIsoDateString(value: any): boolean {
    return value && typeof value === "string" && isoDateFormat.test(value);
  }
  function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== "object")
      return body;

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (isIsoDateString(value)) body[key] = parseISO(value);
      else if (Array.isArray(value)) {
        for (let el of value) {
          handleDates(el);
        }
      } else if (typeof value === "object") handleDates(value);
    }
  }

  apiWorker.interceptors.response.use(
    async (response) => {
      if (initialGetRequestRanOk === undefined) {
        dispatch({
          type: GlobalActionTypes.SetInitialGetRequestRanOk,
          payload: true,
        });
      }
      // the browser swallows the redirect and axios only provides the responseURL https://stackoverflow.com/a/55981466
      if (!response.request.responseURL.includes(response.config.url)) {
        // manual redirect to /register most likely when response url does not match which means there was a redirect
        if (response.request.responseURL.includes("/register")) {
          // "shortcut" to avoid doing a full page refresh, since we're already in react we keep using react for the redirect
          history.push("/register");
        } else {
          window.location.href = response.request.responseURL;
        }
      }
      handleDates(response.data);
      return response;
    },
    (error: AxiosError) => {
      toast.dismiss();
      const providerError = `There was an unexpected error with your request, please try again. If further issues persist please contact customer service at ${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`;
      if (!error.response) return;
      const { data, config, status } = error.response!;
      switch (status) {
        case 400:
          dispatch({
            type: GlobalActionTypes.SetInitialGetRequestRanOk,
            payload: true,
          });
          if (data.errors) {
            if (
              data.errors[""] &&
              data.errors[""].indexOf(
                "Failed to read the request form. Request body too large."
              ) > -1
            ) {
              const error =
                "You have exceeded the limit for media that can be uploaded to your profile. Please delete some of your media or upload a smaller file.";
              toast.error(error);
            } else {
              throw data.errors;
            }
          } else {
            if (
              (currentUser?.permissions || []).some((x: any) =>
                ["providerprofile.editany"].includes(x)
              )
            ) {
              // Tricking the .get/.post/.put methods into thinking the error object has the same structure as the validation errors, so that the
              // ValidationError component can render it without problems
              const err: any = {
                "": [adminGenericError],
              };
              throw err;
            } else {
              const err: any = {
                "": [providerError],
              };
              throw err;
            }
          }
          break;
        case 401:
          SessionStorageHelper.clearSessionStorageExceptReturnUrl();
          localStorage.clear();
          if (initialGetRequestRanOk === undefined) {
            dispatch({
              type: GlobalActionTypes.SetInitialGetRequestRanOk,
              payload: false,
            });
            window.location.replace(window.location.href);
            return;
          }
          throw error.response;
        case 404:
          // get requests are caught by the first interceptor when the provider needs to get redirected to registration
          // put and post requests are not and therefore handled here
          if (
            error?.response?.request?.responseURL?.includes(
              "/providers/account/register"
            )
          ) {
            history.push("/account/register");
          }
          break;
        case 403:
          // clear token in case the user is denied, so she gets redirected to the login page
          localStorage.clear();
          dispatch({
            type: GlobalActionTypes.SetInitialGetRequestRanOk,
            payload: true,
          });
          authService
            .revokeAccessToken()
            .then(
              () =>
                (window.location.href = `${basePathName}/account/access-denied`)
            );
          break;
        case 500:
          // An internal server error is not a 401 error
          dispatch({
            type: GlobalActionTypes.SetInitialGetRequestRanOk,
            payload: true,
          });
          toast.dismiss();
          throw error.response;
      }
      return Promise.reject(error);
    }
  );

  return useMemo(() => {
    return apiWorker;
  }, [apiWorker]);
};
