import { CoreEnum } from "../../../Features/Shared/Models/SharedModels";

export class BusinessHoursVM {
  id: any;
  onMonday?: boolean = false;
  onTuesday?: boolean = false;
  onWednesday?: boolean = false;
  onThursday?: boolean = false;
  onFriday?: boolean = false;
  onSaturday?: boolean = false;
  onSunday?: boolean = false;
  startTime?: Date;
  endTime?: Date;
  startTimeUI?: string;
  endTimeUI?: string;
  startPeriodType?: CoreEnum;
  endPeriodType?: CoreEnum;
  position?: number;
  runPublishingValidators?: boolean;
}