import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import "./ProviderProfile.scss";
import { Col, Container, Form, Row, Alert } from "reactstrap";
import { ErrorAlert, LoadingSection } from "../../Components/Display";
import { SkinnedButton } from "../../Components/Form/Buttons";
import { useApiWorker } from "../../Utilities/CommonHooks";
import {
  ProviderProfileVM,
  ProfileStatus,
  PublishStatus,
} from "../../Components/ProviderProfile/Models/";
import {
  ProviderProfileEdit,
  ProviderProfileStatusAlert,
} from "../../Components/ProviderProfile/";
import {
  useGlobalState,
  useGlobalDispatch,
  GlobalActionTypes,
} from "../../Context";
import { toast } from "react-toastify";
import { Helper } from "../../Utilities/HelperData";
import SessionExpiredError from "../Errors/SessionExpiredError";
import {
  providerProfileValidationSchema,
  profileNotLoaded,
  genericServerError,
} from "../../Utilities/HelperData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import ValidationErrors from "../Errors/ValidationErrors";
import { ActionType, CoreEnum } from "../Shared/Models/SharedModels";
import { CompleteProfileAlert } from "../Shared";
import { ProfileStatusIndicator } from "../Home";
import { UrlHelper } from "../../Utilities/UrlHelper";
import { apiUrls, constants } from "../../Utilities/Constants";
import { SessionStorageHelper } from "../../Utilities/SessionStorageHelper";
import { UpgradeAdBanner } from "../Shared/UpgradeAdBanner";

export const ProviderProfile = () => {
  const initialTab = "1";
  const totalTabs = "5";
  const apiWorker = useApiWorker();
  const [providerProfile, setProviderProfile] = useState<
    ProviderProfileVM | undefined
  >(undefined);
  const [errorMessageIsVisible, setErrorMessageIsVisible] =
    useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [actionType, setActionType] = useState(ActionType.Publish);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [profileLoadedOk, setProfileLoadedOk] = useState(false);
  // file upload
  const [newProfilePhoto, setNewProfilePhoto] = useState();
  const [isProfileImageSelected, setIsProfileImageSelected] = useState(false);
  const [runPublishingValidators, setRunPublishingValidators] = useState(false);
  const [serverErrors, setServerErrors] = useState<any[] | null>(null);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const {
    currentUser,
    user_fullname,
    customerSupportContactEmail,
    customerSupportContactPhoneNumber,
  } = useGlobalState();
  const [touched, setTouched] = useState<string[]>([]);
  const [formErrors, setFormErrors] = useState<any>({});

  const licensesRef = useRef<any>({});
  const boardCertificationsRef = useRef<any>({});
  const certificationsRef = useRef<any>({});
  const professionalAssociationsRef = useRef<any>({});
  const schoolsRef = useRef<any>({});

  const [officePhotos, setOfficePhotos] = useState<any[]>([]);
  const dispatch = useGlobalDispatch();
  const scrollElementRef = useRef<null | HTMLDivElement>(null);
  const pageElementRef = useRef<null | HTMLDivElement>(null);

  const [currentTabPosition, setTabPosition] = useState<string>(initialTab);
  const tabPosition = (activeTab: string) => {
    setTabPosition(activeTab);
  };
  const changeTabPosition = (
    event: any,
    direction: string,
    position: string
  ) => {
    handleSave(event, false, true);
    let el = event.target;
    let tabInt: number = parseInt(currentTabPosition);
    tabInt = direction === "prev" ? tabInt - 1 : tabInt + 1;
    tabPosition(tabInt.toString());
    el.blur();
    if (position === "bottom" && scrollElementRef && scrollElementRef.current) {
      scrollElementRef.current.scrollIntoView({ behavior: "auto" });
    }
  };
  const [inputsChanged, setInputsChanged] = useState<boolean>(false);
  const [dropZoneReset, setDropZoneReset] = useState(false);
  const changeDropZoneReset = (reset: boolean) => setDropZoneReset(reset);

  useEffect(() => {
    if (currentUser) {
      getProviderProfile();
      //clear local session storage related to login and registration
      SessionStorageHelper.clearSessionStorageItem(constants.ReturnUrl);
      SessionStorageHelper.clearSessionStorageItem(constants.SelectedSubscriptionSession);
      SessionStorageHelper.clearSessionStorageItem(constants.CampaignSession);
    }
  }, [currentUser]);

  const handleServerErrors = (errors: any): boolean => {
    if (errors?.status === 401) {
      setSessionExpired(true);
      scrollToTop();
      setErrorMessageIsVisible(false);
      setFormErrors({});
      return true;
    } else if (errors?.status === 500) {
      setServerErrors([
        genericServerError +
          `${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`,
      ]);
      scrollToTop();
      setErrorMessageIsVisible(false);
      return true;
    }
    return false;
  };

  const getProviderProfile = async () => {
    let success = true;
    const profile = await apiWorker
      .get<ProviderProfileVM>(
        UrlHelper.getApiUrl(
          currentUser?.permissions,
          apiUrls.ProviderProfileUrl,
          `${apiUrls.ImpersonateProviderProfileUrl}${id}`
        )
      )
      .catch((errors) => {
        if (handleServerErrors(errors)) {
          success = false;
          return;
        }
        errors = Helper.cleanUpErrorMessages(errors);
        setFormErrors(errors);
        if (errors && errors[""]) {
          setServerErrors(errors[""]);
        }
        scrollToTop();
      });

    if (!success) {
      setIsLoading(false);
      return;
    }

    if (profile && profile.data) {
      setProviderProfile(profile.data);
      setProfileLoadedOk(true);
      dispatch({
        type: GlobalActionTypes.SetProfileStatus,
        payload: {
          profileStatus: profile.data.profileStatus,
          publishedStatus: profile.data.publishedStatus,
          publishSucceeded: profile.data.publishSucceeded,
          publishedProfileUrl: profile.data.publishedProfileUrl,
        },
      });

      if (
        `${profile.data.firstName} ${profile.data.lastName}` !== user_fullname
      ) {
        // since the global state is not updated, adjust the username when the provider profile is access in
        // case it was updated in another tab. There's the risk that if the dashboard is accessed first, it will still
        // display the previous username
        dispatch({
          type: GlobalActionTypes.SetUserFullName,
          payload: `${profile.data.firstName} ${profile.data.lastName}`,
        });
      }
    }
    setIsLoading(false);
  };

  const validateForm = async (
    ignoreTouchedFields: boolean = false,
    touchedElements: string[],
    applyRequiredValidations: boolean = false
  ): Promise<any> => {
    let errors: any = {};
    let modifiedProfile: ProviderProfileVM = new ProviderProfileVM();
    setRunPublishingValidators(applyRequiredValidations);
    try {
      if (providerProfile) {
        modifiedProfile = Helper.prepareProfileForValidation(
          applyRequiredValidations,
          providerProfile
        );
      }
      await providerProfileValidationSchema.validate(modifiedProfile, {
        abortEarly: false,
        context: { currentYear: new Date().getFullYear() },
      });
      setFormErrors({});
    } catch (e: any) {
      errors = Helper.processProfileValidationErrors(e);
      if (ignoreTouchedFields) {
        // this allows highlight to persist after submitting form even when not all
        // fields have been touched
        setTouched(Object.keys(errors));
      }
      setFormErrors(
        ignoreTouchedFields
          ? errors
          : Helper.convertProfileValidationErrors(errors, touchedElements)
      );
      return errors;
    }
  };

  const handlePublish = async (event: any) => {
    event.preventDefault();
    if (!profileLoadedOk) {
      setServerErrors([profileNotLoaded]);
      return;
    }
    setActionType(ActionType.Publish);
    setServerErrors(null);
    toast.dismiss();

    Helper.setPositions(
      providerProfile || new ProviderProfileVM(),
      certificationsRef.current.children[0],
      boardCertificationsRef.current.children[0],
      licensesRef && licensesRef.current && licensesRef.current.children
        ? licensesRef.current.children[0]
        : { children: [] },
      schoolsRef.current.children[0],
      professionalAssociationsRef.current.children[0]
    );
    Helper.buildProviderProfile(
      providerProfile || new ProviderProfileVM(),
      officePhotos
    );

    const errors = await validateForm(true, [], true);
    const noErrors = Object.keys(errors || {}).length === 0;
    if (!noErrors) {
      setErrorMessageIsVisible(true);
      scrollToTop();
      toast.dismiss();
      return;
    }

    setErrorMessageIsVisible(false);
    if (providerProfile == null) return;
    let formData = await Helper.buildFormData(
      providerProfile,
      isProfileImageSelected,
      newProfilePhoto,
      officePhotos
    );

    setIsLoading(true);
    let success = true;
    const savedProfile = await apiWorker
      .put<ProviderProfileVM>(
        UrlHelper.getApiUrl(
          currentUser?.permissions,
          apiUrls.ProviderProfilePublishUrl,
          `${apiUrls.ImpersonateProviderProfilePublishUrl}${id}`
        ),
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .catch((errors) => {
        if (handleServerErrors(errors)) {
          success = false;
          return;
        }
        errors = Helper.cleanUpErrorMessages(errors);
        if (Helper.hasValidationErrors(errors)) {
          setErrorMessageIsVisible(true);
        }
        setFormErrors(errors);
        if (errors && errors[""]) {
          setServerErrors(errors[""]);
        }
        scrollToTop();
      });

    if (!success) {
      setIsLoading(false);
      return;
    }

    if (savedProfile) {
      setErrorMessageIsVisible(false);
      setServerErrors(null);
      updateUserName();
      setOfficePhotos([]);
      setProviderProfile(
        await Helper.setProfileRefs(savedProfile, providerProfile, officePhotos)
      );
      if (savedProfile.data.publishSucceeded) {
        // no else condition needed, there's a banner displayed in the page when publishSucceeded is false
        toast.success(
          "Your changes have been saved and will appear on your live profile in less than one hour"
        );
        dispatch({
          type: GlobalActionTypes.SetProfileStatus,
          payload: {
            profileStatus: savedProfile.data.profileStatus,
            publishedStatus: savedProfile.data.publishedStatus,
            publishSucceeded: savedProfile.data.publishSucceeded,
            publishedProfileUrl: savedProfile.data.publishedProfileUrl,
          },
        });
      }
    }

    scrollToTop();
    setIsLoading(false);
  };

  const handleProfileChanges = (currentProviderProfile: ProviderProfileVM) => {
    setProviderProfile(currentProviderProfile);
    setInputsChanged(true);
  };

  const handleProfileImageChanges = (item?: any) => {
    if (item) {
      setNewProfilePhoto(item);
      setIsProfileImageSelected(true);
    } else {
      setNewProfilePhoto(undefined);
      setIsProfileImageSelected(false);
    }
    setInputsChanged(true);
  };

  const handleOfficeImageChanges = (item: any) => {
    setOfficePhotos(item);
    setInputsChanged(true);
    setProviderProfile({
      ...providerProfile,
      inMemoryOfficePhotos: item.map((element: any) => {
        return { id: element.id, description: element.description };
      }),
    });
  };

  const handleBlur = async (item: any) => {
    setFormErrors({});
    await validateForm(false, item, runPublishingValidators);
  };

  const handleSave = async (
    event: any,
    isForApproval: boolean,
    isAsync: boolean = false
  ) => {
    if (!isAsync) {
      event.preventDefault();
    }
    if (!profileLoadedOk) {
      setServerErrors([profileNotLoaded]);
      return;
    }
    setActionType(isForApproval ? ActionType.SubmitForReview : ActionType.Save);
    setDropZoneReset(true);
    setServerErrors(null);
    toast.dismiss();

    Helper.setPositions(
      providerProfile || new ProviderProfileVM(),
      certificationsRef.current.children[0],
      boardCertificationsRef.current.children[0],
      licensesRef && licensesRef.current && licensesRef.current.children
        ? licensesRef.current.children[0]
        : { children: [] },
      schoolsRef.current.children[0],
      professionalAssociationsRef.current.children[0]
    );
    Helper.buildProviderProfile(
      providerProfile || new ProviderProfileVM(),
      officePhotos
    );

    const errors = await validateForm(
      true,
      [],
      isForApproval ||
        providerProfile?.profileStatus?.value === ProfileStatus.PendingApproval
    );
    const noErrors = Object.keys(errors || {}).length === 0;
    if (!noErrors) {
      setErrorMessageIsVisible(true);
      scrollToTop();
      toast.dismiss();
      return;
    }

    setErrorMessageIsVisible(false);
    if (providerProfile == null) return;
    let formData = await Helper.buildFormData(
      providerProfile,
      isProfileImageSelected,
      newProfilePhoto,
      officePhotos
    );

    if (!isAsync) {
      setIsLoading(true);
    }
    let success = true;
    const savedProfile = await apiWorker
      .put<ProviderProfileVM>(
        UrlHelper.getApiUrl(
          currentUser?.permissions,
          apiUrls.ProviderProfileUrl,
          `${apiUrls.ImpersonateProviderProfileUrl}${id}`
        ),
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .catch((errors) => {
        if (handleServerErrors(errors)) {
          success = false;
          return;
        }
        errors = Helper.cleanUpErrorMessages(errors);
        setFormErrors(errors);
        // making sure it's not an axios error is probably redundant, since axios errors should be handled in handleServerErrors
        if (Helper.hasValidationErrors(errors)) {
          setErrorMessageIsVisible(true);
        }
        if (errors && errors[""]) {
          // put errors which don't belong to a field in the banner at the top of the page. But I think we no longer have messages like this
          setServerErrors(errors[""]);
        }
        scrollToTop();
      });

    if (!success) {
      if (!isAsync) {
        setIsLoading(false);
      }
      return;
    }

    if (savedProfile) {
      if (isForApproval && !providerProfile.hasSubmittedProfileForApproval) {
        // this event should be sent only the first time a provider submits their profile
        window.gtag("event", "profile-submission", {
          event_category: "user-action",
        });
      }

      setErrorMessageIsVisible(false);
      setServerErrors(null);
      updateUserName();
      setOfficePhotos([]);
      setProviderProfile(
        await Helper.setProfileRefs(savedProfile, providerProfile, officePhotos)
      );
      let message = "Your profile has been saved.";
      if (
        savedProfile.data.publishedStatus &&
        (savedProfile.data.publishedStatus.value === PublishStatus.Public ||
          savedProfile.data.publishedStatus.value === PublishStatus.Private)
      ) {
        message +=
          " Click Save & Publish to publish any changes to your live profile.";
      }
      if (!isForApproval && !isAsync) {
        toast.success(message);
      }
    }
    if (!isAsync) {
      scrollToTop();
      setIsLoading(false);
    }
    setInputsChanged(false);
  };

  const updateUserName = () => {
    if (
      `${providerProfile?.firstName} ${providerProfile?.lastName}` !==
      user_fullname
    ) {
      dispatch({
        type: GlobalActionTypes.SetUserFullName,
        payload: `${providerProfile?.firstName} ${providerProfile?.lastName}`,
      });
    }
  };

  const scrollToTop = (type: string = "smooth") => {
    if (scrollElementRef && scrollElementRef.current) {
      scrollElementRef.current.scrollIntoView({
        behavior: type as ScrollBehavior,
      });
    }
  };

  return (
    <Container fluid={true} className="mt-4 position-relative">
      <LoadingSection
        isLoading={isLoading}
        // for some reason the loading div does not go all the way to the bottom
        height={(pageElementRef.current?.clientHeight || 0) + 10}
        width={pageElementRef.current?.clientWidth}
      >
        <div ref={pageElementRef}>
          <div ref={scrollElementRef} id="scrollElement"></div>
          <Form
            className="provider-form"
            onSubmit={(event: any) => handleSave(event, false)}
          >
            {(errorMessageIsVisible ||
              (!providerProfile?.publishSucceeded && !errorMessageIsVisible) ||
              serverErrors != null) && (
              <Row className="pt-3 pb-0 alert-wrapper">
                <Col md="12" className="px-0">
                  {errorMessageIsVisible && (
                    <ErrorAlert
                      message={`Please update the highlighted fields on the red tabs below before ${
                        actionType === ActionType.Publish
                          ? "publishing"
                          : actionType === ActionType.Save
                          ? "saving"
                          : "submitting for review"
                      }`}
                    ></ErrorAlert>
                  )}
                  <CompleteProfileAlert
                    isIncompleteForPublishing={
                      providerProfile?.isIncompleteForPublishing || false
                    }
                  ></CompleteProfileAlert>
                  {providerProfile &&
                    !providerProfile?.publishSucceeded &&
                    !providerProfile?.isIncompleteForPublishing &&
                    !errorMessageIsVisible && (
                      <Alert className="alert-danger mb-0 font-weight-bold mt-1">
                        There's a delay publishing your profile, but we'll keep
                        trying and it will be live soon.
                      </Alert>
                    )}
                </Col>
                {/* ValidationErrors here is just for when there's an error without a key, which should not happen in this screen */}
                <Col md="12" className="pt-2 px-0">
                  <ValidationErrors
                    serverErrors={serverErrors}
                    formErrors={{}}
                    customErrors={[]}
                  />
                  <SessionExpiredError showError={sessionExpired} />
                </Col>
              </Row>
            )}
            <ProviderProfileStatusAlert
              profileStatus={providerProfile?.profileStatus?.value}
            />
            <UpgradeAdBanner providerId={id} />
            <Row>
              <Col md="4" className="px-0 pt-3 mt-2">
                <ProfileStatusIndicator
                  profileStatus={
                    new CoreEnum(
                      providerProfile?.profileStatus?.value ||
                        ProfileStatus.Incomplete.toString(),
                      providerProfile?.profileStatus?.value?.toString() ||
                        ProfileStatus.Incomplete.toString()
                    )
                  }
                  publishStatus={
                    new CoreEnum(
                      providerProfile?.publishedStatus?.value ||
                        PublishStatus.None.toString(),
                      providerProfile?.publishedStatus?.value?.toString() ||
                        PublishStatus.None.toString()
                    )
                  }
                  publishSucceeded={providerProfile?.publishSucceeded || false}
                ></ProfileStatusIndicator>
              </Col>
              <Col md="8" className="pt-2 px-0 text-right mt-2">
                <SkinnedButton
                  className="ml-3 white-button btn-profile-edit mb-3"
                  color={
                    providerProfile?.profileStatus?.value ==
                    ProfileStatus.PendingApproval
                      ? "primary"
                      : "secondary"
                  }
                >
                  Save
                </SkinnedButton>
                {(providerProfile?.profileStatus?.value ==
                  ProfileStatus.Approved ||
                  providerProfile?.profileStatus?.value ==
                    ProfileStatus.Hidden) && (
                  <SkinnedButton
                    onClick={handlePublish}
                    className="ml-2 btn-profile-edit mb-3"
                    color="primary"
                  >
                    Save & Publish
                  </SkinnedButton>
                )}
                {(providerProfile?.profileStatus?.value ==
                  ProfileStatus.Denied ||
                  providerProfile?.profileStatus?.value ==
                    ProfileStatus.Incomplete) && (
                  <SkinnedButton
                    onClick={(event: any) => handleSave(event, true)}
                    className="ml-2 btn-profile-edit mb-3"
                    color="primary"
                  >
                    Save & Submit
                  </SkinnedButton>
                )}
                <div className="d-block d-sm-none"></div>
                <SkinnedButton
                  className="arrow-button ml-2 btn-profile-edit mb-3"
                  onClick={(event: any) =>
                    changeTabPosition(event, "prev", "top")
                  }
                  disabled={currentTabPosition === initialTab ? true : false}
                >
                  <FontAwesomeIcon icon={Icon.faChevronLeft} />
                  &nbsp; Back
                </SkinnedButton>
                <SkinnedButton
                  className="arrow-button ml-2 btn-profile-edit mb-3"
                  onClick={(event: any) =>
                    changeTabPosition(event, "next", "top")
                  }
                  disabled={currentTabPosition === totalTabs ? true : false}
                >
                  Next &nbsp;
                  <FontAwesomeIcon icon={Icon.faChevronRight} size="sm" />
                </SkinnedButton>
              </Col>
            </Row>
            <ProviderProfileEdit
              handleProfileChanges={handleProfileChanges}
              currentProviderProfile={providerProfile}
              handleProfileImageChanges={handleProfileImageChanges}
              handleOfficeImageChanges={handleOfficeImageChanges}
              licensesRef={licensesRef}
              boardCertificationsRef={boardCertificationsRef}
              certificationsRef={certificationsRef}
              professionalAssociationsRef={professionalAssociationsRef}
              schoolsRef={schoolsRef}
              formErrors={formErrors}
              serverErrors={serverErrors}
              handleBlurEvent={handleBlur}
              touchedFields={touched}
              tabPosition={tabPosition}
              newTabPosition={currentTabPosition}
              handleSave={handleSave}
              inputsChanged={inputsChanged}
              dropZoneReset={dropZoneReset}
              changeDropZoneReset={changeDropZoneReset}
            ></ProviderProfileEdit>
            <Row>
              <Col md="12" className="pt-3 px-0 text-right">
                <SkinnedButton
                  className="ml-3 white-button btn-profile-edit mb-3"
                  color={
                    providerProfile?.profileStatus?.value ==
                    ProfileStatus.PendingApproval
                      ? "primary"
                      : "secondary"
                  }
                >
                  Save
                </SkinnedButton>
                {(providerProfile?.profileStatus?.value ==
                  ProfileStatus.Denied ||
                  providerProfile?.profileStatus?.value ==
                    ProfileStatus.Incomplete) && (
                  <SkinnedButton
                    onClick={(event: any) => handleSave(event, true)}
                    className="ml-2 btn-profile-edit mb-3"
                    color="primary"
                  >
                    Save & Submit
                  </SkinnedButton>
                )}
                {(providerProfile?.profileStatus?.value ==
                  ProfileStatus.Approved ||
                  providerProfile?.profileStatus?.value ==
                    ProfileStatus.Hidden) && (
                  <SkinnedButton
                    onClick={handlePublish}
                    className="ml-2 btn-profile-edit mb-3"
                    color="primary"
                  >
                    Save & Publish
                  </SkinnedButton>
                )}
                <div className="d-block d-sm-none"></div>
                <SkinnedButton
                  className="arrow-button ml-2 btn-profile-edit mb-3"
                  onClick={(event: any) =>
                    changeTabPosition(event, "prev", "bottom")
                  }
                  disabled={currentTabPosition === initialTab ? true : false}
                >
                  <FontAwesomeIcon icon={Icon.faChevronLeft} />
                  &nbsp; Back
                </SkinnedButton>
                <SkinnedButton
                  className="arrow-button ml-2 btn-profile-edit mb-3"
                  onClick={(event: any) =>
                    changeTabPosition(event, "next", "bottom")
                  }
                  disabled={currentTabPosition === totalTabs ? true : false}
                >
                  Next &nbsp;
                  <FontAwesomeIcon icon={Icon.faChevronRight} />
                </SkinnedButton>
              </Col>
            </Row>
          </Form>
        </div>
      </LoadingSection>
    </Container>
  );
};
