import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

declare global {
  interface Window {
    ENVIRONMENT_NAME: string;
    REACT_APP_BASE_URL: string;
    REACT_APP_GOOGLE_MAPS_KEY: string;
    REACT_APP_STRIPE_KEY: string;
    REACT_APP_WORDPRESSSITE_URL: string;
    REACT_APP_ABOUT_URL: string;
    REACT_APP_PRIVACY_URL: string;
    REACT_APP_TERMS_URL: string;
    REACT_APP_FAQ_URL: string;
    REACT_APP_WRITE_FOR_US_URL: string;
    REACT_APP_GET_LISTED_URL: string;
    REACT_APP_ADVERTISE_WITH_US_URL: string;
    REACT_APP_ACCESSIBILITY_STATEMENT_URL: string;
    REACT_APP_CUSTOMER_SUPPORT_CONTACT_PHONE_NUMBER: string;
    REACT_APP_CUSTOMER_SUPPORT_CONTACT_EMAIL: string;
    REACT_APP_ENABLE_STRIKETHROUGH_TEXT_FOR_FREE_REGISTRATION: boolean;
    REACT_APP_ENABLE_SECONDARY_PRICING: string;
    gtag: any;
    EF: any;
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
