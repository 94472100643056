import { Alert } from "reactstrap";

interface IProps {
  isIncompleteForPublishing: boolean;
}

export const CompleteProfileAlert = (props: IProps) => {
  return (
    <div>
      {props.isIncompleteForPublishing && (
        <Alert className="alert-danger mb-0">
          We are unable to publish your profile because one or more of the
          required fields for your profile are not filled out. Please correct
          this and we will publish your profile.
        </Alert>
      )}
    </div>
  );
};
