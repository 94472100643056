import React, { Fragment } from "react";
import "./App.scss";
import "./PublicLayout.scss";
import { useGlobalState } from "./Context";
import { basePathName } from "./Utilities/HelperData";
import { AdminHelper } from "./Utilities/AdminHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as IconRegular from "@fortawesome/free-regular-svg-icons";
import { SecureTrustSeal } from "./Components/SecureTrustSeal";

export const PublicLayout: React.FC = ({ children }) => {
  const {
    wordPressSiteUrl,
    aboutUrl,
    privacyUrl,
    termsUrl,
    faqUrl,
    writeForUSUrl,
    getListedUrl,
    advertiseWithUsUrl,
    accessibilityStatementUrl,
  } = useGlobalState();

  return (
    <Fragment>
      {AdminHelper.isviewingACampaign() && (
        <div className="main__header regular-font align-items-center py-2">
          This link shows a preview of the campaign. Use the "copy" button (
          <FontAwesomeIcon
            className="pt-0 dusky-blue-color copy pointer-default"
            icon={IconRegular.faClone}
            size="lg"
            onClick={() => {}}
          />
          ) in the Edit Campaign screen to get a link you can share with
          providers.
        </div>
      )}
      <header className="public-header border-bottom box-shadow">
        <div className="large-container">
          <nav className="navbar navbar-expand-sm navbar-light navbar-toggleable-sm">
            <div className="larger-container">
              <a className="navbar-brand" href={wordPressSiteUrl}>
                <img
                  src={`${basePathName}/therapist_logo_blue.svg`}
                  className="logo"
                  alt="logo"
                />
              </a>
              <div className="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"></div>
            </div>
          </nav>
        </div>
      </header>

      <div className="container large-padding">
        <main role="main" className="pb-3 pt-3">
          <div>{children}</div>
        </main>
      </div>

      <footer>
        <div className="container footer-container">
          <div className="row">
            <div className="col-md-12 col-lg-2">
              <a
                className="footer-logo"
                title="Find verified professionals"
                href={wordPressSiteUrl}
              >
                <img
                  src={`${basePathName}/therapist_logo_blue.svg`}
                  alt="logo"
                />
              </a>
            </div>
            <div className="col-md-12 col-lg-8 footer-nav text-center">
              <a
                className="footer-about"
                title="therapist.com About us"
                href={aboutUrl}
              >
                About us
              </a>
              <a
                className="footer-about"
                title="therapist.com FAQs"
                href={faqUrl}
              >
                FAQs
              </a>
              <a
                className="footer-about"
                title="therapist.com Advertise"
                href={advertiseWithUsUrl}
              >
                Advertise
              </a>
              <a
                className="footer-about"
                title="therapist.com Accessibility"
                href={accessibilityStatementUrl}
              >
                Accessibility
              </a>
              <a
                className="footer-about"
                title="therapist.com Privacy policy"
                href={privacyUrl}
              >
                Privacy policy
              </a>
              <a
                className="footer-about"
                title="therapist.com Terms and conditions"
                href={termsUrl}
              >
                Terms and conditions
              </a>
            </div>
            <div className="col-md-12 col-lg-2">
              <div className="row">
                <div className="col-lg-6">
                  <div className="footer-therapist-date">
                    ©{new Date().getFullYear()} therapist.com
                  </div>
                </div>
                <div className="col-lg-6">
                  <SecureTrustSeal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default PublicLayout;
