import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable, { SortOrder, TableColumn } from "react-data-table-component";
import { format } from "date-fns";
import { Row, Col, Form, Container, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classnames from "classnames";

import { ProviderProfileApprovalListVM, ProviderApprovalListVM } from "./Models";
import { PaginationOptions, SortOptions } from "../ProviderSearch";
import { LoadingSection } from "../../Components/Display";
import { SkinnedButton } from "../../Components/Form/Buttons";
import ValidationErrors from "../Errors/ValidationErrors";
import SessionExpiredError from "../Errors/SessionExpiredError";
import { basePathName } from "../../Utilities/HelperData";
import { useApiWorker } from "../../Utilities/CommonHooks";

import "./ProviderProfileApproval.scss";

export const ProviderProfileApproval = () => {

  const defaultPageSize = 10;

  const apiWorker = useApiWorker();
  
  const [data, setData] = useState<ProviderProfileApprovalListVM[]>([]);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isGridLoading, setIsGridLoading] = useState<boolean>(false);

  const [totalRows, setTotalRows] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [page, setPage] = useState<number>(1);
  const [sortOptions, setSortOptions] = useState<SortOptions>();
  
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const [approveModal, setApproveModal] = useState(false);
  const [denyModal, setDenyModal] = useState(false);
  const [disableActionButtons, setDisableActionButtons] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  
  const pageElementRef = useRef<null | HTMLDivElement>(null);
  
  const toggleApproveModal = () => setApproveModal(!approveModal);
  const toggleDenyModal = () => setDenyModal(!denyModal);

  useEffect(() => {
    setIsPageLoading(true);
    searchProviders();
    setIsPageLoading(false);
  }, []);

  const handleRowSelection = (selected: {allSelected: boolean, selectedCount: number, selectedRows: ProviderProfileApprovalListVM}) => {
    const keys: string[] = [];

    if (selected.selectedCount) {
      selected.selectedRows.forEach((row: { key: string; }) => {
        keys.push(row.key!)
      })
      setDisableActionButtons(false);
    } else {
      setDisableActionButtons(true);
    }

    setSelectedRowKeys(keys);

  };

  const confirmApproval = (event: React.MouseEvent) => {
    event.preventDefault();
    toast.dismiss();
    toggleApproveModal();
  };

  const confirmDeny = (event: React.MouseEvent) => {
    event.preventDefault();
    toast.dismiss();
    toggleDenyModal();
  };

  const handleServerErrors = (errors: any): boolean => {
    if (errors?.status === 401) {
      setSessionExpired(true);
      document.querySelector(".main__content")?.scrollTo(0, 0);
      return true;
    }
    return false;
  };

  const approveProfiles = async () => {
    toast.dismiss();
    toggleApproveModal();
    setIsPageLoading(true);
    let success = true;
    const result = await apiWorker
      .post<boolean>(
        `${basePathName}/api/provideractions/approvebatch`,
        {
          selectedRows: selectedRowKeys,
        },
        {}
      )
      .catch((err) => {        
        setIsPageLoading(false);
        if (handleServerErrors(err)) {
          success = false;
          return;
        }
        const message = `There was an error approving ${
          selectedRowKeys.length > 1 ? "some of" : ""
        } the selected profile${
          selectedRowKeys.length > 1 ? "s" : ""
        }. Please refresh this page and try again.`;
        if (err && err[""] && Array.isArray(err[""])) {
          const items = err[""];
          setServerErrors([message, ...items]);
        } else {
          setServerErrors([message]);
        }
      });

    if (!success) return;

    if (result && result.data) {
      setServerErrors([]);
      toast.success("The selected profile(s) have been approved");
      await searchProviders();
    }
    setIsPageLoading(false);
  };

  const denyProfiles = async () => {
    setIsPageLoading(true);
    toast.dismiss();
    const result = await apiWorker
      .post<boolean>(
        `${basePathName}/api/provideractions/deny`,
        {
          selectedRows: selectedRowKeys,
        },
        {}
      )
      .catch((err) => {
        toggleDenyModal();
        setIsPageLoading(false);
        const message = `There was an error disapproving ${
          selectedRowKeys.length > 1 ? "some of" : ""
        } the selected profile${
          selectedRowKeys.length > 1 ? "s" : ""
        }. Please refresh this page and try again.`;
        if (err && err[""] && Array.isArray(err[""])) {
          const items = err[""];
          setServerErrors([message, ...items]);
        } else {
          setServerErrors([message]);
        }
      });

    if (result && result.data) {
      setServerErrors([]);
      toast.success("The selected profile(s) have been disapproved");
      await searchProviders();
    }
    toggleDenyModal();
    setIsPageLoading(false);
  };

  const columns: TableColumn<ProviderProfileApprovalListVM>[] = [
    {
      name: <Row>
              <Col md="12">Name</Col>
              <Col md="12">(Supervisor's name)</Col>
            </Row>,
      selector: row => row.fullName!,
      cell: row => (
        <>
          <Link
            className={classnames({
              "font-weight-normal": true,
              "align-top": row.supervisorLicenseNumber,
            })}
            to={"provider/" + row.key}
          >
            {row.fullName}
          </Link>
          {row.supervisorLicenseNumber && (
            <div>
              ({row.supervisorFirstName} {row.supervisorLastName})
            </div>
          )}
        </>
      ),
      sortable: true,
      width: '18%'
    },
    {
      name: <>License Name</>,
      selector: row => row.licenseName,
      sortable: true,
      width: '12%',
      wrap: true
    },
    {
      name: <>License Number</>,
      selector: row => row.licenseNumber,
      sortable: true,
      width: '12%'
    },
    {
      name: <>License State</>,
      selector: row => row.licenseState,
      sortable: true,
      width: '10%'
    },
    {
      name: <>License Expiration</>,
      selector: row => row.licenseExpiration,
      cell: row => row.licenseExpiration ? format(new Date(row.licenseExpiration), "MM/yyyy") : "",
      sortable: true,
      width: '10%'
    },
    {
      name: <>Licensing Board Website</>,
      selector: row => row.licensingBoardUrl,
      cell: row => <a href={row.licensingBoardUrl} target="_blank" rel="noreferrer">{row.licensingBoardUrl}</a>,
      width: '18%'
    },
    {
      name: <>Submission Date</>,
      selector: row => row.lastSubmittedDate,
      cell: row => row.lastSubmittedDate ? format(new Date(row.lastSubmittedDate), "MM/dd/yyyy") : "",
      sortable: true,
      width: '12%'
    }
  ];

  const searchProviders = async (paginationOptions?: PaginationOptions, sortOptions?: SortOptions) => {
    setIsGridLoading(true);

    const data = {
      pageSize: paginationOptions?.paginationPageSize || defaultPageSize,
      page: paginationOptions?.paginationPage || page,
      sortOrder: sortOptions?.sortOrder || 'desc',
      sortField: sortOptions?.field || 'lastSubmittedDate'
    };

    const result = await apiWorker.get<ProviderApprovalListVM>(
      `${basePathName}/api/provideractions/search`,
      {
        params: {
          ...data,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (result && result.data) {
      setServerErrors([]);
      setData(result.data.data || []);
      setIsGridLoading(false);
      setTotalRows(result.data.total || 0);
    }
  };
 
  const fieldToString = (field: TableColumn<ProviderApprovalListVM>): string => {
    switch (field.id) {
      case 1:
        return 'fullName';
      case 2:
        return 'licenseName';
      case 3:
        return 'licenseNumber';
      case 4:
        return 'licenseState';
      case 5:
        return 'licenseExpiration';
      case 6:
        return 'licensingBoardUrl';
      case 7:
        return 'lastSubmittedDate';
      default:
        return '';
    }
  };

  const handleChangeRowsPerPage = (rowsPerPage: number, page: number) => {
    searchProviders({paginationPage: page, paginationPageSize: rowsPerPage}, 
      {sortOrder: sortOptions?.sortOrder, field: sortOptions?.field });
    setPageSize(rowsPerPage);
  };

  const handleChangePage = (page: number) => {
    searchProviders({paginationPage: page, paginationPageSize: pageSize}, 
      {sortOrder: sortOptions?.sortOrder, field: sortOptions?.field });
    setPage(page);
  };

  const handleSortChange = (field: TableColumn<ProviderProfileApprovalListVM>, sortDirection: SortOrder) => {
    const fieldName = fieldToString(field);
    searchProviders({paginationPageSize: pageSize, paginationPage: page}, {sortOrder: sortDirection, field: fieldName});
    setSortOptions({ sortOrder: sortDirection, field: fieldName});
  };

  const LicenseDetails = (row: any) => (
    <>
      <Row>
        <Col>
          <h5>Licenses Details</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <table className="table table-striped table-bordered table-sm">
            <thead>
              <tr>
                <th>
                  <strong>Credential Type</strong>
                </th>
                <th>
                  <strong>License Number</strong>
                </th>
                <th>
                  <strong>License State</strong>
                </th>
                <th>
                  <strong>License Expiration</strong>
                </th>
                <th>
                  <strong>Licensing Board Website</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {row.data.licenses && row.data.licenses?.map((item: any) => (
                <tr key={item.id}>
                  <td>{item.licenseType?.label}</td>
                  <td>{item.licenseNumber}</td>
                  <td>{item.state?.label}</td>
                  <td>{("0" + item.expirationMonth).slice(-2)}/{item.expirationYear}</td>
                  <td>
                    <a href={item.licensingBoardUrl} target="_blank" rel="noreferrer">
                      {item.licensingBoardUrl}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );

  return (
    <Container fluid={true} className="mt-4 position-relative">
      <LoadingSection
        isLoading={isPageLoading}
        height={pageElementRef.current?.clientHeight}
        width={pageElementRef.current?.clientWidth}
      >
        <Modal isOpen={approveModal} toggle={toggleApproveModal}>
          <ModalHeader toggle={toggleApproveModal}>
            Approve Confirmation
          </ModalHeader>
          <ModalBody>
            Are you sure you want to approve the{" "}
            {selectedRowKeys.length > 1 ? selectedRowKeys.length : ""} selected
            profile
            {selectedRowKeys.length > 1 ? "s" : ""}?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={approveProfiles}>
              Approve
            </Button>
            <Button color="secondary" onClick={toggleApproveModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={denyModal} toggle={toggleDenyModal}>
          <ModalHeader toggle={toggleDenyModal}>
            Disapprove Confirmation
          </ModalHeader>
          <ModalBody>
            Are you sure you want to disapprove the{" "}
            {selectedRowKeys.length > 1 ? selectedRowKeys.length : ""} selected
            profile
            {selectedRowKeys.length > 1 ? "s" : ""}?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={denyProfiles}>
              Disapprove
            </Button>
            <Button color="secondary" onClick={toggleDenyModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <div ref={pageElementRef}>
          <Form
            className="mt-1 pt-1 ml-5 mr-5 profile-approval"
            onSubmit={() => {}}
          >
            <Row>
              <Col className="px-0">
                <ValidationErrors
                  serverErrors={serverErrors}
                  formErrors={[]}
                  customErrors={[]}
                />
                <SessionExpiredError showError={sessionExpired} />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Row className="pb-2">
                  <Col className="pt-2 px-0 text-right pb-3">
                    <SkinnedButton
                      className="green-btn mr-3 px-4"
                      color="primary"
                      onClick={confirmApproval}
                      disabled={disableActionButtons}
                    >
                      Approve selected requests
                    </SkinnedButton>
                    <SkinnedButton
                      onClick={confirmDeny}
                      className="px-4 py-2"
                      disabled={disableActionButtons}
                    >
                      Disapprove selected requests
                    </SkinnedButton>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="px-0 pb-5">
                <DataTable
                  data={data}
                  columns={columns}
                  defaultSortFieldId={7}
                  defaultSortAsc={false}
                  expandableRows
                  expandableRowsComponent={LicenseDetails}
                  expandableRowDisabled={row => row.licenses?.length! < 2}
                  expandableRowExpanded={row => row.licenses?.length! > 1}
                  highlightOnHover
                  onSelectedRowsChange={handleRowSelection}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  onSort={handleSortChange}
                  pagination
                  paginationServer
                  paginationRowsPerPageOptions={[10, 20, 50, 100]}
                  paginationTotalRows={totalRows}
                  progressPending={isGridLoading}
                  selectableRows
                  striped
                />
              </Col>
            </Row>
          </Form>
        </div>
      </LoadingSection>
    </Container>
  );
};
