import "./App.scss";
import { Route, Switch } from "react-router-dom";
import { Layout } from "./Features/Home";
import { Dashboard } from "./Features/Dashboard";
import { Settings, ChangePassword } from "./Features/Settings";
import { ContactSupport, Help } from "./Features/Help";
import { ProviderProfile } from "./Features/ProviderProfile";
import { AuthorizeRoute } from "./Components/ApiAuthorization/AuthorizeRoute";
import { ApiAuthorizationRoutes } from "./Components/ApiAuthorization/ApiAuthorizationRoutes";
import { ProviderSearch } from "./Features/ProviderSearch";
import { ProviderProfileApproval } from "./Features/ProviderProfileApproval";
import { ProductList, ProductEdit } from "./Features/Product";
import { CampaignEdit } from "./Features/Campaign";
import impersonateRoutes from "./ImpersonateRoutes";

import {
  ProviderProfileManagement,
  ResetPassword,
  ChangeEmail,
} from "./Features/ProviderProfileManagement";
import { useGlobalState } from "./Context";

export const PrivateLayout = () => {
  const { currentUser } = useGlobalState();

  return (
    <Layout>
      <Switch>
        {(currentUser?.permissions || []).some((x: any) =>
          ["providerprofile.editany"].includes(x)
        ) && (
          <AuthorizeRoute
            path="/"
            exact
            component={ProviderSearch}
            permissions={["providerprofile.editany"]}
          ></AuthorizeRoute>
        )}
        {impersonateRoutes}
        <AuthorizeRoute
          path="/"
          exact
          component={Dashboard}
          permissions={["providerprofile.editself"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/provider-profile"
          component={ProviderProfile}
          permissions={["providerprofile.editself"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/settings"
          component={Settings}
          permissions={["providerprofile.editself"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/help"
          component={Help}
          permissions={["providerprofile.editself"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/change-password"
          component={ChangePassword}
          permissions={["providerprofile.editself"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/provider-search"
          component={ProviderSearch}
          permissions={["providerprofile.search"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/provider-approval"
          component={ProviderProfileApproval}
          permissions={["providerprofile.approve"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/provider/:id"
          component={ProviderProfileManagement}
          permissions={["providerprofile.editany"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/reset-password/:id"
          component={ResetPassword}
          permissions={["providerprofile.editany"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/change-email/:id"
          component={ChangeEmail}
          permissions={["providerprofile.editany"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/campaign-product"
          component={ProductList}
          permissions={["providerprofile.editany"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/product/:id"
          component={ProductEdit}
          permissions={["providerprofile.editany"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/product"
          component={ProductEdit}
          permissions={["providerprofile.editany"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/campaign/:id"
          component={CampaignEdit}
          permissions={["providerprofile.editany"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/default-campaign/:id"
          component={CampaignEdit}
          permissions={["providerprofile.editany"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/campaign"
          component={CampaignEdit}
          permissions={["providerprofile.editany"]}
        ></AuthorizeRoute>
        <AuthorizeRoute
          path="/contact-support"
          component={ContactSupport}
          permissions={["providerprofile.editself"]}
        ></AuthorizeRoute>
        <Route path="/" component={ApiAuthorizationRoutes}></Route>
      </Switch>
    </Layout>
  );
};

export default PrivateLayout;
