import React from "react";

export const SecureTrustSeal: React.FC = (props) => {
  const onSecureTrustSealClick = () => {
    window.open(
      "https://sealserver.trustwave.com/cert.php?customerId=x4ix8ffC7NOh4gU5amMpiytnX2IMPV&size=105x54&style=",
      "c_TW",
      "location=no, toolbar=no, resizable=yes, scrollbars=yes, directories=no, status=no, width=615, height=720"
    );
    return false;
  };

  const onSecureTrustSealContextMenu = () => {
    alert(
      "Copying Prohibited by Law - Trusted Commerce is a registered trademark of Sysnet Limited, part of the VikingCloud group."
    );
    return false;
  };

  return (
    <img
      id="trustwaveSealImage"
      src="https://sealserver.trustwave.com/seal_image.php?customerId=x4ix8ffC7NOh4gU5amMpiytnX2IMPV&size=105x54&style="
      style={{ cursor: "pointer", border: 0 }}
      onClick={onSecureTrustSealClick}
      onContextMenu={onSecureTrustSealContextMenu}
      alt="This site is protected by Sysnet's Trusted Commerce program"
      title="This site is protected by Sysnet's Trusted Commerce program"
    />
  );
};

export default SecureTrustSeal;
