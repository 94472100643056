import { UserManager, WebStorageStateStore, Log } from 'oidc-client';
import { ApplicationPaths, ApplicationName } from './ApiAuthorizationConstants';
import { GlobalActionTypes } from "../../Context";

export class AuthorizeService {
    async getUser(dispatch) {
        if (this._user && this._user.profile) {
            return this._user.profile;
        }

        await this.ensureUserManagerInitialized(dispatch);
        const user = await this.userManager.getUser();
        return user;
    }

    async changeUserToRegistered() {
        let user;
        if (this._user) {
            user = this._user;
        } else {
            await this.ensureUserManagerInitialized(null);
            user = await this.userManager.getUser();
        }

        if (user && user.profile) {
            user.profile.subscription_status = "registered";
            await this.userManager.storeUser(user);
        }
    }

    async getAccessToken(dispatch) {
        await this.ensureUserManagerInitialized(dispatch);
        const user = await this.userManager.getUser();
        return user && user.access_token;
    }

    async signIn(state, dispatch) {
        await this.ensureUserManagerInitialized(dispatch);
        try {
            const silentUser = await this.userManager.signinSilent(this.createArguments());
            return this.success(state, silentUser);
        } catch (silentError) {
            try {
                await this.userManager.signinRedirect(this.createArguments(state));
                return this.redirect();
            } catch (redirectError) {
                return this.error(redirectError);
            }
        }
    }

    async completeSignIn(url, dispatch) {
        try {
            await this.ensureUserManagerInitialized(dispatch);
            const user = await this.userManager.signinCallback(url);
            return this.success(user && user.state, user);
        } catch (error) {
            console.log(error && error.stack ? error.stack : "");
            return this.error('There was an error while signing in.');
        }
    }

    async revokeAccessToken(dispatch) {
        await this.ensureUserManagerInitialized(dispatch);
        return await this.userManager.revokeAccessToken();
    }

    async signOut(state, dispatch) {
        await this.ensureUserManagerInitialized(dispatch);
        try {
            await this.userManager.signoutRedirect(this.createArguments(state));
            return this.redirect();
        } catch (redirectSignOutError) {
            return this.error(redirectSignOutError);
        }
    }

    async completeSignOut(url, dispatch) {
        await this.ensureUserManagerInitialized(dispatch);
        try {
            const response = await this.userManager.signoutCallback(url);
            return this.success(response && response.data);
        } catch (error) {
            return this.error(error);
        }
    }

    createArguments(state) {
        return { useReplaceToNavigate: true, data: state };
    }

    error(message) {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    success(state, user) {
        return { status: AuthenticationResultStatus.Success, "user": user, state };
    }

    redirect() {
        return { status: AuthenticationResultStatus.Redirect };
    }

    async ensureUserManagerInitialized(dispatch) {
        if (this.userManager !== undefined) {
            return;
        }

        let settings = sessionStorage.getItem('oidc-configuration');
        if (settings) {
            settings = JSON.parse(settings);
        } else {
            let response = await fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl);
            if (!response.ok) {
                throw new Error(`Could not load settings for '${ApplicationName}'`);
            }
            settings = await response.json();
            sessionStorage.setItem('oidc-configuration', JSON.stringify(settings));
        }

        settings.automaticSilentRenew = true;
        settings.includeIdTokenInSilentRenew = true;
        settings.userStore = new WebStorageStateStore({
            prefix: ApplicationName
        });

        this.userManager = new UserManager(settings);

        this.userManager.events.addUserSignedOut(async () => {
            await this.userManager.removeUser();
        });

        if (dispatch) {
            this.userManager.events.addUserLoaded(async (user) => {
                dispatch({
                    type: GlobalActionTypes.SetToken,
                    payload: user.access_token,
                  });
            });
        }
    }

    static get instance() { return authService }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail'
};
