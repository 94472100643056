import React, { Fragment } from "react";
import { Loader } from "./index";
import "./LoadingSection.scss";

interface IProps {
  isLoading: boolean;
}

export const BodyLoadingSection: React.FC<IProps> = ({
  isLoading,
  children,
}) => {
  return (
    <Fragment>
      {isLoading && <Loader className="w-100"></Loader>}
      {!isLoading && children}
    </Fragment>
  );
};
