export class DashboardVM {
  constructor(){
  }
  [key: string]: any
  searchResultsMatches?: number;
  profileViews?: number;
  clickedToEmail?: number;
  clickedToCall?: number;
  clickedToText?: number;
  clickedToShare?: number;
}

export enum Timeframe {
  Last30Days = 0,
  Last60Days = 1,
  Last90Days = 2,
  Last120Days = 3,
  Lifetime = 4
}
