import { Fragment } from "react";
import { Alert } from "reactstrap";

interface Props {
  serverErrors: any;
  formErrors: any;
  customErrors: string[];
  className?: string;
}

export default function ValidationErrors({
  serverErrors,
  formErrors,
  customErrors,
  className = "",
}: Props) {
  return (
    <Fragment>
      {(formErrors &&
        Object.keys(formErrors).filter((prop) => formErrors[prop]).length >
          0) ||
      (serverErrors &&
        Array.isArray(serverErrors) &&
        serverErrors.length > 0) ||
      (customErrors && customErrors.length > 0) ? (
        <Alert color="danger" className={className + " font-weight-bold"}>
          <ul className="pl-0">
            {formErrors &&
              Object.keys(formErrors).map((prop: any, i: any) =>
                formErrors[prop] !== "" && formErrors[prop] !== null ? (
                  <li key={i}>{formErrors[prop]}</li>
                ) : null
              )}
            {serverErrors &&
              serverErrors.map((err: any, i: any) => <li key={i}>{err}</li>)}
            {customErrors &&
              customErrors.map((err: any, i: any) => <li key={i}>{err}</li>)}
          </ul>
        </Alert>
      ) : (
        serverErrors &&
        Object.keys(serverErrors) &&
        Array.isArray(Object.keys(serverErrors)) &&
        Object.keys(serverErrors).length > 0 &&
        Array.isArray(serverErrors[Object.keys(serverErrors)[0]]) && (
          <Alert color="danger" className={className + " font-weight-bold"}>
            <ul className="pl-0">
              {Object.keys(serverErrors).map((prop: any, i: any) =>
                serverErrors[prop].map((err: any, j: any) => (
                  <li key={j}>{err}</li>
                ))
              )}
            </ul>
          </Alert>
        )
      )}
    </Fragment>
  );
}
