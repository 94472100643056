import "./App.scss";
import { Dashboard } from "./Features/Dashboard";
import { Settings, ChangePassword } from "./Features/Settings";
import { ContactSupport, Help } from "./Features/Help";
import { ProviderProfile } from "./Features/ProviderProfile";
import { AuthorizeRoute } from "./Components/ApiAuthorization/AuthorizeRoute";

export default [
  <AuthorizeRoute
    key="/provider/impersonate/provider-profile/:id"
    path="/provider/impersonate/provider-profile/:id"
    component={ProviderProfile}
    permissions={["providerprofile.editany"]}
  />,
  <AuthorizeRoute
    key="/provider/impersonate/dashboard/:id"
    path="/provider/impersonate/dashboard/:id"
    component={Dashboard}
    permissions={["providerprofile.editany"]}
  />,
  <AuthorizeRoute
    key="/provider/impersonate/settings/:id"
    path="/provider/impersonate/settings/:id"
    component={Settings}
    permissions={["providerprofile.editany"]}
  />,
  <AuthorizeRoute
    key="/provider/impersonate/help/:id"
    path="/provider/impersonate/help/:id"
    component={Help}
    permissions={["providerprofile.editany"]}
  />,
  <AuthorizeRoute
    key="/provider/impersonate/change-password/:id"
    path="/provider/impersonate/change-password/:id"
    component={ChangePassword}
    permissions={["providerprofile.editany"]}
    />,
   <AuthorizeRoute
    key="/provider/impersonate/contact-support/:id"
    path="/provider/impersonate/contact-support/:id"
    component={ContactSupport}
    permissions={["providerprofile.editany"]}
   />,
];
