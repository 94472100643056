import {
  ChangeEvent,
  FocusEvent,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Prompt } from "react-router";
import "./ProviderProfileEdit.scss";
import { StaticGoogleMap, Marker } from "react-static-google-map";
import {
  Alert,
  Col,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledCollapse,
} from "reactstrap";
import { MapLocation } from "../../Components/GooglePlaces/GooglePlaces.types";
import RequiredField from "../../Components/Form/RequiredField";
import {
  ActionRequiredSection,
  DeactivatedSection,
  ListedSection,
  NotSubmittedSection,
  PendingReviewSection,
  UnlistedSection,
} from "../../Components/Help";
import {
  ErrorMessage,
  BusinessHours,
  Licenses,
  Certifications,
  BoardCertifications,
  ProfessionalAssociations,
  Schools,
  Logo,
  CheckboxGroupToggler,
  ImageEditModal,
} from "../../Components/Display";
import { format } from "date-fns";
import classnames from "classnames";
import { CoreEnum } from "../../Features/Shared/Models/SharedModels";
import {
  CheckboxVM,
  ProfileStatus,
  PublishStatus,
} from "../../Components/ProviderProfile/Models";
import NumberFormat from "react-number-format";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import * as IconRegular from "@fortawesome/free-regular-svg-icons";
import * as BrandIcon from "@fortawesome/free-brands-svg-icons";
import { useDropzone, FileRejection } from "react-dropzone";
import {
  CustomCheckbox,
  DataDropDown,
  InputWithIcon,
  TextAreaInput,
  TextInput,
} from "../../Components/Form/Inputs";
import GooglePlaces from "../../Components/GooglePlaces";
import {
  BusinessHoursVM,
  LicenseVM,
  CertificationVM,
  BoardCertificationVM,
  ProfessionalAssociationVM,
  ProviderProfileVM,
  SchoolVM,
  OfficePhotoVM,
} from "../../Components/ProviderProfile/Models/index";
import { useGlobalState } from "../../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../node_modules/react-dragula/dist/dragula.min.css";
import {
  months,
  years,
  redBorder,
  grayBorder,
  blueBorder,
  white,
  red,
  blueish,
  imageRecommendations,
  csvInformationMessage,
  licenseInformationMessage,
  toExpirationYear,
  dropDownStyle,
  darkBlue,
  Helper,
  historicYears,
} from "../../Utilities/HelperData";
import { v4 as uuid } from "uuid";
import { VisibilityOffIcon } from "../../Components/Display";
import { SkinnedButton } from "../Form/Buttons";
import TooltipMessage from "../Form/TooltipMessage";
import { ImageDataReturnType } from "../../Utilities/ImageDataReturnType";
import { dropdownSelectLabel } from "../../Utilities/IsMobile";

interface IProps {
  currentProviderProfile: ProviderProfileVM | undefined;
  handleProfileChanges: (item: ProviderProfileVM) => void;
  handleProfileImageChanges: (item?: any) => void;
  handleOfficeImageChanges: (item: any) => void;
  handleBlurEvent: (item: any) => void;
  licensesRef: any;
  boardCertificationsRef: any;
  certificationsRef: any;
  professionalAssociationsRef: any;
  schoolsRef: any;
  formErrors: any;
  serverErrors: any;
  touchedFields: string[];
  tabPosition: (item: string) => void;
  newTabPosition: string;
  handleSave: (event: any, isForApproval: boolean, isAsync: boolean) => void;
  inputsChanged: boolean;
  dropZoneReset: boolean;
  changeDropZoneReset: (reset: boolean) => void;
}

export const ProviderProfileEdit = ({
  currentProviderProfile,
  handleProfileChanges,
  handleProfileImageChanges,
  handleOfficeImageChanges,
  licensesRef,
  boardCertificationsRef,
  certificationsRef,
  professionalAssociationsRef,
  schoolsRef,
  handleBlurEvent,
  formErrors,
  serverErrors,
  touchedFields,
  tabPosition,
  newTabPosition,
  handleSave,
  inputsChanged,
  dropZoneReset,
  changeDropZoneReset,
}: IProps) => {
  const aboutMeLength = 10000;
  const [providerProfile, setProviderProfile] =
    useState<ProviderProfileVM | null>(null);
  const [addressChanged, setaddressChanged] = useState(false);
  const {
    baseUrl,
    googleMapsKey,
    customerSupportContactEmail,
    enableBusinessHoursSection,
  } = useGlobalState();
  const [activeImage, setActiveImage] = useState("");
  const [touched, setTouched] = useState<string[]>(
    touchedFields && touchedFields.length ? touchedFields : []
  );
  const [activeTab, setActiveTab] = useState("1");
  const [characterCount, setCharacterCount] = useState(aboutMeLength);
  const [officePhotos, setOfficePhotos] = useState<any[]>([]);
  const [addressValue, setAddressValue] = useState("");
  const [businessHoursIsEmpty, setBusinessHoursIsEmpty] = useState(false);
  const [isCreditCardsVisible, setIsCreditCardsVisible] =
    useState<boolean>(false);
  const [isInsurancePlansSectionVisible, setIsInsurancePlansSectionVisible] =
    useState<boolean>(false);
  const [hideClientDemographics, setHideClientDemographics] = useState(true);
  const [hideClinicalSpecialties, setHideClinicalSpecialties] = useState(true);
  const [hideTherapyTypes, setHideTherapyTypes] = useState(true);
  const [hideCommunitySpecialties, setHideCommunitySpecialties] =
    useState(true);
  const [hideFaithBasedSpecialties, setHideFaithBasedSpecialties] =
    useState(true);
  const [hideLanguages, setHideLanguages] = useState(true);
  const [hideInsurancePlans, setHideInsurancePlans] = useState(true);
  const [hidePaymentMethods, setHidePaymentMethods] = useState(true);
  const [newFileRejections, setFileRejections] = useState<FileRejection[]>([]);

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      tabPosition(tab);
      changeDropZoneReset(true);
    }
  };
  const saveOnToggle = (event: any, tab: string) => {
    if (activeTab !== tab) {
      handleSave(event, false, true);
    }
  }
  const toggleClientDemographics = (event: any) => {
    setHideClientDemographics(!hideClientDemographics);
    event.preventDefault();
  };
  const toggleClinicalSpecialties = (event: any) => {
    setHideClinicalSpecialties(!hideClinicalSpecialties);
    event.preventDefault();
  };
  const toggleTherapyTypes = (event: any) => {
    setHideTherapyTypes(!hideTherapyTypes);
    event.preventDefault();
  };
  const toggleCommunitySpecialties = (event: any) => {
    setHideCommunitySpecialties(!hideCommunitySpecialties);
    event.preventDefault();
  };
  const toggleFaithBasedSpecialties = (event: any) => {
    setHideFaithBasedSpecialties(!hideFaithBasedSpecialties);
    event.preventDefault();
  };
  const toggleLanguages = (event: any) => {
    setHideLanguages(!hideLanguages);
    event.preventDefault();
  };
  const toggleInsurancePlans = (event: any) => {
    setHideInsurancePlans(!hideInsurancePlans);
    event.preventDefault();
  };
  const togglePaymentMethods = (event: any) => {
    setHidePaymentMethods(!hidePaymentMethods);
    event.preventDefault();
  };

  const [isInputsChanged, setInputsChanged] = useState(inputsChanged);
  const [isDropZoneReset, setNewDropZoneReset] = useState(false);

  const {
    getRootProps,
    getInputProps,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxSize: 8388608,
    accept: ".jpg,.jpeg,.png,.gif",
    onDrop: (acceptedFiles, fileRejections) => {
      changeDropZoneReset(false);
      let newElements = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          id: uuid(),
          description: "",
        })
      );
      const newSetOfFiles = [...officePhotos, ...newElements];
      setOfficePhotos((officePhotos) => newSetOfFiles);
      setFileRejections(fileRejections);
      handleOfficeImageChanges(newSetOfFiles);
    }
  });

  useEffect(() => {
    if (newTabPosition !== activeTab) {
      toggle(newTabPosition);
    }
  });

  useEffect(() => {
    if (touchedFields && touchedFields.length) {
      setTouched(touchedFields);
    }
  }, [touchedFields]);

  useEffect(() => {
    setInputsChanged(inputsChanged);
  }, [inputsChanged]);

  useEffect(() => {
    setNewDropZoneReset(dropZoneReset)
  }, [dropZoneReset]);

  const handleNewCroppedImage = (fileId: string, newImage: any) => {
    setActiveImage(newImage);
    handleProfileImageChanges(newImage.replace("data:image/jpeg;base64,", ""));
  };

  const handleNewCroppedOfficeImage = (fileId: string, newImage: Blob, description: string) => {
    replaceInMemoryFile(fileId, newImage, description);
  };

  const handleNewCroppedOfficeImageFromDatabaseImage = (
    fileId: string,
    newImage: Blob,
    description: string,
  ) => {
    replaceInMemoryFile(fileId, newImage, description);
    removeDatabaseFile(fileId);
  };

  const removeInMemoryOfficeImage = (fileId: string) : any => {
    const currentFiles = [...officePhotos];
    const fileItemIndex = currentFiles.findIndex((x) => x.id === fileId);

    let newFiles = currentFiles.filter((_, i) => i !== fileItemIndex);
    URL.revokeObjectURL(currentFiles[fileItemIndex].preview);
    // need to create the image again because it disappears if we don't do this
    newFiles.forEach((file) => {
      URL.revokeObjectURL(file.preview);
      file.preview = URL.createObjectURL(file);
    });
    return newFiles;
  };

  const removeInMemoryFile = (fileId: string) => {
    let newFiles = removeInMemoryOfficeImage(fileId);
    setOfficePhotos(newFiles);
    handleOfficeImageChanges(newFiles);
  };

  const replaceInMemoryFile = (fileId: string, newImage: Blob, description: string) => {
    let newFiles: any[] = [];
    if (fileId.length > 0 && officePhotos.length > 0) {
      const fileItem = officePhotos.findIndex((x: any) => x.id === fileId);
      newFiles = officePhotos.filter((_, i) => i !== fileItem);
      //remove existing file
      if (fileItem > -1) {
        URL.revokeObjectURL(officePhotos[fileItem].preview);
      }
    }
    let newFileId = uuid();
    let newFile = new File([newImage], newFileId + ".jpeg", {
      type: newImage.type,
    });
    newFiles.push(newFile);
    let newFileInArray = newFiles.pop();
    newFileInArray.description = description;
    newFileInArray.id = uuid();
    newFiles.push(newFileInArray);
    // need to create the image again because it disappears if we don't do this
    newFiles.forEach((file) => {
      URL.revokeObjectURL(file.preview);
      file.preview = URL.createObjectURL(file);
    });
    setOfficePhotos(newFiles);
    handleOfficeImageChanges(newFiles);
  };

  const removeDatabaseFile = (fileId: string) => {
    const newFiles = [...(providerProfile!.officePhotos || [])];
    const fileItem = newFiles.findIndex((x) => x.id === fileId);
    newFiles.splice(fileItem, 1);
    const newProfile = { ...providerProfile, officePhotos: newFiles };
    setProviderProfile(newProfile);
    handleProfileChanges(newProfile);
  };

  const removeProfilePhoto = () => {
    setActiveImage("");
    const newProfile = { ...providerProfile, photoFilename: ""};
    handleProfileImageChanges();
    setProviderProfile(newProfile);
    handleProfileChanges(newProfile);
  }

  const fillOutBusinessHoursProperties = (businessHours: BusinessHoursVM[]) => {
    let emptyBusinessHours = true;
    const items = businessHours.map((x) => {
      if (x.startTime instanceof Date && !isNaN(x.startTime.getTime())) {
        let am_pm = x.startTime!.getHours() >= 12 ? "PM" : "AM";
        const startPeriod = new CoreEnum();
        startPeriod.label = am_pm;
        startPeriod.value = am_pm;
        x.startPeriodType = startPeriod;
        x.startTimeUI = format(x.startTime!, "hh:mm");
        emptyBusinessHours = false;
      } else {
        const startPeriod = new CoreEnum();
        startPeriod.label = "AM";
        startPeriod.value = "AM";
        x.startPeriodType = startPeriod;
      }

      if (x.endTime instanceof Date && !isNaN(x.endTime.getTime())) {
        let am_pm = x.endTime!.getHours() >= 12 ? "PM" : "AM";
        const endPeriod = new CoreEnum();
        endPeriod.label = am_pm;
        endPeriod.value = am_pm;
        x.endPeriodType = endPeriod;
        x.endTimeUI = format(x.endTime!, "hh:mm");
        emptyBusinessHours = false;
      } else {
        const endPeriod = new CoreEnum();
        endPeriod.label = "AM";
        endPeriod.value = "AM";
        x.endPeriodType = endPeriod;
      }
      if (
        x.onFriday ||
        x.onMonday ||
        x.onSaturday ||
        x.onSunday ||
        x.onThursday ||
        x.onTuesday ||
        x.onWednesday
      ) {
        emptyBusinessHours = false;
      }

      return x;
    });
    setBusinessHoursIsEmpty(emptyBusinessHours);
    return items;
  };

  const fillOutLicensesProperties = (licenses: LicenseVM[]) => {
    return licenses.map((x) => {
      const year = new CoreEnum();
      const month = new CoreEnum();
      if (x.expirationMonth) {
        month.value = x.expirationMonth.toString();
        month.label = months.filter((y) => y.value == month.value)[0].label;
        x.convertedExpirationMonth = month;
      }
      if (x.expirationYear) {
        year.value = x.expirationYear.toString();
        year.label = (year.value || "").toString();
        x.convertedExpirationYear = year;
      }
      return x;
    });
  };

  const fillOutCertificationsProperties = (
    certifications: CertificationVM[]
  ) => {
    return certifications.map((x) => {
      if (x.expiration instanceof Date && x.expirationYear === undefined) {
        x.expirationYear = toExpirationYear(x.expiration);
      }
      if (x.issued instanceof Date) {
        const year = new CoreEnum();
        year.label = x.issued!.getFullYear().toString();
        year.value = year.label;
        x.issuedYear = year;
      }
      return x;
    });
  };

  const fillOutMultipleCheckboxProperties = (
    profile: ProviderProfileVM,
    field1: string,
    field2: string
  ) => {
    return profile[field1].map((x: any) => {
      if (profile[field2].find((y: any) => y.value == x.value)) {
        x.checked = true;
      }
      return x;
    });
  };

  const setProfile = async (profile: any) => {
    if (
      // these checks help avoid running this code multiple times
      profile &&
      profile.genderTypes &&
      profile.genderTypes.filter(
        (x: CoreEnum) => x.label === "Select or start typing to filter"
      ).length === 0
    ) {
      const emptyGenderType = new CoreEnum();
      emptyGenderType.label = dropdownSelectLabel;
      emptyGenderType.value = "";
      profile.genderTypes!.splice(0, 0, emptyGenderType);
      const emptyPronounType = new CoreEnum();
      emptyPronounType.label = dropdownSelectLabel;
      emptyPronounType.value = "";
      profile.pronounTypes!.splice(0, 0, emptyPronounType);
      const emptyRaceType = new CoreEnum();
      emptyRaceType.label = dropdownSelectLabel;
      emptyRaceType.value = "";
      profile.races!.splice(0, 0, emptyRaceType);
      const emptyEthnicityType = new CoreEnum();
      emptyEthnicityType.label = dropdownSelectLabel;
      emptyEthnicityType.value = "";
      profile.ethnicities!.splice(0, 0, emptyEthnicityType);

      const emptyTimezoneType = new CoreEnum();
      emptyTimezoneType.label = dropdownSelectLabel;
      emptyTimezoneType.value = "";
      profile.timezones!.splice(0, 0, emptyTimezoneType);

      if (profile.businessHours) {
        profile.businessHours = fillOutBusinessHoursProperties(
          profile.businessHours
        );
      }

      if (
        profile.selectedPaymentMethodTypes &&
        profile.selectedPaymentMethodTypes.find(
          (y: any) => y.label === "Credit Card"
        )
      ) {
        setIsCreditCardsVisible(true);
      }

      if (profile.isAcceptingInsurance) {
        setIsInsurancePlansSectionVisible(true);
      }

      if (profile.isLicensed) {
        profile.supervisorConvertedExpirationMonth = null;
        profile.supervisorConvertedExpirationYear = null;

        if (
          !profile.licenses ||
          (Array.isArray(profile.licenses) && profile.licenses.length === 0)
        ) {
          profile.licenses = [];
          profile.licenses.push(new LicenseVM());
          profile.licenses[0].id = uuid();
          profile.licenses[0].displayInHeader = true;
        }
      } else {
        const year: any = {};
        const month: any = {};
        if (profile.supervisorExpirationMonth) {
          month.value = profile.supervisorExpirationMonth.toString();
          month.label = months.filter((x) => x.value == month.value)[0].label;
          profile.supervisorConvertedExpirationMonth = month;
        }
        if (profile.supervisorExpirationYear) {
          year.value = profile.supervisorExpirationYear.toString();
          year.label = (year.value || "").toString();
          profile.supervisorConvertedExpirationYear = year;
        }
      }

      if (
        !profile.schools ||
        (Array.isArray(profile.schools) && profile.schools.length === 0)
      ) {
        profile.schools = [];
        profile.schools.push(new SchoolVM());
        profile.schools[0].id = uuid();
      }
    }

    if (
      profile.removeOfficePhotosFromMemory &&
      Array.isArray(profile.removeOfficePhotosFromMemory) &&
      profile.removeOfficePhotosFromMemory.length &&
      profile.removeOfficePhotosFromMemory.length > 0
    ) {
      const newElements = officePhotos.map((file) => {
        URL.revokeObjectURL(file.preview);
      });
      setOfficePhotos([]);
      profile.removeOfficePhotosFromMemory = [];
    }

    setCharacterCount(aboutMeLength - (profile.aboutMe?.length || 0));
    if (profile.practiceAddress) {
      setAddressValue(profile.practiceAddress);
    }

    if (profile.licenses) {
      profile.licenses = fillOutLicensesProperties(profile.licenses);
    }
    if (profile.certifications) {
      profile.certifications = fillOutCertificationsProperties(
        profile.certifications
      );
    }
    if (profile.creditCards) {
      profile.creditCards = fillOutMultipleCheckboxProperties(
        profile,
        "creditCards",
        "selectedCreditCards"
      );
    }
    if (profile.serviceTypes) {
      profile.serviceTypes = fillOutMultipleCheckboxProperties(
        profile,
        "serviceTypes",
        "selectedServiceTypes"
      );
    }
    if (profile.sessionTypes) {
      profile.sessionTypes = fillOutMultipleCheckboxProperties(
        profile,
        "sessionTypes",
        "selectedSessionTypes"
      );
    }
    if (profile.clientDemographics) {
      profile.clientDemographics = fillOutMultipleCheckboxProperties(
        profile,
        "clientDemographics",
        "selectedClientDemographics"
      );
    }
    if (profile.clinicalSpecialties) {
      profile.clinicalSpecialties = fillOutMultipleCheckboxProperties(
        profile,
        "clinicalSpecialties",
        "selectedClinicalSpecialties"
      );
    }
    if (profile.therapyTypes) {
      profile.therapyTypes = fillOutMultipleCheckboxProperties(
        profile,
        "therapyTypes",
        "selectedTherapyTypes"
      );
    }
    if (profile.communityTypes) {
      profile.communityTypes = fillOutMultipleCheckboxProperties(
        profile,
        "communityTypes",
        "selectedCommunityTypes"
      );
    }
    if (profile.faithTypes) {
      profile.faithTypes = fillOutMultipleCheckboxProperties(
        profile,
        "faithTypes",
        "selectedFaithTypes"
      );
    }
    if (profile.paymentMethodTypes) {
      profile.paymentMethodTypes = fillOutMultipleCheckboxProperties(
        profile,
        "paymentMethodTypes",
        "selectedPaymentMethodTypes"
      );
    }
    if (profile.languageTypes) {
      profile.languageTypes = fillOutMultipleCheckboxProperties(
        profile,
        "languageTypes",
        "selectedLanguageTypes"
      );
    }
    if (profile.insurancePlans) {
      profile.insurancePlans = fillOutMultipleCheckboxProperties(
        profile,
        "insurancePlans",
        "selectedInsurancePlans"
      );
    }
    if (profile.photoFilename && profile.photoFilename !== undefined) {
      setActiveImage(`${baseUrl}providers/photo?id=${profile?.photoFilename}`);
    }

    setProviderProfile(profile);
  };

  const radioButtonChange = (event: any) => {
    const newProfile = {
      ...providerProfile,
      [event?.target.name]: event.target.value === "true",
    };

    if (event?.target.name === "isAcceptingInsurance") {
      if (event.target.value === "true") {
        setIsInsurancePlansSectionVisible(true);
      } else {
        const fieldInsurancePlan = "insurancePlans";
        const selectedFieldInsurancePlans = "selectedInsurancePlans";

        const insurancePlanData = newProfile[fieldInsurancePlan];
        insurancePlanData?.map((x, index) => {
          x.checked = false;
        });
        newProfile[fieldInsurancePlan] = insurancePlanData;
        newProfile[selectedFieldInsurancePlans] = [];
        setIsInsurancePlansSectionVisible(false);
      }
    } else if (event?.target.name === "isLicensed") {
      if (
        event.target.value === "true" &&
        (!newProfile.licenses ||
          (Array.isArray(newProfile.licenses) &&
            newProfile.licenses.length === 0))
      ) {
        newProfile.licenses = [];
        newProfile.licenses.push(new LicenseVM());
        newProfile.licenses[0].id = uuid();
        newProfile.licenses[0].displayInHeader = true;
      }
    }

    setProviderProfile(newProfile);
    handleProfileChanges(newProfile);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event?.target.name;
    let value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    if (name === "aboutMe") {
      setCharacterCount(aboutMeLength - value.toString().length);
    }

    // inputMode === "numeric" are fields (contactPhoneNumber, sms, costFrom, costTo) that will
    // trigger this event when the page loads and they shouldn't. That means the hook for
    // setInputsChanged will be triggered which is a false positive. This issue has to do with
    // the fact that those fields use a mask plugin.
    if (
      event.target.inputMode === "numeric" &&
      providerProfile &&
      providerProfile[name] == value
    )
      return;

    const newProfile = { ...providerProfile, [name]: value };
    setProviderProfile(newProfile);
    handleProfileChanges(newProfile);
  };

  const handleChangeInMap = (event: MapLocation, source: string) => {
    const newProfile = {
      ...providerProfile,
      [source]: event.value.description,
    };
    setProviderProfile(newProfile);
    handleProfileChanges(newProfile);
  };

  const handleChangeInDropDown = (
    event: ChangeEvent<HTMLInputElement>,
    source: string
  ) => {
    const name = event?.target.name;
    let value;
    if (event.target.type === "select") {
      value = {
        value: event.target.value,
        label: source,
      };
    } else {
      value = event.target.value;
    }
    const newProfile = { ...providerProfile, [name]: value };
    setProviderProfile(newProfile);
    handleProfileChanges(newProfile);
  };

  const handleChangeInBusinessHours = (
    event: ChangeEvent<HTMLInputElement>,
    items: BusinessHoursVM[] | undefined
  ) => {
    let value;
    value = items;
    let emptyBusinessHours = true;
    items?.map((x, index) => {
      x.startTime = new Date();
      if (
        x.onFriday ||
        x.onMonday ||
        x.onSaturday ||
        x.onSunday ||
        x.onThursday ||
        x.onTuesday ||
        x.onWednesday ||
        (!!x.startTimeUI && x.startTimeUI !== "__:__") ||
        (!!x.endTimeUI && x.endTimeUI !== "__:__") ||
        x.startPeriodType?.label === "PM" ||
        x.endPeriodType?.label === "PM"
      ) {
        emptyBusinessHours = false;
      }
      const startHours = parseInt(x.startTimeUI?.split(":")[0] || "");
      x.startTime.setHours(
        x.startPeriodType?.label === "AM"
          ? startHours === 12
            ? 0
            : startHours
          : startHours > 11
          ? startHours
          : startHours + 12
      );
      let minutes = parseInt(x.startTimeUI?.split(":")[1] || "");
      x.startTime.setMinutes(minutes);
      if (
        (startHours > 12 && x.startPeriodType?.label === "AM") ||
        minutes > 59 ||
        (startHours === 0 && x.startPeriodType?.label === "PM")
      ) {
        x.startTime = undefined;
      }
      x.endTime = new Date();
      const endHours = parseInt(x.endTimeUI?.split(":")[0] || "");
      x.endTime.setHours(
        x.endPeriodType?.label === "AM"
          ? endHours === 12
            ? 0
            : endHours
          : endHours > 11
          ? endHours
          : endHours + 12
      );
      minutes = parseInt(x.endTimeUI?.split(":")[1] || "");
      x.endTime.setMinutes(minutes);
      if (
        (endHours > 12 && x.endPeriodType?.label === "AM") ||
        minutes > 59 ||
        (endHours === 0 && x.endPeriodType?.label === "PM")
      ) {
        x.endTime = undefined;
      }
      x.position = index;
      return x;
    });
    setBusinessHoursIsEmpty(emptyBusinessHours);
    const newProfile = { ...providerProfile, businessHours: value };
    setProviderProfile(newProfile);
    handleProfileChanges(newProfile);
  };

  const handleChangeInLicenses = (
    event: ChangeEvent<HTMLInputElement>,
    licenses: LicenseVM[] | undefined
  ) => {
    licenses?.map((x, index) => {
      if (x.convertedExpirationYear && x.convertedExpirationYear.value) {
        x.expirationYear = parseInt(x.convertedExpirationYear.value.toString());
      }
      if (x.convertedExpirationMonth && x.convertedExpirationMonth.value) {
        x.expirationMonth = parseInt(
          x.convertedExpirationMonth.value.toString()
        );
      }
      x.position = index;
      return x;
    });
    const newProfile = { ...providerProfile, licenses: licenses };
    setProviderProfile(newProfile);
    handleProfileChanges(newProfile);
  };

  const handleChangeInMultipleCheckboxes = (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
    selectedField: string
  ) => {
    const index = parseInt(event.target.name);
    const newData = [...(providerProfile![field] || [])];
    let elem = providerProfile![field][index];
    newData.splice(index, 1, {
      label: elem.label,
      value: elem.value,
      checked: event.target.checked,
    });

    const newProfile = {
      ...providerProfile,
      [selectedField]: newData.filter((x: any) => x.checked),
      [field]: newData,
    };

    if (field === "paymentMethodTypes" && elem.label === "Credit Card") {
      if (event.target.checked) {
        setIsCreditCardsVisible(true);
      } else {
        const fieldCreditCard = "creditCards";
        const selectedFieldCreditCard = "selectedCreditCards";

        const creditCardData = newProfile[fieldCreditCard];
        creditCardData?.map((x, index) => {
          x.checked = false;
        });
        newProfile[fieldCreditCard] = creditCardData;
        newProfile[selectedFieldCreditCard] = [];
        setIsCreditCardsVisible(false);
      }
    }
    setProviderProfile(newProfile);
    handleProfileChanges(newProfile);
  };

  const handleChangeInBoardCertifications = (
    event: ChangeEvent<HTMLInputElement>,
    items: BoardCertificationVM[] | undefined
  ) => {
    items?.map((x, index) => {
      x.position = index;
      return x;
    });
    const newProfile = { ...providerProfile, boardCertifications: items };
    setProviderProfile(newProfile);
    handleProfileChanges(newProfile);
  };

  const handleChangeInCertifications = (
    event: ChangeEvent<HTMLInputElement>,
    items: CertificationVM[] | undefined
  ) => {
    items?.map((x, index) => {
      x.expiration = new Date();
      if (x.expirationYear && x.expirationYear.value) {
        x.expiration.setFullYear(parseInt(x.expirationYear.value.toString()));
        x.expiration.setMonth(0);
        x.expiration.setDate(1);
      } else {
        x.expiration = undefined;
      }

      x.issued = new Date();
      if (x.issuedYear && x.issuedYear.value) {
        x.issued.setFullYear(parseInt(x.issuedYear.value.toString()));
        x.issued.setMonth(0);
        x.issued.setDate(1);
      } else {
        x.issued = undefined;
      }

      x.position = index;
      x.certificationNumber = x.certificationNumber || "";
      return x;
    });
    const newProfile = { ...providerProfile, certifications: items };
    setProviderProfile(newProfile);
    handleProfileChanges(newProfile);
  };

  const handleChangeInProfessionalAssociations = (
    event: ChangeEvent<HTMLInputElement>,
    items: ProfessionalAssociationVM[] | undefined
  ) => {
    items?.map((x, index) => {
      x.position = index;
      return x;
    });
    const newProfile = {
      ...providerProfile,
      professionalAssociations: items,
    };
    setProviderProfile(newProfile);
    handleProfileChanges(newProfile);
  };

  const handleChangeInSchools = (
    event: ChangeEvent<HTMLInputElement>,
    items: SchoolVM[] | undefined
  ) => {
    items?.map((x, index) => {
      x.position = index;
      return x;
    });
    const newProfile = {
      ...providerProfile,
      schools: items,
    };
    setProviderProfile(newProfile);
    handleProfileChanges(newProfile);
  };

  const handleBlur = async (event: FocusEvent<HTMLInputElement>) => {
    let touchedElements: string[] = [];
    if (!touched.includes(event.target.name)) {
      touchedElements = [...touched, event.target.name];
      setTouched(touchedElements);
    } else {
      touchedElements = touched;
    }
    handleBlurEvent(touchedElements);
  };

  useEffect(() => {
    if (currentProviderProfile) {
      setProfile(currentProviderProfile);
    }
  }, [currentProviderProfile]);

  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const imageInDatabaseChangedDescription = (event: any, index: number) => {
    var arr: OfficePhotoVM[] = [];
    const newFiles = [...(providerProfile?.officePhotos || arr)];
    const newElements: OfficePhotoVM[] = newFiles.map((file, idx) => {
      if (idx === index) {
        file.description = event.target.value;
      }
      return file;
    });
    const newProfile = { ...providerProfile, officePhotos: newElements };
    setProviderProfile(newProfile);
    handleProfileChanges(newProfile);
  };

  const imageChangedDescription = (event: any, index: number) => {
    const newFiles = [...officePhotos];
    const newElements = newFiles.map((file, idx) => {
      if (idx === index) {
        file.description = event.target.value;
      }
      return file;
    });
    setOfficePhotos(newElements);
    handleOfficeImageChanges(newElements);
  };

  const highlightBasicsTab = (formerrors: any): boolean => {
    return (
      formerrors &&
      Object.keys(formerrors) &&
      Object.keys(formerrors).find(
        (x) =>
          x.includes("firstName") ||
          x.includes("lastName") ||
          x.includes("designation") ||
          x.includes("otherPronoun") ||
          x.includes("headline") ||
          x.includes("aboutMe") ||
          x.includes("practiceAddress") ||
          x.includes("suite") ||
          x.includes("contactPhoneNumber") ||
          x.includes("sms") ||
          x.includes("selectedSessionTypes") ||
          x.includes("businessHours") ||
          x.includes("timezone") ||
          x.includes("businessEmail") ||
          x.includes("linkedInUrl") ||
          x.includes("twitterUrl") ||
          x.includes("facebookUrl") ||
          x.includes("instagramUrl") ||
          x.includes("websiteUrl") ||
          x.includes("timezone.value")
      )
    );
  };

  const highlightLicensesTab = (formerrors: any): boolean => {
    return (
      formerrors &&
      Object.keys(formerrors) &&
      Object.keys(formerrors).find(
        (x) =>
          x.includes("isLicensed") ||
          x.includes("preLicenseType") ||
          x.includes("supervisorCredentialType") ||
          x.includes("supervisorLicenseNumber") ||
          x.includes("supervisorFirstName") ||
          x.includes("supervisorLastName") ||
          x.includes("supervisorState") ||
          x.includes("supervisorConvertedExpirationMonth") ||
          x.includes("supervisorConvertedExpirationYear") ||
          x.includes("practicingSince") ||
          x.includes("licenses") ||
          x.includes("certifications") ||
          x.includes("boardCertifications") ||
          x.includes("professionalAssociations") ||
          x.includes("schools")
      )
    );
  };

  const highlightMediaTab = (formerrors: any): boolean => {
    return (
      formerrors &&
      Object.keys(formerrors) &&
      Object.keys(formerrors).find(
        (x) =>
          x.includes("youTubeUrl") ||
          x.includes("officePhotos") ||
          x.includes("inMemoryOfficePhotos")
      )
    );
  };

  const highlightClientFocusTab = (formerrors: any): boolean => {
    return (
      formerrors &&
      Object.keys(formerrors) &&
      Object.keys(formerrors).find(
        (x) =>
          x.includes("selectedServiceTypes") ||
          x.includes("isAcceptingNewPatients") ||
          x.includes("selectedClientDemographics") ||
          x.includes("selectedClinicalSpecialties") ||
          x.includes("selectedLanguageTypes") ||
          x.includes("costFrom") ||
          x.includes("costTo") ||
          x.includes("isAcceptingInsurance") ||
          x.includes("selectedInsurancePlans") ||
          x.includes("selectedCreditCards") ||
          x.includes("otherInsurancePlans") ||
          x.includes("selectedPaymentMethodTypes") ||
          x.includes("isSlidingScale") ||
          x.includes("isFreeInitialConsultation")
      )
    );
  };

  const checkboxesCheckedCount = (
    checkboxlist: CheckboxVM[] | undefined
  ): number => {
    let count: number = 0;
    checkboxlist?.forEach((item: CheckboxVM) => {
      if (item.checked) {
        count++;
      }
    });
    return count;
  };

  return (
    <Row className="profile-edit-form">
      <Col md="12" className="px-0">
        <Prompt
          when={isInputsChanged}
          message={
            "Are you sure you want to leave the Edit Profile page? Any unsaved changes will be lost.\r\nClick OK to Proceed. Click Cancel to stay."
          }
        />
        {newFileRejections.length > 0 && activeTab === "3" && !isDropZoneReset && (
          <Row>
            <Col md={12}>
              <Alert color="danger">
                <ul>
                  {newFileRejections.map(({ file, errors }, i) => (
                    <li key={i}>
                      {file.name} - {file.size} bytes
                      <ul>
                        {errors.map((e) => (
                          <li key={e.code}>{e.message}</li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </Alert>
            </Col>
          </Row>
        )}
        <Nav tabs className="profile-edit-nav">
          {activeTab === "1" && <div className="border-filler"></div>}
          <NavItem
            className={classnames({
              active: activeTab === "1",
            })}
          >
            <NavLink
              className={classnames({
                active: activeTab === "1",
                "highlighted-text": highlightBasicsTab(formErrors),
                "nav-link-first": true,
              })}
              onClick={(event: any) => {
                toggle("1");
                saveOnToggle(event, "1");
              }}
              title="1. Basics"
            >
              1. Basics
            </NavLink>
          </NavItem>
          <NavItem
            className={classnames({
              active: activeTab === "2",
            })}
          >
            <NavLink
              className={classnames({
                active: activeTab === "2",
                "highlighted-text": highlightLicensesTab(formErrors),
              })}
              onClick={(event: any) => {
                toggle("2");
                saveOnToggle(event, "2");
              }}
              title="2. Licenses & Certifications"
            >
              2. Licenses & Certifications
            </NavLink>
          </NavItem>
          <NavItem
            className={classnames({
              active: activeTab === "3",
            })}
          >
            <NavLink
              className={classnames({
                active: activeTab === "3",
                "highlighted-text": highlightMediaTab(formErrors),
              })}
              onClick={(event: any) => {
                toggle("3");
                saveOnToggle(event, "3");
              }}
              title="3. Media"
            >
              3. Media
            </NavLink>
          </NavItem>
          <NavItem
            className={classnames({
              active: activeTab === "4",
            })}
          >
            <NavLink
              className={classnames({
                active: activeTab === "4",
                "highlighted-text": highlightClientFocusTab(formErrors),
              })}
              onClick={(event: any) => {
                toggle("4");
                saveOnToggle(event, "4");
              }}
              title="4. Services, Client Focus & Fees"
            >
              4. Services, Client Focus & Fees
            </NavLink>
          </NavItem>
          <NavItem
            className={classnames({
              active: activeTab === "5",
            })}
          >
            <NavLink
              className={classnames({ active: activeTab === "5" })}
              onClick={(event: any) => {
                toggle("5");
                saveOnToggle(event, "5");
              }}
              title={
                providerProfile?.profileStatus?.value === 10 ||
                providerProfile?.profileStatus?.value === 15 ||
                providerProfile?.profileStatus?.value === 20
                  ? "5. Review & Submit"
                  : "5. Review & Publish"
              }
            >
              5.{" "}
              {providerProfile?.profileStatus?.value === 10 ||
              providerProfile?.profileStatus?.value === 15 ||
              providerProfile?.profileStatus?.value === 20
                ? "Review & Submit"
                : "Review & Publish"}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="box profile-edit-tab">
          <TabPane tabId="1">
            <div className="icon-indicator pt-3 pb-4 mt-2">
              <FontAwesomeIcon
                icon={Icon.faAsterisk}
                className="required-field-indicator"
                size="xs"
              />
              <span className="pr-5">
                &nbsp;&nbsp;Indicates a required field
              </span>
              <VisibilityOffIcon />
              &nbsp;&nbsp; Indicates which data will not be visible on your
              public profile
            </div>
            <Row>
              <Col lg="4" md="6">
                <TextInput
                  maxLength={255}
                  labelName="First Name"
                  requiredField={true}
                  labelClass={classnames({
                    "highlighted-text": isNotUndefined(formErrors.firstName),
                  })}
                  className={classnames({
                    highlighted: isNotUndefined(formErrors.firstName),
                  })}
                  error={formErrors.firstName}
                  formGroupClass={classnames({
                    "highlighted-text": isNotUndefined(formErrors.firstName),
                  })}
                  fieldText={providerProfile?.firstName || ""}
                  fieldName="firstName"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                ></TextInput>
              </Col>
              <Col lg="4" md="6">
                <TextInput
                  maxLength={255}
                  labelName="Last Name"
                  requiredField={true}
                  labelClass={classnames({
                    "highlighted-text": isNotUndefined(formErrors.lastName),
                  })}
                  className={classnames({
                    highlighted: isNotUndefined(formErrors.lastName),
                  })}
                  error={formErrors.lastName}
                  fieldText={providerProfile?.lastName || ""}
                  fieldName="lastName"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                ></TextInput>
              </Col>
              <Col lg="4" md="6">
                <TextInput
                  maxLength={50}
                  labelName="Professional Designation Acronym"
                  tooltipMessage="Enter your credentials as you'd like them to appear after your name, ie., PsyD, PhD, MA, LPC, etc."
                  className={classnames({
                    highlighted: isNotUndefined(formErrors.designation),
                  })}
                  labelClass={classnames({
                    "highlighted-text": isNotUndefined(formErrors.designation),
                  })}
                  error={formErrors.designation}
                  fieldText={providerProfile?.designation || ""}
                  fieldName="designation"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                ></TextInput>
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="6">
                <DataDropDown
                  styles={dropDownStyle}
                  values={[
                    ...Object.values(providerProfile?.genderTypes || []),
                  ]}
                  selectedValue={providerProfile?.genderType}
                  handleChange={handleChangeInDropDown}
                  fieldName="genderType"
                  fieldText="Gender"
                  className={classnames({ selectControl: true })}
                  visibilityOff={true}
                  tooltipMessage="Consider identifying your gender because sometimes clients filter their search by gender."
                ></DataDropDown>
              </Col>
              <Col lg="4" md="6">
                <DataDropDown
                  styles={dropDownStyle}
                  values={[
                    ...Object.values(providerProfile?.pronounTypes || []),
                  ]}
                  selectedValue={providerProfile?.pronounType}
                  handleChange={handleChangeInDropDown}
                  fieldName="pronounType"
                  fieldText="Pronoun"
                  className={classnames({ selectControl: true })}
                ></DataDropDown>
              </Col>
              {providerProfile &&
                providerProfile.pronounType &&
                providerProfile?.pronounTypes?.filter(
                  (p) => p.value === providerProfile?.pronounType?.value
                )[0].label === "Other" && (
                  <Col lg="4" md="6">
                    <TextInput
                      maxLength={100}
                      showLabel={true}
                      labelName="&nbsp;"
                      placeholder="Add pronoun"
                      requiredField={false}
                      className={classnames({
                        highlighted:
                          isNotUndefined(formErrors.otherPronoun) ||
                          isNotUndefined(formErrors.otherPronoun),
                      })}
                      fieldText={currentProviderProfile?.otherPronoun || ""}
                      fieldName="otherPronoun"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                    ></TextInput>
                    <ErrorMessage
                      visible={
                        isNotUndefined(formErrors[formErrors.otherPronoun]) ||
                        isNotUndefined(formErrors.otherPronoun)
                      }
                      message={formErrors.otherPronoun}
                    />
                  </Col>
                )}
            </Row>
            <Row>
              <Col lg="4" md="6">
                <DataDropDown
                  styles={dropDownStyle}
                  values={[...Object.values(providerProfile?.races || [])]}
                  selectedValue={providerProfile?.race}
                  handleChange={handleChangeInDropDown}
                  fieldName="race"
                  fieldText="Race"
                  className={classnames({ selectControl: true })}
                  visibilityOff={true}
                  tooltipMessage="Consider identifying your race because sometimes clients filter their search by race."
                ></DataDropDown>
              </Col>
              <Col lg="4" md="6">
                <DataDropDown
                  styles={dropDownStyle}
                  values={[
                    ...Object.values(providerProfile?.ethnicities || []),
                  ]}
                  selectedValue={providerProfile?.ethnicity}
                  handleChange={handleChangeInDropDown}
                  fieldName="ethnicity"
                  fieldText="Ethnicity"
                  className={classnames({ selectControl: true })}
                  visibilityOff={true}
                  tooltipMessage="Consider identifying your ethnicity because sometimes clients filter their search by ethnicity."
                ></DataDropDown>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>
                    <span
                      className={classnames({
                        "highlighted-text": isNotUndefined(
                          formErrors.profileImage
                        ),
                      })}
                    >
                      Profile Image
                    </span>{" "}
                    <span className="help-text-button">
                      <FontAwesomeIcon
                        icon={IconRegular.faQuestionCircle}
                        className="visible shape"
                      />
                      <span className="help-text">{imageRecommendations}</span>
                    </span>
                  </Label>
                  <div className="row">
                    <div className="profile-image-col ml-3">
                      <div className="profile-image-wrapper rounded">
                        {activeImage && activeImage !== undefined ? (
                          <>
                            <img
                              id="profileImage"
                              alt="Provider Profile"
                              src={activeImage}
                            />
                            <FontAwesomeIcon
                              icon={Icon.faTimesCircle}
                              color="white"
                              className="blue-2 ml-n4 white-radial-gradient align-top"
                              viewBox="0 0 512 512"
                              size="lg"
                              onClick={removeProfilePhoto}
                            />
                          </>
                        ) : (
                          <Logo />
                        )}
                      </div>
                    </div>
                    <div className="pl-3 pt-2 col-md-6 normal-font gray-text d-flex align-items-center">
                      <ImageEditModal
                        buttonLabel="Upload image"
                        buttonClass="update-button rounded"
                        activeImage={activeImage}
                        handleNewCroppedImage={handleNewCroppedImage}
                        returnType={ImageDataReturnType.Base64String}
                        defaultAspect={1 / 1}
                        allowAspectChange={false}
                      ></ImageEditModal>
                    </div>
                  </div>
                  <ErrorMessage
                    visible={isNotUndefined(formErrors.profileImage)}
                    message={formErrors.profileImage}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <TextAreaInput
                  maxLength={1000}
                  rows={2}
                  labelName="Professional Statement"
                  subText={[
                    "Write a brief introduction statement that will appear as a headline above your About Me section. For example, ",
                    <i>
                      I am an experienced child psychologist who is dedicated to
                      helping children cope with anxiety, depression, and anger.
                    </i>,
                  ]}
                  className={classnames({
                    highlighted: isNotUndefined(formErrors.headline),
                  })}
                  labelClass={classnames({
                    "highlighted-text": isNotUndefined(formErrors.headline),
                  })}
                  error={formErrors.headline}
                  fieldText={providerProfile?.headline || ""}
                  fieldName="headline"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                ></TextAreaInput>
              </Col>
              <Col md="12">
                <TextAreaInput
                  maxLength={aboutMeLength}
                  formClassName={classnames({
                    "mb-0": true,
                  })}
                  rows={4}
                  inputClassName={classnames({
                    highlighted: isNotUndefined(formErrors.aboutMe),
                  })}
                  subText={[
                    "Let clients get to know you! This is where you'll describe yourself, your practice, and what you have to offer prospective clients.",
                  ]}
                  labelName="About Me"
                  fieldText={providerProfile?.aboutMe || ""}
                  fieldName="aboutMe"
                  labelClass={classnames({
                    "highlighted-text": isNotUndefined(formErrors.aboutMe),
                  })}
                  error={formErrors.aboutMe}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                ></TextAreaInput>{" "}
                <Label
                  className={classnames({
                    "character-limit": true,
                    red: characterCount < 0,
                    "font-weight-bold": characterCount < 0,
                  })}
                >
                  Remaining character count:{" "}
                  {Helper.formatMoney(characterCount, 0)}
                </Label>
              </Col>
            </Row>
            <Row>
              <Col md="8" className="mt-1">
                <FormGroup className="mb-2">
                  <Label
                    className={classnames({
                      "highlighted-text": isNotUndefined(
                        formErrors.selectedSessionTypes
                      ),
                    })}
                  >
                    Session Type
                    <RequiredField />
                  </Label>
                  <Row>
                    {providerProfile?.sessionTypes?.map(
                      (item: any, index: any) => (
                        <Col
                          xl="3"
                          lg="4"
                          key={item.value}
                          className="ml-4 wrap-around profile-checkbox-item"
                        >
                          <CustomCheckbox
                            className={classnames("light-font", {
                              "highlighted-text": isNotUndefined(
                                formErrors.selectedSessionTypes
                              ),
                            })}
                            key={item.value}
                            index={index}
                            text={item.label || ""}
                            checked={item.checked || false}
                            fieldName={item.label || ""}
                            handleChange={(ev: any) => {
                              handleChangeInMultipleCheckboxes(
                                ev,
                                "sessionTypes",
                                "selectedSessionTypes"
                              );
                              handleBlurEvent([item.label || ""]);
                            }}
                          ></CustomCheckbox>
                        </Col>
                      )
                    )}
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="mt-4">
                <Label className="thirdTitle">Business Contact</Label>
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="6">
                <TextInput
                  maxLength={100}
                  labelName="Business Name"
                  requiredField={false}
                  fieldText={providerProfile?.businessName || ""}
                  fieldName="businessName"
                  className={classnames({
                    highlighted: isNotUndefined(formErrors.businessName),
                  })}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  labelClass={classnames({
                    "highlighted-text": isNotUndefined(formErrors.businessName),
                  })}
                  error={formErrors.businessName}
                ></TextInput>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <FormGroup>
                  <Label
                    className={classnames({
                      "highlighted-text": isNotUndefined(
                        formErrors.practiceAddress
                      ),
                    })}
                  >
                    Business Address
                    {(providerProfile?.selectedSessionTypes?.find(
                      (x: any) => x.label === "In Person"
                    ) ||
                      providerProfile?.suite) && <RequiredField />}
                    <span className="help-text-button">
                      <FontAwesomeIcon
                        icon={IconRegular.faQuestionCircle}
                        className="visible shape"
                      />
                      <span className="help-text">
                        List your business address to make it easier for clients
                        to find your practice's location. Business address must
                        include city and state or zip code. P.O. boxes cannot be
                        used.
                      </span>
                    </span>
                  </Label>
                  <GooglePlaces
                    autocompletionRequest={{
                      types: ["geocode"],
                      componentRestrictions: { country: [] },
                    }}
                    inputText={providerProfile?.practiceAddress}
                    savedValue={providerProfile?.practiceAddress}
                    apiKey={googleMapsKey}
                    handleBlur={handleBlur}
                    onSelected={(e: MapLocation) => {
                      setaddressChanged(true);
                      setAddressValue(e.value.description);
                      handleChangeInMap(e, "practiceAddress");
                    }}
                    selectProps={{
                      styles: {
                        control: (provided) => ({
                          ...provided,
                          border: isNotUndefined(formErrors.practiceAddress)
                            ? redBorder
                            : grayBorder,
                          backgroundColor: isNotUndefined(
                            formErrors.practiceAddress
                          )
                            ? red
                            : white,
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: blueish,
                        }),
                        option: (provided) => ({
                          ...provided,
                          color: blueish,
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: blueish,
                        }),
                        indicatorsContainer: (base: any) => ({
                          display: "none",
                        }),
                      },
                    }}
                  />
                  <ErrorMessage
                    visible={isNotUndefined(formErrors.practiceAddress)}
                    message={formErrors.practiceAddress}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <TextInput
                  maxLength={50}
                  labelName="Suite"
                  requiredField={false}
                  fieldText={providerProfile?.suite || ""}
                  fieldName="suite"
                  className={classnames({
                    highlighted: isNotUndefined(formErrors.suite),
                  })}
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  labelClass={classnames({
                    "highlighted-text": isNotUndefined(formErrors.suite),
                  })}
                  error={formErrors.suite}
                ></TextInput>
              </Col>
            </Row>
            <Row className="pb-1">
              <Col md="5" className="profile-checkbox-item">
                <CustomCheckbox
                  className="light-font"
                  hideEmptyLabel={true}
                  text="Hide Business Name & Address from Public Profile"
                  checked={providerProfile?.hidePracticeAddress || false}
                  fieldName="hidePracticeAddress"
                  handleChange={handleChange}
                ></CustomCheckbox>
              </Col>
              <Col md="6" className="profile-checkbox-item">
                <CustomCheckbox
                  className="light-font"
                  hideEmptyLabel={true}
                  text="Yes, my office is ADA accessible"
                  checked={providerProfile?.officeIsAdaAccessible || false}
                  fieldName="officeIsAdaAccessible"
                  handleChange={handleChange}
                ></CustomCheckbox>
              </Col>
            </Row>
            <Row>
              <Col md="8" className="gmaps-image-wrapper pb-2">
                {providerProfile?.practiceAddress &&
                 addressValue ? (
                  <div className="gmaps-image">
                    <StaticGoogleMap
                      size="280x198"
                      zoom="13"
                      center={encodeURIComponent(addressValue)}
                      className="img-fluid"
                      apiKey={googleMapsKey}
                      scale="2"
                    >
                      <Marker
                        location={encodeURIComponent(addressValue)}
                        color="red"
                        size="mid"
                        label={encodeURIComponent(addressValue)}
                      />
                    </StaticGoogleMap>
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="6">
                <InputWithIcon
                  className={{
                    highlighted: isNotUndefined(formErrors.businessEmail),
                    "pl-5": true,
                  }}
                  labelClass={classnames({
                    "highlighted-text": isNotUndefined(
                      formErrors.businessEmail
                    ),
                  })}
                  visibilityOff={true}
                  error={formErrors.businessEmail}
                  requiredField={true}
                  labelName="Business Email"
                  fieldText={providerProfile?.businessEmail || ""}
                  fieldName="businessEmail"
                  handleChange={handleChange}
                  icon={IconRegular.faEnvelope}
                  handleBlur={handleBlur}
                  tooltipMessage="We do not publish this email address to your public profile. Potential clients can click a link that will email the address you provide. This email does not need to match your therapist.com log in email address."
                ></InputWithIcon>
              </Col>
              <Col lg="4" md="6">
                <FormGroup className="input-field-icons">
                  <div>
                    <Label
                      className={classnames({
                        "highlighted-text": isNotUndefined(
                          formErrors.contactPhoneNumber
                        ),
                      })}
                    >
                      Business Phone Number
                    </Label>
                  </div>
                  <FontAwesomeIcon
                    icon={Icon.faPhone}
                    size="sm"
                    className="ml-2 mt-2"
                  />
                  <NumberFormat
                    name="contactPhoneNumber"
                    className={classnames({
                      highlighted: isNotUndefined(
                        formErrors.contactPhoneNumber
                      ),
                      "input-field": true,
                      "form-control": true,
                      "pl-5": true,
                    })}
                    defaultValue={providerProfile?.contactPhoneNumber}
                    value={providerProfile?.contactPhoneNumber}
                    allowEmptyFormatting
                    format="(###) ###-####"
                    mask="_"
                    onValueChange={(selected) => {
                      let event: any = {};
                      event.target = {};
                      event.target.name = "contactPhoneNumber";
                      event.target.inputMode = "numeric";
                      event.target.value = selected.value;
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                  ></NumberFormat>
                  <ErrorMessage
                    visible={isNotUndefined(formErrors.contactPhoneNumber)}
                    message={formErrors.contactPhoneNumber}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="6" className="mb-2">
                <FormGroup className="input-field-icons mb-4">
                  <div>
                    <Label
                      className={classnames({
                        "highlighted-text": isNotUndefined(formErrors.sms),
                      })}
                    >
                      Text Message
                      <span className="help-text-button">
                        <FontAwesomeIcon
                          icon={IconRegular.faQuestionCircle}
                          className="visible shape"
                        />
                        <span className="help-text">
                          If you'd like potential clients to contact you via
                          text message, enter that number here.
                        </span>
                      </span>
                    </Label>
                  </div>
                  <FontAwesomeIcon
                    icon={Icon.faMobileAlt}
                    size="sm"
                    className="ml-2 mt-2"
                  />
                  <NumberFormat
                    name="sms"
                    className={classnames({
                      highlighted: isNotUndefined(formErrors.sms),
                      "input-field": true,
                      "form-control": true,
                      "pl-5": true,
                    })}
                    defaultValue={providerProfile?.sms}
                    value={providerProfile?.sms}
                    allowEmptyFormatting
                    format="(###) ###-####"
                    mask="_"
                    onValueChange={(selected) => {
                      let event: any = {};
                      event.target = {};
                      event.target.name = "sms";
                      event.target.inputMode = "numeric";
                      event.target.value = selected.value;
                      handleChange(event);
                    }}
                  ></NumberFormat>
                  <div className="character-limit rates">
                    Message and data rates may apply
                  </div>
                  <ErrorMessage
                    visible={isNotUndefined(formErrors.sms)}
                    message={formErrors.sms}
                  />
                </FormGroup>
              </Col>
            </Row>
            {enableBusinessHoursSection && (
              <Fragment>
                <Row>
                  <Col>
                    <Label
                      className={classnames({
                        thirdTitle: true,
                        "highlighted-text": isNotUndefined(
                          formErrors.businessHours
                        )
                          ? true
                          : false,
                      })}
                    >
                      Hours of Operation
                    </Label>
                  </Col>
                </Row>
                {(providerProfile?.businessHours?.length || 0) > 0 && (
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label
                          className={classnames({
                            "highlighted-text":
                              isNotUndefined(formErrors.businessHours) ||
                              isNotUndefined(formErrors.businessHoursOnMonday),
                          })}
                        >
                          Tell Us the Days and Times You're Open
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="5">
                      <FormGroup>
                        <Label
                          className={classnames({
                            "highlighted-text": isNotUndefined(
                              formErrors["businessHoursStartTime"]
                            ),
                          })}
                        >
                          Start time
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="5">
                      <FormGroup>
                        <Label
                          className={classnames({
                            "highlighted-text": isNotUndefined(
                              formErrors["businessHoursEndTime"]
                            ),
                          })}
                        >
                          End time
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <ErrorMessage
                  visible={isNotUndefined(formErrors["businessHours"])}
                  message={formErrors["businessHours"]}
                />
                <BusinessHours
                  formErrors={formErrors}
                  elements={providerProfile?.businessHours}
                  handleChange={handleChangeInBusinessHours}
                ></BusinessHours>
                {!businessHoursIsEmpty && (
                  <Row>
                    <Col md="4" sm="12">
                      <DataDropDown
                        fieldName="timezone"
                        fieldText="Time zone"
                        hideLabel={false}
                        requiredField={true}
                        values={providerProfile?.timezones || []}
                        styles={{
                          control: (provided: any) => ({
                            ...provided,
                            border:
                              isNotUndefined(formErrors.timezone) ||
                              isNotUndefined(formErrors["timezone.value"])
                                ? redBorder
                                : blueBorder,
                            backgroundColor:
                              isNotUndefined(formErrors.timezone) ||
                              isNotUndefined(formErrors["timezone.value"])
                                ? red
                                : white,
                          }),
                          dropdownIndicator: (provided: any) => ({
                            ...provided,
                            color: darkBlue,
                          }),
                        }}
                        selectedValue={providerProfile?.timezone}
                        handleChange={handleChangeInDropDown}
                        className={classnames({
                          selectControl: true,
                        })}
                        labelClass={classnames({
                          "highlighted-text":
                            isNotUndefined(formErrors.timezone) ||
                            isNotUndefined(formErrors["timezone.value"]),
                        })}
                        error={
                          formErrors.timezone || formErrors["timezone.value"]
                        }
                        onBlur={handleBlur}
                      ></DataDropDown>
                    </Col>
                  </Row>
                )}
                <hr></hr>
              </Fragment>
            )}
            <Row>
              <Col>
                <Label className="thirdTitle">Website &amp; Social Media</Label>
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="6">
                <InputWithIcon
                  className={{
                    highlighted: isNotUndefined(formErrors.websiteUrl),
                    "pl-5": true,
                  }}
                  labelClass={classnames({
                    "highlighted-text": isNotUndefined(formErrors.websiteUrl),
                  })}
                  error={formErrors.websiteUrl}
                  labelName="Website"
                  fieldText={providerProfile?.websiteUrl || ""}
                  fieldName="websiteUrl"
                  handleChange={handleChange}
                  icon={Icon.faGlobe}
                  handleBlur={handleBlur}
                  placeholder="www.yourwebsite.com"
                ></InputWithIcon>
              </Col>
              <Col lg="4" md="6">
                <InputWithIcon
                  className={{
                    highlighted: isNotUndefined(formErrors.linkedInUrl),
                    "pl-5": true,
                  }}
                  labelClass={classnames({
                    "highlighted-text": isNotUndefined(formErrors.linkedInUrl),
                  })}
                  error={formErrors.linkedInUrl}
                  labelName="LinkedIn"
                  fieldText={providerProfile?.linkedInUrl || ""}
                  fieldName="linkedInUrl"
                  handleChange={handleChange}
                  icon={BrandIcon.faLinkedin}
                  handleBlur={handleBlur}
                  placeholder="www.linkedin.com/in/myprofileid"
                ></InputWithIcon>
              </Col>
              <Col lg="4" md="6">
                <InputWithIcon
                  className={{
                    highlighted: isNotUndefined(formErrors.twitterUrl),
                    "pl-5": true,
                  }}
                  labelClass={classnames({
                    "highlighted-text": isNotUndefined(formErrors.twitterUrl),
                  })}
                  error={formErrors.twitterUrl}
                  labelName="Twitter"
                  fieldText={providerProfile?.twitterUrl || ""}
                  fieldName="twitterUrl"
                  placeholder="www.twitter.com/myhandle"
                  handleChange={handleChange}
                  icon={BrandIcon.faTwitter}
                  handleBlur={handleBlur}
                ></InputWithIcon>
              </Col>
              <Col lg="4" md="6">
                <InputWithIcon
                  className={{
                    highlighted: isNotUndefined(formErrors.instagramUrl),
                    "pl-5": true,
                  }}
                  labelClass={classnames({
                    "highlighted-text": isNotUndefined(formErrors.instagramUrl),
                  })}
                  error={formErrors.instagramUrl}
                  labelName="Instagram"
                  fieldText={providerProfile?.instagramUrl || ""}
                  fieldName="instagramUrl"
                  handleChange={handleChange}
                  icon={BrandIcon.faInstagram}
                  handleBlur={handleBlur}
                  placeholder="www.instagram.com/myusername/"
                ></InputWithIcon>
              </Col>
              <Col lg="4" md="6">
                <InputWithIcon
                  className={{
                    highlighted: isNotUndefined(formErrors.facebookUrl),
                    "pl-5": true,
                  }}
                  labelClass={classnames({
                    "highlighted-text": isNotUndefined(formErrors.facebookUrl),
                  })}
                  error={formErrors.facebookUrl}
                  labelName="Facebook"
                  fieldText={providerProfile?.facebookUrl || ""}
                  fieldName="facebookUrl"
                  handleChange={handleChange}
                  icon={BrandIcon.faFacebook}
                  handleBlur={handleBlur}
                  placeholder="www.facebook.com/myprofile"
                ></InputWithIcon>
              </Col>
            </Row>
            <Row></Row>
            <Row></Row>
          </TabPane>
          <TabPane tabId="2">
            <Row className="pb-2">
              <Col md="11">
                <div className="pt-2 pb-2 mt-2 regular-font">
                  All professional licenses will be verified before a profile is
                  publicly displayed.
                </div>
                <div>
                  <Label
                    className={classnames({
                      "highlighted-text": isNotUndefined(formErrors.isLicensed),
                      "regular-font": true,
                    })}
                  >
                    Please choose one <RequiredField />
                  </Label>
                  <div className="regular-font">
                    <FormGroup check>
                      <CustomInput
                        type="radio"
                        id="isLicensed"
                        name="isLicensed"
                        label={
                          <Fragment>
                            <strong>
                              I have a professional license that is current, in
                              good standing, and allows me to practice
                              independently.
                            </strong>
                            <span className="d-inline d-md-none">
                              <button
                                type="button"
                                id="professionalLicenseExplainToggler"
                                className="btn btn-link btn-info-toggle"
                                data-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="professionalLicenseExplain"
                              >
                                <FontAwesomeIcon
                                  icon={IconRegular.faQuestionCircle}
                                  className="visible shape"
                                />
                              </button>
                            </span>
                            <br />
                            <UncontrolledCollapse
                              toggler="professionalLicenseExplainToggler"
                              id="professionalLicenseExplain"
                              className="d-md-block"
                            >
                              Select this option if you have completed all
                              required clinical supervision hours, have passed
                              the licensing exam, and are licensed to practice
                              independently. If you are unsure, please contact
                              your board for verification before moving forward
                              with building your clinician profile.
                            </UncontrolledCollapse>
                          </Fragment>
                        }
                        checked={providerProfile?.isLicensed === true}
                        onChange={(event: any) => {
                          event.target.value = true;
                          radioButtonChange(event);
                        }}
                      ></CustomInput>
                      <CustomInput
                        type="radio"
                        id="isNotLicensed"
                        className="mt-2"
                        name="isLicensed"
                        label={
                          <Fragment>
                            <strong>
                              I am currently under clinical supervision and/or
                              completing my residency toward clinical/full
                              licensure.
                            </strong>
                            <span className="d-inline d-md-none">
                              <button
                                type="button"
                                id="clinicalSupervisionExplainToggler"
                                className="btn btn-link btn-info-toggle"
                                data-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="clinicalSupervisionExplain"
                              >
                                <FontAwesomeIcon
                                  icon={IconRegular.faQuestionCircle}
                                  className="visible shape"
                                />
                              </button>
                            </span>
                            <br />
                            <UncontrolledCollapse
                              toggler="clinicalSupervisionExplainToggler"
                              id="clinicalSupervisionExplain"
                              className="d-md-block"
                            >
                              Select this option if you are completing your
                              clinical supervision hours, are provisionally
                              licensed, and/or are practicing under a clinical
                              supervisor's license. If you are unsure, please
                              contact your board for verification before moving
                              forward with building your clinician profile.
                              Note: Clinicians under supervision are responsible
                              for disclosing their supervision status and any
                              professional limitations to potential clients as
                              indicated in their respective profession's Code of
                              Ethics.
                            </UncontrolledCollapse>
                          </Fragment>
                        }
                        checked={providerProfile?.isLicensed === false}
                        onChange={(event: any) => {
                          event.target.value = false;
                          radioButtonChange(event);
                        }}
                      ></CustomInput>
                    </FormGroup>
                  </div>
                  <ErrorMessage
                    visible={isNotUndefined(formErrors.isLicensed)}
                    message={formErrors.isLicensed}
                  />
                </div>
              </Col>
            </Row>
            {providerProfile?.isLicensed === false ? (
              <Fragment>
                <Row>
                  <Col>
                    <Label
                      className={classnames({
                        thirdTitle: true,
                      })}
                    >
                      Professional License <RequiredField />
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col lg="4" md="6">
                        <DataDropDown
                          tooltipMessage={
                            licenseInformationMessage +
                            `<a href="mailto:${customerSupportContactEmail}" target="_blank">${customerSupportContactEmail}</a>.`
                          }
                          fieldName="preLicenseType"
                          fieldText="Pre-license Type"
                          hideLabel={false}
                          requiredField={true}
                          values={providerProfile?.preLicenseTypes || []}
                          styles={{
                            control: (provided: any) => ({
                              ...provided,
                              border: isNotUndefined(
                                formErrors["preLicenseType"]
                              )
                                ? redBorder
                                : blueBorder,
                              backgroundColor: isNotUndefined(
                                formErrors["preLicenseType"]
                              )
                                ? red
                                : white,
                            }),
                            dropdownIndicator: (provided: any) => ({
                              ...provided,
                              color: darkBlue,
                            }),
                          }}
                          selectedValue={providerProfile?.preLicenseType}
                          handleChange={handleChangeInDropDown}
                          labelClass={classnames({
                            "highlighted-text": isNotUndefined(
                              formErrors["preLicenseType"]
                            ),
                          })}
                          error={formErrors["preLicenseType"]}
                          className={classnames({
                            selectControl: true,
                          })}
                          onBlur={handleBlur}
                        ></DataDropDown>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4" md="6">
                        <TextInput
                          maxLength={50}
                          labelName="Clinical Supervisor First Name"
                          className={classnames({
                            highlighted: isNotUndefined(
                              formErrors?.supervisorFirstName
                            ),
                          })}
                          visibilityOff={true}
                          requiredField={true}
                          fieldText={providerProfile?.supervisorFirstName || ""}
                          fieldName="supervisorFirstName"
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          labelClass={classnames({
                            "highlighted-text": isNotUndefined(
                              formErrors.supervisorFirstName
                            ),
                          })}
                          error={formErrors.supervisorFirstName}
                        ></TextInput>
                      </Col>
                      <Col lg="4" md="6">
                        <TextInput
                          maxLength={50}
                          labelName="Clinical Supervisor Last Name"
                          className={classnames({
                            highlighted: isNotUndefined(
                              formErrors.supervisorLastName
                            ),
                          })}
                          visibilityOff={true}
                          requiredField={true}
                          fieldText={providerProfile?.supervisorLastName || ""}
                          fieldName="supervisorLastName"
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          labelClass={classnames({
                            "highlighted-text": isNotUndefined(
                              formErrors.supervisorLastName
                            ),
                          })}
                          error={formErrors.supervisorLastName}
                        ></TextInput>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4" md="6">
                        <DataDropDown
                          tooltipMessage={
                            licenseInformationMessage +
                            `<a href="mailto:${customerSupportContactEmail}" target="_blank">${customerSupportContactEmail}</a>.`
                          }
                          fieldName="supervisorCredentialType"
                          fieldText="Clinical Supervisor Credential Type"
                          hideLabel={false}
                          requiredField={true}
                          visibilityOff={true}
                          values={
                            providerProfile?.supervisorCredentialTypes || []
                          }
                          styles={{
                            control: (provided: any) => ({
                              ...provided,
                              border: isNotUndefined(
                                formErrors["supervisorCredentialType"]
                              )
                                ? redBorder
                                : blueBorder,
                              backgroundColor: isNotUndefined(
                                formErrors["supervisorCredentialType"]
                              )
                                ? red
                                : white,
                            }),
                            dropdownIndicator: (provided: any) => ({
                              ...provided,
                              color: darkBlue,
                            }),
                          }}
                          selectedValue={
                            providerProfile?.supervisorCredentialType
                          }
                          labelClass={classnames({
                            "highlighted-text": isNotUndefined(
                              formErrors["supervisorCredentialType"]
                            ),
                          })}
                          handleChange={handleChangeInDropDown}
                          className={classnames({
                            selectControl: true,
                          })}
                          onBlur={handleBlur}
                          error={formErrors["supervisorCredentialType"]}
                        ></DataDropDown>
                      </Col>
                      <Col lg="4" md="6">
                        <TextInput
                          maxLength={100}
                          visibilityOff={true}
                          labelName="Clinical Supervisor License Number"
                          className={classnames({
                            highlighted: isNotUndefined(
                              formErrors?.supervisorLicenseNumber
                            ),
                          })}
                          requiredField={true}
                          fieldText={
                            providerProfile?.supervisorLicenseNumber || ""
                          }
                          fieldName="supervisorLicenseNumber"
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          labelClass={classnames({
                            "highlighted-text": isNotUndefined(
                              formErrors.supervisorLicenseNumber
                            ),
                          })}
                          error={formErrors.supervisorLicenseNumber}
                        ></TextInput>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4" md="6">
                        <DataDropDown
                          fieldName="supervisorState"
                          fieldText="Clinical Supervisor License State"
                          hideLabel={false}
                          styles={{
                            control: (provided: any) => ({
                              ...provided,
                              border: isNotUndefined(
                                formErrors["supervisorState"]
                              )
                                ? redBorder
                                : blueBorder,
                              backgroundColor: isNotUndefined(
                                formErrors["supervisorState"]
                              )
                                ? red
                                : white,
                            }),
                            dropdownIndicator: (provided: any) => ({
                              ...provided,
                              color: darkBlue,
                            }),
                          }}
                          requiredField={true}
                          values={providerProfile?.states || []}
                          selectedValue={
                            providerProfile.supervisorState || null
                          }
                          labelClass={classnames({
                            "highlighted-text": isNotUndefined(
                              formErrors["supervisorState"]
                            ),
                          })}
                          handleChange={handleChangeInDropDown}
                          className={classnames({
                            selectControl: true,
                          })}
                          onBlur={handleBlur}
                          error={formErrors.supervisorState}
                        ></DataDropDown>
                      </Col>
                      <Col lg="4" md="6">
                        <DataDropDown
                          fieldName="supervisorConvertedExpirationMonth"
                          fieldText="Clinical Supervisor License Expiration Month"
                          hideLabel={false}
                          values={months}
                          visibilityOff={true}
                          requiredField={true}
                          styles={{
                            control: (provided: any) => ({
                              ...provided,
                              border: isNotUndefined(
                                formErrors["supervisorConvertedExpirationMonth"]
                              )
                                ? redBorder
                                : blueBorder,
                              backgroundColor: isNotUndefined(
                                formErrors["supervisorConvertedExpirationMonth"]
                              )
                                ? red
                                : white,
                            }),
                            dropdownIndicator: (provided: any) => ({
                              ...provided,
                              color: darkBlue,
                            }),
                          }}
                          selectedValue={
                            providerProfile?.supervisorConvertedExpirationMonth
                          }
                          labelClass={classnames({
                            "highlighted-text": isNotUndefined(
                              formErrors["supervisorConvertedExpirationMonth"]
                            ),
                          })}
                          handleChange={handleChangeInDropDown}
                          className={classnames({
                            selectControl: true,
                          })}
                          onBlur={handleBlur}
                          error={formErrors.supervisorConvertedExpirationMonth}
                        ></DataDropDown>
                      </Col>
                      <Col lg="4" md="6">
                        <DataDropDown
                          fieldName="supervisorConvertedExpirationYear"
                          fieldText="Clinical Supervisor License Expiration Year"
                          hideLabel={false}
                          visibilityOff={true}
                          styles={{
                            control: (provided: any) => ({
                              ...provided,
                              border: isNotUndefined(
                                formErrors["supervisorConvertedExpirationYear"]
                              )
                                ? redBorder
                                : blueBorder,
                              backgroundColor: isNotUndefined(
                                formErrors["supervisorConvertedExpirationYear"]
                              )
                                ? red
                                : white,
                            }),
                            dropdownIndicator: (provided: any) => ({
                              ...provided,
                              color: darkBlue,
                            }),
                          }}
                          requiredField={true}
                          values={years}
                          selectedValue={
                            providerProfile?.supervisorConvertedExpirationYear
                          }
                          labelClass={classnames({
                            "highlighted-text": isNotUndefined(
                              formErrors["supervisorConvertedExpirationYear"]
                            ),
                          })}
                          handleChange={handleChangeInDropDown}
                          className={classnames({
                            selectControl: true,
                          })}
                          onBlur={handleBlur}
                          error={formErrors.supervisorConvertedExpirationYear}
                        ></DataDropDown>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="8">
                        <InputWithIcon
                          className={{
                            highlighted:
                              isNotUndefined(
                                formErrors.supervisorLicensingBoardUrl
                              ) ||
                              isNotUndefined(
                                formErrors.SupervisorLicensingBoardUrl
                              ),
                            "pl-5": true,
                          }}
                          visibilityOff={true}
                          requiredField={false}
                          labelClass={classnames({
                            "highlighted-text":
                              isNotUndefined(
                                formErrors.supervisorLicensingBoardUrl
                              ) ||
                              isNotUndefined(
                                formErrors.SupervisorLicensingBoardUrl
                              ),
                          })}
                          error={
                            formErrors.supervisorLicensingBoardUrl ||
                            formErrors.SupervisorLicensingBoardUrl
                          }
                          tooltipMessage={
                            "License status must be verified by therapist.com before your profile can be activated. Please help expedite this process by providing the website of the state board(s) issuing your license(s)."
                          }
                          labelName="Clinical Supervisor Licensing Board Website"
                          fieldText={
                            providerProfile?.supervisorLicensingBoardUrl || ""
                          }
                          fieldName="supervisorLicensingBoardUrl"
                          placeholder="https://www.bhec.texas.gov/verify-a-license/"
                          handleChange={handleChange}
                          icon={Icon.faGlobe}
                          handleBlur={handleBlur}
                          autoCapitalizeValue="none"
                          tooltipPlacementLeft={true}
                        ></InputWithIcon>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Fragment>
            ) : null}
            {providerProfile?.isLicensed === true ? (
              <Fragment>
                <Row>
                  <Col>
                    <Label
                      className={classnames({
                        "highlighted-text": isNotUndefined(
                          formErrors?.licenses
                        ),
                        thirdTitle: true,
                      })}
                    >
                      Professional License(s)
                      <RequiredField />
                    </Label>
                  </Col>
                </Row>
                <ErrorMessage
                  visible={isNotUndefined(formErrors.licenses)}
                  message={formErrors.licenses}
                />
                <div ref={licensesRef} className="pb-2">
                  <Licenses
                    displayStatusMessage={true}
                    formErrors={formErrors}
                    elements={providerProfile?.licenses}
                    handleItemsChange={handleChangeInLicenses}
                    isLicensed={providerProfile?.isLicensed || false}
                    isApproved={
                      providerProfile?.profileStatus?.value == 1 || false
                    }
                    states={providerProfile?.states || []}
                    licenseTypes={providerProfile?.licenseTypes || []}
                    onBlur={handleBlur}
                  ></Licenses>
                </div>
              </Fragment>
            ) : null}
            <Row className="pt-4 mt-2">
              <Col>
                <Label className="thirdTitle">Practicing Since</Label>
                <TooltipMessage
                  tooltipPlacementLeft={false}
                  tooltipMessage={
                    "The number of years in practice displayed on your public profile will be calculated based on this date"
                  }
                ></TooltipMessage>
              </Col>
            </Row>
            <Row className="pt-1">
              <Col md="3">
                <DataDropDown
                  fieldName="practicingSince"
                  fieldText="Practicing Since"
                  hideLabel={true}
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      border: blueBorder,
                      backgroundColor: white,
                    }),
                    dropdownIndicator: (provided: any) => ({
                      ...provided,
                      color: darkBlue,
                    }),
                  }}
                  values={historicYears}
                  selectedValue={providerProfile?.practicingSince}
                  handleChange={handleChangeInDropDown}
                  className={classnames({
                    selectControl: true,
                  })}
                  onBlur={handleBlur}
                ></DataDropDown>
              </Col>
            </Row>
            <Row className="pt-4 mt-2">
              <Col>
                <Label className="thirdTitle">Board Certification(s)</Label>
              </Col>
            </Row>
            <div ref={boardCertificationsRef} className="pb-2">
              <BoardCertifications
                formErrors={formErrors}
                elements={providerProfile?.boardCertifications}
                handleItemsChange={handleChangeInBoardCertifications}
                onBlur={handleBlur}
              ></BoardCertifications>
            </div>
            <Row className="pt-4 mt-2">
              <Col>
                <Label className="thirdTitle">
                  Professional Certification(s)
                </Label>
              </Col>
            </Row>
            <div ref={certificationsRef} className="pb-2">
              <Certifications
                formErrors={formErrors}
                elements={providerProfile?.certifications}
                handleItemsChange={handleChangeInCertifications}
                certificationOrganizationTypes={
                  providerProfile?.certificationOrganizationTypes || []
                }
                onBlur={handleBlur}
              ></Certifications>
            </div>
            <Row className="pt-4 mt-2">
              <Col>
                <Label className="thirdTitle">
                  Professional Association(s)
                </Label>
              </Col>
            </Row>
            <div ref={professionalAssociationsRef} className="pb-2">
              <ProfessionalAssociations
                formErrors={formErrors}
                elements={providerProfile?.professionalAssociations}
                handleItemsChange={handleChangeInProfessionalAssociations}
                onBlur={handleBlur}
              ></ProfessionalAssociations>
            </div>
            <Row className="pt-4 mt-2">
              <Col>
                <Label
                  className={classnames({
                    "highlighted-text": isNotUndefined(formErrors?.schools),
                    thirdTitle: true,
                  })}
                >
                  Education
                  <RequiredField />
                </Label>
              </Col>
            </Row>
            <div ref={schoolsRef} className="pb-2">
              <Schools
                formErrors={formErrors}
                elements={providerProfile?.schools}
                handleItemsChange={handleChangeInSchools}
                onBlur={handleBlur}
              ></Schools>
              <ErrorMessage
                visible={isNotUndefined(formErrors.schools)}
                message={formErrors.schools}
              />
            </div>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col md="12" className="pt-4">
                <FormGroup>
                  <Label>
                    <span
                      className={classnames({
                        "highlighted-text": isNotUndefined(
                          formErrors.officePhotos
                        ),
                        thirdTitle: true,
                      })}
                    >
                      Office Images
                    </span>
                    <span className="help-text-button">
                      <FontAwesomeIcon
                        icon={IconRegular.faQuestionCircle}
                        className="visible shape"
                      />
                      <span className="help-text">
                        For best results, use JPG or PNG formatted images less
                        than 8MB in size. Ideal image size is 180x180 pixels.
                      </span>
                    </span>
                  </Label>
                  <ErrorMessage
                    visible={isNotUndefined(formErrors.officePhotos)}
                    message={formErrors.officePhotos}
                  />
                </FormGroup>
              </Col>
              <Col md="12" className="office-images">
                <div className="container pr-0 pl-0">
                  <section>
                    <div className="dropBaseStyle" {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <div className="text-center">
                        <div className="d-none d-lg-block">
                          <img
                            width="96"
                            height="84"
                            alt="upload"
                            src="/providers/upload.png"
                          />
                          <div className="regular-font font-weight-light blue-header pb-2">
                            Drag & Drop images here
                          </div>
                          <div className="regular-font font-weight-light blue-header pb-2">
                            or
                          </div>
                        </div>
                        <div>
                          <SkinnedButton
                            className="btn-profile-edit px-5"
                            onClick={(event: any) => {
                              event.preventDefault();
                            }}
                          >
                            Browse Files
                          </SkinnedButton>
                        </div>
                      </div>
                    </div>
                  </section>
                  <Row className="thumb mt-3">
                    {providerProfile?.officePhotos != null &&
                      providerProfile?.officePhotos.length > 0 &&
                      providerProfile?.officePhotos.map(
                        (file: any, index: any) => (
                          <Col
                            md="3"
                            sm="12"
                            className="mb-3 fromfile"
                            key={file.id}
                          >
                            <div>
                              <img
                                className="mb-3 officeImg"
                                alt="Office"
                                src={
                                  "data:image/png;base64," + file.name
                                    ? `${baseUrl}providers/photo?id=${file.name}`
                                    : "#"
                                }
                              />
                              <FontAwesomeIcon
                                icon={Icon.faTimesCircle}
                                color="white"
                                className="blue-2 ml-n4 white-radial-gradient align-top"
                                viewBox="0 0 512 512"
                                size="lg"
                                onClick={() => removeDatabaseFile(file.id)}
                              />
                              <ImageEditModal
                                buttonLabel=""
                                activeImage={
                                  "data:image/png;base64," + file.name
                                    ? `${baseUrl}providers/photo?id=${file.name}`
                                    : "#"
                                }
                                handleNewCroppedImage={
                                  handleNewCroppedOfficeImageFromDatabaseImage
                                }
                                returnType={ImageDataReturnType.Blob}
                                defaultAspect={4 / 3}
                                allowAspectChange={true}
                                hideUpload={true}
                                showEditIcon={true}
                                fileId={file.id}
                                overlayButton={true}
                                description={file.description}
                              ></ImageEditModal>
                            </div>
                            <Input
                              className={classnames({
                                highlighted:
                                  isNotUndefined(
                                    formErrors[
                                      `officePhotos[${index}].description`
                                    ]
                                  ) ||
                                  isNotUndefined(
                                    formErrors[
                                      `officePhotos[${index}].Description`
                                    ]
                                  ),
                              })}
                              value={file.description}
                              id={`imageDescriptions${index}`}
                              name={`imageDescriptions${index}`}
                              onChange={(event) => {
                                imageInDatabaseChangedDescription(event, index);
                              }}
                              onBlur={handleBlur}
                              placeholder="Description"
                            ></Input>
                            <ErrorMessage
                              visible={
                                isNotUndefined(
                                  formErrors[
                                    `officePhotos[${index}].description`
                                  ]
                                ) ||
                                isNotUndefined(
                                  formErrors[
                                    `officePhotos[${index}].Description`
                                  ]
                                )
                              }
                              message={
                                formErrors[
                                  `officePhotos[${index}].description`
                                ] ||
                                formErrors[`officePhotos[${index}].Description`]
                              }
                            />
                          </Col>
                        )
                      )}
                    {officePhotos.length > 0 &&
                      officePhotos.map((file, index) => (
                        <Col
                          md="3"
                          sm="12"
                          className="mb-3 inmemory"
                          key={index}
                        >
                          <div>
                            <img
                              alt="Office"
                              src={file.preview}
                              className="mb-3 officeImg"
                            />
                            <FontAwesomeIcon
                              icon={Icon.faTimesCircle}
                              color="white"
                              viewBox="0 0 512 512"
                              className="blue-2 ml-n4 white-radial-gradient align-top"
                              size="lg"
                              onClick={() => removeInMemoryFile(file.id)}
                            />
                          </div>
                          <ImageEditModal
                            buttonLabel=""
                            activeImage={file.preview}
                            handleNewCroppedImage={handleNewCroppedOfficeImage}
                            returnType={ImageDataReturnType.Blob}
                            defaultAspect={4 / 3}
                            allowAspectChange={true}
                            hideUpload={true}
                            showEditIcon={true}
                            fileId={file.id}
                            overlayButton={true}
                            description={file.description}
                          ></ImageEditModal>
                          <Input
                            className={classnames({
                              highlighted:
                                isNotUndefined(
                                  formErrors[
                                    `inMemoryOfficePhotos[${index}].description`
                                  ]
                                ) ||
                                isNotUndefined(
                                  formErrors[
                                    `inMemoryOfficePhotos[${index}].Description`
                                  ]
                                ),
                            })}
                            value={file.description}
                            id={`imageDescriptions${index}`}
                            name={`imageDescriptions${index}`}
                            onChange={(event) => {
                              imageChangedDescription(event, index);
                            }}
                            onBlur={handleBlur}
                            placeholder="Description"
                          ></Input>
                          <ErrorMessage
                            visible={
                              isNotUndefined(
                                formErrors[
                                  `inMemoryOfficePhotos[${index}].description`
                                ]
                              ) ||
                              isNotUndefined(
                                formErrors[
                                  `inMemoryOfficePhotos[${index}].Description`
                                ]
                              )
                            }
                            message={
                              formErrors[
                                `inMemoryOfficePhotos[${index}].description`
                              ] ||
                              formErrors[
                                `inMemoryOfficePhotos[${index}].Description`
                              ]
                            }
                          />
                        </Col>
                      ))}
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col md="12">
                <FormGroup className="mb-0">
                  <Label>
                    <span className="thirdTitle">Introductory Video</span>
                  </Label>
                </FormGroup>
              </Col>
              <Col md="8">
                <InputWithIcon
                  className={{
                    highlighted: isNotUndefined(formErrors.youTubeUrl),
                    "pl-5": true,
                  }}
                  labelClass={classnames({
                    "highlighted-text": isNotUndefined(formErrors.youTubeUrl),
                  })}
                  error={formErrors.youTubeUrl}
                  labelName="YouTube"
                  fieldText={providerProfile?.youTubeUrl || ""}
                  fieldName="youTubeUrl"
                  handleChange={handleChange}
                  icon={BrandIcon.faYoutube}
                  handleBlur={handleBlur}
                  placeholder="www.youtube.com/watch?v=video"
                  tooltipMessage="We recommend a max length of 1 minute"
                ></InputWithIcon>
              </Col>
            </Row>
          </TabPane>
          <TabPane className="column-sort" tabId="4">
            <Row>
              <Col md="12">
                <div className="pt-2 pb-3 mt-2 normal-font">
                  To maximize your client exposure, please select all that apply
                  in each category as clients may filter on any of these
                  categories. How complete your profile is will also impact the
                  frequency in which your profile will show in search results.
                </div>
              </Col>
              <Col>
                <Label className="thirdTitle">Services Offered</Label>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="pt-1">
                <FormGroup>
                  <Label>Service Type</Label>
                  <Row>
                    {providerProfile?.serviceTypes?.map(
                      (item: any, index: any) => (
                        <Col
                          xl="3"
                          lg="4"
                          key={item.value}
                          className="ml-4 wrap-around profile-checkbox-item"
                        >
                          <CustomCheckbox
                            key={item.value}
                            index={index}
                            text={item.label || ""}
                            checked={item.checked || false}
                            fieldName={item.label || ""}
                            handleChange={(ev: any) => {
                              handleChangeInMultipleCheckboxes(
                                ev,
                                "serviceTypes",
                                "selectedServiceTypes"
                              );
                            }}
                          ></CustomCheckbox>
                        </Col>
                      )
                    )}
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label>Currently Accepting New Patients</Label>
                  <div>
                    <FormGroup
                      check
                      inline
                      className="custom-radio-control no-line-height"
                    >
                      <CustomInput
                        type="radio"
                        id={"isNotAcceptingNewPatients"}
                        name="isAcceptingNewPatients"
                        label="No"
                        checked={
                          providerProfile?.isAcceptingNewPatients === false
                        }
                        onChange={(event: any) => {
                          event.target.value = false;
                          radioButtonChange(event);
                        }}
                      />
                      <CustomInput
                        className="ml-4"
                        type="radio"
                        id={"isAcceptingNewPatients"}
                        name="isAcceptingNewPatients"
                        label="Yes"
                        checked={
                          providerProfile?.isAcceptingNewPatients == true
                        }
                        onChange={(event: any) => {
                          event.target.value = true;
                          radioButtonChange(event);
                        }}
                      />
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                <Label className="thirdTitle">Clinical Treatment Profile</Label>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="pt-1">
                <FormGroup>
                  <Label>Client Demographics</Label>
                  <Row className={"profile-checkbox-group"}>
                    <Col>
                      <ul>
                        {providerProfile?.clientDemographics?.map(
                          (item: any, index: any) => (
                            <Fragment key={item.value}>
                              <li
                                key={item.value}
                                className={
                                  (index >
                                    4 -
                                      checkboxesCheckedCount(
                                        providerProfile?.clientDemographics
                                      ) &&
                                  hideClientDemographics &&
                                  !item.checked
                                    ? "profile-checkbox-hide-mobile "
                                    : "") +
                                  "ml-4 wrap-around profile-checkbox-item"
                                }
                              >
                                <CustomCheckbox
                                  key={item.value}
                                  index={index}
                                  text={item.label || ""}
                                  checked={item.checked || false}
                                  fieldName={item.label || ""}
                                  handleChange={(ev: any) => {
                                    handleChangeInMultipleCheckboxes(
                                      ev,
                                      "clientDemographics",
                                      "selectedClientDemographics"
                                    );
                                  }}
                                ></CustomCheckbox>
                              </li>
                            </Fragment>
                          )
                        )}
                        <li className="checkbox-group-toggle">
                          <CheckboxGroupToggler
                            toggleGroupMethod={toggleClientDemographics}
                            hideGroup={hideClientDemographics}
                          />
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md="12" className="pt-1">
                <FormGroup>
                  <Label>
                    Clinical Specialties
                    <span className="help-text-button">
                      <FontAwesomeIcon
                        icon={IconRegular.faQuestionCircle}
                        className="visible shape"
                      />
                      <span className="help-text">
                        Please select all clinical issues and conditions you
                        treat. We recommend choosing a broad selection so that
                        your profile appears in as many search results as
                        possible.
                      </span>
                    </span>
                  </Label>
                  <Row className={"profile-checkbox-group"}>
                    <Col>
                      <ul>
                        {providerProfile?.clinicalSpecialties?.map(
                          (item: any, index: any) => (
                            <li
                              key={item.value}
                              className={
                                (index >
                                  4 -
                                    checkboxesCheckedCount(
                                      providerProfile?.clinicalSpecialties
                                    ) &&
                                hideClinicalSpecialties &&
                                !item.checked
                                  ? "profile-checkbox-hide-mobile "
                                  : "") +
                                "ml-4 wrap-around profile-checkbox-item"
                              }
                            >
                              <CustomCheckbox
                                key={item.value}
                                index={index}
                                text={item.label || ""}
                                checked={item.checked || false}
                                fieldName={item.label || ""}
                                tooltipEnabled={item.label === "Other"}
                                tooltipMessage={
                                  item.label === "Other"
                                    ? `${csvInformationMessage}clinical specialties.`
                                    : ""
                                }
                                handleChange={(ev: any) => {
                                  handleChangeInMultipleCheckboxes(
                                    ev,
                                    "clinicalSpecialties",
                                    "selectedClinicalSpecialties"
                                  );
                                }}
                              ></CustomCheckbox>
                              {item.label === "Other" && (
                                <Row className="li-textbox-row">
                                  {providerProfile?.clinicalSpecialties?.find(
                                    (x) => x.label === "Other"
                                  ) && (
                                    <Col
                                      xl="9"
                                      className="pl-0 text-input-container"
                                    >
                                      {providerProfile?.clinicalSpecialties?.find(
                                        (x) => x.label === "Other" && x.checked
                                      ) && (
                                        <div>
                                          <TextInput
                                            maxLength={1000}
                                            showLabel={false}
                                            labelName=""
                                            placeholder="Add other clinical specialties"
                                            requiredField={false}
                                            className={classnames({
                                              highlighted: isNotUndefined(
                                                formErrors.otherClinicalSpecialty
                                              ),
                                            })}
                                            fieldText={
                                              providerProfile?.otherClinicalSpecialty ||
                                              ""
                                            }
                                            fieldName="otherClinicalSpecialty"
                                            handleChange={handleChange}
                                            onBlur={handleBlur}
                                          ></TextInput>
                                          <ErrorMessage
                                            visible={isNotUndefined(
                                              formErrors.otherClinicalSpecialty
                                            )}
                                            message={
                                              formErrors.otherClinicalSpecialty
                                            }
                                            displayAsCol={true}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  )}
                                </Row>
                              )}
                            </li>
                          )
                        )}
                        <li className="checkbox-group-toggle">
                          <CheckboxGroupToggler
                            toggleGroupMethod={toggleClinicalSpecialties}
                            hideGroup={hideClinicalSpecialties}
                          />
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md="12" className="pt-1">
                <FormGroup>
                  <Label>
                    Therapy Types
                    <span className="help-text-button">
                      <FontAwesomeIcon
                        icon={IconRegular.faQuestionCircle}
                        className="visible shape"
                      />
                      <span className="help-text">
                        Please select all modalities/treatment approaches you
                        utilize in your practice.
                      </span>
                    </span>
                  </Label>
                  <Row className={"profile-checkbox-group"}>
                    <Col>
                      <ul>
                        {providerProfile?.therapyTypes?.map(
                          (item: any, index: any) => (
                            <li
                              key={item.value}
                              className={
                                (index >
                                  4 -
                                    checkboxesCheckedCount(
                                      providerProfile?.therapyTypes
                                    ) &&
                                hideTherapyTypes &&
                                !item.checked
                                  ? "profile-checkbox-hide-mobile "
                                  : "") +
                                "ml-4 wrap-around profile-checkbox-item"
                              }
                            >
                              <CustomCheckbox
                                key={item.value}
                                index={index}
                                text={item.label || ""}
                                checked={item.checked || false}
                                fieldName={item.label || ""}
                                tooltipEnabled={item.label === "Other"}
                                tooltipMessage={
                                  item.label === "Other"
                                    ? `${csvInformationMessage}therapy types.`
                                    : ""
                                }
                                handleChange={(ev: any) => {
                                  handleChangeInMultipleCheckboxes(
                                    ev,
                                    "therapyTypes",
                                    "selectedTherapyTypes"
                                  );
                                }}
                              ></CustomCheckbox>
                              {item.label === "Other" && (
                                <Row className="li-textbox-row">
                                  {providerProfile?.therapyTypes?.find(
                                    (x) => x.label === "Other"
                                  ) && (
                                    <Col
                                      xl="9"
                                      className="pl-0 text-input-container"
                                    >
                                      {providerProfile?.therapyTypes?.find(
                                        (x) => x.label === "Other" && x.checked
                                      ) && (
                                        <div>
                                          <TextInput
                                            maxLength={1000}
                                            showLabel={false}
                                            labelName=""
                                            placeholder="Add other therapy types"
                                            requiredField={false}
                                            className={classnames({
                                              highlighted: isNotUndefined(
                                                formErrors.otherTherapyType
                                              ),
                                            })}
                                            fieldText={
                                              providerProfile?.otherTherapyType ||
                                              ""
                                            }
                                            fieldName="otherTherapyType"
                                            handleChange={handleChange}
                                            onBlur={handleBlur}
                                          ></TextInput>
                                          <ErrorMessage
                                            visible={isNotUndefined(
                                              formErrors.otherTherapyType
                                            )}
                                            message={
                                              formErrors.otherTherapyType
                                            }
                                            displayAsCol={true}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  )}
                                </Row>
                              )}
                            </li>
                          )
                        )}
                        <li className="checkbox-group-toggle">
                          <CheckboxGroupToggler
                            toggleGroupMethod={toggleTherapyTypes}
                            hideGroup={hideTherapyTypes}
                          />
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md="12" className="pt-1">
                <FormGroup>
                  <Label>
                    Community Specialties
                    <span className="help-text-button">
                      <FontAwesomeIcon
                        icon={IconRegular.faQuestionCircle}
                        className="visible shape"
                      />
                      <span className="help-text">
                        Indicate here whether you have expertise working with
                        clients who identify with the following cultures or who
                        are a part of the following communities.
                      </span>
                    </span>
                  </Label>
                  <Row className={"profile-checkbox-group"}>
                    <Col>
                      <ul>
                        {providerProfile?.communityTypes?.map(
                          (item: any, index: any) => (
                            <li
                              key={item.value}
                              className={
                                (index >
                                  4 -
                                    checkboxesCheckedCount(
                                      providerProfile?.communityTypes
                                    ) &&
                                hideCommunitySpecialties &&
                                !item.checked
                                  ? "profile-checkbox-hide-mobile "
                                  : "") +
                                "ml-4 wrap-around profile-checkbox-item"
                              }
                            >
                              <CustomCheckbox
                                key={item.value}
                                index={index}
                                text={item.label || ""}
                                checked={item.checked || false}
                                fieldName={item.label || ""}
                                tooltipEnabled={item.label === "Other"}
                                tooltipMessage={
                                  item.label === "Other"
                                    ? `${csvInformationMessage}community specialties.`
                                    : ""
                                }
                                handleChange={(ev: any) => {
                                  handleChangeInMultipleCheckboxes(
                                    ev,
                                    "communityTypes",
                                    "selectedCommunityTypes"
                                  );
                                }}
                              ></CustomCheckbox>
                              {item.label === "Other" && (
                                <Row className="li-textbox-row">
                                  {providerProfile?.communityTypes?.find(
                                    (x) => x.label === "Other"
                                  ) && (
                                    <Col
                                      xl="9"
                                      className="pl-0 text-input-container"
                                    >
                                      {providerProfile?.communityTypes?.find(
                                        (x) => x.label === "Other" && x.checked
                                      ) && (
                                        <div>
                                          <TextInput
                                            maxLength={1000}
                                            showLabel={false}
                                            labelName=""
                                            placeholder="Add other community specialties"
                                            requiredField={false}
                                            className={classnames({
                                              highlighted: isNotUndefined(
                                                formErrors.otherCommunityType
                                              ),
                                            })}
                                            fieldText={
                                              providerProfile?.otherCommunityType ||
                                              ""
                                            }
                                            fieldName="otherCommunityType"
                                            handleChange={handleChange}
                                            onBlur={handleBlur}
                                          ></TextInput>
                                          <ErrorMessage
                                            visible={isNotUndefined(
                                              formErrors.otherCommunityType
                                            )}
                                            message={
                                              formErrors.otherCommunityType
                                            }
                                            displayAsCol={true}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  )}
                                </Row>
                              )}
                            </li>
                          )
                        )}
                        <li className="checkbox-group-toggle">
                          <CheckboxGroupToggler
                            toggleGroupMethod={toggleCommunitySpecialties}
                            hideGroup={hideCommunitySpecialties}
                          />
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md="12" className="pt-1">
                <FormGroup>
                  <Label>
                    Faith-Based Specialties
                    <span className="help-text-button">
                      <FontAwesomeIcon
                        icon={IconRegular.faQuestionCircle}
                        className="visible shape"
                      />
                      <span className="help-text">
                        Indicate here whether you have expertise working with
                        clients in these communities.
                      </span>
                    </span>
                  </Label>
                  <Row className={"profile-checkbox-group"}>
                    <Col>
                      <ul>
                        {providerProfile?.faithTypes?.map(
                          (item: any, index: any) => (
                            <li
                              key={item.value}
                              className={
                                (index >
                                  4 -
                                    checkboxesCheckedCount(
                                      providerProfile?.faithTypes
                                    ) &&
                                hideFaithBasedSpecialties &&
                                !item.checked
                                  ? "profile-checkbox-hide-mobile "
                                  : "") +
                                "ml-4 wrap-around profile-checkbox-item"
                              }
                            >
                              <CustomCheckbox
                                key={item.value}
                                index={index}
                                text={item.label || ""}
                                checked={item.checked || false}
                                fieldName={item.label || ""}
                                tooltipEnabled={item.label === "Other"}
                                tooltipMessage={
                                  item.label === "Other"
                                    ? `${csvInformationMessage}faith-based specialties.`
                                    : ""
                                }
                                handleChange={(ev: any) => {
                                  handleChangeInMultipleCheckboxes(
                                    ev,
                                    "faithTypes",
                                    "selectedFaithTypes"
                                  );
                                }}
                              ></CustomCheckbox>
                              {item.label === "Other" && (
                                <Row className="li-textbox-row">
                                  {providerProfile?.faithTypes?.find(
                                    (x) => x.label === "Other"
                                  ) && (
                                    <Col
                                      xl="9"
                                      className="pl-0 text-input-container"
                                    >
                                      {providerProfile?.faithTypes?.find(
                                        (x) => x.label === "Other" && x.checked
                                      ) && (
                                        <div>
                                          <TextInput
                                            maxLength={1000}
                                            showLabel={false}
                                            labelName=""
                                            placeholder="Add other faith-based specialties"
                                            requiredField={false}
                                            className={classnames({
                                              highlighted: isNotUndefined(
                                                formErrors.otherFaithType
                                              ),
                                            })}
                                            fieldText={
                                              providerProfile?.otherFaithType ||
                                              ""
                                            }
                                            fieldName="otherFaithType"
                                            handleChange={handleChange}
                                            onBlur={handleBlur}
                                          ></TextInput>
                                          <ErrorMessage
                                            visible={isNotUndefined(
                                              formErrors.otherFaithType
                                            )}
                                            message={formErrors.otherFaithType}
                                            displayAsCol={true}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  )}
                                </Row>
                              )}
                            </li>
                          )
                        )}
                        <li className="checkbox-group-toggle">
                          <CheckboxGroupToggler
                            toggleGroupMethod={toggleFaithBasedSpecialties}
                            hideGroup={hideFaithBasedSpecialties}
                          />
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md="12" className="pt-1">
                <FormGroup>
                  <Label>Languages Spoken</Label>
                  <Row className={"profile-checkbox-group"}>
                    <Col>
                      <ul>
                        {providerProfile?.languageTypes?.map(
                          (item: any, index: any) => (
                            <li
                              key={item.value}
                              className={
                                (index >
                                  4 -
                                    checkboxesCheckedCount(
                                      providerProfile?.languageTypes
                                    ) &&
                                hideLanguages &&
                                !item.checked
                                  ? "profile-checkbox-hide-mobile "
                                  : "") +
                                "ml-4 wrap-around profile-checkbox-item"
                              }
                            >
                              <CustomCheckbox
                                key={item.value}
                                index={index}
                                text={item.label || ""}
                                checked={item.checked || false}
                                fieldName={item.label || ""}
                                tooltipEnabled={item.label === "Other"}
                                tooltipMessage={
                                  item.label === "Other"
                                    ? `${csvInformationMessage}languages spoken.`
                                    : ""
                                }
                                handleChange={(ev: any) => {
                                  handleChangeInMultipleCheckboxes(
                                    ev,
                                    "languageTypes",
                                    "selectedLanguageTypes"
                                  );
                                }}
                              ></CustomCheckbox>
                              {item.label === "Other" && (
                                <Row className="li-textbox-row">
                                  {providerProfile?.languageTypes?.find(
                                    (x) => x.label === "Other"
                                  ) && (
                                    <Col
                                      xl="9"
                                      className="pl-0 text-input-container"
                                    >
                                      {providerProfile?.languageTypes?.find(
                                        (x) => x.label === "Other" && x.checked
                                      ) && (
                                        <div>
                                          <TextInput
                                            maxLength={1000}
                                            showLabel={false}
                                            labelName=""
                                            placeholder="Add other languages spoken"
                                            requiredField={false}
                                            className={classnames({
                                              highlighted: isNotUndefined(
                                                formErrors.otherLanguageSpoken
                                              ),
                                            })}
                                            fieldText={
                                              providerProfile?.otherLanguageSpoken ||
                                              ""
                                            }
                                            fieldName="otherLanguageSpoken"
                                            handleChange={handleChange}
                                            onBlur={handleBlur}
                                          ></TextInput>
                                          <ErrorMessage
                                            visible={isNotUndefined(
                                              formErrors.otherLanguageSpoken
                                            )}
                                            message={
                                              formErrors.otherLanguageSpoken
                                            }
                                            displayAsCol={true}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  )}
                                </Row>
                              )}
                            </li>
                          )
                        )}
                        <li className="checkbox-group-toggle">
                          <CheckboxGroupToggler
                            toggleGroupMethod={toggleLanguages}
                            hideGroup={hideLanguages}
                          />
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                <Label className="thirdTitle">Fees & Insurance</Label>
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="4" sm="4">
                <FormGroup className="mb-0">
                  <Label
                    className={classnames({
                      "highlighted-text":
                        isNotUndefined(formErrors.costFrom) ||
                        isNotUndefined(formErrors.costTo),
                    })}
                  >
                    Cost Per Session
                  </Label>
                </FormGroup>
                <Row>
                  <Col>
                    <FormGroup>
                      <div>
                        <NumberFormat
                          name="costFrom"
                          className={classnames({
                            highlighted: isNotUndefined(formErrors.costFrom),
                            "input-field": true,
                            "form-control": true,
                            "form-control-cost": true,
                          })}
                          defaultValue={providerProfile?.costFrom}
                          value={providerProfile?.costFrom}
                          thousandSeparator={true}
                          prefix={"$"}
                          width={"500px"}
                          onValueChange={(selected) => {
                            let event: any = {};
                            event.target = {};
                            event.target.name = "costFrom";
                            event.target.inputMode = "numeric";
                            event.target.value = selected.value;
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                        ></NumberFormat>
                        <span> &nbsp; to &nbsp; </span>
                        <NumberFormat
                          name="costTo"
                          className={classnames({
                            highlighted: isNotUndefined(formErrors.costTo),
                            "input-field": true,
                            "form-control": true,
                            "form-control-cost": true,
                          })}
                          defaultValue={providerProfile?.costTo}
                          value={providerProfile?.costTo}
                          thousandSeparator={true}
                          prefix={"$"}
                          onValueChange={(selected) => {
                            let event: any = {};
                            event.target = {};
                            event.target.name = "costTo";
                            event.target.inputMode = "numeric";
                            event.target.value = selected.value;
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                        ></NumberFormat>
                      </div>
                      <ErrorMessage
                        visible={isNotUndefined(formErrors.costFrom)}
                        message={formErrors.costFrom}
                      />
                      <ErrorMessage
                        visible={isNotUndefined(formErrors.costTo)}
                        message={formErrors.costTo}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col lg="4" md="4" sm="4">
                <FormGroup>
                  <Label>
                    Sliding Scale{" "}
                    <span className="help-text-button">
                      <FontAwesomeIcon
                        icon={IconRegular.faQuestionCircle}
                        className="visible shape ml-1"
                      />
                      <span className="help-text">
                        Please indicate here whether you offer services at a
                        reduced rate for clients with fewer resources to pay.
                      </span>
                    </span>
                  </Label>
                  <div>
                    <FormGroup
                      check
                      inline
                      className="custom-radio-control no-line-height"
                    >
                      <CustomInput
                        type="radio"
                        id={"isNotSlidingScale"}
                        name="isSlidingScale"
                        label="No"
                        checked={providerProfile?.isSlidingScale === false}
                        onChange={(event: any) => {
                          event.target.value = false;
                          radioButtonChange(event);
                        }}
                      />
                      <CustomInput
                        className="ml-4"
                        type="radio"
                        id={"isSlidingScale"}
                        name="isSlidingScale"
                        label="Yes"
                        checked={providerProfile?.isSlidingScale == true}
                        onChange={(event: any) => {
                          event.target.value = true;
                          radioButtonChange(event);
                        }}
                      />
                    </FormGroup>
                  </div>
                 </FormGroup>
              </Col>
              <Col lg="4" md="4" sm="4">
                <FormGroup>
                    <Label>
                        Free Initial Consultation{" "}
                        <span className="help-text-button">
                            <FontAwesomeIcon
                                icon={IconRegular.faQuestionCircle}
                                className="visible shape ml-1"
                            />
                            <span className="help-text">
                                Please indicate here whether you offer free initial consultations.
                            </span>
                        </span>
                    </Label>
                    <div>
                        <FormGroup
                            check
                            inline
                            className="custom-radio-control no-line-height"
                        >
                            <CustomInput
                                type="radio"
                                id={"isNotFreeInitialConsultation"}
                                name="isFreeInitialConsultation"
                                label="No"
                                checked={providerProfile?.isFreeInitialConsultation === false}
                                onChange={(event: any) => {
                                    event.target.value = false;
                                    radioButtonChange(event);
                                }}
                            />
                            <CustomInput
                                className="ml-4"
                                type="radio"
                                id={"isFreeInitialConsultation"}
                                name="isFreeInitialConsultation"
                                label="Yes"
                                checked={providerProfile?.isFreeInitialConsultation === true}
                                onChange={(event: any) => {
                                    event.target.value = true;
                                    radioButtonChange(event);
                                }}
                            />
                        </FormGroup>
                    </div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col md="4">
                <FormGroup>
                  <Label>Accepting Insurance</Label>
                  <div>
                    <FormGroup
                      check
                      inline
                      className="custom-radio-control no-line-height"
                    >
                      <CustomInput
                        type="radio"
                        id={"isNotAcceptingInsurance"}
                        name="isAcceptingInsurance"
                        label="No"
                        checked={
                          providerProfile?.isAcceptingInsurance === false
                        }
                        onChange={(event: any) => {
                          event.target.value = false;
                          radioButtonChange(event);
                        }}
                      />
                      <CustomInput
                        className="ml-4"
                        type="radio"
                        id={"isAcceptingInsurance"}
                        name="isAcceptingInsurance"
                        label="Yes"
                        checked={providerProfile?.isAcceptingInsurance == true}
                        onChange={(event: any) => {
                          event.target.value = true;
                          radioButtonChange(event);
                        }}
                      />
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
              <Col md="8">
                {isInsurancePlansSectionVisible && (
                  <FormGroup className="column-sort-sub">
                    <Label
                      className={classnames({
                        "highlighted-text": isNotUndefined(
                          formErrors.selectedInsurancePlans
                        ),
                      })}
                    >
                      Accepted Insurance Plans
                    </Label>
                    <Row className={"profile-checkbox-group"}>
                      <Col>
                        <ul>
                          {providerProfile?.insurancePlans?.map(
                            (item: any, index: any) => (
                              <li
                                key={item.value}
                                className={
                                  (index >
                                    4 -
                                      checkboxesCheckedCount(
                                        providerProfile?.insurancePlans
                                      ) &&
                                  hideInsurancePlans &&
                                  !item.checked
                                    ? "profile-checkbox-hide-mobile "
                                    : "") +
                                  "ml-4 wrap-around profile-checkbox-item"
                                }
                              >
                                <CustomCheckbox
                                  key={item.value}
                                  index={index}
                                  text={item.label || ""}
                                  checked={item.checked || false}
                                  fieldName={item.label || ""}
                                  tooltipEnabled={item.label === "Other"}
                                  tooltipMessage={
                                    item.label === "Other"
                                      ? `${csvInformationMessage}accepted insurance plans.`
                                      : ""
                                  }
                                  handleChange={(ev: any) => {
                                    handleChangeInMultipleCheckboxes(
                                      ev,
                                      "insurancePlans",
                                      "selectedInsurancePlans"
                                    );
                                  }}
                                ></CustomCheckbox>
                                {item.label === "Other" && (
                                  <Row className="li-textbox-row">
                                    {providerProfile?.insurancePlans?.find(
                                      (x) => x.label === "Other"
                                    ) && (
                                      <Col
                                        xl="9"
                                        className="pl-0 text-input-container"
                                      >
                                        {providerProfile?.insurancePlans?.find(
                                          (x) =>
                                            x.label === "Other" && x.checked
                                        ) && (
                                          <div>
                                            <TextInput
                                              maxLength={1000}
                                              visibilityOffNextToTextInput={
                                                false
                                              }
                                              showLabel={false}
                                              labelName=""
                                              placeholder="Enter other insurance plans"
                                              requiredField={false}
                                              className={classnames({
                                                highlighted: isNotUndefined(
                                                  formErrors.otherInsurancePlans
                                                ),
                                              })}
                                              fieldText={
                                                providerProfile?.otherInsurancePlans ||
                                                ""
                                              }
                                              fieldName="otherInsurancePlans"
                                              handleChange={handleChange}
                                              onBlur={handleBlur}
                                            ></TextInput>
                                            <ErrorMessage
                                              visible={isNotUndefined(
                                                formErrors.otherInsurancePlans
                                              )}
                                              message={
                                                formErrors.otherInsurancePlans
                                              }
                                              displayAsCol={true}
                                            />
                                          </div>
                                        )}
                                      </Col>
                                    )}
                                  </Row>
                                )}
                              </li>
                            )
                          )}
                          <li className="checkbox-group-toggle">
                            <CheckboxGroupToggler
                              toggleGroupMethod={toggleInsurancePlans}
                              hideGroup={hideInsurancePlans}
                            />
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </FormGroup>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="6">
                <FormGroup>
                  <Label>Accepted Payment Methods</Label>
                  <Row className={"profile-checkbox-group"}>
                    {providerProfile?.paymentMethodTypes?.map(
                      (item: any, index: any) => (
                        <Col
                          md="8"
                          key={item.value}
                          className={
                            (index >
                              4 -
                                checkboxesCheckedCount(
                                  providerProfile?.paymentMethodTypes
                                ) &&
                            hidePaymentMethods &&
                            !item.checked
                              ? "profile-checkbox-hide-mobile "
                              : "") + "ml-4 wrap-around profile-checkbox-item"
                          }
                        >
                          <CustomCheckbox
                            key={item.value}
                            index={index}
                            text={item.label || ""}
                            checked={item.checked || false}
                            fieldName={item.label || ""}
                            handleChange={(ev: any) => {
                              handleChangeInMultipleCheckboxes(
                                ev,
                                "paymentMethodTypes",
                                "selectedPaymentMethodTypes"
                              );
                            }}
                          ></CustomCheckbox>
                        </Col>
                      )
                    )}
                    <Col md="8" className="checkbox-group-toggle">
                      <CheckboxGroupToggler
                        toggleGroupMethod={togglePaymentMethods}
                        hideGroup={hidePaymentMethods}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col lg="8" md="6">
                {isCreditCardsVisible ? (
                  <FormGroup>
                    <Label
                      className={classnames({
                        "highlighted-text": isNotUndefined(
                          formErrors.selectedCreditCards
                        ),
                      })}
                    >
                      Accepted Credit Cards
                    </Label>
                    <Row>
                      {providerProfile?.creditCards?.map(
                        (item: any, index: any) => (
                          <Col
                            md="8"
                            key={item.value}
                            className="ml-4 wrap-around profile-checkbox-item"
                          >
                            <CustomCheckbox
                              key={item.value}
                              index={index}
                              text={item.label || ""}
                              checked={item.checked || false}
                              fieldName={item.label || ""}
                              handleChange={(ev: any) => {
                                handleChangeInMultipleCheckboxes(
                                  ev,
                                  "creditCards",
                                  "selectedCreditCards"
                                );
                              }}
                            ></CustomCheckbox>
                          </Col>
                        )
                      )}
                      <ErrorMessage
                        visible={isNotUndefined(formErrors.selectedCreditCards)}
                        message={formErrors.selectedCreditCards}
                        displayAsCol={true}
                      />
                    </Row>
                  </FormGroup>
                ) : null}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col className="px-3 blue-header">
                {Helper.isProfilePublished(
                  providerProfile?.profileStatus?.value,
                  providerProfile?.publishedStatus?.value,
                  providerProfile?.publishSucceeded
                ) ? (
                  <span className="green larger-font mr-3 font-weight-bold">
                    <ListedSection />
                  </span>
                ) : providerProfile?.profileStatus?.value ==
                  ProfileStatus.Denied ? (
                  <span className="red larger-font mr-3 font-weight-bold">
                    <ActionRequiredSection />
                  </span>
                ) : providerProfile?.profileStatus?.value ==
                  ProfileStatus.Incomplete ? (
                  <span className="grey larger-font mr-3 font-weight-bold">
                    <NotSubmittedSection handleSave={handleSave} />
                  </span>
                ) : providerProfile?.profileStatus?.value ==
                  ProfileStatus.PendingApproval ? (
                  <span className="grey larger-font mr-3 font-weight-bold">
                    <PendingReviewSection />
                  </span>
                ) : providerProfile?.profileStatus?.value ==
                  ProfileStatus.Hidden ? (
                  <span className="red larger-font mr-3 font-weight-bold">
                    <UnlistedSection />
                  </span>
                ) : Helper.isProfileUnpublished(
                    providerProfile?.profileStatus?.value,
                    providerProfile?.publishedStatus?.value
                  ) ? (
                  <span className="red larger-font mr-3 font-weight-bold">
                    <UnlistedSection />
                  </span>
                ) : providerProfile?.profileStatus?.value ==
                    ProfileStatus.Approved &&
                  providerProfile?.publishedStatus?.value ==
                    PublishStatus.Public &&
                  !providerProfile?.publishSucceeded ? (
                  <span className="green larger-font mr-3 font-weight-bold">
                    <ListedSection />
                  </span>
                ) : providerProfile?.profileStatus?.value ==
                  ProfileStatus.DeactivatedAndLoggedOut ? (
                  <span className="red larger-font mr-3 font-weight-bold">
                    <DeactivatedSection />
                  </span>
                ) : null}
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
};
