import { Fragment } from "react";
import { Container } from "reactstrap";
import PublicLayout from "../../PublicLayout";
import "./Subscription.scss";
import { SubscriptionSelect } from "./SubscriptionSelect";

export const Subscription = () => {
  return (
    <Container fluid={true} className="sub-root">
      <PublicLayout>
        <>
          <div id="progress-wrapper">
            <div className="progress-control">
              <div className="step step-1">
                <div className="circle current-step"></div>
                <div className="step-header current-step">STEP 1</div>
              </div>
              <div className="step step-2">
                <div className="circle uncompleted-step"></div>
                <div className="step-header">STEP 2</div>
              </div>
              <div className="step step-3">
                <div className="circle uncompleted-step"></div>
                <div className="step-header">STEP 3</div>
              </div>
              <div className="progress-bar"></div>
            </div>
          </div>
          <SubscriptionSelect />
        </>
      </PublicLayout>
    </Container>
  );
};
