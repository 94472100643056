export class ProductVM {
  [value: string]: any
  key?: string | null;
  id?: string;
  name?: string;
  campaignName?: string;
  campaignStatus?: string;
  campaignStartDate?: string;
  campaignEndDate?: string;
  offerPage?: string; 
  isEnabled?: boolean;
}
