interface IProps {
  tooltipMessage?: string;
}

export const MoveIcon = ({ tooltipMessage }: IProps) => {
  return (
    <span className="help-text-button relative move-icon">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="92px"
        height="92px"
        viewBox="10 10 72 72"
        enableBackground="new 0 0 92 92"
        xmlSpace="preserve"
        focusable="false"
        className="svg-inline--fa fa-w-14"
        role="img"
      >
        <path
          id="XMLID_871_"
          d="M92,46.3c0,1.1-0.4,2.1-1.2,2.8l-14.1,14c-0.8,0.8-1.8,1.1-2.8,1.1c-1,0-2.1-0.4-2.8-1.2
	c-1.6-1.6-1.5-4.2,0-5.7l7.3-7.4H50v28.4l7.3-7.3c1.6-1.6,4.1-1.5,5.7,0c1.6,1.6,1.5,4.1,0,5.7L48.9,90.8C48.1,91.6,47.1,92,46,92
	s-2-0.4-2.8-1.2L29.1,76.8c-1.6-1.6-1.6-4.1,0-5.7c1.6-1.6,4.1-1.6,5.6,0l7.3,7.3V50H13.7l7.3,7.4c1.6,1.6,1.6,4.2,0,5.8
	c-0.8,0.8-1.8,1.2-2.8,1.2c-1,0-2-0.4-2.8-1.2L1.2,49.1C0.4,48.4,0,47.4,0,46.3s0.4-2.1,1.2-2.8l14.1-14.1c1.6-1.6,4.1-1.6,5.7,0
	c1.6,1.6,1.5,4,0,5.6L13.7,42H42V13.6l-7.3,7.3c-1.6,1.6-4.1,1.5-5.6,0c-1.6-1.6-1.5-4.1,0-5.7L43.2,1.2c1.6-1.6,4.1-1.6,5.6,0
	L63,15.2c1.6,1.6,1.6,4.1,0,5.7c-0.8,0.8-1.8,1.2-2.8,1.2c-1,0-2.1-0.4-2.8-1.2L50,13.6V42h28.3L71,34.9c-1.6-1.6-1.6-3.9,0-5.5
	c1.6-1.6,4.1-1.6,5.7,0l14.1,14.1C91.6,44.2,92,45.2,92,46.3z"
        />
      </svg>
      <span className="help-text text-left move-up">{tooltipMessage}</span>
    </span>
  );
};
