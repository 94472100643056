import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { ExpanderComponentProps } from "react-data-table-component";

import { CampaignListVM, ProductVM } from "../Product/Models";
import { constants } from "../../Utilities/Constants";

import "./CampaignDetails.scss";

export const CampaignDetails: React.FC<ExpanderComponentProps<ProductVM>> = ({data}) => (
  <>
    <Row>
      <Col>
        <h5>Campaign Details</h5>
      </Col>
    </Row>
    <Row>
      <Col>
        <table className="table table-bordered table-sm">
          <thead>
            <tr>
              <th>
                <strong>Name</strong>
              </th>
              <th>
                <strong>Start Date</strong>
              </th>
              <th>
                <strong>End Date</strong>
              </th>
              <th>
                <strong>Offer Page</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {(data.campaigns || []).map((item: CampaignListVM) => (
              <tr key={item.id}>
                <td>
                  <Link
                    className="font-weight-normal"
                    to={
                      (item.isDefault ? "/default-campaign/" : "/campaign/") +
                      // This control is used in different places and the campaign id varies
                      (item.campaignId || item.id)
                    }
                    target="_blank"
                    tabIndex={-1}
                  >
                    {item.name}
                  </Link>
                </td>
                <td>{item.startDate}</td>
                <td>{item.endDate}</td>
                <td>
                  <Link
                    className="font-weight-normal"
                    to={`${constants.CampaignPreview}/${item.offerPage || ""}`}
                    target="_blank"
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Col>
    </Row>
  </>
)
