export const LargeVisibilityIcon = () => {
  return (
    <svg
      className="mt-1"
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_0_1)">
        <path
          d="M64 6H12C9.79086 6 8 7.79086 8 10V62C8 64.2091 9.79086 66 12 66H64C66.2091 66 68 64.2091 68 62V10C68 7.79086 66.2091 6 64 6Z"
          fill="black"
        />
      </g>
      <path
        d="M64 6H12C9.79086 6 8 7.79086 8 10V62C8 64.2091 9.79086 66 12 66H64C66.2091 66 68 64.2091 68 62V10C68 7.79086 66.2091 6 64 6Z"
        fill="url(#paint0_linear_0_1)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38 33C37.2105 33 36.4368 33.319 35.8811 33.8779C35.3221 34.4368 35 35.2104 35 36C35 36.7896 35.3221 37.5629 35.8811 38.1221C36.44 38.681 37.2105 39 38 39C38.7895 39 39.5632 38.681 40.1221 38.1221C40.6811 37.5629 41 36.7896 41 36C41 35.2104 40.6811 34.4368 40.1221 33.8779C39.5632 33.319 38.7926 33 38 33Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.4568 33.7712C56.5132 33.7712 56.5696 33.7745 56.6252 33.7808C56.5719 33.7749 56.5155 33.7712 56.4568 33.7712ZM20.4392 35.3491C22.3362 37.8759 28.5153 45.0312 37.6323 45.0312C46.6681 45.0312 52.891 38.0578 54.5903 35.5873C54.5697 35.5666 54.5488 35.5454 54.5282 35.5239C52.4529 33.4078 46.1385 26.9688 37.441 26.9688C28.9302 26.9688 22.5031 33.1065 20.4392 35.3491ZM37.6323 48C24.8862 48 17.5306 36.5188 17.2239 36.0301C16.8958 35.5079 16.9309 34.8429 17.3119 34.356C17.6426 33.9333 25.5492 24 37.441 24C47.4423 24 54.4341 31.13 56.7323 33.4732C56.9178 33.6628 57.1232 33.8721 57.2394 33.9823C57.4349 34.0951 57.6059 34.2484 57.7343 34.4339C57.9039 34.6792 58.0003 34.9921 58.0003 35.2875C58.0003 36.6112 55.0551 40.0673 52.422 42.2731C49.3035 44.8857 44.2061 48 37.6323 48Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0002 28C35.8871 28 33.8278 28.8533 32.3509 30.3404C30.856 31.8369 30 33.8987 30 36C30 38.1013 30.856 40.1628 32.3478 41.6561C33.8359 43.1457 35.8959 44 38.0002 44C40.1105 44 42.1746 43.1457 43.6627 41.6561C45.1481 40.1693 46 38.1078 46 36C46 33.8922 45.1481 31.8304 43.6627 30.3435C42.175 28.8543 40.1112 28 38.0002 28ZM38.0002 47C35.1097 47 32.2783 45.8276 30.2329 43.7834C28.1784 41.7309 27 38.8941 27 36C27 33.1059 28.1784 30.2687 30.2329 28.2158C32.265 26.1731 35.0975 25 38.0002 25C40.898 25 43.7329 26.1721 45.778 28.2162C47.8255 30.2621 49 33.0993 49 36C49 38.9007 47.8255 41.7376 45.7776 43.7834C43.7322 45.8276 40.8973 47 38.0002 47Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_0_1"
          x="0"
          y="0"
          width="76"
          height="76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_0_1"
          x1="68"
          y1="66"
          x2="8"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8298CB" />
          <stop offset="1" stopColor="#4B5F9C" />
        </linearGradient>
      </defs>
    </svg>
  );
};
