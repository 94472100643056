import { Fragment, useEffect, useRef, useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { LoadingSection, MaterialDesignSwitch } from "../../Components/Display";
import { LabelValue } from "../../Components/Form/LabelValue";
import classnames from "classnames";
import {
  useGlobalState,
  useGlobalDispatch,
  GlobalActionTypes,
} from "../../Context";
import { SkinnedButton } from "../../Components/Form/Buttons";
import { useApiWorker } from "../../Utilities/CommonHooks";
import {
  ProfileStatus,
  ProviderProfileVM,
} from "../../Components/ProviderProfile/Models";
import { ProfileStatusVM } from "./Models";
import { toast } from "react-toastify";
import "./Settings.scss";
import { genericServerError } from "../../Utilities/HelperData";
import ValidationErrors from "../Errors/ValidationErrors";
import SessionExpiredError from "../Errors/SessionExpiredError";
import { useHistory, useParams } from "react-router";
import { UrlHelper } from "../../Utilities/UrlHelper";
import { AdminHelper } from "../../Utilities/AdminHelper";
import { constants, apiUrls } from "../../Utilities/Constants";
import { SessionStorageHelper } from "../../Utilities/SessionStorageHelper";
import { NavLink } from "react-router-dom";
import { BillingManagement } from "./BillingManagement";
import { ThreeDots } from "react-loader-spinner";

export const Settings = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProfileStatusLoading, setIsProfileStatusLoading] = useState<boolean>(true);
  const {
    currentUser,
    customerSupportContactEmail,
    customerSupportContactPhoneNumber,
    providerProfileStatus,
  } = useGlobalState();
  const { id } = useParams<{ id: string }>();
  const apiWorker = useApiWorker();
  const [switchChecked, setSwitchChecked] = useState(false);
  const [hasExternalLogins, setHasExternalLogins] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [switchStatusModal, setSwitchStatusModal] = useState(false);
  const [serverErrors, setServerErrors] = useState<any[] | null>(null);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const dispatch = useGlobalDispatch();
  const scrollElementRef = useRef<null | HTMLDivElement>(null);
  const pageElementRef = useRef<null | HTMLDivElement>(null);
  const history = useHistory();
  const toggleSwitchStatusModal = () =>
    setSwitchStatusModal(!switchStatusModal);

  const handleServerErrors = (
    errors: any,
    include500Error: boolean = true
  ): boolean => {
    if (errors?.status === 401) {
      setSessionExpired(true);
      scrollToTop();
      return true;
    } else if (errors?.status === 500 && include500Error) {
      setServerErrors([
        genericServerError +
          `${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`,
      ]);
      scrollToTop();
      return true;
    }
    return false;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    let success = true;
    let result = await apiWorker
      .post(
        UrlHelper.getApiUrl(
          currentUser?.permissions,
          apiUrls.GetStripeSessionUrl,
          `${apiUrls.ImpersonateGetStripeSessionUrl}${id}`
        ),
        {},
        {}
      )
      .catch((err: any) => {
        setIsLoading(false);
        if (handleServerErrors(err, false)) success = false;
      });

    if (!success) return;

    setIsLoading(false);
    if (result && result.data && result.data.success && success) {
      setServerErrors([]);
      window.open(result.data.redirectUrl, "_blank");
    } else {
      setServerErrors([
        `There was an issue connecting to the billing portal, please try again. If further issues persist please contact customer service at ${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`,
      ]);
      scrollToTop();
    }
  };

  useEffect(() => {
    if (currentUser) {
      getPublicProfileStatus();
      SessionStorageHelper.clearSessionStorageItem(constants.ReturnUrl);
    }
  }, [currentUser]);

  const getPublicProfileStatus = async () => {
    let success = true;
    const result = await apiWorker
      .get<ProfileStatusVM>(
        UrlHelper.getApiUrl(
          currentUser?.permissions,
          apiUrls.GetProfileStatusUrl,
          `${apiUrls.ImpersonateGetProfileStatusUrl}${id}`
        ),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((err) => {
        if (handleServerErrors(err)) success = false;
        setIsProfileStatusLoading(false);
      });

    if (!success) return;

    setIsProfileStatusLoading(false);
    if (result && result.data) {
      setServerErrors([]);
      // false means Listed
      setSwitchChecked(result.data.isUnlisted!);
      setLoginEmail(result.data.loginEmail || "");
      setHasExternalLogins(result.data.hasExternalLogins || false);
    }
  };

  const handleSwitchChange = () => {
    document.querySelector(".main__content")?.scrollTo(0, 0);
    toast.dismiss();
    toggleSwitchStatusModal();
  };

  const postSwitchChange = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    toast.dismiss();
    toggleSwitchStatusModal();
    let success = true;
    const result = await apiWorker
      .put<boolean>(
        UrlHelper.getApiUrl(
          currentUser?.permissions,
          apiUrls.SetPublicProfileStatusUrl,
          `${apiUrls.ImpersonateSetPublicProfileStatusUrl}${id}`
        ),
        { isHidden: !switchChecked },
        {}
      )
      .catch((err: any) => {
        setIsLoading(false);
        if (handleServerErrors(err)) success = false;
        toggleSwitchStatusModal();
      });

    if (!success) return;

    if (result) {
      setServerErrors(null);
      toast.success(
        result.data
          ? "Your profile has been unlisted from therapist.com and will exclude you from any search results"
          : "Your profile is live on therapist.com"
      );
      // true means unlisted. result.data is equal to the value we sent in the PUT request above
      setSwitchChecked(result.data);
      success = true;
      const profileStatus = await apiWorker
        .get<ProviderProfileVM>(
          UrlHelper.getApiUrl(
            currentUser?.permissions,
            apiUrls.PublicProfileStatusUrl,
            `${apiUrls.ImpersonatePublicProfileStatusUrl}${id}`
          )
        )
        .catch((err: any) => {
          setIsLoading(false);
          if (handleServerErrors(err)) success = false;
        });

      if (!success) return;

      if (profileStatus && profileStatus.data) {
        setServerErrors([]);
        dispatch({
          type: GlobalActionTypes.SetProfileStatus,
          payload: profileStatus.data,
        });
      }
    }
    setIsLoading(false);
  };

  const scrollToTop = () => {
    if (scrollElementRef && scrollElementRef.current) {
      scrollElementRef.current.scrollIntoView({ behavior: "auto" });
    }
  };

  return (
    <Container fluid={true} className="mt-4 position-relative">
      <LoadingSection
        isLoading={isLoading}
        height={pageElementRef.current?.clientHeight}
        width={pageElementRef.current?.clientWidth}
      >
        <div ref={scrollElementRef} id="scrollElement"></div>
        <Modal
          fade={false}
          isOpen={switchStatusModal}
          toggle={toggleSwitchStatusModal}
        >
          <ModalHeader toggle={toggleSwitchStatusModal}>
            Change Profile Status Confirmation
          </ModalHeader>
          <ModalBody className="large-font">
            Are you sure you want to {switchChecked ? "list" : "unlist"} your
            profile?{" "}
            {!switchChecked ? (
              <div>
                <br></br>
                Setting this to unlisted will remove your profile listing from
                therapist.com and will exclude you from any search results.
              </div>
            ) : (
              ""
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={postSwitchChange}
              className="px-4 large-font"
            >
              Yes
            </Button>
            <Button
              color="secondary"
              onClick={toggleSwitchStatusModal}
              className="large-font"
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <div ref={pageElementRef}>
          <Form className="ml-5 mr-5 settings">
            <Row>
              <Col md="12" className="px-0">
                <ValidationErrors
                  serverErrors={serverErrors}
                  formErrors={{}}
                  customErrors={[]}
                />
                <SessionExpiredError showError={sessionExpired} />
              </Col>
            </Row>
            <Row className="pb-4 pt-3 mt-2">
              <Col md="12" className="rounded">
                <Row className="pb-4">
                  <Col md="12" className="px-0">
                    <Label className={classnames("subTitle")}>
                      Log in Email Address
                    </Label>
                  </Col>
                  <Col md="12" className="px-0">
                    <LabelValue
                      labelName="Email Address"
                      formGroupClass="mb-1"
                      fieldText={
                        AdminHelper.userIsAdmin(currentUser?.permissions)
                          ? loginEmail
                          : currentUser?.emailAddress ||
                            currentUser?.preferred_username ||
                            ""
                      }
                      className="large-font"
                      hideLabel={true}
                    ></LabelValue>
                  </Col>
                  {(AdminHelper.userIsAdmin(currentUser?.permissions)
                    ? !hasExternalLogins
                    : currentUser?.idp !== "Google" &&
                      currentUser?.idp !== "Facebook" &&
                      currentUser?.idp !== "AzureOpenId" &&
                      currentUser?.idp !== "OpenIdConnect") && (
                    <Col md="12" className="pb-4 px-0">
                      <SkinnedButton
                        onClick={() => {
                          history.push(
                            UrlHelper.getMenuLink(
                              currentUser?.permissions,
                              "/change-password",
                              `${constants.PartialImpersonateBrowserPath}change-password/${id}`
                            )
                          );
                        }}
                        color="primary"
                        className="px-4"
                      >
                        Change Password
                      </SkinnedButton>
                    </Col>
                  )}
                  {!isProfileStatusLoading &&
                    providerProfileStatus?.profileStatus &&
                    providerProfileStatus?.profileStatus.value !=
                      ProfileStatus.Denied &&
                    providerProfileStatus?.profileStatus.value !=
                      ProfileStatus.Incomplete &&
                    providerProfileStatus?.profileStatus.value !=
                      ProfileStatus.DeactivatedAndLoggedOut &&
                    providerProfileStatus?.profileStatus.value !=
                      ProfileStatus.PendingApproval && (
                      <Fragment>
                        <Col md="12" className="px-0">
                          <Label className={classnames("subTitle", "pt-4")}>
                            Public Profile Status
                          </Label>
                        </Col>
                        <Col md="12" className="pb-2 large-font px-0">
                          Turning this off will remove your profile listing
                          from therapist.com and will exclude you from any
                          search results.
                        </Col>
                        <Col md="12" className="pb-2 px-0">
                          <Row style={{ padding: "1em" }}>
                            <span className="mt-1 mr-3 regular-font">
                              Listed
                            </span>
                            <MaterialDesignSwitch
                              status={switchChecked}
                              onChange={handleSwitchChange}
                              className="align-middle mb-0"
                            />
                            <span className="mt-1 ml-3 regular-font">
                              Unlisted
                            </span>
                          </Row>
                        </Col>
                      </Fragment>
                    )}
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#4b5f9c"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={isProfileStatusLoading}
                  />
                  <Col md="12" className="px-0">
                    <Label className={classnames("subTitle", "pt-4")}>
                      Customer Support
                    </Label>
                  </Col>
                  <Col md="12" className="large-font px-0">
                    For assistance changing your log in email or with any other
                    account issues please contact therapist.com customer support
                    by using our{" "}
                    <NavLink
                      className="link"
                      to={UrlHelper.getMenuLink(
                        currentUser?.permissions,
                        "/contact-support",
                        `${constants.PartialImpersonateBrowserPath}contact-support/${id}`
                      )}
                    >
                      contact form
                    </NavLink>
                    , or by emailing us at{" "}
                    <a
                      className="link"
                      href={`mailto:${customerSupportContactEmail}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {customerSupportContactEmail}
                    </a>
                    .
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="px-0">
                    <Label className={classnames("subTitle", "pt-4")}>
                      Billing & Subscription
                    </Label>
                  </Col>
                  {/*<Col md="12">*/}
                  {/*  <BillingManagement id={id} />*/}
                  {/*</Col>*/}
                  <Col md="12" className="pb-2 large-font px-0 pt-5">
                    Use the billing portal to manage your subscription and edit
                    billing information. Pop-ups may need to be enabled to
                    navigate to the payment portal.
                  </Col>
                  <Col md="12" className="pb-4 px-0">
                    <SkinnedButton
                      className="px-4"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      &nbsp;&nbsp;Billing Portal&nbsp;&nbsp;
                    </SkinnedButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </LoadingSection>
    </Container>
  );
};
