import { BaseEnum, CoreEnum } from "../../../Features/Shared/Models/SharedModels";
import { CheckboxVM } from "./CheckboxVM";
import { FileType } from "./FileType";
import {
  ProfessionalAssociationVM, BusinessHoursVM, LicenseVM, CertificationVM,
  BoardCertificationVM, SchoolVM, ProviderGroupVM
} from "./index";
import { OfficePhotoVM } from "./OfficePhotoVM";

export class ProviderProfileVM {
  [key: string]: any
  id?: string | null;
  firstName?: string;
  lastName?: string;
  designation?: string;
  genderTypes?: CoreEnum[];
  genderType?: BaseEnum;
  pronounTypes?: CoreEnum[];
  pronounType?: BaseEnum;
  otherPronoun?: string;
  ethnicities?: CoreEnum[];
  ethnicity?: BaseEnum;
  headline?: string;
  photoFilename?: string;
  aboutMe?: string;
  businessAddress?: string;
  businessHours?: BusinessHoursVM[];
  licenses?: LicenseVM[];
  businessName?: string;
  HidePracticeAddress?: boolean;
  officeIsAdaAccessible?: boolean;
  email?: string;
  contactPhoneNumber?: string;
  sms?: string;
  linkedInUrl?: string;
  twitterUrl?: string;
  websiteUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  files?: any;
  practiceAddress?: string;
  states?: CoreEnum[];
  isLicensed?: boolean;
  licenseTypes?: CoreEnum[];
  preLicenseTypes?: CoreEnum[];
  preLicenseType?: CoreEnum;
  races?: CoreEnum[];
  race?: CoreEnum;
  certificationOrganizationTypes? : CoreEnum[];
  supervisorCredentialTypes?: CoreEnum[];
  supervisorCredentialType?: CoreEnum;
  supervisorLicenseNumber?: string;
  supervisorState?: BaseEnum;
  supervisorFirstName?: string; 
  supervisorLastName?: string;
  supervisorConvertedExpirationMonth?: CoreEnum;
  supervisorConvertedExpirationYear?: CoreEnum;
  supervisorExpirationMonth?: number | null;
  supervisorExpirationYear?: number | null;
  supervisorLicensingBoardUrl?: string;
  certifications?: CertificationVM[];
  boardCertifications?: BoardCertificationVM[];
  professionalAssociations?: ProfessionalAssociationVM[];
	schools?: SchoolVM[];
  practicingSince?: number;
  fileTypes?: FileType[];
  officePhotos?: OfficePhotoVM[];
  inMemoryOfficePhotos?: OfficePhotoVM[];
  youTubeUrl?: string;
  providerGroup?: ProviderGroupVM;
  
  creditCards?: CheckboxVM[];
  serviceTypes?: CheckboxVM[];
  sessionTypes?: CheckboxVM[];
  clientDemographics?: CheckboxVM[];
  clinicalSpecialties?: CheckboxVM[];
  therapyTypes?: CheckboxVM[];
  communityTypes?: CheckboxVM[];
  faithTypes?: CheckboxVM[];
  paymentMethodTypes?: CheckboxVM[];
  languageTypes?: CheckboxVM[];
  insurancePlans?: CheckboxVM[];
  
  selectedCreditCards?: CoreEnum[];
  selectedServiceTypes?: CoreEnum[];
  selectedSessionTypes?: CoreEnum[];
  selectedClientDemographics?: CoreEnum[];
  selectedClinicalSpecialties?: CoreEnum[];
  selectedTherapyTypes?: CoreEnum[];
  selectedCommunityTypes?: CoreEnum[];
  selectedFaithTypes?: CoreEnum[];
  selectedPaymentMethodTypes?: CoreEnum[];
  selectedLanguageTypes?: CoreEnum[];
  selectedInsurancePlans?: CoreEnum[];
  
  isAcceptingNewPatients?: boolean;
  isAcceptingInsurance?: boolean;
  isSlidingScale?: boolean;
  isFreeInitialConsultation?: boolean;
  // if the input has been edited and cleared, value for costFrom is ''
  costFrom?: number | string;
  costTo?: number | string;
  suite?: string;
  
  profileStatus?: CoreEnum;
  accountType?: CoreEnum;
  publishedStatus?: CoreEnum;
  publishedProfileUrl?: string;
  timezones?: CoreEnum[];
  timezone?: CoreEnum;

  // helper property  
  removeOfficePhotosFromMemory?: string[];
  publishSucceeded?: boolean;
  runPublishingValidators?: boolean;
  otherInsurancePlans?: string;
  otherLanguageSpoken?: string;
  otherClinicalSpecialty?: string;
  otherTherapyType?: string;
  otherCommunityType?: string;
  otherFaithType?: string;
  isIncompleteForPublishing?: boolean;
  hasSubmittedProfileForApproval?: boolean;
  loginEmail?: string;
}