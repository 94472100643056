import { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";

interface IProps {
  initialValues: any;
  validationSchema: any;
  onSubmit: any;
  formChanged?: () => void;
}

export const useCustomForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  formChanged = () => true,
}: IProps) => {
  const [values, setValues] = useState(initialValues || {});
  const [touched, setTouched] = useState<string[]>([]);
  const [formErrors, setFormErrors] = useState<any>({});
  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);
  const [onBlur, setOnBlur] = useState<boolean>(false);

  const formRendered = useRef(true);

  useEffect(() => {
    if (formRendered.current) {
      setValues(initialValues);
      setFormErrors({});
      setTouched([]);
      setOnSubmitting(false);
      setOnBlur(false);
    }
    formRendered.current = false;
  }, [initialValues]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { name, value } = target;
    event.persist();

    if (event.target.type === "checkbox") {
      setValues({ ...values, [name]: event.target.checked });
    } else {
      setValues({ ...values, [name]: value });
    }
    formChanged();
  };

  const handleYesNoRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({
      ...values,
      [event?.target.name]: event.target.value === "true",
    });
    formChanged();
  };

  const radioButtonChange = (event: any) => {
    setValues({
      ...values,
      [event?.target.name]: event.target.value === "true",
    });
    formChanged();
  };

  const handleChangeInDropDown = (
    event: React.ChangeEvent<HTMLInputElement>,
    source: string
  ) => {
    const name = event?.target.name;
    let value;
    if (event.target.type === "select") {
      value = {
        value: event.target.value,
        label: source,
      };
    } else {
      value = event.target.value;
    }
    setValues({ ...values, [name]: value });
    formChanged();
  };

  const handleBlur = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let touchedElements: string[] = [];
    if (!touched.includes(event.target.name)) {
      touchedElements = [...touched, event.target.name];
      setTouched(touchedElements);
    } else {
      touchedElements = touched;
    }
    setFormErrors({});
    await validateForm(false, touchedElements, values);
  };

  const handleBlurNoValidate = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let touchedElements: string[] = [];
    if (!touched.includes(event.target.name)) {
      touchedElements = [...touched, event.target.name];
      setTouched(touchedElements);
    } else {
        touchedElements = touched;
    }
  };

  async function validateForm(
    ignoreTouchedFields: boolean = false,
    touchedElements: string[],
    values: any
  ) {
    let errors: any = {};
    try {
      const res = await validationSchema.validate(values, {
        abortEarly: false,
        context: { currentYear: new Date().getFullYear() },
      });
      setFormErrors({});
    } catch (e:any) {
      e.inner.map((err: Yup.ValidationError) => {
        errors[err.path!] = err.message;
      });
      const touchedErrors = Object.keys(errors)
        .filter((key) => touchedElements.includes(key))
        .reduce((acc: any, key) => {
          if (!acc[key]) {
            acc[key] = errors[key];
          }
          return acc;
        }, {});
      if (ignoreTouchedFields) {
        // this allows highlight to persist after submitting form even when not all
        // fields have been touched
        setTouched(Object.keys(errors));
      }
      setFormErrors(ignoreTouchedFields ? errors : touchedErrors);
      return errors;
    }
  }

  const handleSubmit = async (event: any) => {
    toast.dismiss();
    if (event) event.preventDefault();
    const errors = await validateForm(true, [], values);
    setFormErrors({ ...errors });
    await onSubmit({ values, errors });
  };

  return {
    values,
    formErrors,
    touched,
    handleChange,
    handleBlur,
    handleBlurNoValidate,
    handleSubmit,
    handleChangeInDropDown,
    handleYesNoRadioChange,
    radioButtonChange,
    setValues,
    setFormErrors,
  };
};
