import * as Yup from "yup";
import { AnyObject, Maybe } from "yup/lib/types";
import {
  ProviderProfileVM,
  FileType,
  SchoolVM,
  LicenseVM,
  ProfileStatus,
  PublishStatus,
} from "../Components/ProviderProfile/Models";
import { CoreEnum } from "../Features/Shared/Models/SharedModels";
import { format } from "date-fns";
import { v4 as uuid } from "uuid";
import { dropdownSelectLabel } from "../Utilities/IsMobile";

export const basePathName = "/providers";
export const urlRegex =
  /^$|^((https|http):\/\/)?[a-z0-9.\-~]+\.([a-z]{2,10})(\/[\-\w@\+\.~#\?&\/=%]*)?$/i;
export const redBorder = "solid 2px #dd2a34";
export const grayBorder = "1px solid";
export const blueBorder = "solid 2px #adc5de";
export const white = "white";
export const gray = "#dadada";
export const red = "#f8d7da";
export const blueish = "#292f42";
export const darkBlue = "#4B5F9C";
export const adminGenericError =
  "There was an unexpected error with your request, please try again. If further issues persist please contact IT.";
export const profileNotLoaded =
  "Please refresh the page, your profile data did not load correctly.";
export const genericServerError =
  "There was an unexpected error with your request, please try again. If further issues persist please contact customer service at ";

export const months: any[] = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const dropDownStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: blueBorder,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: darkBlue,
  }),
};

const currentYear = new Date().getFullYear();
const firstYear = 2022;
const firstHistoricYear = 1922;

export const years: any[] = Array(100 + (currentYear - firstYear))
  .fill("")
  .map((v, idx) => ({
    value: (firstYear + idx).toString(),
    label: (firstYear + idx).toString(),
  })) as Array<any>;

export const historicYears: any[] = Array(currentYear - firstHistoricYear + 1)
  .fill("")
  .map((v, idx) => ({
    value: (firstHistoricYear + idx).toString(),
    label: (firstHistoricYear + idx).toString(),
  }))
  .reverse() as Array<any>;

const shouldValidateProfessionalLicenses = (
  context: Yup.TestContext<Record<string, any>>
): boolean => {
  const parent = context.parent;

  // No need to run validation if the User is not publishing
  if (!parent.runPublishingValidators) {
    return false;
  }

  // Always run the validation when it's the first license in the array
  if (!parent.position || parent.position === 0) {
    return true;
  }

  // Check the rest of the fields for the presence of data to prompt further validation
  return (
    parent.state?.value ||
    parent.licenseNumber ||
    parent.licenseType?.value ||
    parent.expirationMonth ||
    parent.expirationYear
  );
};

const validateRequiredProfessionalLicenseDropdowns = (
  value: any,
  context: Yup.TestContext<Record<string, any>>
): boolean | Yup.ValidationError => {
  if (shouldValidateProfessionalLicenses(context)) {
    if (!value?.label) {
      return context.createError({
        path: context.path + ".label",
      });
    }
  }
  return true;
};

const shouldValidateProcessionalCertifications = (
  context: Yup.TestContext<Record<string, any>>
): boolean => {
  const parent = context.parent;
  if (!parent.runPublishingValidators) {
    return false;
  }

  return (
    parent.certificationName ||
    parent.certificationNumber ||
    parent.certificationOrganizationType?.value ||
    parent.issuedYear?.value ||
    parent.expirationYear?.value
  );
};

const validateRequiredProfessionalCertificationDropdowns = (
  value: any,
  context: Yup.TestContext<Record<string, any>>
): boolean | Yup.ValidationError => {
  if (shouldValidateProcessionalCertifications(context)) {
    if (!value?.label) {
      return context.createError({
        path: context.path + ".label",
      });
    }
  }
  return true;
};

const shouldValidateEducationFields = (
  context: Yup.TestContext<Record<string, any>>
): boolean => {
  const parent = context.parent;
  if (!parent.runPublishingValidators) {
    return false;
  }

  // Always run the validation when it's the first school in the array
  if (!parent.position || parent.position === 0) {
    return true;
  }

  return parent.name || parent.degree || parent.yearGraduated;
};

Yup.addMethod<Yup.NumberSchema>(
  Yup.number,
  "isLessThanOrEqualToCurrentYear",
  function (errorMessage) {
    return this.test(
      "isLessThanOrEqualToCurrentYear",
      errorMessage,
      function (value) {
        const { path, createError } = this;

        return (
          (value || 0) <= this.options?.context?.currentYear ||
          createError({ path, message: errorMessage })
        );
      }
    );
  }
);

declare module "Yup" {
  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    isLessThanOrEqualToCurrentYear(
      errorMessage: string
    ): NumberSchema<TType, TContext>;
  }
}
export const providerProfileValidationSchema = Yup.object({
  firstName: Yup.string()
    .max(255, "The length of First Name must be 255 characters or fewer")
    .required(""),
  lastName: Yup.string()
    .max(255, "The length of Last Name must be 255 characters or fewer")
    .required(""),
  designation: Yup.string()
    .max(
      50,
      "The length of Professional Designation must be 50 characters or fewer"
    )
    .nullable(),
  headline: Yup.string()
    .max(
      1000,
      "The length of Professional Statement must be 1,000 characters or fewer"
    )
    .nullable(),
  otherPronoun: Yup.string()
    .max(100, "The length of Other Pronoun must be 100 characters or fewer")
    .nullable()
    .when("runPublishingValidators", {
      is: true,
      then: Yup.string()
        .nullable()
        .test("validations", "", function (value) {
          if (
            this.parent.pronounTypes?.filter(
              (p: any) => p.value === this.parent.pronounType?.value
            )[0]?.label === "Other" &&
            !value
          ) {
            return false;
          }
          return true;
        }),
    }),
  aboutMe: Yup.string()
    .nullable()
    .max(10000, "The length of About me must be 10,000 characters or fewer"),
  businessName: Yup.string()
    .nullable()
    .max(100, "The length of Business Name must be 100 characters or fewer"),
  practiceAddress: Yup.string()
    .nullable()
    .max(500, "The length of Business Address must be 500 characters or fewer")
    .when("runPublishingValidators", {
      is: true,
      then: Yup.string()
        .nullable()
        .test("validations", (items, { createError, parent }) => {
          let aggregateError: any;

          // Check address is filled when session type is In Person and suite is filled
          if (
            !parent.practiceAddress &&
            parent.selectedSessionTypes.find(
              (x: any) => x.label === "In Person"
            ) &&
            parent.suite
          ) {
            aggregateError = Helper.addError(
              aggregateError,
              "practiceAddress",
              "",
              createError
            );
            return aggregateError || true;
          }

          // Check address is filled when suite is filled
          if (!parent.practiceAddress && parent.suite) {
            aggregateError = Helper.addError(
              aggregateError,
              "practiceAddress",
              "",
              createError
            );
            return aggregateError || true;
          }

          // Check address is filled when session type is In Person
          if (
            !parent.practiceAddress &&
            parent.selectedSessionTypes.find(
              (x: any) => x.label === "In Person"
            )
          ) {
            aggregateError = Helper.addError(
              aggregateError,
              "practiceAddress",
              "",
              createError
            );
            return aggregateError || true;
          }

          return true;
        }),
    }),
  suite: Yup.string()
    .nullable()
    .max(50, "The length of Suite must be 50 characters or fewer"),
  contactPhoneNumber: Yup.string()
    .nullable()
    .max(
      15,
      "The length of Contact Phone Number must be 15 characters or fewer"
    )
    .when("runPublishingValidators", {
      is: true,
      then: Yup.string()
        .nullable()
        .matches(/^[0-9]{10}$|^$/, "Please enter a valid Contact Phone Number"),
    }),
  sms: Yup.string()
    .nullable()
    .max(15, "The length of Text Message must be 15 characters or fewer")
    .when("runPublishingValidators", {
      is: true,
      then: Yup.string()
        .nullable()
        .matches(
          /^[0-9]{10}$|^$/,
          "Text Message does not contain a valid number"
        ),
    }),
  businessHours: Yup.array().test("validations", (items, { createError }) => {
    let aggregateError: any;
    let failedValidation = false;
    if (items && items.length) {
      for (let index = 0; index < items.length; index++) {
        let daysSelected = false;
        let hoursSelected = false;
        if (
          items[index].runPublishingValidators &&
          (items[index].onMonday ||
            items[index].onTuesday ||
            items[index].onWednesday ||
            items[index].onThursday ||
            items[index].onFriday ||
            items[index].onSaturday ||
            items[index].onSunday)
        ) {
          daysSelected = true;
        }
        if (
          items[index].runPublishingValidators &&
          (items[index].endTime || items[index].startTime)
        ) {
          hoursSelected = true;
        }
        if (
          items[index].runPublishingValidators &&
          hoursSelected &&
          !items[index].onMonday &&
          !items[index].onTuesday &&
          !items[index].onWednesday &&
          !items[index].onThursday &&
          !items[index].onFriday &&
          !items[index].onSaturday &&
          !items[index].onSunday
        ) {
          failedValidation = true;
          aggregateError = Helper.addError(
            aggregateError,
            "businessHoursOnMonday",
            "",
            createError
          );
          aggregateError = Helper.addError(
            aggregateError,
            `businessHours[${index}].onMonday`,
            "At least one day of the week must be selected in the Hours of Operation section",
            createError
          );
        }

        if (
          items[index].runPublishingValidators &&
          daysSelected &&
          (!items[index].startTime ||
            (items[index].startTime instanceof Date &&
              isNaN(items[index].startTime.getTime())))
        ) {
          failedValidation = true;
          aggregateError = Helper.addError(
            aggregateError,
            "businessHoursStartTime",
            "",
            createError
          );
          aggregateError = Helper.addError(
            aggregateError,
            `businessHours[${index}].startTime`,
            "",
            createError
          );
        }

        if (
          items[index].runPublishingValidators &&
          daysSelected &&
          (!items[index].endTime ||
            (items[index].endTime instanceof Date &&
              isNaN(items[index].endTime.getTime())))
        ) {
          failedValidation = true;
          aggregateError = Helper.addError(
            aggregateError,
            "businessHoursEndTime",
            "",
            createError
          );
          aggregateError = Helper.addError(
            aggregateError,
            `businessHours[${index}].endTime`,
            "",
            createError
          );
        }

        if (
          items[index].runPublishingValidators &&
          items[index].startTime > items[index].endTime
        ) {
          aggregateError = Helper.addError(
            aggregateError,
            "businessHoursEndTime",
            "",
            createError
          );
          failedValidation = true;
          aggregateError = Helper.addError(
            aggregateError,
            `businessHours[${index}].endTime`,
            "Start Time must not be greater than End Time in the Hours of Operation section",
            createError
          );
        }
      }

      if (!failedValidation) {
        for (let index = 0; index < items.length; index++) {
          let overlap = items.filter(
            (x) =>
              x.startTime &&
              x.endTime &&
              items[index].startTime != null &&
              items[index].endTime != null &&
              x.startTime < items[index].endTime &&
              items[index].startTime < x.endTime &&
              ((x.onMonday && items[index].onMonday) ||
                (x.onTuesday && items[index].onTuesday) ||
                (x.onTuesday && items[index].onTuesday) ||
                (x.onWednesday && items[index].onWednesday) ||
                (x.onThursday && items[index].onThursday) ||
                (x.onFriday && items[index].onFriday) ||
                (x.onSaturday && items[index].onSaturday) ||
                (x.onSunday && items[index].onSunday))
          );

          if (overlap && overlap.length && overlap.length > 1) {
            aggregateError = Helper.addError(
              aggregateError,
              `businessHoursStartTime`,
              "",
              createError
            );
            aggregateError = Helper.addError(
              aggregateError,
              `businessHours[${index}].startTime`,
              `The schedules in 'Hours of operation' overlap (${format(
                overlap[0].startTime,
                "hh:mm aa"
              )} - ${format(overlap[0].endTime, "hh:mm aa")}).`,
              createError
            );
          }
        }
      }
    }
    return aggregateError || true;
  }),
  timezone: Yup.object()
    .nullable()
    .test("validations", "", function (value) {
      let emptyBusinessHours = true;

      let items = this.parent.businessHours;
      if (items && items.length) {
        for (let index = 0; index < items.length; index++) {
          if (
            items[index].onFriday ||
            items[index].onMonday ||
            items[index].onSaturday ||
            items[index].onSunday ||
            items[index].onThursday ||
            items[index].onTuesday ||
            items[index].onWednesday ||
            (!!items[index].startTimeUI &&
              items[index].startTimeUI !== "__:__") ||
            (!!items[index].endTimeUI && items[index].endTimeUI !== "__:__") ||
            items[index].startPeriodType?.label === "PM" ||
            items[index].endPeriodType?.label === "PM"
          ) {
            emptyBusinessHours = false;
          }
        }
      }

      if (!emptyBusinessHours && !value?.value) {
        return false;
      }
      return true;
    }),
  businessEmail: Yup.string()
    .nullable()
    .email("Please enter a valid Business Email")
    .max(255, "The length of Business Email must be 255 characters or fewer")
    .when("runPublishingValidators", {
      is: true,
      then: Yup.string().nullable().required(""),
    }),
  linkedInUrl: Yup.string()
    .matches(
      urlRegex,
      "Invalid URL format. Please enter an address with a format similar to www.linkedin.com/myprofileid."
    )
    .max(
      2000,
      "The length of LinkedIn address must be 2000 characters or fewer"
    )
    .nullable(),
  twitterUrl: Yup.string()
    .matches(
      urlRegex,
      "Invalid URL format. Please enter an address with a format similar to www.twitter.com/myhandle."
    )
    .max(2000, "The length of Twitter address must be 2000 characters or fewer")
    .nullable(),
  facebookUrl: Yup.string()
    .matches(
      urlRegex,
      "Invalid URL format. Please enter an address with a format similar to www.facebook.com/myprofile."
    )
    .max(
      2000,
      "The length of Facebook address must be 2000 characters or fewer"
    )
    .nullable(),
  instagramUrl: Yup.string()
    .matches(
      urlRegex,
      "Invalid URL format. Please enter an address with a format similar to www.instagram.com/myusername."
    )
    .max(
      2000,
      "The length of Instagram address must be 2000 characters or fewer"
    )
    .nullable(),
  websiteUrl: Yup.string()
    .matches(
      urlRegex,
      "Invalid URL format. Please enter an address with a format similar to www.yourwebsite.com."
    )
    .max(2000, "The length of Website address must be 2000 characters or fewer")
    .nullable(),
  isLicensed: Yup.boolean()
    .nullable()
    .when("runPublishingValidators", {
      is: true,
      then: Yup.boolean()
        .nullable()
        .transform((value: string, originalValue: string) => {
          return originalValue === null ? undefined : value;
        })
        .required(""),
    }),
  preLicenseType: Yup.object()
    .nullable()
    .when("runPublishingValidators", {
      is: true,
      then: Yup.object()
        .nullable()
        .when("isLicensed", {
          is: false,
          then: Yup.object().required("").nullable(),
        }),
    }),
  supervisorCredentialType: Yup.object()
    .nullable()
    .when("runPublishingValidators", {
      is: true,
      then: Yup.object()
        .nullable()
        .when("isLicensed", {
          is: false,
          then: Yup.object().required("").nullable(),
        }),
    }),
  supervisorLicenseNumber: Yup.string()
    .max(
      100,
      "The length of Supervisor License Number must be 100 characters or fewer"
    )
    .nullable()
    .when("runPublishingValidators", {
      is: true,
      then: Yup.string()
        .nullable()
        .when("isLicensed", {
          is: false,
          then: Yup.string().nullable().required(""),
        }),
    }),
  supervisorFirstName: Yup.string()
    .max(
      255,
      "The length of Supervisor First Name must be 255 characters or fewer"
    )
    .nullable()
    .when("runPublishingValidators", {
      is: true,
      then: Yup.string()
        .nullable()
        .when("isLicensed", {
          is: false,
          then: Yup.string().nullable().required(""),
        }),
    }),
  supervisorLastName: Yup.string()
    .max(
      255,
      "The length of Supervisor Last Name must be 255 characters or fewer"
    )
    .nullable()
    .when("runPublishingValidators", {
      is: true,
      then: Yup.string()
        .nullable()
        .when("isLicensed", {
          is: false,
          then: Yup.string().nullable().required(""),
        }),
    }),
  supervisorState: Yup.object()
    .nullable()
    .when("runPublishingValidators", {
      is: true,
      then: Yup.object()
        .default({})
        .nullable()
        .when("isLicensed", {
          is: false,
          then: Yup.object().required("").nullable(),
        }),
    }),
  supervisorConvertedExpirationMonth: Yup.object()
    .nullable()
    .when("runPublishingValidators", {
      is: true,
      then: Yup.object()
        .nullable()
        .when("isLicensed", {
          is: false,
          then: Yup.object()
            .transform((value: string, originalValue: string) => {
              return originalValue === null ? undefined : value;
            })
            .required("")
            .nullable(),
        }),
    }),
  supervisorConvertedExpirationYear: Yup.object()
    .nullable()
    .when("runPublishingValidators", {
      is: true,
      then: Yup.object()
        .nullable()
        .when("isLicensed", {
          is: false,
          then: Yup.object()
            .transform((value: string, originalValue: string) => {
              return originalValue === null ? undefined : value;
            })
            .required(""),
        }),
    }),
  supervisorLicensingBoardUrl: Yup.string()
    .max(
      2000,
      "The length of Supervisor Licensing Board Website must be 2000 characters or fewer"
    )
    .matches(
      urlRegex,
      "Invalid URL format. Please enter a URL with a format similar to http://licensesearch.wi.gov/."
    )
    .nullable(),
  youTubeUrl: Yup.string()
    .matches(
      urlRegex,
      "Invalid URL format. Please enter an address with a format similar to www.youtube.com/watch?v=video."
    )
    .nullable()
    .max(
      2000,
      "The length of YouTube address must be 2000 characters or fewer"
    ),
  officePhotos: Yup.array()
    .test(
      "validations",
      "The max number of office images is 10",
      function (value) {
        let total = 0;
        if (value && value.length) {
          total += value.length;
        }
        if (
          this.parent.inMemoryOfficePhotos &&
          this.parent.inMemoryOfficePhotos.length
        ) {
          total += this.parent.inMemoryOfficePhotos.length;
        }
        return total <= 10;
      }
    )
    .nullable()
    .of(
      Yup.object({
        description: Yup.string()
          .nullable()
          .max(
            1000,
            "The length of the office photo description must be 100 characters or fewer"
          ),
      })
    ),
  inMemoryOfficePhotos: Yup.array().of(
    Yup.object({
      description: Yup.string()
        .nullable()
        .max(
          1000,
          "The length of the office photo description must be 100 characters or fewer"
        )
        .when("runPublishingValidators", {
          is: true,
          then: Yup.string().required(""),
        }),
    })
  ),
  licenses: Yup.array().when("isLicensed", {
    is: true,
    then: Yup.array()
      .of(
        Yup.object({
          licenseNumber: Yup.string()
            .nullable()
            .max(
              100,
              "The length of License Number must be 100 characters or fewer"
            )
            .test({
              name: "further-validate-license-number",
              message: "",
              test: (value: any, context) => {
                if (shouldValidateProfessionalLicenses(context)) {
                  return Yup.string().required().isValid(value);
                }
                return true;
              },
            }),
          licenseType: Yup.object().nullable().test({
            name: "further-validate-license-type",
            message: "",
            test: validateRequiredProfessionalLicenseDropdowns,
          }),
          state: Yup.object().nullable().test({
            name: "further-validate-license-state",
            message: "",
            test: validateRequiredProfessionalLicenseDropdowns,
          }),
          convertedExpirationMonth: Yup.object().nullable().test({
            name: "further-validate-license-expiration-month",
            message: "",
            test: validateRequiredProfessionalLicenseDropdowns,
          }),
          convertedExpirationYear: Yup.object().nullable().test({
            name: "further-validate-license-expiration-year",
            message: "",
            test: validateRequiredProfessionalLicenseDropdowns,
          }),
          licensingBoardUrl: Yup.string()
            .nullable()
            .matches(
              urlRegex,
              "Invalid URL format. Please enter a URL with a format similar to http://licensesearch.wi.gov/."
            )
            .max(
              2000,
              "The length of Licensing Board Website must be 2000 characters or fewer"
            ),
        })
      )
      .when("runPublishingValidators", {
        is: true,
        then: Yup.array()
          .min(1, "Please enter at least one professional license")
      }),
  }),
  certifications: Yup.array()
    .of(
      Yup.object({
        certificationName: Yup.string()
          .nullable()
          .max(
            255,
            "The length of Certification Name must be 255 characters or fewer"
          )
          .test({
            name: "further-validate-certification-name",
            message: "",
            test: (value: any, context) => {
              if (shouldValidateProcessionalCertifications(context)) {
                return Yup.string().required().isValid(value);
              }
              return true;
            },
          }),
        certificationNumber: Yup.string().max(
          100,
          "The length of Certification Number must be 100 characters or fewer"
        ),
        certificationOrganizationType: Yup.object().nullable().test({
          name: "further-validate-certification-organization-type",
          message: "",
          test: validateRequiredProfessionalCertificationDropdowns,
        }),
        otherCertificationOrganizationType: Yup.string()
          .nullable()
          .max(
            255,
            "The length of the Other Certification Organization must be 255 characters or fewer"
          )
          .test({
            name: "further-validate-other-certification-type",
            message: "",
            test: (value: any, context) => {
              if (
                context.parent.certificationOrganizationType?.label ===
                  "Other" &&
                !value
              ) {
                return false;
              }
              return true;
            },
          }),
      })
    )
    .when("runPublishingValidators", {
      is: true || false,
      then: Yup.array().test("validations", (items, { createError }) => {
        let aggregateError: any;
        if (items && items.length) {
          for (let index = 0; index < items.length; index++) {
            if (items[index].issuedYear && items[index].expirationYear) {
              if (items[index].issuedYear > items[index].expirationYear) {
                aggregateError = Helper.addError(
                  aggregateError,
                  `certifications[${index}].expirationYear.label`,
                  "Certification Issued Year must not be greater than Certification Expiration Year",
                  createError
                );
              }
            }
          }
        }
        return aggregateError || true;
      }),
    }),
  boardCertifications: Yup.array().of(
    Yup.object({
      name: Yup.string()
        .nullable()
        .max(255, "The length of Name must be 255 characters or fewer")
        .when("runPublishingValidators", {
          is: true,
          then: Yup.string().nullable(),
        }),
    })
  ),
  professionalAssociations: Yup.array()
    .of(
      Yup.object({
        name: Yup.string()
          .nullable()
          .max(
            255,
            "The length of Professional Association Name must be 255 characters or fewer"
          )
          .test({
            name: "further-validate-professional-associations",
            message: "",
            test: (value: any, context) => {
              if (context.parent.membershipType) {
                return Yup.string().required().isValid(value);
              }
              return true;
            },
          }),
        membershipType: Yup.string()
          .nullable()
          .max(
            255,
            "The length of Membership Type must be 255 characters or fewer"
          ),
      })
    )
    .nullable(),
  schools: Yup.array()
    .of(
      Yup.object({
        name: Yup.string()
          .transform((value: string, originalValue: string) => {
            return originalValue === null ? undefined : value;
          })
          .max(255, "The length of School Name must be 255 characters or fewer")
          .test({
            name: "further-validate-school-name",
            message: "",
            test: (value: any, context) =>
              shouldValidateEducationFields(context)
                ? Yup.string().required().isValid(value)
                : true,
          }),
        degree: Yup.string()
          .transform((value: string, originalValue: string) => {
            return originalValue === null ? undefined : value;
          })
          .max(
            255,
            "The length of School Degree must be 255 characters or fewer"
          )
          .test({
            name: "further-validate-school-degree",
            message: "",
            test: (value: any, context) =>
              shouldValidateEducationFields(context)
                ? Yup.string().required().isValid(value)
                : true,
          }),
        yearGraduated: Yup.number()
          .nullable(true)
          .typeError("Year Graduated must be a number")
          .transform((value: string, originalValue: string) => {
            if (typeof originalValue === "string" && originalValue === "") {
              return null;
            }
            return value;
          })
          .isLessThanOrEqualToCurrentYear(
            "Year Graduated should be less than or equal to the current year"
          )
          .when("runPublishingValidators", {
            is: true,
            then: Yup.number()
              .nullable(true)
              .typeError("Year Graduated must be a number")
              .transform((value: string, originalValue: string) => {
                if (typeof originalValue === "string" && originalValue === "") {
                  return null;
                }
                return value;
              })
              .max(10000, "Year Graduated must be 4 digits")
              .min(999, "Year Graduated must be 4 digits")
              .test({
                name: "further-validate-school-graduation-year",
                message: "",
                test: (value: any, context) =>
                  shouldValidateEducationFields(context)
                    ? Yup.string().required().isValid(value)
                    : true,
              }),
          }),
      })
    )
    .nullable()
    .when("runPublishingValidators", {
      is: true,
      then: Yup.array().min(1, "Please enter a graduate school"),
    }),
  selectedSessionTypes: Yup.array().when("runPublishingValidators", {
    is: true,
    then: Yup.array().min(1, ""),
  }),
  costFrom: Yup.number()
    .nullable(true)
    .transform((value: string, originalValue: string) => {
      return originalValue
        ? originalValue.toString().trim() === ""
          ? null
          : value
        : null;
    })
    .typeError("Cost must be a number")
    .when("runPublishingValidators", {
      is: true,
      then: Yup.number()
        .nullable(true)
        .transform((value: string, originalValue: string) => {
          return originalValue
            ? originalValue.toString().trim() === ""
              ? null
              : value
            : null;
        })
        .min(0, "")
        .test(
          "validations",
          "Cost Per Session has an invalid range",
          function (value) {
            if (!isNaN(value || 0) && this.parent.costTo) {
              if ((value || 0) > this.parent.costTo) {
                return false;
              }
            }
            return true;
          }
        ),
    }),
  costTo: Yup.number()
    .nullable(true)
    .transform((value: string, originalValue: string) => {
      return originalValue
        ? originalValue.toString().trim() === ""
          ? null
          : value
        : null;
    })
    .typeError("Cost must be a number"),
  otherInsurancePlans: Yup.string()
    .nullable()
    .max(
      1000,
      "The length of the Other Insurance Plans must be 1000 characters or fewer"
    ),
  otherLanguageSpoken: Yup.string()
    .nullable()
    .max(
      1000,
      "The length of the Other Languages Spoken must be 1000 characters or fewer"
    ),
  otherClinicalSpecialty: Yup.string()
    .nullable()
    .max(
      1000,
      "The length of the Other Clinical Specialties must be 1000 characters or fewer"
    ),
  otherTherapyType: Yup.string()
    .nullable()
    .max(
      1000,
      "The length of the Other Therapy Types must be 1000 characters or fewer"
    ),
  otherCommunityType: Yup.string()
    .nullable()
    .max(
      1000,
      "The length of the Other Community Types must be 1000 characters or fewer"
    ),
  otherFaithType: Yup.string()
    .nullable()
    .max(
      1000,
      "The length of the Other Faith-Based Specialties must be 1000 characters or fewer"
    ),
});

Date.prototype.toJSON = function () {
  if (isNaN(this.getTime())) {
    return "";
  }
  const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
  const newDate = new Date(this);
  newDate.setHours(hoursDiff);
  return newDate.toISOString();
};

export class Helper {
  static setProfileRefs = async (
    profile: any,
    modifiedProviderProfile: ProviderProfileVM,
    officePhotos: any[]
  ): Promise<any> => {
    if (profile.data.businessHours) {
      profile.data.businessHours = profile.data.businessHours.map((x: any) => {
        if (x.startTime instanceof Date && !isNaN(x.startTime.getTime())) {
          let am_pm = x.startTime!.getHours() >= 12 ? "PM" : "AM";
          const startPeriod = new CoreEnum();
          startPeriod.label = am_pm;
          startPeriod.value = am_pm;
          x.startPeriodType = startPeriod;
          x.startTimeUI = format(x.startTime!, "hh:mm");
        }
        if (x.endTime instanceof Date && !isNaN(x.endTime.getTime())) {
          let am_pm = x.endTime!.getHours() >= 12 ? "PM" : "AM";
          const endPeriod = new CoreEnum();
          endPeriod.label = am_pm;
          endPeriod.value = am_pm;
          x.endPeriodType = endPeriod;
          x.endTimeUI = format(x.endTime!, "hh:mm");
        }
        return x;
      });
    }
    if (profile.data.licenses) {
      profile.data.licenses = profile.data.licenses.map((x: any) => {
        if (x.expirationDate instanceof Date) {
          const month = new CoreEnum();
          const year = new CoreEnum();
          year.label = x.expirationDate!.getFullYear().toString();
          year.value = year.label;
          x.convertedExpirationYear = year;
          month.value = (x.expirationDate!.getMonth() + 1).toString();
          month.label = months.filter((x) => x.value == month.value)[0].label;
          x.convertedExpirationMonth = month;
        }
        return x;
      });
    }
    if (profile.data.certifications) {
      profile.data.certifications = profile.data.certifications.map(
        (x: any) => {
          if (x.expiration instanceof Date) {
            x.expirationYear = toExpirationYear(x.expiration);
          }
          if (x.issued instanceof Date) {
            const year = new CoreEnum();
            year.label = x.issued!.getFullYear().toString();
            year.value = year.label;
            x.issuedYear = year;
          }
          return x;
        }
      );
    }

    return {
      ...profile.data,
      supervisorConvertedExpirationMonth:
        modifiedProviderProfile?.supervisorConvertedExpirationMonth,
      supervisorConvertedExpirationYear:
        modifiedProviderProfile?.supervisorConvertedExpirationYear,
      removeOfficePhotosFromMemory: officePhotos.map((x) => x.id),
      genderTypes: modifiedProviderProfile?.genderTypes,
      pronounTypes: modifiedProviderProfile?.pronounTypes,
      races: modifiedProviderProfile?.races,
      ethnicities: modifiedProviderProfile?.ethnicities,
      states: modifiedProviderProfile?.states,
      supervisorCredentialTypes:
        modifiedProviderProfile?.supervisorCredentialTypes,
      certificationOrganizationTypes:
        modifiedProviderProfile?.certificationOrganizationTypes,
      preLicenseTypes: modifiedProviderProfile?.preLicenseTypes,
      licenseTypes: modifiedProviderProfile?.licenseTypes,
      creditCards: modifiedProviderProfile?.creditCards,
      serviceTypes: modifiedProviderProfile?.serviceTypes,
      sessionTypes: modifiedProviderProfile?.sessionTypes,
      clientDemographics: modifiedProviderProfile?.clientDemographics,
      clinicalSpecialties: modifiedProviderProfile?.clinicalSpecialties,
      therapyTypes: modifiedProviderProfile?.therapyTypes,
      communityTypes: modifiedProviderProfile?.communityTypes,
      faithTypes: modifiedProviderProfile?.faithTypes,
      paymentMethodTypes: modifiedProviderProfile?.paymentMethodTypes,
      languageTypes: modifiedProviderProfile?.languageTypes,
      insurancePlans: modifiedProviderProfile?.insurancePlans,
      timezones: modifiedProviderProfile?.timezones,
    };
  };

  static buildProviderProfile = (
    modifiedProviderProfile: ProviderProfileVM,
    officePhotos: any[]
  ) => {
    modifiedProviderProfile!.supervisorFirstName =
      modifiedProviderProfile!.supervisorFirstName || "";
    modifiedProviderProfile!.supervisorLastName =
      modifiedProviderProfile!.supervisorLastName || "";
    modifiedProviderProfile!.supervisorLicenseNumber =
      modifiedProviderProfile!.supervisorLicenseNumber || "";

    modifiedProviderProfile!.selectedCreditCards =
      modifiedProviderProfile!.creditCards!.filter((x: any) => x.checked);
    modifiedProviderProfile!.selectedServiceTypes =
      modifiedProviderProfile!.serviceTypes!.filter((x: any) => x.checked);
    modifiedProviderProfile!.selectedSessionTypes =
      modifiedProviderProfile!.sessionTypes!.filter((x: any) => x.checked);
    modifiedProviderProfile!.selectedClientDemographics =
      modifiedProviderProfile!.clientDemographics!.filter(
        (x: any) => x.checked
      );
    modifiedProviderProfile!.selectedClinicalSpecialties =
      modifiedProviderProfile!.clinicalSpecialties!.filter(
        (x: any) => x.checked
      );
    modifiedProviderProfile!.selectedTherapyTypes =
      modifiedProviderProfile!.therapyTypes!.filter((x: any) => x.checked);
    modifiedProviderProfile!.selectedCommunityTypes =
      modifiedProviderProfile!.communityTypes!.filter((x: any) => x.checked);
    modifiedProviderProfile!.selectedFaithTypes =
      modifiedProviderProfile!.faithTypes!.filter((x: any) => x.checked);
    modifiedProviderProfile!.selectedPaymentMethodTypes =
      modifiedProviderProfile!.paymentMethodTypes!.filter(
        (x: any) => x.checked
      );
    modifiedProviderProfile!.selectedLanguageTypes =
      modifiedProviderProfile!.languageTypes!.filter((x: any) => x.checked);
    modifiedProviderProfile!.selectedInsurancePlans =
      modifiedProviderProfile!.insurancePlans!.filter((x: any) => x.checked);

    if (modifiedProviderProfile?.isLicensed) {
      modifiedProviderProfile!.preLicenseType = undefined;
      modifiedProviderProfile!.supervisorCredentialType = undefined;
      modifiedProviderProfile!.supervisorFirstName = "";
      modifiedProviderProfile!.supervisorLastName = "";
      modifiedProviderProfile!.supervisorLicenseNumber = "";
      modifiedProviderProfile!.supervisorState = undefined;
      modifiedProviderProfile!.supervisorExpirationYear = null;
      modifiedProviderProfile!.supervisorExpirationMonth = null;
      modifiedProviderProfile!.supervisorExpirationDate = null;
    } else {
      if (
        modifiedProviderProfile!.supervisorConvertedExpirationYear &&
        modifiedProviderProfile!.supervisorConvertedExpirationYear.value
      ) {
        modifiedProviderProfile!.supervisorExpirationYear = parseInt(
          modifiedProviderProfile!.supervisorConvertedExpirationYear.value.toString()
        );
      }
      if (
        modifiedProviderProfile!.supervisorConvertedExpirationMonth &&
        modifiedProviderProfile!.supervisorConvertedExpirationMonth.value
      ) {
        modifiedProviderProfile!.supervisorExpirationMonth = parseInt(
          modifiedProviderProfile!.supervisorConvertedExpirationMonth.value.toString()
        );
      }
    }

    if (
      modifiedProviderProfile!.timezone !== undefined &&
      modifiedProviderProfile!.timezone?.label === dropdownSelectLabel
    ) {
      modifiedProviderProfile!.timezone = undefined;
    }

    modifiedProviderProfile!.fileTypes = [];
    if (officePhotos && officePhotos.length > 0) {
      for (let file of officePhotos) {
        modifiedProviderProfile?.fileTypes.push(FileType.Office);
      }
    }

    if (
      modifiedProviderProfile!.insurancePlans?.find(
        (x) => x.label === "Other" && !x.checked
      )
    ) {
      modifiedProviderProfile.otherInsurancePlans = "";
    }

    if (
      modifiedProviderProfile!.languageTypes?.find(
        (x) => x.label === "Other" && !x.checked
      )
    ) {
      modifiedProviderProfile.otherLanguageSpoken = "";
    }

    if (
      modifiedProviderProfile!.clinicalSpecialties?.find(
        (x) => x.label === "Other" && !x.checked
      )
    ) {
      modifiedProviderProfile.otherClinicalSpecialty = "";
    }

    if (
      modifiedProviderProfile!.therapyTypes?.find(
        (x) => x.label === "Other" && !x.checked
      )
    ) {
      modifiedProviderProfile.otherTherapyType = "";
    }

    if (
      modifiedProviderProfile!.communityTypes?.find(
        (x) => x.label === "Other" && !x.checked
      )
    ) {
      modifiedProviderProfile.otherCommunityType = "";
    }

    if (
      modifiedProviderProfile!.faithTypes?.find(
        (x) => x.label === "Other" && !x.checked
      )
    ) {
      modifiedProviderProfile.otherFaithType = "";
    }

    if (modifiedProviderProfile!.certifications) {
      for (
        let index = 0;
        index < modifiedProviderProfile!.certifications.length;
        index++
      ) {
        if (
          modifiedProviderProfile!.certifications[index]
            .certificationOrganizationType?.label !== "Other"
        ) {
          modifiedProviderProfile!.certifications[
            index
          ].otherCertificationOrganizationType = "";
        }
      }
    }
  };

  static buildFormData = async (
    newProviderProfile: any,
    isProfileImageSelected: boolean,
    newProfilePhoto: any,
    officePhotos: any[]
  ) => {
    const formData = new FormData();

    if (isProfileImageSelected) {
      formData.append("newProfilePhoto", newProfilePhoto);
    }
    if (officePhotos && officePhotos.length > 0) {
      for (let file of officePhotos) {
        formData.append("files", file, file.name);
      }
      let descriptions = officePhotos.map((file) => file.description);
      formData.append("officePhotosDescription", JSON.stringify(descriptions));
    }

    const excludedProperties = [
      "insurancePlans",
      "languageTypes",
      "paymentMethodTypes",
      "faithTypes",
      "communityTypes",
      "therapyTypes",
      "clinicalSpecialties",
      "clientDemographics",
      "serviceTypes",
      "creditCards",
      "supervisorCredentialTypes",
      "timezones",
      "publishedProfileId",
      "publishedProfileUrl",
      "youTubeUrlDescription",
      "certificationOrganizationTypes",
      "licenseTypes",
      "preLicenseTypes",
      "states",
      "pronounTypes",
      "genderTypes",
      "ethnicities",
      "races",
      "sessionTypes",
      "inMemoryOfficePhotos",
      "supervisorConvertedExpirationMonth",
      "supervisorConvertedExpirationYear",
    ];

    Object.keys(newProviderProfile || {}).forEach((prop) => {
      const value = newProviderProfile![prop];
      const type = typeof value;
      if (!excludedProperties.includes(prop)) {
        if (type === null || type === undefined) {
          formData.append(prop, value);
        } else if (type === "object" || Array.isArray(value)) {
          if (value) {
            if (value instanceof Date) {
              formData.append(
                prop,
                new Date(newProviderProfile![prop]).toUTCString()
              );
            } else {
              formData.append(prop, JSON.stringify(newProviderProfile![prop]));
            }
          }
        } else {
          formData.append(prop, value);
        }
      }
    });

    return formData;
  };

  // The next 2 methods are used only by the Profile status indicator and
  // the search results to avoid writing duplicate code
  static isProfilePublished = (
    profileStatus: string | number | undefined,
    publishStatus: any,
    publishSucceeded: boolean | undefined
  ): boolean => {
    return (
      (profileStatus == ProfileStatus.Approved &&
        publishStatus == PublishStatus.Public) ||
      // this second condition is not really necessary, but it's for
      // clarity for when a provider tries to publish and it fails
      (profileStatus == ProfileStatus.Approved &&
        publishStatus == PublishStatus.Public &&
        !publishSucceeded)
    );
  };
  static isProfileUnpublished = (
    profileStatus: string | number | undefined,
    publishStatus: any
  ): boolean => {
    return (
      profileStatus == ProfileStatus.Approved &&
      (publishStatus == PublishStatus.None ||
        publishStatus == PublishStatus.Private)
    );
  };

  static setPositions = (
    profile: ProviderProfileVM,
    certifications: any,
    boardCertifications: any,
    licenses: any,
    schools: any,
    professionalAssociations: any
  ) => {
    let positions: Array<any> = [];
    for (let i = 0; i < certifications.children.length; i++) {
      positions.push({
        id: certifications.children[i].dataset.elementid,
        position: i,
      });
    }
    profile!.certifications?.map((x) => {
      let position = positions.filter((y) => y.id == x.id)[0];
      x.position = parseInt(position.position);
      return x;
    });

    positions = [];
    for (let i = 0; i < boardCertifications.children.length; i++) {
      positions.push({
        id: boardCertifications.children[i].dataset.elementid,
        position: i,
      });
    }
    profile!.boardCertifications?.map((x) => {
      let position = positions.filter((y) => y.id == x.id)[0];
      x.position = parseInt(position.position);
      return x;
    });

    if (profile?.isLicensed) {
      positions = [];
      for (let i = 0; i < licenses.children.length; i++) {
        positions.push({
          id: licenses.children[i].dataset.elementid,
          position: i,
        });
      }
      profile!.licenses?.map((x) => {
        let position = positions.filter((y) => y.id == x.id)[0];
        x.position = parseInt(position.position);
        return x;
      });
    }

    positions = [];
    for (let i = 0; i < schools.children.length; i++) {
      positions.push({
        id: schools.children[i].dataset.elementid,
        position: i,
      });
    }
    profile!.schools?.map((x: any) => {
      let position = positions.filter((y) => y.id == x.id)[0];
      x.position = parseInt(position?.position || 0);
      return x;
    });

    positions = [];
    for (let i = 0; i < professionalAssociations.children.length; i++) {
      positions.push({
        id: professionalAssociations.children[i].dataset.elementid,
        position: i,
      });
    }
    profile!.professionalAssociations?.map((x: any) => {
      let position = positions.filter((y) => y.id == x.id)[0];
      x.position = parseInt(position.position);
      return x;
    });
  };

  static prepareProfileForValidation = (
    applyRequiredValidations: boolean = false,
    currentProviderProfile: ProviderProfileVM
  ): ProviderProfileVM => {
    currentProviderProfile.runPublishingValidators = applyRequiredValidations;
    if (currentProviderProfile.boardCertifications) {
      for (let boardCertification of currentProviderProfile.boardCertifications) {
        boardCertification.runPublishingValidators = applyRequiredValidations;
      }
    }
    if (currentProviderProfile.businessHours) {
      for (let businessHour of currentProviderProfile.businessHours) {
        businessHour.runPublishingValidators = applyRequiredValidations;
      }
    }
    if (currentProviderProfile.certifications) {
      for (let certification of currentProviderProfile.certifications) {
        certification.runPublishingValidators = applyRequiredValidations;
      }
    }
    if (currentProviderProfile.licenses) {
      for (let license of currentProviderProfile.licenses) {
        license.runPublishingValidators = applyRequiredValidations;
      }
    }
    if (currentProviderProfile.professionalAssociations) {
      for (let association of currentProviderProfile.professionalAssociations) {
        association.runPublishingValidators = applyRequiredValidations;
      }
    }
    if (currentProviderProfile.schools) {
      for (let item of currentProviderProfile.schools) {
        item.runPublishingValidators = applyRequiredValidations;
      }
    }
    if (applyRequiredValidations) {
      // expand required sections
      if (
        !currentProviderProfile.schools ||
        (Array.isArray(currentProviderProfile.schools) &&
          currentProviderProfile.schools.length === 0)
      ) {
        currentProviderProfile.schools = [];
        currentProviderProfile.schools.push(new SchoolVM());
        currentProviderProfile.schools[0].id = uuid();
        currentProviderProfile.schools[0].runPublishingValidators = true;
      }
      if (
        !currentProviderProfile.licenses ||
        (Array.isArray(currentProviderProfile.licenses) &&
          currentProviderProfile.licenses.length === 0)
      ) {
        currentProviderProfile.licenses = [];
        currentProviderProfile.licenses.push(new LicenseVM());
        currentProviderProfile.licenses[0].id = uuid();
        currentProviderProfile.licenses[0].displayInHeader = true;
        currentProviderProfile.licenses[0].runPublishingValidators = true;
      }
    }
    return currentProviderProfile;
  };

  static processProfileValidationErrors = (e: any): any => {
    let errors: any = {};
    e.inner.map((err: Yup.ValidationError) => {
      errors[err.path!] = err.message.endsWith(" is invalid")
        ? ""
        : err.message;
      if (err.errors && Array.isArray(err.errors) && err.errors.length) {
        err.errors.map((text: string) => {
          if (text.includes("|||")) {
            let split = text.split("|||");
            errors[split[0]] = split.length > 1 ? split[1] : "";
          }
        });
      }
    });
    return errors;
  };

  static convertProfileValidationErrors = (
    errors: any,
    touchedElements: string[]
  ): any => {
    return Object.keys(errors)
      .filter((key) => touchedElements.includes(key))
      .reduce((acc: any, key) => {
        if (!acc[key]) {
          acc[key] = errors[key];
        }
        return acc;
      }, {});
  };

  static formatMoney = (
    amount: any,
    decimalCount = 2,
    decimal = ".",
    thousands = ","
  ) => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i: any = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
    }
  };

  static cleanUpErrorMessages = (errors: any) => {
    // clean up errors (even though fluent validation returns an empty message, the
    // validation middleware replaces "" with "The input was not valid.")
    for (const key in errors) {
      if (errors[key] && Array.isArray(errors[key])) {
        for (let index = 0; index < errors[key].length; index++) {
          if (errors[key][index] === "The input was not valid.") {
            errors[key][index] = "";
          }
        }
      }
    }
    return errors;
  };

  static convertErrorsToArray = (errors: any) => {
    const modalStateErrors = [];
    for (const key in errors) {
      if (errors[key]) {
        modalStateErrors.push(errors[key]);
      }
    }
    return modalStateErrors.flat();
  };

  static convertNonFieldErrorsToArray = (errors: any) => {
    const modalStateErrors = [];
    for (const key in errors) {
      if (key === "" && errors[key]) {
        modalStateErrors.push(errors[key]);
      }
    }
    return modalStateErrors.flat();
  };

  static addError = (
    aggregateError: any,
    path: string,
    message: string,
    createError: (params?: Yup.CreateErrorOptions) => Yup.ValidationError
  ): any => {
    if (aggregateError === null || aggregateError === undefined) {
      aggregateError = createError({
        path: path,
        message: message,
      });
    } else {
      aggregateError.errors.push(`${path}|||${message}`);
    }
    return aggregateError;
  };

  // only display this message when there are fields to highlight
  static hasValidationErrors = (errors: any) => {
    return (
      errors &&
      errors.isAxiosError === undefined &&
      (Object.keys(errors).filter((x) => x !== "").length > 0 ||
        Object.keys(errors).filter((x) => x === "").length === 0)
    );
  };

  static setMinHeight = () => {
    let maxHeightDesc = 0;
    let maxHeightBullets = 0;
    let maxHeightPromo = 0;
    document
      .querySelectorAll(".card-wrapper-desc")
      .forEach(
        (el: any) =>
          (maxHeightDesc =
            maxHeightDesc > el.clientHeight ? maxHeightDesc : el.clientHeight)
      );
    document
      .querySelectorAll(".card-wrapper-bullets")
      .forEach(
        (el: any) =>
          (maxHeightBullets =
            maxHeightBullets > el.clientHeight
              ? maxHeightBullets
              : el.clientHeight)
      );
    document
      .querySelectorAll(".card-wrapper-promo")
      .forEach(
        (el: any) =>
          (maxHeightPromo =
            maxHeightPromo > el.clientHeight ? maxHeightPromo : el.clientHeight)
      );
    document
      .querySelectorAll(".card-wrapper-desc")
      .forEach((el: any) => (el.style.minHeight = `${maxHeightDesc}px`));
    document
      .querySelectorAll(".card-wrapper-bullets")
      .forEach((el: any) => (el.style.minHeight = `${maxHeightBullets}px`));
    document
      .querySelectorAll(".card-wrapper-promo")
      .forEach((el: any) => (el.style.minHeight = `${maxHeightPromo}px`));
  };
}

export const imageRecommendations =
  "For best results, use JPG or PNG formatted images less than 5MB in size. Ideal image size is 180x180 pixels.";
export const csvInformationMessage = "Please use commas between ";
export const licenseInformationMessage =
  "License names vary by state board; if you do not see your license/credential listed, please contact customer support at ";

export const toExpirationYear = (expiration: Date): CoreEnum => {
  const expirationYear = expiration.getFullYear().toString();
  return {
    value: expirationYear,
    label: expirationYear === "9999" ? "Does not expire" : expirationYear,
  };
};
