import { ChangeEvent, FocusEvent } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import * as Icons from "@fortawesome/fontawesome-common-types";
import RequiredField from "../RequiredField";
import TooltipMessage from "../TooltipMessage";
import { ErrorMessage, VisibilityOffIcon } from "../../Display";

interface IProps {
  fieldText: string;
  fieldName: string;
  labelName: string;
  requiredField?: boolean;
  showLabel?: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  icon: Icons.IconDefinition;
  className: any;
  handleBlur: (event: FocusEvent<HTMLInputElement>) => void;
  labelClass?: string;
  error?: string;
  tooltipMessage?: string;
  tooltipPlacementLeft?: boolean;
  visibilityOff?: boolean;
  placeholder?: string;
  autoCapitalizeValue?: string;
}

export const InputWithIcon = ({
  fieldName,
  fieldText,
  handleChange,
  labelName,
  requiredField = false,
  showLabel = true,
  icon,
  className,
  handleBlur,
  labelClass = "",
  error = "",
  tooltipMessage = "",
  tooltipPlacementLeft = false,
  visibilityOff = false,
  placeholder = "",
  autoCapitalizeValue = "on",
}: IProps) => {
  return (
    <FormGroup>
      <div>
        {showLabel ? (
          <Label className={labelClass}>
            {labelName} {requiredField ? <RequiredField /> : null}{" "}
            {visibilityOff && <VisibilityOffIcon />}
            {tooltipMessage && (
              <TooltipMessage tooltipPlacementLeft={tooltipPlacementLeft} tooltipMessage={tooltipMessage}></TooltipMessage>
            )}
          </Label>
        ) : null}
      </div>
      <span className="input-field-icons">
        <FontAwesomeIcon icon={icon} size="sm" className="ml-2 mt-2" />
      </span>

      <Input
        className={classnames({ "input-field": true, ...className })}
        type="text"
        value={fieldText}
        name={fieldName}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        autoCapitalize={autoCapitalizeValue}
      ></Input>
      <ErrorMessage visible={isNotUndefined(error)} message={error} />
    </FormGroup>
  );
};
