import React, { Fragment, useEffect, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import { components, OptionsType, OptionTypeBase } from "react-select";
import { useDebouncedCallback } from "use-debounce";
import GooglePlacesProps, { AutocompletionRequest } from "./GooglePlaces.types";
import "./GooglePlaces.scss";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import injectScript from "./utils/scriptInjection";
import autocompletionRequestBuilder from "./utils/requestBuilder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile, dropdownSelectLabel } from '../../Utilities/IsMobile';

const Input = (selectProps: any) => (
  <Fragment>
    <FontAwesomeIcon icon={Icon.faMapMarkerAlt} size="1x" color="#4f629e" />
    <components.Input {...selectProps} isHidden={false} />
  </Fragment>
);

const GooglePlaces = ({
  apiKey = "",
  autocompletionRequest = {},
  debounce = 300,
  minLengthAutocomplete = 0,
  selectProps = {},
  onLoadFailed = console.error,
  withSessionToken = false,
  onSelected,
  inputText,
  savedValue,
  handleBlur,
}: GooglePlacesProps): React.ReactElement => {
  const [placesService, setPlacesService] = useState<
    google.maps.places.AutocompleteService | undefined
  >(undefined);
  const [sessionToken, setSessionToken] = useState<
    google.maps.places.AutocompleteSessionToken | undefined
  >(undefined);
  const fetchSuggestions = useDebouncedCallback(
    (
      value: string,
      cb: (options: OptionsType<OptionTypeBase>) => void
    ): void => {
      if (!placesService) return cb([]);
      if (value.length < minLengthAutocomplete) return cb([]);

      const autocompletionReq: AutocompletionRequest = {
        ...autocompletionRequest,
      };

      placesService.getPlacePredictions(
        autocompletionRequestBuilder(
          autocompletionReq,
          value,
          withSessionToken && sessionToken
        ),
        (suggestions) => {
          cb(
            (suggestions || [])
              .filter((x) =>
                x.types.find(
                  (y) =>
                    // https://developers.google.com/maps/documentation/places/web-service/supported_types
                    y === "street_address" ||
                    y === "premise" ||
                    y === "subpremise" ||
                    y === "locality" ||
                    y === "postal_code"
                )
              )
              .map((suggestion) => ({
                label: suggestion.description,
                value: suggestion,
              }))
          );
        }
      );
    },
    debounce
  );

  const initializeService = () => {
    if (!window.google)
      throw new Error(
        "[react-google-places-autocomplete]: Google script not loaded"
      );
    if (!window.google.maps)
      throw new Error(
        "[react-google-places-autocomplete]: Google maps script not loaded"
      );
    if (!window.google.maps.places)
      throw new Error(
        "[react-google-places-autocomplete]: Google maps places script not loaded"
      );

    setPlacesService(new window.google.maps.places.AutocompleteService());
    setSessionToken(new google.maps.places.AutocompleteSessionToken());
  };
  const asyncRef = useRef<any>();
  useEffect(() => {
    const init = async () => {
      try {
        if (apiKey) await injectScript(apiKey);
        initializeService();
      } catch (error: any) {
        onLoadFailed(error);
      }
    };

    init();
  }, []);

  useEffect(() => {
    setInputValue(inputText || "");
  }, [inputText]);
  useEffect(() => {
    setStoredValue(savedValue || "");
  }, [savedValue]);

  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState(inputText || "");
  const [storedValue, setStoredValue] = useState(savedValue || "");

  const onInputChange = (inputValue: any, actionMeta: any) => {
    if (actionMeta.action === "input-blur") {
      setInputValue(storedValue || "");
    } else if (actionMeta.action === "input-change") {
      setInputValue(inputValue);
      if (inputValue === "") {
        let clearedLocation: any = {};
        clearedLocation.label = "";
        clearedLocation.value = {
          description: "",
        };
        setValue(clearedLocation);
        setStoredValue("");
        onSelected(clearedLocation);
      }
    }
  };

  const onChange = (option: any) => {
    setValue(option);
    setStoredValue(option ? option.label : "");
    setInputValue(option ? option.label : "");
    onSelected(option || undefined);
  };

  const onFocus = (e: any) => {
    if (e && e.target) {
      e.target.select();
    }
  };

  return (
    <AsyncSelect
      ref={asyncRef}
      className="googlePlaces"
      {...selectProps}
      loadOptions={fetchSuggestions.callback}
      getOptionValue={({ value }) => value.place_id}
      value={value}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={dropdownSelectLabel}
      components={{
        Input,
      }}
    />
  );
};

export default GooglePlaces;
