export const SearchIcon = () => {
  return (
    <svg
      className="mt-1"
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_0_1)">
        <path
          d="M64 6H12C9.79086 6 8 7.79086 8 10V62C8 64.2091 9.79086 66 12 66H64C66.2091 66 68 64.2091 68 62V10C68 7.79086 66.2091 6 64 6Z"
          fill="black"
        />
      </g>
      <path
        d="M64 6H12C9.79086 6 8 7.79086 8 10V62C8 64.2091 9.79086 66 12 66H64C66.2091 66 68 64.2091 68 62V10C68 7.79086 66.2091 6 64 6Z"
        fill="url(#paint0_linear_0_1)"
      />
      <mask
        id="mask0_0_1"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="18"
        y="16"
        width="40"
        height="40"
      >
        <path d="M18 16H57.0911V56H18V16Z" fill="white" />
      </mask>
      <g mask="url(#mask0_0_1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.5601 30.2464C32.5601 24.385 37.2514 19.6159 43.0183 19.6159C48.7842 19.6159 53.4756 24.385 53.4756 30.2464C53.4756 36.1083 48.7842 40.8774 43.0183 40.8774C37.2514 40.8774 32.5601 36.1083 32.5601 30.2464ZM28.945 30.2464C28.945 33.5474 30.0623 36.5869 31.9305 39.0047L18.5854 52.5383C17.7968 53.3384 17.8059 54.6261 18.6059 55.4147C19.0018 55.8056 19.5177 56.0002 20.0336 56.0002C20.5586 56.0002 21.0836 55.7984 21.4818 55.3947L34.8501 41.8374C37.1551 43.5065 39.9742 44.4933 43.0183 44.4933C50.7783 44.4933 57.0911 38.1024 57.0911 30.2464C57.0911 22.3909 50.7783 16 43.0183 16C35.2582 16 28.945 22.3909 28.945 30.2464Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_0_1"
          x="0"
          y="0"
          width="76"
          height="76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_0_1"
          x1="68"
          y1="66"
          x2="8"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8298CB" />
          <stop offset="1" stopColor="#4B5F9C" />
        </linearGradient>
      </defs>
    </svg>
  );
};
