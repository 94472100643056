import { useState, useCallback, useEffect } from "react";
import "./ImageEditModal.scss";
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { toast } from "react-toastify";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
import getCroppedImg from "../../Utilities/CropImageHelper";
import classnames from "classnames";
import { genericServerError } from "../../Utilities/HelperData";
import { useGlobalState } from "../../Context";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import { ImageDataReturnType } from '../../Utilities/ImageDataReturnType';

interface IProps {
  buttonLabel: string;
  buttonClass?: string;
  activeImage: string;
  handleNewCroppedImage: (fileId: string, item: any, description: string) => void; 
  returnType: ImageDataReturnType;
  defaultAspect: number;
  allowAspectChange: boolean;
  hideUpload?: boolean;
  showEditIcon?: boolean;
  fileId?: string;
  overlayButton?: boolean;
  description?: string;
}

export const ImageEditModal = ({
  buttonLabel,
  buttonClass = "",
  activeImage,
  handleNewCroppedImage,
  returnType,
  defaultAspect,
  allowAspectChange,
  hideUpload = false,
  showEditIcon = false,
  fileId = "",
  overlayButton = false,
  description = "",
}: IProps) => {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    toast.dismiss();
    document.querySelector(".main__content")?.scrollTo(0, 0);
    setModal(!modal);
    if (modal) {
      setDisplayFileTypeError(false);
      setDisplayGenericError(false);
    }
  };
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [rotation, setRotation] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);
  const [aspect, setAspect] = useState<number>(defaultAspect);
  const [displayFileTypeError, setDisplayFileTypeError] = useState(false);
  const [displayGenericError, setDisplayGenericError] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [image, setImage] = useState(activeImage);
  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );
  const { customerSupportContactEmail, customerSupportContactPhoneNumber } =
    useGlobalState();

  const closeBtn = (
    <button className={"close"} onClick={toggle} type="button">
      &times;
    </button>
  );

  const applyCroppedImage = useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImg(
        returnType,
        image,
        croppedAreaPixels,
        rotation
      );
      setZoom(1);
      setCrop({ x: 0, y: 0 });
      setRotation(0);
      toggle();
      handleNewCroppedImage(fileId, croppedImage, description);
    } catch (e: any) {
      console.log(e);
      setDisplayGenericError(true);
    }
  }, [croppedAreaPixels, handleNewCroppedImage, image, rotation]);

  const handleChangeImage = (event: any) => {
    setDisplayGenericError(false);
    if (event.target.files && event.target.files[0]) {
      if (
        event.target.files[0].type !== "image/jpeg" &&
        event.target.files[0].type !== "image/png" &&
        event.target.files[0].type !== "image/gif" &&
        event.target.files[0].type !== "image/jpg"
      ) {
        setDisplayFileTypeError(true);
        return;
      }
      setDisplayFileTypeError(false);
      let fileItem = URL.createObjectURL(event.target.files[0]);
      setImage(fileItem);
    } else {
      setDisplayFileTypeError(true);
    }
  };

  useEffect(() => {
    if (activeImage) {
      setImage(activeImage);
    }
  }, [activeImage]);

  return (
    <div className={overlayButton ? "image-editor-overlay" : "image-editor"}>
      <button className={buttonClass} type="button" onClick={toggle} data-toggle="tooltip" title="Edit Image">
        {showEditIcon && <FontAwesomeIcon icon={Icon.faEdit} />}
        {buttonLabel}
      </button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        contentClassName="edit-image-modal"
      >
        <ModalHeader toggle={toggle} close={closeBtn}>
          Edit Image {description}
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="crop-container">
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                rotation={rotation}
                showGrid={true}
              />
            </div>
            <div
              className={
                allowAspectChange
                  ? "controls-container controls-container-extra"
                  : "controls-container"
              }
            >
              <div className="zoom-controls">
                <Typography
                  variant="overline"
                  classes={{ root: "slider-label" }}
                >
                  Zoom
                </Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(Number(zoom))}
                  classes={{ root: "slider" }}
                />
              </div>
              <div className="rotation-controls">
                <Typography
                  variant="overline"
                  classes={{ root: "slider-label" }}
                >
                  Rotation
                </Typography>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  classes={{ root: "slider" }}
                  onChange={(e, rotation) => setRotation(Number(rotation))}
                />
              </div>
              <div className={allowAspectChange ? "aspect-controls" : "d-none"}>
                <Typography
                  variant="overline"
                  classes={{ root: "slider-label" }}
                >
                  Aspect Ratio
                </Typography>
                &nbsp; &nbsp;
                <Select
                  value={aspect}
                  onChange={(e) => setAspect(e.target.value as number)}
                >
                  <MenuItem value={4 / 3}>Landscape</MenuItem>
                  <MenuItem value={3 / 4}>Portrait</MenuItem>
                  <MenuItem value={1 / 1}>Square</MenuItem>
                </Select>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={allowAspectChange ? "extra-height" : ""}>
          <div
            className={classnames({
              "d-none": !displayFileTypeError && !displayGenericError,
              "w-100": true,
              "highlighted-text": true,
            })}
          >
            {displayFileTypeError &&
              "Please select a supported file type: JPEG, PNG, GIF."}
            {displayGenericError &&
              genericServerError +
                `${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`}
          </div>
          {!hideUpload && (
            <div className="mr-auto">
              <Label
                for="file-upload"
                className="custom-file-upload p-2 rounded mb-0"
              >
                Browse
              </Label>
              <input
                type="file"
                name="file"
                id="file-upload"
                accept=".jpg,.jpeg,.png,.gif"
                onChange={handleChangeImage}
              />
            </div>
          )}
          <Button
            color="primary"
            onClick={applyCroppedImage}
            disabled={displayFileTypeError}
          >
            Apply
          </Button>
          <Button
            color="secondary"
            className="crop-cancel-button"
            onClick={toggle}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
