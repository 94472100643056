import { Fragment } from "react";
import { Alert, Row, Col } from "reactstrap";
import { useGlobalState } from "../../Context";
import { ProfileStatus } from "./Models";

interface IProps {
  profileStatus?: string | number;
}

export const ProviderProfileStatusAlert = ({ profileStatus }: IProps) => {
  const { customerSupportContactEmail } = useGlobalState();
  return (
    <Fragment>
      {profileStatus == ProfileStatus.PendingApproval && (
        <Row className="py-2">
          <Col md="12" className="px-0">
            <Alert className="alert-success mb-0">
              Pending Review: Thank you for completing your profile. Your
              profile is pending review, if you haven't seen your profile go
              live in 2-4 business days please contact customer service at{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={`mailto:${customerSupportContactEmail}`}
              >
                {customerSupportContactEmail}
              </a>
              .
            </Alert>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};
