import { CoreEnum } from "../../../Features/Shared/Models/SharedModels";

export class LicenseVM {
  id: any;
  licenseType?: CoreEnum;
  licenseNumber?: string;
  state?: CoreEnum;
  convertedExpirationMonth?: CoreEnum;
  convertedExpirationYear?: CoreEnum;
  expirationMonth?: number;
  expirationYear?: number;
  displayInHeader?: boolean;
  position?: number;
  runPublishingValidators?: boolean;
  licensingBoardUrl?: string;
}