export {};

declare global {
  function isNotUndefined(object: any): boolean;
}

const _global = (window /* browser */ || global) /* node */ as any;
_global.isNotUndefined = function (object: any): boolean {
  return object !== undefined;
};
