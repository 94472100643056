export const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 179.6 179.7"
      enableBackground="new 0 0 179.6 179.7"
      xmlSpace="preserve"
    >
      <path
        style={{ fill: "#7FC093" }}
        d="M112.6,52.4c0,4.1-3.6,7.4-8.1,7.4s-8.1-3.3-8.1-7.4s3.6-7.4,8.1-7.4C109,45,112.6,48.3,112.6,52.4"
      />
      <path
        style={{ fill: "#2D5F9D" }}
        d="M72.9,85.5c0,0-1.3-2.6-5.9-12.3c-5.6-11.7-15.6-30-30.7-24.8C0,60.8,63.6,143.2,63.6,143.2S36,118.7,23.2,97.1  C7.4,70.3,9.3,26.6,42,35.4C71.2,43.2,72.9,85.5,72.9,85.5"
      />
      <path
        style={{ fill: "#7FC093" }}
        d="M126,102.6c-5.5-3.7-8.8-7.8-10.5-11.9c-2.4,4-6,8.4-10.7,13c-0.1,0.1-0.1,0.1-0.2,0.2  c15.5,17.6,58.5,24.3,60.7,23C166.8,123.7,144,115,126,102.6"
      />
      <path
        style={{ fill: "#2D5F9D" }}
        d="M96.6,72.2c2.5,0,4.8,0.3,6.8,0.8c1.1-1.4,2.4-2.9,3.9-4.4C96,64.4,77.7,67.2,73,74.4  C71.3,77,85.3,72.2,96.6,72.2"
      />
      <path
        style={{ fill: "#2D5F9D" }}
        d="M104.2,86.7C90,100.1,80,106.9,70,120.4c-13.9,18.7-9.3,27.2-7.2,27.2s3.6-18.1,19.7-29.5  c28.3-20.1,35.7-33.4,33.3-41.5L104.2,86.7z"
      />
      <path
        style={{ fill: "#7FC093" }}
        d="M125.6,56.8c-21.4,14.9-26,25.9-26.8,31.2l17.8-15.7c1.9-3.8,5.8-9.5,14.4-16C150.6,41.5,159.8,32.9,125.6,56.8  "
      />
    </svg>
  );
};
