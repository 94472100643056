import { basePathName } from "../Utilities/HelperData";
import { AdminHelper } from "../Utilities/AdminHelper";

export class UrlHelper {
  static getApiUrl = (
    permissions: string[] | undefined,
    providerRoute: string,
    adminRoute: string
  ): string => {
    return `${basePathName}${
      AdminHelper.userIsAdmin(permissions || []) ? adminRoute : providerRoute
    }`;
  };

  static getMenuLink = (
    permissions: string[] | undefined,
    providerRoute: string,
    adminRoute: string
  ): string => {
    return AdminHelper.userIsAdmin(permissions) ? adminRoute : providerRoute;
  };

  static getProviderIdFromUrl = (): string => {
    // the number of charactes in "/providers/provider/impersonate/"
    const numberOfCharactersInBaseUrl = 32;
    let providerId = window.location.pathname.includes(
      "/providers/provider/impersonate/"
    )
      ? window.location.pathname.substring(
          window.location.pathname.indexOf("/", numberOfCharactersInBaseUrl)
        )
      : "";
    providerId = providerId.replaceAll("/", "");
    return providerId;
  };
}
