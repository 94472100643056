import React, { Fragment } from "react";
import { Loader } from "./index";
import { useGlobalState } from "../../Context";
import "./LoadingSection.scss";
import { useLocation } from "react-router-dom";
import { constants } from "../../Utilities/Constants";

interface IProps {
  isLoading: boolean;
  height?: number;
  width?: number;
  isFullPage?: boolean;
}

export const LoadingSection: React.FC<IProps> = ({
  isLoading,
  children,
  height = 0,
  width = 0,
  isFullPage = false,
}) => {
  const location = useLocation();
  const { initialGetRequestRanOk } = useGlobalState();
  return (
    <Fragment>
      {(isLoading || !initialGetRequestRanOk) && (
        <Loader height={height} width={width} isFullPage={isFullPage}></Loader>
      )}
      {/* {children} should always be displayed after the <Loader /> section so that the
          spinner gets displayed */}
      {(initialGetRequestRanOk ||
        location.pathname.indexOf("/register") >= 0 || location.pathname.indexOf("/billing") >= 0 ||
        location.pathname.indexOf(constants.CampaignPreview) >= 0) && (
        <div>{children}</div>
      )}
    </Fragment>
  );
};
