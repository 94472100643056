import { FormGroup, Input, Label } from "reactstrap";
import { ChangeEvent, Fragment, KeyboardEvent } from "react";
import RequiredField from "../RequiredField";
import { ErrorMessage } from "../../Display";

interface IProps {
  fieldName: string;
  fieldValue?: string;
  labelName?: string;
  requiredField?: boolean;
  showLabel?: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
  labelClass?: string;
  placeholder?: string;
  className?: any;
  inputClassName?: any;
  skipFormGroup?: boolean;
  disabled?: boolean;
  error?: string;
}

export const DatePicker = ({
  fieldName,
  fieldValue = "",
  labelName,
  requiredField = false,
  showLabel = true,
  handleChange,
  onKeyPress = (event) => {},
  labelClass = "",
  placeholder = "",
  className = "",
  inputClassName = "",
  error = "",
  skipFormGroup = false,
  disabled = false,
}: IProps) => {
  const field = (
    <Input
      type="date"
      name={fieldName}
      id={fieldName}
      value={fieldValue}
      onChange={handleChange}
      placeholder={placeholder}
      className={inputClassName}
      disabled={disabled}
      onKeyPress={onKeyPress}
    />
  );

  return skipFormGroup ? (
    <Fragment>
      {field}
      <ErrorMessage visible={isNotUndefined(error)} message={error} />
    </Fragment>
  ) : (
    <FormGroup className={className}>
      {showLabel && (
        <Label className={labelClass}>
          {labelName} {requiredField ? <RequiredField /> : null}
        </Label>
      )}
      {field}
      <ErrorMessage visible={isNotUndefined(error)} message={error} />
    </FormGroup>
  );
};
