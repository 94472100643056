export const PhoneIcon = () => {
  return (
    <svg
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_0_1)">
        <path
          d="M64 6H12C9.79086 6 8 7.79086 8 10V62C8 64.2091 9.79086 66 12 66H64C66.2091 66 68 64.2091 68 62V10C68 7.79086 66.2091 6 64 6Z"
          fill="black"
        />
      </g>
      <path
        d="M64 6H12C9.79086 6 8 7.79086 8 10V62C8 64.2091 9.79086 66 12 66H64C66.2091 66 68 64.2091 68 62V10C68 7.79086 66.2091 6 64 6Z"
        fill="url(#paint0_linear_0_1)"
      />
      <mask
        id="mask0_0_1"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="18"
        y="16"
        width="40"
        height="40"
      >
        <path d="M18 16H58V56H18V16Z" fill="white" />
      </mask>
      <g mask="url(#mask0_0_1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.6322 42.6297C36.4038 50.8595 27.1785 53.0969 25.6047 52.3387L21.5763 47.7089C21.5776 47.7044 21.5789 47.7 21.5803 47.6955L27.3794 43.1022L28.9389 45.4471C28.9696 45.4929 29.0025 45.5377 29.0371 45.5804C29.5647 46.2324 30.3425 46.6173 31.2269 46.664C32.0891 46.7097 34.7087 46.8466 41.7789 39.7751C43.5273 38.0262 48.8482 32.7044 48.6655 29.223C48.6189 28.3377 48.2344 27.5599 47.5829 27.0319C47.5398 26.9968 47.4953 26.9643 47.4491 26.9337L45.1042 25.3732L49.6971 19.575C49.702 19.5732 49.7069 19.5714 49.7122 19.5697L54.3393 23.599C55.0971 25.1732 52.8611 34.3986 44.6322 42.6297ZM56.8829 21.1048C56.854 21.0759 56.8237 21.0479 56.7931 21.0212L52.0166 16.8616C51.3598 16.2372 50.4326 15.9283 49.4602 16.0141C48.5935 16.0896 47.7553 16.475 47.1602 17.0705C47.1122 17.119 47.0669 17.1696 47.0247 17.223L42.3327 23.1461C41.1078 24.4626 41.1358 26.531 42.4162 27.8132C42.4998 27.8968 42.5913 27.9715 42.6891 28.0368L45.0882 29.6337C44.8975 30.4652 43.646 32.879 39.2651 37.2604C34.8842 41.6422 32.4705 42.8946 31.6389 43.0862L30.0429 40.6866C29.9776 40.5884 29.9029 40.4968 29.8194 40.4137C28.5371 39.1319 26.4687 39.1039 25.1527 40.3297L19.2305 45.0213C19.1758 45.0644 19.1247 45.1102 19.0758 45.1591C17.7234 46.5142 17.6363 48.7195 18.8701 50.0155L23.0278 54.7929C23.0545 54.824 23.0825 54.8542 23.1118 54.8831C23.9327 55.7044 25.1647 56 26.514 56C28.9509 56 31.7691 55.0347 33.234 54.4587C36.0656 53.3444 41.638 50.6533 47.146 45.144C51.2282 41.0608 54.5357 36.119 56.4589 31.2292C57.354 28.9532 59.1877 23.4101 56.8829 21.1048Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_0_1"
          x="0"
          y="0"
          width="76"
          height="76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_0_1"
          x1="68"
          y1="66"
          x2="8"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8298CB" />
          <stop offset="1" stopColor="#4B5F9C" />
        </linearGradient>
      </defs>
    </svg>
  );
};
