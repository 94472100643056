import { constants } from "../Utilities/Constants";

export class AdminHelper {
  static userIsAdmin = (permissions: string[] | undefined): boolean => {
    return (permissions || []).some((x: any) =>
      ["providerprofile.editany"].includes(x)
    );
  };
  static isUserBeingImpersonated = (): boolean => {
    return window.location.pathname.includes(
      constants.PartialImpersonateBrowserPath
    );
  };
  static isEditingDefaultCampaign = (): boolean => {
    return window.location.pathname.includes(constants.DefaultCampaign);
  };
  static isviewingACampaign = (): boolean => {
    return window.location.pathname.includes(constants.CampaignPreview);
  };
}
