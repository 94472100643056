import { ChangeEvent, FocusEvent, Fragment, useEffect, useState } from "react";
import "./Certifications.scss";
import { Button, Col, NavLink, Row } from "reactstrap";
import { CertificationVM } from "../../Components/ProviderProfile/Models/CertificationVM";
import { v4 as uuid } from "uuid";
import { DataDropDown, TextInput } from "../Form/Inputs";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import { CoreEnum } from "../../Features/Shared/Models/SharedModels";
import {
  years,
  historicYears,
  redBorder,
  white,
  red,
  blueBorder,
  darkBlue,
} from "../../Utilities/HelperData";
import Dragula from "react-dragula";
import { ErrorMessage, MoveIcon } from ".";
import { dropdownSelectLabel } from "../../Utilities/IsMobile";

interface IProps {
  elements: CertificationVM[] | undefined;
  certificationOrganizationTypes: CoreEnum[];
  formErrors: any;
  handleItemsChange: (
    event: ChangeEvent<HTMLInputElement>,
    items: CertificationVM[] | undefined
  ) => void;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
}

export const Certifications = ({
  elements,
  formErrors,
  handleItemsChange,
  certificationOrganizationTypes,
  onBlur,
}: IProps) => {
  const yearOptions = [
    { value: "", label: dropdownSelectLabel },
    { value: "9999", label: "Does not expire" },
    ...years,
  ];
  useEffect(() => {
    setItems(elements);
    setAvailableCertificationOrganizationTypes(certificationOrganizationTypes);
  }, [elements]);
  const containers: any[] = [];
  const [items, setItems] = useState(elements);

  useEffect(() => {
    const drake = Dragula(containers, {
      moves: function (el: any, source: any, handle: any, sibling: any) {
        return (
          handle &&
          handle.parentElement &&
          (handle.parentElement.className === "handle position-relative" ||
            (handle.parentElement.parentElement &&
              handle.parentElement.parentElement.className ===
                "handle position-relative"))
        );
      },
    });

    // handle drop manually to adjust CSS because React rendering 
    // does not get notified from changes after moving the DOM
    drake.on('drop', (el : Element) => {
      // add top border to all children
      el.parentElement?.querySelectorAll('[data-part="separator"]')
      .forEach(separator => separator.classList.add('border-top'));
  
      // remove top border from first child
      el.parentElement?.querySelector('[data-part="separator"]')
        ?.classList.remove('border-top');
    });
  }, []);

  const dragulaDecorator = (componentBackingInstance: any) => {
    if (componentBackingInstance) {
      containers.push(componentBackingInstance);
    }
  };

  const [
    availableCertificationOrganizationTypes,
    setAvailableCertificationOrganizationTypes,
  ] = useState<CoreEnum[]>([]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    let value: any;
    value = event.target.value;

    const elements = items?.map((el) =>
      el.id === id ? { ...el, [event.target.name]: value } : el
    );
    setItems(elements);
    handleItemsChange(event, elements);
  };

  const handleFromDropDownChange = (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
    text: string
  ) => {
    const value = {
      value: event.target.value,
      label: text,
    };

    const elements = items?.map((el) =>
      el.id === id
        ? {
            ...el,
            [event.target.name]: value,
          }
        : el
    );
    setItems(elements);
    handleItemsChange(event, elements);
  };

  const addRow = (event: any) => {
    event.preventDefault();
    const item = new CertificationVM();
    item.id = uuid();
    let elements = [...(items || []), item];
    setItems(elements);
    handleItemsChange(event, elements);
  };

  const deleteRow = (event: any, id: string) => {
    event.preventDefault();
    const elements = items?.filter((x) => x.id !== id);
    setItems(elements);
    handleItemsChange(event, elements);
  };

  return (
    <Fragment>
      <div
        className={classnames({ "drag-handles": true })}
        ref={dragulaDecorator}
      >
        {items?.map((item, index) => (
          <Row key={item.id} className="pb-1" data-elementid={item.id}>
            <Col>
              <Row 
                data-part="separator" 
                className={classnames({
                'border-top': index !== 0,
                'hide-font': true,
                'mx-1': true
                })}
              >
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col lg="6" md="5">
                  <TextInput
                    maxLength={500}
                    labelName="Certification Name"
                    className={classnames({
                      highlighted:
                        isNotUndefined(
                          formErrors[
                            `certifications[${index}].certificationName`
                          ]
                        ) ||
                        isNotUndefined(
                          formErrors[
                            `certifications[${index}].CertificationName`
                          ]
                        ),
                    })}
                    labelClass={classnames({
                      "highlighted-text":
                        isNotUndefined(
                          formErrors[
                            `certifications[${index}].certificationName`
                          ]
                        ) ||
                        isNotUndefined(
                          formErrors[
                            `certifications[${index}].CertificationName`
                          ]
                        ),
                    })}
                    error={
                      formErrors[
                        `certifications[${index}].certificationName`
                      ] ||
                      formErrors[`certifications[${index}].CertificationName`]
                    }
                    requiredField={true}
                    fieldText={item?.certificationName || ""}
                    fieldName="certificationName"
                    handleChange={(selected) => {
                      handleChange(selected, item.id);
                    }}
                    onBlur={onBlur}
                  ></TextInput>
                </Col>
                <Col lg="3" md="5">
                  <TextInput
                    maxLength={100}
                    visibilityOff={true}
                    labelName="Certification Number"
                    className={classnames({
                      highlighted:
                        isNotUndefined(
                          formErrors[
                            `certifications[${index}].certificationNumber`
                          ]
                        ) ||
                        isNotUndefined(
                          formErrors[
                            `certifications[${index}].CertificationNumber`
                          ]
                        ),
                    })}
                    fieldText={item?.certificationNumber || ""}
                    fieldName="certificationNumber"
                    handleChange={(selected) => {
                      handleChange(selected, item.id);
                    }}
                    labelClass={classnames({
                      "highlighted-text":
                        isNotUndefined(
                          formErrors[
                            `certifications[${index}].certificationNumber`
                          ]
                        ) ||
                        isNotUndefined(
                          formErrors[
                            `certifications[${index}].CertificationNumber`
                          ]
                        ),
                    })}
                    onBlur={onBlur}
                    error={
                      formErrors[
                        `certifications[${index}].certificationNumber`
                      ] ||
                      formErrors[`certifications[${index}].CertificationNumber`]
                    }
                  ></TextInput>
                </Col>
                <Col lg="3" md="2" className="text-right">
                  <div>&nbsp;</div>
                  <span className="handle position-relative">
                    {items && items.length > 1 && (
                      <MoveIcon tooltipMessage="When adding multiple professional certifications to your profile use the up/down arrow to the right of each entry to change the order in which it displays on your public profile"></MoveIcon>
                    )}
                  </span>
                  <Button
                    className={classnames({ trashCan: true, "ml-3": true })}
                    onClick={(event) => deleteRow(event, item.id)}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={Icon.faTrash}
                    ></FontAwesomeIcon>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <DataDropDown
                    fieldName="certificationOrganizationType"
                    fieldText="Certification Organization"
                    hideLabel={false}
                    requiredField={true}
                    values={availableCertificationOrganizationTypes}
                    styles={{
                      control: (provided: any) => ({
                        ...provided,
                        border:
                          isNotUndefined(
                            formErrors[
                              `certifications[${index}].certificationOrganizationType.label`
                            ]
                          ) ||
                          isNotUndefined(
                            formErrors[
                              `certifications[${index}].CertificationOrganizationType`
                            ]
                          )
                            ? redBorder
                            : blueBorder,
                        backgroundColor:
                          isNotUndefined(
                            formErrors[
                              `certifications[${index}].certificationOrganizationType.label`
                            ]
                          ) ||
                          isNotUndefined(
                            formErrors[
                              `certifications[${index}].CertificationOrganizationType`
                            ]
                          )
                            ? red
                            : white,
                      }),
                      dropdownIndicator: (provided: any) => ({
                        ...provided,
                        color: darkBlue,
                      }),
                    }}
                    selectedValue={item.certificationOrganizationType}
                    handleChange={(selected, text) => {
                      handleFromDropDownChange(selected, item.id, text);
                    }}
                    labelClass={classnames({
                      "highlighted-text":
                        isNotUndefined(
                          formErrors[
                            `certifications[${index}].certificationOrganizationType.label`
                          ]
                        ) ||
                        isNotUndefined(
                          formErrors[
                            `certifications[${index}].CertificationOrganizationType`
                          ]
                        ),
                    })}
                    className={classnames({
                      selectControl: true,
                    })}
                    onBlur={onBlur}
                    error={
                      formErrors[
                        `certifications[${index}].certificationOrganizationType.label`
                      ] ||
                      formErrors[
                        `certifications[${index}].CertificationOrganizationType`
                      ]
                    }
                  ></DataDropDown>
                  {item.certificationOrganizationType?.label === "Other" && (
                    <Fragment>
                      <TextInput
                        maxLength={255}
                        showLabel={false}
                        labelName=""
                        placeholder="Enter the other certification organization here"
                        requiredField={false}
                        className={classnames({
                          highlighted:
                            isNotUndefined(
                              formErrors[
                                `certifications[${index}].OtherCertificationOrganizationType`
                              ]
                            ) ||
                            isNotUndefined(
                              formErrors[
                                `certifications[${index}].otherCertificationOrganizationType`
                              ]
                            ) ||
                            isNotUndefined(
                              formErrors[
                                `certifications[${index}].OtherCertificationOrganizationType.label`
                              ]
                            ) ||
                            isNotUndefined(
                              formErrors[
                                `certifications[${index}].otherCertificationOrganizationType.label`
                              ]
                            ),
                        })}
                        fieldText={
                          item?.otherCertificationOrganizationType || ""
                        }
                        fieldName="otherCertificationOrganizationType"
                        handleChange={(selected) => {
                          handleChange(selected, item.id);
                        }}
                        onBlur={onBlur}
                      ></TextInput>
                      <ErrorMessage
                        visible={
                          isNotUndefined(
                            formErrors[
                              `certifications[${index}].OtherCertificationOrganizationType`
                            ]
                          ) ||
                          isNotUndefined(
                            formErrors[
                              `certifications[${index}].otherCertificationOrganizationType`
                            ]
                          ) ||
                          isNotUndefined(
                            formErrors[
                              `certifications[${index}].OtherCertificationOrganizationType.label`
                            ]
                          ) ||
                          isNotUndefined(
                            formErrors[
                              `certifications[${index}].otherCertificationOrganizationType.label`
                            ]
                          )
                        }
                        message={
                          formErrors[
                            `certifications[${index}].OtherCertificationOrganizationType`
                          ] ||
                          formErrors[
                            `certifications[${index}].otherCertificationOrganizationType`
                          ] ||
                          formErrors[
                            `certifications[${index}].OtherCertificationOrganizationType.label`
                          ] ||
                          formErrors[
                            `certifications[${index}].otherCertificationOrganizationType.label`
                          ]
                        }
                        displayAsCol={true}
                      />
                    </Fragment>
                  )}
                </Col>
                <Col lg="3" md="6">
                  <DataDropDown
                    fieldName="issuedYear"
                    fieldText="Certification Issued Year"
                    hideLabel={false}
                    styles={{
                      control: (provided: any) => ({
                        ...provided,
                        border:
                          isNotUndefined(
                            formErrors[
                              `certifications[${index}].issuedYear.label`
                            ]
                          ) ||
                          isNotUndefined(
                            formErrors[`certifications[${index}].IssuedYear`]
                          )
                            ? redBorder
                            : blueBorder,
                        backgroundColor:
                          isNotUndefined(
                            formErrors[
                              `certifications[${index}].issuedYear.label`
                            ]
                          ) ||
                          isNotUndefined(
                            formErrors[`certifications[${index}].IssuedYear`]
                          )
                            ? red
                            : white,
                      }),
                      dropdownIndicator: (provided: any) => ({
                        ...provided,
                        color: darkBlue,
                      }),
                    }}
                    labelClass={classnames({
                      "highlighted-text":
                        isNotUndefined(
                          formErrors[
                            `certifications[${index}].issuedYear.label`
                          ]
                        ) ||
                        isNotUndefined(
                          formErrors[`certifications[${index}].IssuedYear`]
                        ),
                    })}
                    error={
                      formErrors[`certifications[${index}].issuedYear.label`] ||
                      formErrors[`certifications[${index}].IssuedYear`]
                    }
                    values={historicYears}
                    selectedValue={item.issuedYear}
                    handleChange={(selected, text) => {
                      handleFromDropDownChange(selected, item.id, text);
                    }}
                    className={classnames({
                      selectControl: true,
                    })}
                  ></DataDropDown>
                </Col>
                <Col lg="3" md="6">
                  <DataDropDown
                    fieldName="expirationYear"
                    fieldText="Certification Expiration Year"
                    hideLabel={false}
                    visibilityOff={true}
                    styles={{
                      control: (provided: any) => ({
                        ...provided,
                        border:
                          isNotUndefined(
                            formErrors[
                              `certifications[${index}].expirationYear.label`
                            ]
                          ) ||
                          isNotUndefined(
                            formErrors[
                              `certifications[${index}].ExpirationYear`
                            ]
                          )
                            ? redBorder
                            : blueBorder,
                        backgroundColor:
                          isNotUndefined(
                            formErrors[
                              `certifications[${index}].expirationYear.label`
                            ]
                          ) ||
                          isNotUndefined(
                            formErrors[
                              `certifications[${index}].ExpirationYear`
                            ]
                          )
                            ? red
                            : white,
                      }),
                      dropdownIndicator: (provided: any) => ({
                        ...provided,
                        color: darkBlue,
                      }),
                    }}
                    labelClass={classnames({
                      "highlighted-text":
                        isNotUndefined(
                          formErrors[
                            `certifications[${index}].expirationYear.label`
                          ]
                        ) ||
                        isNotUndefined(
                          formErrors[`certifications[${index}].ExpirationYear`]
                        ),
                    })}
                    error={
                      formErrors[
                        `certifications[${index}].expirationYear.label`
                      ] || formErrors[`certifications[${index}].ExpirationYear`]
                    }
                    values={yearOptions}
                    selectedValue={item.expirationYear}
                    handleChange={(selected, text) => {
                      handleFromDropDownChange(selected, item.id, text);
                    }}
                    className={classnames({ selectControl: true })}
                  ></DataDropDown>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </div>
      <Row>
        <Col md="10">
          <NavLink className="plusMessage pt-0" onClick={addRow}>
            <FontAwesomeIcon size="sm" icon={Icon.faPlus}></FontAwesomeIcon>
            &nbsp;&nbsp;Add New Professional Certification
          </NavLink>
        </Col>
      </Row>
    </Fragment>
  );
};
