import { useState } from "react";
import "./CheckedDay.scss";
import { Label } from "reactstrap";
import classnames from "classnames";

interface IProps {
  isChecked: boolean;
  text: string;
  classname?: any;
  name: string;
  handle: (event: any, checked: boolean, name: string) => void;
}

export const CheckedDay = ({
  isChecked,
  text,
  handle,
  classname,
  name,
}: IProps) => {
  const [checked, setChecked] = useState(isChecked);
  return (
    <Label
      className={classnames({
        [`${classname}`]: true,
        greenColor: checked,
        whiteColor: !checked,
        "ml-2": true,
        "mt-2": true,
        "mr-2": true,
        "mb-2": true,
        "pl-2": true,
        "pt-2": true,
        "pr-2": true,
        "pb-2": true,
      })}
      onClick={(event) => {
        setChecked(!checked);
        handle(event, !checked, name);
      }}
    >
      {text}
    </Label>
  );
};
