import { adminGenericError } from "./HelperData";

export class ErrorHelper {
  static handleServerErrors = (
    errors: any,
    setServerErrors: any,
    setSessionExpired: any,
    scrollToTop?: any
  ): boolean => {
    if (errors?.status === 401) {
      setSessionExpired(true);
      if (scrollToTop) scrollToTop();
      return true;
    } else if (errors?.status === 500) {
      setServerErrors([adminGenericError]);
      if (scrollToTop) scrollToTop();
      return true;
    }
    return false;
  };
}
